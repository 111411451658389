@import '../../shared/assets/css/mixins.scss';
@import '../../shared/assets/css/variables';

.footer {
    width: 100%;
    font-size: 14px;
    color: $white;
    background-color: $secondary;
}

.upper-container {
    @include flex-row;
    justify-content: space-between;
    padding: 4em;
    padding-bottom: 1em !important
}

.left-part {
    @include flex-row;
    @include font;
    justify-content: space-around;
    width: 100%;
    row-gap: 3em;
}

.column {
    width: 32%;

    & h5 {
        text-decoration: underline;
        font-size: 14px;
        font-weight: 700;
        line-height: 2.2rem;
        
        & .heading-color{
            color: $white;
        }
    }
    & p {
        font-size: 14px;
        font-weight: 400;
    }
}
.head-style{
    text-decoration: none !important;
    color: $white !important;
    border-bottom: none !important;
    display: inline !important;
}
.nav-list{
    & .solution-style{
        border-bottom: none;
        padding-top: 0;
        padding-bottom: 0;
        text-decoration: none
    }
}
.routelink-style{
    text-decoration: none !important;
    color: $white !important;
}

.mob-link{
    border-bottom: none;
    text-decoration: none;
}
.right-part {
    @include flex-column;
    align-items: center;
    justify-content: center;

    & h5 {
        text-align: center;
        padding-bottom: 0.2vh;
    }

    & .social-media {
        @include flex-row;

        & img {
            margin-right: 5%;
            height: 15%;
            width: 15%;
        }

        & .social-link-logo {
            height: 50px;
            width: 50px;
            margin-right: 10px;
        }
    }

    & .logo {
        margin-top: 4%;
        width: 75%;
        height: 60%;
    }
}

.trademark {
    @include flex-row;
    justify-content: center;
    border-top: 1px solid $white;
    margin: 0 auto;
    margin-right: -4vw;
    padding-top: 1%;
    font-weight: 400;
}

.mob-footer {
    @include display-none;
}

.nav-list {
    @include display-none;
    padding-left: 1rem;
    padding-right: 2.1rem;
}

ul {
    list-style-type: none;
}

.nav a,
.nav label {
    display: block;
    padding: .5rem;
    padding-left: 4px;
    width: 100%;
    color: $white;
    background-color: $secondary;
    border-bottom: 0.1px solid gray;
    -webkit-transition: all .25s ease-in;
    transition: all .25s ease-in;
}

.nav label {
    cursor: pointer;
}

.group-list a,
.group-list label {
    text-decoration: none;
    padding: 0.5%;
    padding-bottom: 0;
    background-color: $secondary;
    border: 0px;

}

.address-contact-container {
    @include flex-row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    padding: 2em 4em;
}
.location-content{
    @include flex-row;
    gap: 10px;
    .location-pin{
        height: 17px;
        width: 16px;
    }
    & p {
        font-size: 14px;
        line-height: 20px;
    }
}

// Hide nested lists
.group-list {
    height: 100%;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .1s ease-in-out;
    transition: max-height .1s ease-in-out;
    padding: 1% 3%;
}

.nav-list input[type=radio]:checked+label+ul {
    /* reset the height when checkbox is checked */
    max-height: 1000px;
   
}

.nav-list input[type=radio]:not(:checked)+label+ul {
    max-height: 0;
}

label>.arrows-footer {
    float: right;
    -webkit-transition: -webkit-transform .1s ease;
    transition: transform .1s ease;
    padding-top: 7px;
}

.nav-list input[type=radio]:checked+label>.arrows-footer {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

@media screen and (min-width: 1600px) {
    .column {
        & h5 {
            font-size: 1rem;
        }

        & p {
            font-size: 0.9rem;
        }
    }
    .location-content{
        .location-pin{
            height: 17px;
            width: 15px;
        }
        & p {
            font-size: 0.9rem !important;
            line-height: 1.4rem !important;
        }
    }
}

@media screen and (min-width: 1800px) {
    .column {
        & h5 {
            font-size: 1.2rem;
        }

        & p {
            font-size: 1rem;
        }
    }
    .location-content{
        .location-pin{
            height: 17px;
            width: 14px;
        }
        & p {
            font-size: 1rem !important;
            line-height: 1.5rem !important;
        }
    }
}

@media screen and (min-width: 2000px) {
    .column {
        & h5 {
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
        }

        & p {
            font-size: 1.2rem;
        }
    }
    .location-content {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.8rem !important;
        }

        .location-pin {
            height: 25px !important;
            width: 20px !important;
        }
    }
}

@media screen and (min-width: 2200px) {
    .column {
        & h5 {
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }

        & p {
            font-size: 1.4rem;
        }
    }
    .trademark {
        & p {
            font-size: 1.1rem;
        }
    }
    .right-part {
        & .social-media {
    
            & .social-link-logo {
                height: 50px;
                width: 50px;
                margin-right: 10px;
            }
        }
    }
    .location-content {
        & p {
            font-size: 1.4rem !important;
            line-height: 2rem !important;
        }
    }
}

@media screen and (min-width: 2600px) {
    .column {
        & h5 {
            font-size: 2rem;
            margin-bottom: 1.6rem;
        }

        & p {
            font-size: 1.7rem;
        }
    }
    .trademark {
        & p {
            font-size: 1.3rem;
        }
    }
    .right-part {
        & .social-media {
    
            & .social-link-logo {
                height: 5vh;
                width: 3vw;
                margin-top: 2vh;
                margin-right: 10px;
            }
        }
    }
    .location-content {
        & p {
            font-size: 1.7rem !important;
            line-height: 2.2rem !important;
        }

        .location-pin {
            height: 35px !important;
            width: 30px !important;
        }
    }
}

@media screen and (min-width: 3000px) {
    .column {
        & h5 {
            font-size: 2.1rem;
            margin-bottom: 1.6rem;
        }

        & p {
            font-size: 2.1rem;
        }
    }
    .trademark {
        & p {
            font-size: 1.5rem;
        }
    }
    .right-part {
        & .social-media {
    
            & .social-link-logo {
                height: 5vh;
                width: 3vw;
                margin-top: 2vh;
                margin-right: 10px;
            }
        }
    }
    .location-content {
        & p {
            font-size: 2.1rem !important;
            line-height: 2.5rem !important;
        }

        .location-pin {
            height: 40px;
            width: 30px;
        }
    }
}

@media screen and (min-width: 3800px) {
    .column {
        & h5 {
            font-size: 3.5rem;
        }

        & p {
            font-size: 3rem;
        }
    }
    .trademark {
        & p {
            font-size: 2.1rem;
        }
    }

    .right-part {
        & .social-media {
    
            & .social-link-logo {
                height: 5vh;
                width: 3vw;
                margin-top: 2vh;
                margin-right: 10px;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 870px) {
    .column {
        & p {
            font-size: 11px;
        }
    }
     .right-part {

        & h5 {
            @include text-center;
        }

        & .social-media {
            justify-content: center;

            & img {
                height: 8%;
                width: 8%;
            }

            & .social-link-logo {
                height: 30px;
                width: 30px;
                margin-right: 10px;
            }
        }
        & .logo {
            width: 90%;
            height: 70%;
            margin-left: 0 auto;
        }
    }
    .location-content{
        .location-pin{
            height: 14px;
            width: 12px;
        }
        & p {
            font-size: 11px;
            line-height: 15px;
        }
    }
}

@media screen and (max-width: 767px) {

    .nav-list {
        display: block;
    }

    .upper-container {
        display: block;
        padding: 1.5em 0em 0em 0em;

        .left-part {
            @include display-none;
        }

        .column {
            width: 100%;
        }

        .mob-footer {
            display: block;
        }

        & .right-part {

            & h5 {
                @include text-center;
            }

            & .social-media {
                justify-content: center;

                & img {
                    height: 8%;
                    width: 8%;
                }

                & .social-link-logo {
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                }
            }

            & .logo {
                width: 40%;
                height: 40%;
                margin-left: 0 auto;
            }
        }
    }
    .upper-container {
        padding: 5% 0% 5% 5%;
    }
    .trademark {
        font-size: 13px;
        text-align: center;
    }
    .group-list a{
        font-size: 14px;
    }
    .address-contact-container {
        justify-content: center !important;
    }
    .location-content{
        & p{
            font-size: 13px !important;
        }
        &img{
            height: 14px;
            width: 17px;
        }
    }
}
@media screen and (max-width: 425px) {
    .trademark {
        & p {
            margin: 2px;
            font-size: 11px;
        }
    }
}