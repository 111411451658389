@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';


.services-footer {
    background-image: url('../../../../shared/assets/images/services-footer-bg.jpg');
    padding: 4% 0;
    background-size: cover;
    background-repeat: no-repeat;
}

.header-services-footer {
    @include text-center;

    & p {
        font-size: 60px;
        color: $white;
        font-weight: 600;
        letter-spacing: 0.1em;
    }

    & button {
        margin-top: 1%;
        background-color: transparent;
        padding: 1% 1%;
        border-radius: 8px;
        color: $white;
        border: 1px solid $white;
        text-align: center;
    }

    & button:hover {
        background-color: $primary;
        border: 1px solid $primary;
    }
}

@media screen and (max-width: 1400px) {
    .header-services-footer {
        & p {
            font-size: 32px;
            ;
        }
    }
}

@media screen and (min-width: 2200px) {
    .header-services-footer {
        & button {
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: 2600px) {
    .header-services-footer {
        & button {
            font-size: 3rem;
        }
    }
}

@media screen and (min-width: 3000px) {
    .header-services-footer {
        & button {
            font-size: 3.5rem;
        }
    }
}

@media screen and (min-width: 3400px) {
    .header-services-footer {
        & button {
            font-size: 4rem;
        }
    }
}

@media screen and (min-width: 3800px) {
    .header-services-footer {
        & button {
            font-size: 4.1rem;
        }
    }
}