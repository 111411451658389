@import "../../shared/assets/css/mixins.scss";
@import "../../shared/assets/css/_variables.scss";
@import "../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";
.blog-container {
  @include flex-column;
  @include font;
  z-index: -1;
  padding-left: 10%;
}
@media (max-width: 767px) {
  .blog-container {
    padding-left: 0;
    margin-top: 7.5vh;
  }
}
.blog-image-container {
  background-image: url("../../shared/assets/images/blog-bg.jpg");
}
.blog-card-container {
  overflow: hidden;
  @include flex-column;
  padding: 6vh 0vw 8vh 2vw;
  background-color: $background-color;
  justify-content: center;
  & .blog-insight-header {
    color: $primary;
    text-align: center;
    & h2 {
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
    }
  }
  & .blog-card-row {
    @include flex-row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow-x: hidden;
    padding-top: 4%;
    padding-left: 0%;
    padding-bottom: 2%;
    row-gap: 4vh;
    & .blog-card-col {
      @include flex-column;
      font-weight: 600;
      margin-right: 2%;
      border: 0;
      background-color: $white;
      box-shadow: 1px 1px 10px $black-shadow;
      border-radius: 0%;
      padding: 0;
      width: 30%;
      justify-content: space-between;
      & .blog-card-body {
        padding: 3vh 3.5vh 2.5vh 3.5vh;
        & .blog-card-content {
          @include flex-column;
          flex-wrap: wrap;
          row-gap: 1.5vh;
          & p {
            margin: 0;
            font-size: 1rem;
          }
          & .blog-card-first-word {
            color: $primary;
          }
        }
      }
      & .read-btn-blog {
        position: relative;
        bottom: 8px;
        left: 1.7vw;
        padding-top: 2vh;
        width: 7vw;
        a {
          @include flex-row;
          row-gap: 2vw;
          align-items: center;
          font-size: 1.2rem;
          text-decoration: none;
          color: $primary;
          width: 100%;
        }

        .arrow-icon {
          margin-left: 10px;
          transition: transform 0.2s ease-in-out;
        }

        .effect.hover-effect:hover .arrow-icon {
          transform: translateX(5px);
        }
      }
    }
  }
}
.see-all-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6vh;
}

//Media Queries started from here
@media only screen and (min-width: 2001px) {
  .blog-card-container {
    overflow: hidden;
    @include flex-column;
    padding: 6vh 3vw 7vh 0vw;
    & .blog-insight-header {
      color: $primary;
      text-align: center;
      & h2 {
        font-size: 3.8rem;
        font-weight: 500;
        margin: 0;
      }
    }
    & .blog-card-row {
      justify-content: center;
      padding-top: 6vh;
      padding-left: 3vw;
      padding-bottom: 1vh;
      margin-right: -3vw;
      row-gap: 4vh;
      & .blog-card-col {
        font-weight: 600;
        margin-right: 2%;
        border: 0;
        background-color: $white;
        box-shadow: 1px 1px 10px $black-shadow;
        border-radius: 0%;
        padding: 0;
        width: 30%;
        & .blog-card-body {
          padding: 3vh 3.5vh 2.5vh 3.5vh;
          & .blog-card-content {
            @include flex-column;
            flex-wrap: wrap;
            row-gap: 1.5vh;
            & p {
              margin: 0;
              font-size: 1.3rem;
            }
            & .blog-card-first-word {
              color: $primary;
            }
          }
        }
        & .read-btn-blog {
          left: 2vw;
          width: 9vw;
          & a {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 2500px) {
  .blog-card-container {
    & .blog-card-row {
      & .blog-card-col {
        & .blog-card-body {
          & .blog-card-content {
            & p {
              font-size: 1.7rem;
            }

            & a {
              font-size: 1.4rem;
            }
          }
        }

        & .read-btn-blog {
          & a {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 3000px) {
  .blog-card-container {
    & .blog-card-row {
      & .blog-card-col {
        & .blog-card-body {
          & .blog-card-content {
            & p {
              font-size: 1.88rem;
            }

            & a {
              font-size: 1.66rem;
            }
          }
        }

        & .read-btn-blog {
          left: 1.6vw;
          & a {
            font-size: 1.8rem;
          }
        }
      }
    }

    & .see-all-btn {
      & button {
        font-size: 3rem;
      }
    }
  }
}

@media only screen and (min-width: 1501px) and (max-width: 2000px) {
  .blog-card-container {
    overflow: hidden;
    @include flex-column;
    padding: 6vh 3vw 7vh 0vw;
    & .blog-insight-header {
      color: $primary;
      text-align: center;
      & h2 {
        font-size: 3.5rem;
        font-weight: 500;
        margin: 0;
      }
    }
    & .blog-card-row {
      justify-content: center;
      padding-top: 6vh;
      padding-left: 3vw;
      padding-bottom: 1vh;
      margin-right: -3vw;
      row-gap: 4vh;
      & .blog-card-col {
        font-weight: 600;
        margin-right: 2%;
        border: 0;
        background-color: $white;
        box-shadow: 1px 1px 10px $black-shadow;
        border-radius: 0%;
        padding: 0 !important;
        width: 30%;
        & .blog-card-body {
          padding: 3vh 2vh 2.5vh 4vh !important;
          & .blog-card-content {
            @include flex-column;
            flex-wrap: wrap;
            row-gap: 1.5vh;
            & p {
              margin: 0;
              font-size: 1rem;
            }
            & .blog-card-first-word {
              color: $primary;
            }
          }
        }
        & .read-btn-blog {
          width: 10vw;
          & a {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1101px) and (max-width: 1500px) {
  .blog-card-container {
    overflow: hidden;
    @include flex-column;
    padding: 6vh 3vw 12vh 0vw;
    background-color: $background-color;
    justify-content: center;
    & .blog-insight-header {
      color: $primary;
      text-align: center;
      & h2 {
        font-size: 3.5rem;
        font-weight: 500;
        margin: 0;
      }
    }
    & .blog-card-row {
      @include flex-row;
      flex-wrap: wrap;
      justify-content: center;
      overflow-x: hidden;
      padding-top: 6vh;
      padding-left: 3vw;
      padding-bottom: 1vh;
      margin-right: -3vw;
      row-gap: 4vh;
      & .blog-card-col {
        font-weight: 600;
        margin-right: 2%;
        border: 0;
        background-color: $white;
        box-shadow: 1px 1px 10px $black-shadow;
        border-radius: 0%;
        padding: 0;
        width: 30%;
        & .blog-card-body {
          padding: 3vh 2vh 2.5vh 2vh;
          & .blog-card-content {
            @include flex-column;
            flex-wrap: wrap;
            row-gap: 1.5vh;
            & p {
              margin: 0;
              font-size: 1rem;
            }
            & .blog-card-first-word {
              color: $primary;
            }
          }
        }
        & .read-btn-blog {
          left: 1.8vw;
          width: 15vw !important;
          & a {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .blog-card-container {
    padding: 3vh 2vw 5vh 6vw;
    & .blog-insight-header {
      & h2 {
        font-size: 2rem;
        padding-left: 4vw;
      }
    }
    & .blog-card-row {
      padding-top: 3vh;
      padding-left: 0vw;
      margin-right: -2vw;
      margin-left: -4vw;
      row-gap: 2.5vh;
      column-gap: 0;

      & .blog-card-col {
        flex: 0 0 42%;
        & .blog-card-body {
          padding: 3vh 4vw 2vh 4vw;
          & .blog-card-content {
            row-gap: 0.4vh;
          }
        }
        & .read-btn-blog {
          left: 4vw;
          width: 24vw;

          & a {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .see-all-btn {
    padding-top: 3vh;
    padding-left: 2vh;
  }
}
@media only screen and (min-width: 481px) and (max-width: 600px) {
  .blog-card-container {
    padding: 3vh 4vw 5vh 0vw;
    & .blog-insight-header {
      & h2 {
        font-size: 2rem;
        padding-left: 4vw;
      }
    }
    & .blog-card-row {
      padding-top: 3vh;
      padding-left: 5vw;
      margin-right: -1vw;
      row-gap: 2.5vh;

      & .blog-card-col {
        flex: 0 0 90%;
        & .blog-card-body {
          padding: 3vh 4vw 2vh 4vw;
          & .blog-card-content {
            row-gap: 0.4vh;
          }
        }
        .read-btn-blog {
          left: 4vw;
          width: 28vw !important;
        }
      }
    }
  }
  .see-all-btn {
    padding-top: 3vh;
  }
}
@media only screen and (min-width: 312px) and (max-width: 480px) {
  .blog-card-container {
    padding: 3vh 1vw 3vh 0vw;
    & .blog-insight-header {
      & h2 {
        font-size: 2rem;
      }
    }
    & .blog-card-row {
      padding-top: 3vh;
      padding-left: 1vw;
      margin-right: -2vw;
      row-gap: 2.5vh;

      & .blog-card-col {
        flex: 0 0 90%;
        & .blog-card-body {
          padding: 3vh 4vw 2vh 4vw;
          & .blog-card-content {
            row-gap: 0.4vh;
          }
        }
       .read-btn-blog {
          left: 6vw;
          width: 42vw !important;
        }
      }
    }
  }
  .see-all-btn {
    padding-top: 2vh;
  }
}
