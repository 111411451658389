@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/mixins.scss';

.bradcrumb-hover {
    cursor: default;
}

// css for owl stage outer
.owl-stage-outer {
    padding: 0% 5%
}

.owl-carousel.owl-loaded {
    padding-left: 5%;
}

.page-container-SE {
    @include font;
    background-color: $background-color;
    overflow-x: hidden;
    padding-left: 10%;
    @include position-rel;

    .blue-box-bg-SE {
        @include display-none;
        @include position-abs;
        top: 3%;
        right: 0%;
        z-index: -1;
        transform: rotate(90deg);
        height: 27%;
    }

    .circles-SE {
        @include display-none;
        @include position-abs;
        top: 20%;
        right: 3%;
        z-index: -1;
    }
}



.about-our-service {
    padding: 3% 6%;
    @include flex-row;
    height: 70%;
    @include position-rel;
    justify-content: space-between;
    z-index: 100;

    & .left-section {
        @include position-rel;
        height: 80%;
        width: 50%;
        z-index: 0;
        @include flex-column;
        justify-content: space-between;
        margin: auto 0;

        & h3 {
            @include display-none;
        }

        & h5 {
            color: $primary;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0.1rem;
        }

        &>h2 {
            @include text-stroke;
            font-size: 5rem;
            font-weight: 700;
            letter-spacing: 0.1rem;
            line-height: 6rem;
            width: 100%;
            text-align: justify;
            color: transparent;
        }

        & p {
            font-weight: 500;
            font-size: 22px;
            padding-top: 3%;

            & .blue-box {
                @include position-abs;
                top: -7%;
                height: 10%;
                right: 3%;
            }

            & .red-box {
                @include display-none;
            }

            & .red-box-sq {
                @include position-abs;
                height: 11%;
                width: 11%;
                left: 53%;
                bottom: -8%;
                background-color: $primary;
            }
        }

        &>img {
            @include position-abs;
            top: 0%;
            right: -7%;
            height: 100%;
            z-index: -1;
        }
    }

    & .right-section {
        width: 38%;
        height: 100%;

        &>img {
            width: 100%;
            height: 100%;
        }
    }
}

.lower-div {
    &>h2 {
        @include text-center;
        color: $primary;
        font-weight: 500;
        font-size: 3.2rem;
        letter-spacing: 0.1rem;
        padding-top: 3%;
    }

    .technologies {
        padding-top: 3%;
        background-color: $white;
        overflow-y: hidden;

        & h2 {
            @include text-center;
            font-weight: 600;
            letter-spacing: 0.1rem;
        }

        .images-Tech {
            padding-top: 1%;
            display: flex;
            flex-wrap: wrap;
            @include flex-row;
            justify-content: space-evenly;
            padding-bottom: 4%;
            align-items: center;
        }

        .image-wrapper {
            position: relative;
            margin: 10px;
        }

        .hover-effect {
            width: 100px; // Set the desired width
            height: auto;
            transition: transform 0.3s ease-in-out;
        }

        .text-overlay {
            position: absolute;
            left: 0;
            right: 0;
            background-color: transparent;
            color: gray;
            text-align: center;
            padding: 10px;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        .image-wrapper:hover .hover-effect {
            transform: scale(1.1); // Set the desired scale on hover
        }

        .image-wrapper:hover .text-overlay {
            opacity: 1;
        }
    }
}

.header-footer {
    padding-top: 10%;
}

.owl-carousel-mob {
    @include display-none;
}

@media screen and (min-width: 1800px) {
    .about-our-service {
        & .left-section {

            & h5,
            p {
                font-size: 1.8rem;
            }

            & h2 {
                font-size: 6.5rem;
                line-height: 7rem;
            }

            &>img {
                height: 110%;
            }

            & p {
                & .blue-box {
                    top: -7%;
                    height: 15%;
                    right: 3%;
                }

                & .red-box-sq {
                    bottom: -18%;
                    height: 15%;
                    width: 15%;
                }
            }

        }
    }
}

@media screen and (min-width: 2500px) {
    .about-our-service {
        & .left-section {
            height: 58vh;

            & h5 {
                font-size: 4.5rem;
            }

            & p {
                font-size: 3rem;
            }

            & h2 {
                font-size: 8rem;
                line-height: 7rem;
            }

            & p {
                & .blue-box {
                    top: -7%;
                    height: 15%;
                    right: 3%;
                }

                & .red-box-sq {
                    bottom: -18%;
                    height: 15%;
                    width: 15%;
                }
            }

        }
    }

    .lower-div {

        .technologies {
            & h2 {
                font-size: 3rem;
            }

            .text-overlay {
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (min-width: 3000px) {
    .about-our-service {
        & .left-section {
            height: 58vh;

            & h5 {
                font-size: 5.8rem;
            }

            & p {
                font-size: 3.5rem;
            }

            & h2 {
                font-size: 10rem;
                line-height: 9rem;
            }

            & p {
                & .blue-box {
                    top: -7%;
                    height: 15%;
                    right: 3%;
                }

                & .red-box-sq {
                    bottom: -18%;
                    height: 15%;
                    width: 15%;
                }
            }

        }
    }

    .lower-div {
        &>h2 {
            font-size: 5rem;
        }

        .technologies {
            & h2 {
                font-size: 4rem;
            }

            .text-overlay {
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (max-width: 1520px) {
    .right-section {
        width: 42% !important;
        height: 100%;
    }

    .images-Tech {
        display: none !important;
    }

    .owl-carousel-mob {
        padding: 1% 10%;
        display: block;

        & img {
            width: 40% !important;
            height: 50%;
        }
    }

    .page-container-SE {
        & .about-our-service {
            & .left-section {

                & h2 {
                    font-size: 4rem;
                    line-height: 5rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1050) and (max-width: 1500px) {
    .image-container {
        height: 60vh;
    }
}

@media screen and (max-width: 1200px) {
    .page-container-SE {
        & .about-our-service {
            padding-top: 5vh;

            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .page-container-SE {
        & .about-our-service {
            padding-top: 5vh;

            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .page-container-SE {
        & .about-our-service {
            padding-top: 5vh;

            & .left-section {
                & h2 {
                    font-size: 3rem;
                    line-height: 3.5rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
    .page-container-SE {
        & .about-our-service {
            padding-top: 5vh;

            & .left-section {
                & h2 {
                    font-size: 3rem;
                    line-height: 3.5rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-container-SE {
        padding-left: 0%;
        margin-top: 7.5vh;

        & .blue-box-bg-SE {
            display: block;
        }

        & .circles-SE {
            display: block;
        }

        & .about-our-service {
            .left-section {
                width: 100%;

                & h2 {
                    font-size: 70px;
                    line-height: 4.5rem;
                }

                & h5 {
                    @include display-none;
                }

                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;

                }


                & p {
                    margin-top: 4%;
                    position: relative;
                    background-image: url('../../shared/assets/images/se-img.png');
                    background-color: gray;
                    background-size: cover;
                    color: $white;
                    @include text-center;
                    width: 100%;
                    height: fit-content;
                    padding: 30% 10%;
                    font-weight: 500;
                    z-index: 200;
                    font-size: 1.7rem;

                    & .blue-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        right: -9%;
                        top: -3%
                    }

                    & .red-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        left: -9%;
                        bottom: -4%
                    }

                    & .red-box-sq {
                        @include display-none;
                    }
                }

                & p::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    z-index: -1;
                }

                &>img {
                    @include display-none;
                }

            }

            .right-section {
                @include display-none;
            }


        }

        & .lower-div {
            & .technologies {
                margin-top: 5%;

                & .images-Tech {
                    @include display-none;
                }

                & .owl-carousel-mob {
                    padding: 0% 10%;
                    display: block;

                    & img {
                        width: 50%;
                        height: 50%;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 550px) {
    .page-container-SE {
        & .about-our-service {
            & .left-section {
                & h2 {
                    font-size: 60px;
                }

                & h3 {
                    font-size: 18px;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 468px) {
    .page-container-SE {
        .about-our-service {
            & .left-section {
                & h2 {
                    font-size: 2.8rem;
                    line-height: 3rem;
                }

                & h3 {
                    font-size: 18px;
                }

                & p {
                    font-size: 18px;
                }
            }
        }

        & .lower-div {
            padding-top: 8%;

            & h2 {
                font-size: 36px;
            }

            & .grid-card {
                & p {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .page-container-SE {
        & .about-our-service {

            & .left-section {
                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;
                }

                & p {
                    padding: 30% 5%;
                    font-weight: 500;
                    z-index: 200;
                    font-size: 1.2rem;
                }
            }

            & .blue-box-bg-SE {
                top: 2%;
                height: 28%;
            }
        }

        & .lower-div {
            & .page-container-Cards {
                padding-bottom: 5em;
            }

            & .owl-carousel-mob {
                & img {
                    height: 40%;
                    width: 40%;
                }
            }
        }

    }
}

@media screen and (min-width:401px) and (max-width: 767px) {
    .owl-carousel-mob {
        & img {
            width: 50% !important;
        }
    }
}

@media screen and (max-width: 400px) {
    .page-container-SE {
        & .about-our-service {
            & .blue-box-bg-SE {
                height: 28%;
            }

            & .circles-SE {
                top: 24%;
                right: 2.5%;
            }
        }
    }

    .owl-carousel-mob {
        & img {
            width: 60% !important;
        }
    }
}

@media screen and (min-width:768px) and (max-width: 1024px) {
    .page-container-SE {
        & .about-our-service {
            & .left-section {
                & p {
                    font-size: 1.1rem;
                }
            }
        }
    }
}