@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap");
@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/mixins.scss";

.page-container-services {
  @include flex-column;
  background-color: $background-color;
  padding: 2rem 0rem;
  font-family: Open Sans;
  font-weight: 500;
  position: relative;
  overflow-x: hidden;
}
.heading-services {
  @include height-fit-content;
  & h1 {
    font-size: 3.2rem;
    margin: 0;
    @include text-center;
    color: $primary;
    font-weight: 500;
  }

  & div {
    @include text-center;
    padding-top: 1%;

    & p {
      font-size: 18px;
      @include height-fit-content;
      @include text-center;
      padding-bottom: 0.6%;
      margin: 0rem auto;
      width: 80%;
    }
  }
}
.gif-container-services {
  @include flex-row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 3%;
  margin: 0 auto;
  align-items: center;
  row-gap: 3vh;
  padding-left: 6vw;

  & a {
    width: 30%;
    text-decoration: none;
    color: black;
    & div {
      @include text-center;
      width: fit-content;
      @include flex-column;
      row-gap: 2vh;
      justify-content: center;
      align-items: center;

      & .gif0 {
        width: 80%;
      }

      & .gif1 {
        width: 90%;
      }

      & .gif2 {
        width: 55%;
      }

      & .gif3 {
        width: 60%;
      }

      & .gif4 {
        width: 67%;
      }

      & p {
        font-size: 24px;
        width: 50%;
        transition: transform 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
      }
      & p:hover {
        transform: scale(1.03);
        text-shadow: 12px 8px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
  & :nth-child(2) {
    margin-top: 2.5%;
  }
  & :nth-child(4) {
    margin-left: 14%;
    margin-top: 0.5%;
  }

  & :nth-child(5) {
    margin-right: 17%;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1700px) {
  .gif-container-services {
    padding-left: 0;
    & a {
      width: 25%;
      & div {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        & .gif0 {
          width: 80%;
        }

        & .gif1 {
          width: 90%;
        }

        & .gif2 {
          width: 55%;
        }

        & .gif3 {
          width: 80%;
        }

        & .gif4 {
          width: 105%;
        }
        & p {
          font-size: 24px;
          width: 50%;
          @include text-center;
        }
      }
    }
    & :nth-child(2) {
      margin-top: 2.3%;
    }
    & :nth-child(4) {
      margin-left: 7%;
    }

    & :nth-child(5) {
      margin-top: -2%;
      margin-right: 4%;
    }
  }
}
@media screen and (min-width: 2500px) {
  .heading-services {
    & h1 {
      font-size: 3rem;
    }
    & div {
      & p {
        font-size: 1.5rem;
      }
    }
  }
}
@media screen and (min-width: 3000px) {
  .heading-services {
    & h1 {
      font-size: 6rem;
    }
    & div {
      & p {
        font-size: 2.5rem;
      }
    }
  }
  .gif-container-services {
    padding-left: 0;
    & a {
      width: 25%;
      & div {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        & .gif0 {
          width: 90%;
        }

        & .gif1 {
          width: 100%;
        }

        & .gif2 {
          width: 65%;
        }

        & .gif3 {
          width: 80%;
        }

        & .gif4 {
          width: 87%;
        }
        & p {
          font-size: 1.8rem;
          width: 100%;
          @include text-center;
        }
      }
    }
    & :nth-child(2) {
      margin-top: 2.3%;
    }
    & :nth-child(4) {
      margin-left: 7%;
    }

    & :nth-child(5) {
      margin-top: 1%;
      margin-right: 4%;
    }
  }
}
@media screen and (max-width: 1100px) {
  .gif-container-services {
    padding-left: 0;
    & a {
      width: 30%;
      & div {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        row-gap: 1vh !important;
        & .gif0 {
          width: 70%;
        }

        & .gif1 {
          width: 80%;
        }

        & .gif2 {
          width: 45%;
        }

        & .gif3 {
          width: 70%;
        }

        & .gif4 {
          width: 95%;
        }
        & p {
          font-size: 1.1rem;
          line-height: 1.3rem;
          width: 60%;
          @include text-center;
        }
      }
    }
    & :nth-child(2) {
      margin-top: 2.3%;
    }
    & :nth-child(4) {
      margin-left: 7%;
    }

    & :nth-child(5) {
      margin-top: -3%;
      margin-right: 6%;
    }
  }
}

@media screen and (max-width: 767px) {
  .page-container-services {
    @include height-fit-content;

    & .red-vert-line {
      @include display-none;
    }
  }
  .heading-services {
    @include height-fit-content;
    & h1 {
      font-size: 44px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      letter-spacing: 0.1rem;
    }
    & div {
      & p {
        font-size: 20px;
        font-weight: 500;
        width: 100%;
        @include text-center;
        padding: 1% 0%;
        @include height-fit-content;
      }

      & button {
        @include display-none;
      }
    }
  }
  .gif-container-services {
    padding-top: 6%;
    display: block;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    & :nth-child(4) {
      margin-left: auto;
      margin-top: auto;
    }

    & :nth-child(5) {
      margin-left: auto;
    }
    & div {
      width: 75%;
      padding-top: 5%;
      margin-left: auto;
      margin-top: auto;
      margin-right: auto;
      row-gap: 0 !important;

      & .gif0 {
        width: 100%;
      }

      & .gif1 {
        width: 100%;
      }

      & .gif2 {
        width: 70%;
      }

      & .gif3 {
        width: 90%;
      }

      & .gif4 {
        width: 85% !important;
      }
      & p {
        font-size: 28px;
        width: 100%;
        padding-top: 3%;
        @include text-center;
      }
    }

    .btn-services {
      display: block;
      @include flex-row;
      justify-content: center;
    }

    & .btn-services button {
      border: 3px solid $secondary;
      border-radius: 8px;
      padding: 2%;
      padding-top: 3%;
      padding-bottom: 3%;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 400px) {
  .heading-services {
    & h1 {
      font-size: 32px;
    }
    & div {
      & p {
        font-size: 16px;
      }
    }
  }
  .gif-container-services {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    & div {
      padding-top: 7%;
      & p {
        font-size: 22px;
      }
    }
  }
}

@media screen and (min-width:1400px) and (max-width:1999px) {
  .heading-services  {
      & div{
        & p{
          font-size: 1.5rem !important;
        }
      }
  }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  .heading-services  {
      & div{
        & p{
          font-size: 1.1rem !important;
        }
      }
  }
}
@media screen and (min-width:2000px) and (max-width:2400px) {
  .heading-services  {
      & div{
        & p{
          font-size: 1.8rem !important;
        }
      }
  }
}
@media screen and (min-width:1300px) and (max-width:1699px) {
  .heading-services  {
      & div{
        & p{
          font-size: 18px !important;
        }
      }
  }
}
@media screen and (min-width:2401px) and (max-width:2800px) {
  .heading-services  {
      & div{
        & p{
          font-size: 1.9rem !important;
        }
      }
  }
}
@media screen and (min-width:2801px) and (max-width:2999px) {
  .heading-services  {
      & div{
        & p{
          font-size: 2.2rem !important;
        }
      }
  }
}
