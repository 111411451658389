@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/mixins.scss";
@import "../../../../shared/assets/css/common.scss";

.company-overview-home{
    font-family: Open Sans;
    background-color: white;
    position: relative;
    padding-bottom: 4px;
    padding-left: 5%;
    width:auto;

    & > h1 {
        color: $primary;
        font-size: 3.2rem;
        line-height: 2.5rem;
        text-align: center;
        font-weight: 500;
        padding: 2rem 0rem 0rem 0rem;
      }
    
      & > p {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding: 1rem 6rem 1rem 0rem;
      }
}

@media screen and (min-width: 312px)and (max-width: 400px){
 .company-overview-home{
  & > h1 {
    color: $primary;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    font-weight: 500;
    padding: 2rem 1rem 0rem 0rem;
  }
  
  & > p{
    font-size: 16px;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    padding: 1rem 2rem 1rem 1rem;
    font-weight: 500;
  }
 }
}

@media screen and (min-width: 401px) and (max-width: 767px){
  .company-overview-home{
   & > h1 {
     color: $primary;
     font-size: 2.75rem;
     line-height: 3rem;
     text-align: center;
     font-weight: 500;
     padding: 2rem 1rem 0.5rem 0rem;
   }
   
   & > p{
     font-size: 20px;
     text-align: justify;
     hyphens: auto;
     -webkit-hyphens: auto;
     word-spacing: -0.05em;
     padding: 0rem 2rem 1rem 1rem;
     font-weight: 500;
   }
  }
 }

@media screen and (min-width: 768px) and (max-width: 1120px){
  .company-overview-home{
   & > h1 {
     color: $primary;
     font-size: 3.2rem;
     line-height: 2.5rem;
     text-align: center;
     font-weight: 500;
     padding: 2rem 1rem 0.5rem 0rem;
   }
   
   & > p{
     font-size: 1.1rem;
     line-height: 1.3rem;
     text-align: center;
     padding: 0.3rem 5rem 1rem 1rem;
     font-weight: 500;
   }
  }
 }

@media screen and (min-width: 2400px){
  .company-overview-home{
   & > h1 {
     color: $primary;
     font-size: 3rem;
     line-height: 2.5rem;
     text-align: center;
     font-weight: 500;
     padding: 3.5rem 1rem 1.5rem 0rem;
   }
   
   & > p{
     font-size: 1.5rem;
     text-align: center;
     padding: 0rem 10rem 3rem 1rem;
     font-weight: 500;
   }
  }
 }

@media screen and (min-width: 3000px){
  .company-overview-home{
   & > h1 {
     color: $primary;
     font-size: 6rem;
     line-height: 2.5rem;
     text-align: center;
     font-weight: 500;
     padding: 4.6rem 1rem 1.5rem 0rem;
   }
   
   & > p{
     font-size: 2.5rem;
     text-align: center;
     padding: 2.2rem 11rem 3rem 0rem;
     font-weight: 500;
   }
  }
 }

@media screen and (min-width:1400px) and (max-width:1999px) {
    .company-overview-home{
      & > p{
        font-size:1.5rem !important;
      }
    }
 }
@media screen and (min-width:2000px) and (max-width:2400px){
  .company-overview-home{
   & > p{
     font-size: 1.8rem !important;
   }
  }
}
@media screen and (min-width:1300px) and (max-width:1699px){
  .company-overview-home{
   & > p{
     font-size: 1.125rem !important;
   }
  }
}
@media screen and (min-width:2401px) and (max-width:2800px){
  .company-overview-home{
   & > p{
     font-size: 1.9rem !important;
   }
  }
}
@media screen and (min-width:2801px) and (max-width:2999px){
  .company-overview-home{
   & > p{
     font-size: 2.2rem !important;
   }
  }
}