@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/mixins.scss';

.breadcrumb-hover {
    cursor: default;
}
.page-container-Operations {
    @include font;
    background-color: $background-color;
    overflow-x: hidden;
    padding-left: 10%;
    @include position-rel;

    .image-container {
        background-image: url('../../shared/assets/images/operations-bg.jpg');

    }

    & .lower-div {

        & .page-container-Cards {
            & :nth-child(6) p {
                width: 50%;
            }
        }

        & .teams-accreditation {
            background-color: $white;
            padding: 2.5rem 0rem;

            &>h2 {
                @include text-center;
                font-weight: 600;
                font-size: 46px;
            }

            &>p {
                @include text-center;
                font-weight: 500;
                font-size: 22px;
                padding: 2.5rem 0rem 3rem 0rem;
            }

            & .images-teams-accreditation {
                @include flex-row;
                justify-content: space-around;

                & img {
                    height: 25%;
                    width: 25%;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .page-container-Operations {
        & .about-our-service {
            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }

                & p {
                    font-size: 21px;
                }
            }
        }

        & .lower-div {
            & .teams-accreditation {
                & .images-teams-accreditation {
                    & img {
                        height: 35%;
                        width: 35%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 2500px) {
    .page-container {
        .about-our-service {
            & .left-section {
                height: 58vh;

                & h5 {
                    font-size: 4.5rem;
                }

                & p {
                    font-size: 2.5rem;
                }

                & h2 {
                    font-size: 8rem;
                }

                & p {
                    & .blue-box {
                        top: -7%;
                        height: 15%;
                        right: 3%;
                    }

                    & .red-box-sq {
                        bottom: -18%;
                        height: 15%;
                        width: 15%;
                    }
                }

            }
        }

        .lower-div {
            .teams-accreditation {
                &>h2 {
                    font-size: 3rem;
                }

                &>p {
                    font-size: 2rem;
                }
            }
        }
    }
}

@media screen and (min-width: 3000px) {
    .page-container-Operations {
        .about-our-service {
            & .left-section {
                height: 58vh;

                & h5 {
                    font-size: 5.8rem;
                }

                & p {
                    font-size: 3.2rem;
                }

                & h2 {
                    font-size: 10rem;
                    line-height: 8rem;
                }

                & p {
                    & .blue-box {
                        top: -7%;
                        height: 15%;
                        right: 3%;
                    }

                    & .red-box-sq {
                        bottom: -18%;
                        height: 15%;
                        width: 15%;
                    }
                }

            }
        }

        .lower-div {
            .teams-accreditation {
                &>h2 {
                    font-size: 5rem;
                }

                &>p {
                    font-size: 3rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .page-container-Operations {
        & .about-our-service {
            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .page-container-Operations {
        & .about-our-service {
            & .left-section {
                & h2 {
                    font-size: 3rem;
                    line-height: 3.5rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .page-container-Operations {
        & .about-our-service {
            padding:6% 6%;
            & .left-section {
                & h2 {
                    &>h2 {
                        @include text-center;
                        font-weight: 600;
                        font-size: 46px;
                    }

                    &>p {
                        @include text-center;
                        font-weight: 500;
                        font-size: 22px;
                        padding: 2.5rem 0rem 3rem 0rem;
                    }

                    font-size: 56px;
                    line-height: 4rem;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-container-Operations {
        padding-left: 0%;
        margin-top: 7.5vh;
        & .image-container {
            & .heading-desc {
                @include display-none;
            }
        }

        & .about-our-service {

            & .left-section {
                @include position-rel;
                width: 100%;

                & h5 {
                    @include display-none;
                }

                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;

                    & img {
                        padding-left: 3%;
                        padding-bottom: 1%;
                    }
                }

                & p {
                    margin-top: 4%;
                    position: relative;
                    background-image: url('../../shared/assets/images/operations-img.png');
                    background-color: gray;
                    background-size: cover;
                    color: $white;
                    @include text-center;
                    width: 100%;
                    height: fit-content;
                    padding: 30% 10%;
                    font-weight: 500;
                    z-index: 200;
                    font-size: 1.7rem;

                    & .blue-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        right: -9%;
                        top: -3%
                    }

                    & .red-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        left: -9%;
                        bottom: -4%
                    }

                    & .red-box-sq {
                        @include display-none;
                    }
                }

                & p::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    /* Adjust the color and opacity as needed */
                    z-index: -1;
                    /* Adjust the z-index to position the overlay above the background image */
                }

                &>img {
                    @include display-none;
                }
            }

            & .right-section {
                @include display-none;
            }
        }

        & .lower-div {
            & .teams-accreditation {

                &>h2 {
                    font-size: 36px;
                }

                &>p {
                    font-size: 16px;
                    padding: 1.5rem 0rem 2rem 0rem;
                }

                & .images-teams-accreditation {
                    & img {
                        height: 45%;
                        width: 45%;
                    }
                }
            }
        }
    }
    .ops-mob-bg {
        background-color: $blue-shadow;
        width: 60vw;
        height: 10vh;
        right: -9%;
        z-index: -1;
        overflow-x: hidden;
      }
      
      .ops-mob-bg img {
        width: 15%;
        height: 15%;
        bottom: 1em;
        right: 1em;
      }
}

@media screen and (max-width: 450px) {
    .page-container-Operations {
        & .about-our-service {

            & .left-section {
                width: 100%;

                & h5 {
                    @include display-none;
                }

                & h2 {
                    font-size: 50px;
                    line-height: 3.5rem;
                }

                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;

                    & img {
                        padding-left: 3%;
                        padding-bottom: 1%;
                    }
                }

                & p {
                    font-size: 1rem;
                }

            }

            & .right-section {
                @include display-none;
            }
        }

        & .lower-div {
            & h2 {
                font-size: 36px;
            }

            & .page-container-Cards {
                padding-bottom: 5em;
            }

            & .teams-accreditation {
                & .images-teams-accreditation {
                    & img {
                        height: 65%;
                        width: 65%;
                    }
                }
            }

        }
    }
}
@media screen and (min-width:768px) and (max-width: 800px) {
    .page-container-Operations {
        & .about-our-service {
            & .left-section {
                & p {
                    font-size: 1.1rem;
                }
            }
        }
    }
}
