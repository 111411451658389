@import '../../../../shared/assets/css/variables';

.card {
  width: 30vw;
  height: 40vh;
  perspective: 1000px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1.5s;
  cursor: pointer;
  box-shadow: 4px 4px 20px 0px $black-shadow;
  border: 3px solid $grey;
  border-radius: 10px;
  flex: 0 0 30.3%;
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: $stroke;
  text-align: center;
  margin-top: 0.3em;
}

.card-front {
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 10px;
  row-gap: 10px;
}

.card img {
  height: 50%;
  width: 60%;
}

.main-container {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  gap: 2.5em;
  flex-wrap: wrap;
}

.card-front {
  transform: rotateY(0deg);
}

.card-back {
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px $black-shadow;
  transform: rotateY(180deg);
  width: 101%;
  overflow: hidden;
  padding: 2em !important;  
  background-image: url(../../../../shared/assets/images/card-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border: none;
}

.overlay-text {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $white;
  padding-top: 1em;
}

.overlay-text .back-header {
  font-size: 24px;
  font-weight: 500;
  color: $white;
  text-align: center;
}

.overlay-text .back-content {
  font-size: 16px;
  font-weight: 400;
  color: $white;
}

.card.flipped {
  transform: rotateY(180deg);
  border: none;
}
@media (min-width:3001px) and (max-width:3500px) {
  .card-front {
    padding: 1.5em;
  }

  .card-text {
    font-size: 2.8rem;
    font-weight: 600;
  }

  .overlay-text .back-header {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 5.5rem;
  }

  .overlay-text .back-content {
    font-size: 2.2rem;
    font-weight: 500;
  }

  .card-back {
    width: 100%;
    height: 100%;
  }
}
@media (min-width:2601px) and (max-width:3000px) {
  .card-front {
    padding: 1.5em;
  }

  .card-text {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .overlay-text .back-header {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 5rem;
  }

  .overlay-text .back-content {
    font-size: 1.4rem;
    font-weight: 500;
  }

  .card-back {
    width: 100%;
    height: 100%;
  }
}

@media (min-width:2001px) and (max-width:2600px) {
  .card-front {
    padding: 1.5em;
  }

  .card-text {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .overlay-text .back-header {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 3rem;
  }

  .overlay-text .back-content {
    font-size: 1.4rem;
    font-weight: 500;
  }

  .card-back {
    width: 100%;
    height: 100%;
  }
}

@media (min-width:1500px) and (max-width:2000px) {
  .card-front {
    padding: 2em;
  }

  .card-text {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .overlay-text .back-header {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 2.1rem;
  }

  .overlay-text .back-content {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .card-back {
    width: 100%;
    height: 100%;
  }
}
@media (min-width:1200px) and (max-width:1399px) {
  .card{
    width: 32vw;
    height: 40vh;
  }
  .card img {
    width: 50%;
    height: 60%;
  }
  .card-front {
    padding: 2.2em;
    height: 100%;
  }
  .card-text {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .overlay-text .back-header {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .overlay-text .back-content {
    font-size: 0.9rem;
    font-weight: 500;
  }
}
@media (min-width:1041px) and (max-width:1199px){
  .card {
    width: 80%;
    height: 30vh;
    flex: 0 0 30%;
  }

  .card img {
    width: 75%;
    height: 65%;
  }

  .card-front {
    padding: 2em;
  }
  .overlay-text .back-header {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.4rem;
  }

  .overlay-text .back-content {
    font-size: 0.8rem;
    font-weight: 500;
  }
}
@media (min-width:769px) and (max-width:1040px){
  .main-container {
    display: none;
  }
}
@media (min-width:481px) and (max-width:768px) {
  .card {
    width: 100%;
    height: 30vh;
    flex: 0 0 43%;
  }

  .card img {
    width: 85%;
    height: 70%;
  }

  .card-front {
    padding: 1.3em;
  }

  .card-text {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }

  .main-container {
    display: none;
  }
}
@media (min-width:312px) and (max-width:480px) {
  .card {
    width: 100%;
    height: 25vh;
    flex: 0 0 47%;
  }

  .card img {
    width: 60%;
    height: 50%;
  }

  .card-front {
    padding: 2em;
  }

  .card-text {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
  }

  .main-container {
    display: none;
    padding: 0.5em 0;
    gap: 0.8em;
  }
}
@media screen and (min-width:1500px) and (max-width:1800px) {
  .overlay-text{
    & .back-content{
      font-size: 1rem !important;
    }
    .overlay-text {
      & .back-header {
        font-size: 1.6rem;
        margin-bottom: 1.3rem !important;
    }
  }
}
}

@media screen and (min-width:2000px) and (max-width:2100px) {
  .overlay-text{
    & .back-content{
      font-size: 1.27rem !important;
    }
    & .back-header {
        margin-bottom: 2.1rem !important;
    }
}
}

@media screen and (min-width:2200px) and (max-width:2400px) {
  .overlay-text{
    & .back-content{
      font-size: 1.5rem !important;
    }
    & .back-header {
        margin-bottom: 2.5rem !important;
    }
}
}

@media screen and (min-width:2401px) and (max-width:2600px) {
  .overlay-text{
    & .back-content{
      font-size: 1.6rem !important;
    }
    & .back-header {
        margin-bottom: 2.7rem !important;
    }
}
}

@media screen and (min-width:2601px) and (max-width:3000px) {
  .overlay-text{
    & .back-content{
      font-size: 1.8rem !important;
    }
    & .back-header {
        font-size: 2.4rem;
        margin-bottom: 2.9rem !important;
    }
}
}

@media screen and (min-width:3001px) {
  .overlay-text{
    & .back-content{
      font-size: 2rem !important;
    }
    & .back-header {
      font-size: 2.5rem;
        margin-bottom: 2.3rem !important;
    }
}
}

@media screen and (min-width:1400px) and (max-width:1499px){
  .overlay-text{
    & .back-content{
      font-size: 1rem !important;
    }
    & .back-header {
      font-size: 1.6rem;
        margin-bottom: 1.3rem !important;
    }
}
}