@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/mixins.scss';

.page-container-Cards {
    display: grid;
    grid-template-columns: repeat(3, 27%);
    grid-template-rows: repeat(2, 45%);
    gap: 5%;
    padding-left: 10%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    padding-right: 2.5%;

    & a {
        text-decoration: none;
    }
}

.grid-card {
    width: 100%;
    height: 100%;
    border: 5px solid $grey;
    border-radius: 8px;
    background-color: $white;
    @include text-center;
    padding: 10% 6% 0 6%;
    @include flex-column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    & img {
        margin-left: auto;
        margin-right: auto;
        width: 45%;
        height: 50%;
       
    }

    & p {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8%;
        font-weight: 600;
        font-size: 1.7rem;
        color:$black;
    }

    & .image-cards3 {
        width: 60%;
    }
}

.grid-card:hover {
    transform: translateY(-15px);
    box-shadow: 2px 2px 15px $black-shadow;

}

@media screen and (min-width: 3001px) and (max-width: 3500px) {
    .grid-card p {
        font-size: 2.5rem;
        line-height: 3.2rem;
        padding: 15% 6% 0 6%;
    }

}

@media screen and (min-width: 2600px) and (max-width: 3000px) {
    .grid-card p {
        font-size: 2rem;
        line-height: 2.3rem;
        padding: 15% 6% 0 6%;
    }

}

@media screen and (min-width: 1025px) and (max-width: 1500px) {
    .grid-card p {
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding: 15% 6% 0 6%;
    }

}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .grid-card p {
        font-size: 1rem;
        line-height: 1.3rem;
        padding: 15% 6% 0 6%;
    }
}


@media screen and (max-width: 767px) {
    .page-container-Cards {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: none;
        padding-left: 5%;
        padding-right: 10%;
        padding-bottom: 15%;
    }

    .grid-card {
        padding: 10% 0;

        & img {
            height: 50%;
            width: 50%;
        }

        & p {
            font-size: 18px;
        }
    }
}