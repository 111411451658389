@import "../../../../shared/assets/css/mixins.scss";
@import "../../../../shared/assets/css/variables";

.page-container-CaseStudy {
  overflow-x: hidden;
  @include position-rel;
  background-color: $background-color;
  @include flex-row;
  @include height-fit-content;
  align-items: center;
  @include font;
}

.main-container-CaseStudy {
  @include flex-row;
  width: 100%;
  @include height-fit-content;
  padding: 4% 1.8% 3% 7%;
  @include font;
  font-weight: 600;
  background-color: $white;
}
.carousel .slider {
  margin: 0;
  position: relative;
  list-style: none;
  width: 100%;
  // padding-bottom: 1vh;
}
.btn-container {
  @include flex-row;
  column-gap: 1.5vw;
  padding-top: 2vh;
}
.casestudy-mob-bg {
  background-color: $blue-shadow;
  width: 60vw;
  height: 15vh;
  right: -9%;
  z-index: -1;
  overflow-x: hidden;
  margin-top: 1vh;
}

.casestudy-mob-bg img {
  width: 15%;
  height: 15%;
  bottom: 1em;
  right: 1em;
}
.content-CaseStudy {
  .casestudy-upper-section {
    @include flex-column;
    row-gap: 3vh;
    & h1 {
      @include position-rel;
      @include font;
      @include text-stroke;
      font-size: 7rem !important;
      line-height: 7rem;
      margin: 0;
    }
    & .blue-box-CaseStudy {
      width: 63%;
      z-index: -1;
      @include position-abs;
      transform: translateX(-50%);
      left: 73.9%;
      top: 8%;
    }
    & .circles-mob {
      @include display-none;
    }
  }
  & h2 {
    color: $secondary;
    letter-spacing: 0.1rem;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    z-index: 2;
  }
  & p {
    font-size: 1.8rem;
    width: 100%;
    font-weight: 500;
    margin: 0;
  }
  .lower-div-CaseStudy {
    @include flex-row;
    padding-top: 5%;
    & p {
      color: $secondary;
    }
    & .Arrows-CaseStudy {
      @include flex-column;
      row-gap: 2vh;
      & .ind-CaseStudy {
        @include flex-row;
        margin: 0 auto;
        & p {
          font-size: 2rem;
          padding: 1px;
        }
      }
      & .case-study-round-btn {
        @include flex-row;
        column-gap: 1vw;
        vertical-align: middle;
        .btn {
          &.rounded-circle {
            &.btn-lg {
              padding: 18px;
              .svg-inline--fa {
                color: $white;
                font-size: 2rem;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
  & .disableButton {
    background-color: $light-secondary;
    cursor: default !important;
  }
  .enableButton {
    background-color: $secondary;
  }
  & .carousel-heading-CaseStudy {
    @include flex-column;
    row-gap: 1vh;
    padding-left: 4vw;
    & p {
      @include flex-column;
      font-size: 3.3rem;
      line-height: 4.1rem;
      text-align: left;
      height: 100%;
      & .even-word {
        color: $stroke;
      }

      & .odd-word {
        color: $light-red;
      }
    }
  }
}
.vert-line-CaseStudy {
  width: 2px;
  background-color: $dark-gray;
  margin-left: 7%;
  padding: 0;
}
.carousel .slide-CaseStudy {
  text-align: left;
  height: 100%;
  & img {
    height: 100%;
    width: 100%;
  }
}
// .slide-CaseStudy-2 {
//   & img{
//     height: 80vh;
//   }
// }
.carousel-img-CaseStudy {
  width: 36%;
  margin: auto;
}

.carousel-root {
  & button {
    @include display-none;
  }
  & ul {
    @include display-none;
    list-style: none;
  }
}

.carousel-img-mob-CaseStudy {
  @include display-none;
}

.Arrows-mob-CaseStudy {
  @include display-none;
}

.blue-box-CaseStudy {
  @include display-none;
}

@media screen and (min-width: 2200px) {
  .content-CaseStudy {
    & h1 {
      font-size: 8rem;
    }

    & h2 {
      font-size: 2.5rem;
    }
    & p {
      font-size: 2.1rem;
    }
  }
  .lower-div-CaseStudy {
    & .carousel-heading-CaseStudy {
      & p {
        font-size: 56px;
        line-height: 5rem;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .main-container-CaseStudy {
    padding-left: 4%;
  }
  .content-CaseStudy {
    & .casestudy-upper-section {
      row-gap: 3vh;
      & h1 {
        font-size: 5rem !important;
        line-height: 5rem;
      }
      & h2 {
        font-size: 1.8rem;
        font-weight: 700;
      }
      & p {
        font-size: 1.5rem;
      }
    }
    & .carousel-heading-CaseStudy {
      @include flex-column;
      row-gap: 1vh;
      padding-left: 2vw;
      & p {
        @include flex-column;
        font-size: 2.5rem;
        line-height: 2.6rem;
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .main-container-CaseStudy {
    padding-left: 4%;
  }
  .content-CaseStudy {
    & .casestudy-upper-section {
      row-gap: 3vh;
      & h1 {
        font-size: 4.5rem !important;
        line-height: 4.5rem;
      }
      & h2 {
        font-size: 1.6rem;
        font-weight: 600;
      }
      & p {
        font-size: 1.3rem;
      }
    }
    & .carousel-heading-CaseStudy {
      @include flex-column;
      row-gap: 1vh;
      padding-left: 2vw;
      & p {
        @include flex-column;
        font-size: 2.2rem;
        line-height: 2.3rem;
      }
    }
    .lower-div-CaseStudy {
      & .Arrows-CaseStudy {
        & .case-study-round-btn {
          @include flex-row;
          column-gap: 1vw;
          vertical-align: middle;
          .btn {
            &.rounded-circle {
              &.btn-lg {
                padding: 8px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .main-container-CaseStudy {
    padding-left: 4%;
  }
  .content-CaseStudy {
    & .casestudy-upper-section {
      row-gap: 2vh;
      & h1 {
        font-size: 4rem !important;
        line-height: 4.1rem;
      }
      & h2 {
        font-size: 1.4rem;
        font-weight: 600;
      }
      & p {
        font-size: 1.1rem;
      }
    }
    .lower-div-CaseStudy {
      & .Arrows-CaseStudy {
        & .case-study-round-btn {
          @include flex-row;
          column-gap: 1vw;
          vertical-align: middle;
          .btn {
            &.rounded-circle {
              &.btn-lg {
                padding: 7px;
              }
            }
          }
        }
      }
    }
    & .carousel-heading-CaseStudy {
      @include flex-column;
      row-gap: 1vh;
      padding-left: 2vw;
      & p {
        @include flex-column;
        font-size: 2rem;
        line-height: 2.1rem;
      }
    }
  }
}
@media screen and (min-width: 3000px) {
  .main-container-CaseStudy {
    align-items: center;
  }
  .content-CaseStudy {
    & .casestudy-upper-section {
      row-gap: 3vh;
      & h1 {
        font-size: 8rem !important;
        line-height: 8rem;
      }
      & h2 {
        font-size: 3rem;
        font-weight: 700;
      }
      & p {
        font-size: 2.2rem;
      }
    }
    & .carousel-heading-CaseStudy {
      @include flex-column;
      row-gap: 1vh;
      padding-left: 4vw;
      & p {
        @include flex-column;
        font-size: 4.8rem;
        line-height: 5rem;
      }
    }
  }
  .lower-div-CaseStudy {
    & .Arrows-CaseStudy {
      & .case-study-round-btn {
        @include flex-row;
        column-gap: 1vw;
        vertical-align: middle;
        .btn {
          &.rounded-circle {
            &.btn-lg {
              padding: 30px !important;
              .svg-inline--fa {
                color: $white;
                font-size: 3rem !important;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .page-container-CaseStudy {
    padding-left: 0;
    @include height-fit-content;
    background-color: $background-color;
  }
  .main-container-CaseStudy {
    background-color: $background-color;
    width: 100%;
    z-index: 1;
  }
  .vert-line-CaseStudy {
    @include display-none;
  }
  .carousel-img-CaseStudy {
    @include display-none;
  }
  .Arrows-CaseStudy {
    @include display-none;
    display: none !important;
  }

  .blue-box-CaseStudy {
    display: block;
  }

  .carousel-img-mob-CaseStudy {
    display: contents;
    width: 100%;
    z-index: 0;
    padding-top: 2%;
    box-shadow: 50px 50px 50px $black-shadow;

    .carousel .slider-wrapper {
      height: 301px;
    }
  }
  .Arrows-mob-CaseStudy {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding-top: 2vh;

    & .ind-mob-CaseStudy {
      display: flex;
      & p {
        display: flex !important;
        padding-top: 15%;
        font-size: 28px;
        margin: 0;
      }
    }
    & .case-study-btn {
      @include flex-row;
      column-gap: 5vw;
      vertical-align: middle;
      padding: 0;
      
      .btn {
        &.rounded-circle {
          padding: 12px;
          margin-left: 15px;
          .svg-inline--fa {
            color: $white;
            font-size: 2rem;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .lower-div-CaseStudy {
    display: block !important;
    @include position-rel;
    & .carousel-heading-CaseStudy {
      box-shadow:  5px 2px 5px $black-shadow;
      width: 65%;
      @include position-abs;
      background-color: $white;
      z-index: 1;
      padding: 3% 1% 3% 3%;
      flex-direction: row;
      display: block;
      & p {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 5px !important;
        & span {
          @include flex-row;
          font-size: 30px;
          line-height: 2rem;
        }
        @include height-fit-content;
        margin-bottom: 0;
      }
    }
  }
  .content-CaseStudy {
    width: 100%;
    & h1 {
      font-size: 80px;
      -webkit-text-stroke: 1px $stroke !important;
      margin-top: 2%;
      margin-bottom: 5%;
      font-weight: 900;

      & .blue-box-CaseStudy {
        top: 4%;
      }

      & .circles-mob {
        @include position-abs;
        display: block;
        right: 1%;
        bottom: 10%;
      }
    }

    & h2 {
      font-size: 30px;
      font-weight: 700;
    }

    & > p {
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      width: 80%;
    }
  }

  .carousel .thumbs-wrapper {
    overflow: visible;
    margin-top: 3.5%;
  }
}
@media screen and (max-width: 500px) {
  .page-container-CaseStudy {
    padding-left: 0;
    @include height-fit-content;
    background-color: $background-color;
  }
  .main-container-CaseStudy {
    background-color: $background-color;
    width: 100%;
    z-index: 1;
  }
  .vert-line-CaseStudy {
    @include display-none;
  }
  .carousel-img-CaseStudy {
    @include display-none;
  }
  .Arrows-CaseStudy {
    @include display-none;
    display: none !important;
  }

  .blue-box-CaseStudy {
    display: block;
  }

  .carousel-img-mob-CaseStudy {
    display: contents;
    width: 100%;
    z-index: 0;
    padding-top: 2%;
    box-shadow: 100px 100px 100px $black-shadow;
    .carousel .slider-wrapper {
      height: 301px;
      box-shadow:  5px 2px 5px $black-shadow;

    }
  }

  .Arrows-mob-CaseStudy {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding-top: 2vh;

    & .ind-mob-CaseStudy {
      display: flex;
      & p {
        display: flex !important;
        padding-top: 15%;
        font-size: 28px;
        margin: 0;
      }
    }
    & .case-study-btn {
      @include flex-row;
      column-gap: 2vw;
      vertical-align: middle;
      padding: 0;
      .btn {
        &.rounded-circle {
          padding: 12px;
          .svg-inline--fa {
            color: $white;
            font-size: 2rem;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .lower-div-CaseStudy {
    display: block !important;
    @include position-rel;
    & .carousel-heading-CaseStudy {
      box-shadow:  5px 2px 5px $black-shadow;
      width: 70%;
      @include position-abs;
      background-color: $white;
      z-index: 1;
      padding: 3% 1% 3% 3%;
      flex-direction: row;
      display: block;
      & p {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 5px !important;
        & span {
          @include flex-row;
          font-size: 29px;
          line-height: 2rem;
        }
        @include height-fit-content;
        margin-bottom: 0;
      }
    }
  }
  .content-CaseStudy {
    width: 100%;
    & h1 {
      font-size: 80px;
      -webkit-text-stroke: 1px $stroke !important;
      margin-top: 2%;
      margin-bottom: 5%;
      font-weight: 700;

      & .blue-box-CaseStudy {
        top: 4%;
      }

      & .circles-mob {
        @include position-abs;
        display: block;
        right: 1%;
        bottom: 10%;
      }
    }

    & h2 {
      font-size: 30px;
      font-weight: 700;
    }

    & > p {
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      width: 80%;
    }
  }

  .carousel .thumbs-wrapper {
    overflow: visible;
    margin-top: 3.5%;
  }
}

@media screen and (min-width:312px) and (max-width:350px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & h1 {
        font-size: 3.1rem !important;
        line-height: 3.5rem;
      }
    }
  }
  .lower-div-CaseStudy {
    & .carousel-heading-CaseStudy {
      & p {
        & span {
          @include flex-row;
          font-size: 25px;
          line-height: 2rem;
        }
      }
    }
  }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & p {
        font-size: 1.1rem !important;
      }
    }
  }
}
//   .slide-CaseStudy-2 {
//     & img{
//       height: 450px !important;
//     }
//   }
// }
@media screen and (min-width:1400px) and (max-width:1999px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & p {
        font-size: 1.5rem;
      }
    }
  }
}
@media screen and (min-width:1400px) and (max-width:1499px) {
  .content-CaseStudy {
    & .carousel-heading-CaseStudy{
      & p {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }
  }
}
@media screen and (min-width:1200px) and (max-width:1399px) {
  .content-CaseStudy {
    & .carousel-heading-CaseStudy{
      & p {
        font-size: 2.7rem;
        line-height: 3.2rem;
      }
    }
  }
}
@media screen and (min-width:1000px) and (max-width:1199px) {
  .content-CaseStudy {
    & .carousel-heading-CaseStudy{
      & p {
        font-size: 2.3rem;
        line-height: 2.8rem;
      }
    }
  }
}
@media screen and (min-width:900px) and (max-width:999px) {
  .content-CaseStudy {
    & .carousel-heading-CaseStudy{
      & p {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
  }
}
@media screen and (min-width:768px) and (max-width:899px) {
  .content-CaseStudy {
    & .carousel-heading-CaseStudy{
      & p {
        font-size: 1.7rem;
        line-height: 2.3rem;
      }
    }
  }
}
@media screen and (min-width:1300px) and (max-width:1699px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & p {
        font-size: 1.125rem !important;
      }
    }
  }
}
@media screen and (min-width:2000px) and (max-width:2400px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & p {
        font-size: 1.8rem !important;
      }
    }
  }
}
@media screen and (min-width:2401px) and (max-width:2800px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & p {
        font-size: 1.9rem !important;
      }
    }
  }
}
@media screen and (min-width:2801px) and (max-width:2999px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & p {
        font-size: 2.2rem !important;
      }
    }
  }
}
@media screen and (min-width:3000px) {
  .content-CaseStudy {
    & .casestudy-upper-section {
      & p {
        font-size: 2.5rem !important;
      }
    }
  }
}