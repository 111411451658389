@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';

.page-container-footer{
    width: 100%;
    background-color: $background-color;
    font-family: Open Sans;
    font-weight: 600;
    @include position-rel;
    overflow-x: hidden;

    & .header-footer{
        padding-top: 3%;
        text-align: center;
        & .heading-footer{
            padding-left: 15%;
            padding-right: 15%;
            font-size: 44px;
            letter-spacing: 0.1rem;
            @include text-center;
        }
    
        & .heading-desc-footer{
            display: block;
            width: 100%;
            color: $stroke;
            font-size: 22px;
            margin: 0 auto;
            @include text-center;  
            padding-top: 1%
        }
    
        & .btnsd-footer{
            @include flex-row;
            justify-content: center;
            padding-top: 3%;
            padding-bottom: 5%;
        }
    
        & .btnsd-footer button{
            font-size: 24px;
            width: fit-content;
            border-radius: 8px;
        }
    }
}

@media screen and (min-width: 2300px) {
    .page-container-footer{
    
        & .header-footer{
            & .heading-footer{
                font-size: 3.1rem;
            }
        
            & .heading-desc-footer{
                font-size: 2rem;
            }
        
            & .btnsd-footer button{
                font-size: 1.9rem;
            }
        }
    }
}

@media screen and (min-width: 2700px) {
    .page-container-footer{
    
        & .header-footer{
            & .heading-footer{
                font-size: 3.4rem;
            }
        
            & .heading-desc-footer{
                font-size: 2.2rem;
            }
        
            & .btnsd-footer button{
                font-size: 2.1rem;
            }
        }
    }
}

@media screen and (min-width: 3200px) {
    .page-container-footer{
    
        & .header-footer{
            & .heading-footer{
                font-size: 3.8rem;
            }
        
            & .heading-desc-footer{
                font-size: 2.6rem;
            }
        
            & .btnsd-footer button{
                font-size: 2.4rem;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .page-container-footer{
        padding-left: 0%;
        & .header-footer .heading-footer{
            padding: 0%;
        }
    }
}

@media screen and (max-width: 450px) {
    .page-container-footer{
        background-color: $white;
        &  .header-footer{
            & .heading-footer{
                padding: 0%;
                font-size: 34px;
                @include text-center;
                letter-spacing: 0.1rem;
                padding: 3%;
                line-height: 2.6rem;
            }
    
            & .heading-desc-footer{
                font-size: 18px;
                padding: 2%;
                font-weight: 400;
            }
    
            & .btnsd-footer button{
                padding: 2.5% 2% 2.5% 2%;
                font-size: 20px;
            }
    
        }
    }
    .circles-Footer{
        @include display-none;
    }
   
}

@media screen and (min-width:1400px) and (max-width:1999px) {
    .page-container-footer{
        & .header-footer{
            & .heading-desc-footer{
                font-size: 1.5rem !important;
            }
        }
    }
  }
@media screen and (min-width:768px) and (max-width:1024px) {
    .page-container-footer{
        & .header-footer{
            & .heading-desc-footer{
                font-size: 1.1rem !important;
            }
        }
    }
  }
@media screen and (min-width:2000px) and (max-width:2400px) {
    .page-container-footer{
        & .header-footer{
            & .heading-desc-footer{
                font-size: 1.8rem !important;
            }
        }
    }
  }
@media screen and (min-width:1300px) and (max-width:1699px) {
    .page-container-footer{
        & .header-footer{
            & .heading-desc-footer{
                font-size: 1.125rem !important;
            }
        }
    }
  }
@media screen and (min-width:2401px) and (max-width:2800px) {
    .page-container-footer{
        & .header-footer{
            & .heading-desc-footer{
                font-size: 1.9rem !important;
            }
        }
    }
  }
  @media screen and (min-width:2801px) and (max-width:2999px) {
    .page-container-footer{
        & .header-footer{
            & .heading-desc-footer{
                font-size: 2.2rem !important;
            }
        }
    }
  }
@media screen and (min-width:3000px){
    .page-container-footer{
        & .header-footer{
            & .heading-desc-footer{
                font-size: 2.5rem !important;
            }
        }
    }
  }
