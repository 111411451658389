@import "../../shared/assets/css/mixins.scss";
@import "../../shared/assets/css/_variables.scss";
@import "../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";
.leadership-container {
  @include flex-column;
  @include font;
  font-weight: 500;
  z-index: -1;
  padding-left: 10%;
  overflow-x: hidden;
}
@media (max-width:767px) {
  .leadership-container{
    margin-top: 7.5vh;
  }
}
.know-us-better {
  padding: 6vh 6vw 0vh 6vw;
}
.leadership-image-container {
  background-image: url("../../shared/assets/images/leadership-bg.png");
}
.know-us-better {
  @include flex-column;
  row-gap: 6vh;
  & .know-us-better-heading {
    color: $primary;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 500;
    & h1 {
      margin: 0;
    }
  }
  & .content-container {
    & .content-row-right{
      display: grid;
     grid-template-columns: 40vw 1fr;
     gap: 8rem;
     max-width: 100%;
     margin: 0 auto;
     padding-bottom: 10vh;
     & img{
      min-width: 75%;
      width: 100%;
     }
    }
    & .content-row-left{
     display: grid;
     grid-template-columns: 1fr 40vw;
     max-width: 100%;
     gap: 8rem;
     margin: 0 auto;
     padding-bottom: 10vh;

     & img{
      width: 100%
     }
    }  

  }
  & .col-content {
    @include flex-column;
    row-gap: 6px;
    flex: 0 0 48%;
    & .col-heading {
      @include flex-column;
      flex-wrap: wrap;
      & p {
        font-size: 2.8rem;
        font-weight: 600;
        margin: 0;
        width: 70%
      }
    }
    & .col-subheading {
      @include flex-row;
      align-items: center;
      column-gap: 8px;
      justify-content: space-between;
      & .kub-icon{
          @include flex-row;
          column-gap: 1vw;
        & img {
          width: 3vw;
        }
      }
      & p {
        font-size: 1.7rem;
        margin: 0;
        font-weight: 500;
        padding-right: 1vw;
      }
    }
    & .col-desc {
      & p {
        width: 100%;
        font-size: 1.5rem;
        margin: 0;
        font-weight: 500;
        color: $stroke;
        line-height: 1.5em;
        padding-top: 0.4em;
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
      }
    }
  }
  & .kub-mv {
    display: none;
    margin-top: -2vh;
    row-gap: 3vh;
    & .kub-mv-content {
      @include flex-column;
      flex-wrap: wrap;
      row-gap: 20px;
      margin-bottom: 3vh;
      & .kub-img-mv {
        height: 100%;
      }
    }
  }
}
@media only screen and (min-width: 2600px) and (max-width: 3500px){
  .know-us-better {
    row-gap: 6vh;
    padding-bottom: 2vh;
    & .know-us-better-heading {
      font-size: 4.5rem;
    }
    & .content-container {
      & .content-row-right{
       gap: 8rem;
       padding-bottom: 10vh;
      }
      & .content-row-left{
       gap: 8rem;
       padding-bottom: 10vh;
      }  
  
    }
    & .col-content {
      row-gap: 6px;
      & .col-heading {
        & p {
          font-size: 3.5rem;
        }
      }
      & .col-subheading {
        column-gap: 10px;
        & .kub-icon{
            column-gap: 1vw;
        }
        & p {
          font-size: 2.8rem;
        }
      }
      & .col-desc {
        & p {
          font-size: 2.5rem;
        }
      }
    }
    & .kub-mv {
      display: none;
    }
  }
}
@media only screen and (min-width: 2001px) and (max-width: 2600px){
  .know-us-better {
    row-gap: 6vh;
    padding-bottom: 2vh;
    & .know-us-better-heading {
      font-size: 3.8rem;
    }
    & .content-container {
      & .content-row-right{
       gap: 8rem;
       padding-bottom: 10vh;
      }
      & .content-row-left{
       gap: 8rem;
       padding-bottom: 10vh;
      }  
  
    }
    & .col-content {
      row-gap: 6px;
      & .col-heading {
        & p {
          font-size: 3rem;
        }
      }
      & .col-subheading {
        column-gap: 8px;
        & .kub-icon{
            column-gap: 1vw;
        }
        & p {
          font-size: 2rem;
        }
      }
      & .col-desc {
        & p {
          font-size: 1.8rem;
        }
      }
    }
    & .kub-mv {
      display: none;
    }
  }
}
@media only screen and (min-width: 1151px) and (max-width: 1499px) {
  .know-us-better {
    & .know-us-better-heading {
      font-size: 3rem;
    }
    & .content-container {
      & .content-row-right{
       grid-template-columns: 40vw 1fr;
       gap: 4rem;
       max-width: 100%;
       padding-bottom: 10vh;
      }
      & .content-row-left{
       gap: 4rem;
       padding-bottom: 10vh;
      }  
    }
    & .col-content {
      & .col-heading {
        & p {
          font-size: 2.6rem;
        }
      }
      & .col-subheading {
        & p {
          font-size: 1.6rem;
        }
      }
      & .col-desc {
        & p {
          font-size: 1.3rem;
        }
      }
    }
  }
    .kub-mv {
      display: none;
    }
  }
  @media only screen and (min-width: 1040px) and (max-width: 1150px) {
    .know-us-better {
      & .know-us-better-heading {
        font-size: 3rem;
      }
      & .content-container {
        & .content-row-right{
         grid-template-columns: 40vw 1fr;
         gap: 3rem;
         max-width: 100%;
         padding-bottom: 10vh;
        }
        & .content-row-left{
         gap: 3rem;
         padding-bottom: 10vh;
        }  
      }
      & .col-content {
        & .col-heading {
          & p {
            font-size: 2.4rem;
          }
        }
        & .col-subheading {
          & p {
            font-size: 1.4rem;
          }
        }
        & .col-desc {
          & p {
            font-size: 1.2rem;
          }
        }
      }
    }
    .kub-mv {
      display: none;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1039px) {
    .know-us-better {
      padding-left: 3vw;
      padding-right: 3vw;
      & .know-us-better-heading {
        font-size: 2.5rem;
      }
      & .content-container {
        & .content-row-right{
         gap: 2rem;
         max-width: 100%;
         padding-bottom: 10vh;
        }
        & .content-row-left{
         gap: 2rem;
         padding-bottom: 10vh;
        }  
      }
      & .col-content {
        row-gap:3px ;
        & .col-heading {
          & p {
            font-size: 2rem;
            width: 100%;
          }
        }
        & .col-subheading {
          & p {
            font-size: 1.2rem;
          }
        }
        & .col-desc {
          & p {
            font-size: 1rem;
          }
        }
      }
    }
    .kub-mv {
      display: none;
    }
  }
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .know-us-better {
    padding: 0;
    row-gap: 6vh;
    overflow: hidden;
    padding-top: 4vh;
    & .know-us-better-heading {
      color: $primary;
      text-align: center;
      width: 100%;
      & p {
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
        margin: 0;
      }
    }
  }
  .kub-mv {
    margin-top: -2vh;
    padding-top: 0;
    padding-bottom: 2vh;
    & .kub-mv-display{
    & .kub-mv-content {
      & .kub-img-mv {
        width: 100%;
        margin-top: 0;
        img {
          width: 100%;
        }
      }
      & .content-header-mv {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5vw 0 5vw;
        & .content-col {
          & .content-name {
            font-size: 2rem;
            font-weight: 600;
            & p {
              margin: 0;
            }
          }
          & .content-desg {
            font-size: 1.6rem;
            font-weight: 600;
            & p {
              margin: 0;
            }
          }
        }
        & .content-icon {
          @include flex-row;
          column-gap: 10px;
          & img {
            height: 3vh;
          }
        }
      }
      & .content-para {
        font-size: 1.4rem;
        font-weight: 500;
        text-align: justify;
        hyphens: auto;
       -webkit-hyphens: auto;
        word-spacing: -0.05em;
        padding: 0 5vw 0 5vw;
        & p {
          margin: 0;
        }
      }
    }
  }
}
}
@media only screen and (min-width: 312px) and (max-width: 480px) {
  .know-us-better {
    @include flex-column;
    padding: 0;
    row-gap: 6vh;
    overflow: hidden;
    padding-top: 4vh;
    & .know-us-better-heading {
      height: 10vh;
      & p {
        font-size: 2.2rem;
        font-weight: 500;
        width: 80%;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  .kub-mv {
    padding-top: 0;
    padding-bottom: 2vh;
    & .kub-mv-display{
    & .kub-mv-content {
      & .kub-img-mv {
        width: 100%;
        margin-top: 0;
        img {
          width: 100%;
        }
      }
      & .content-header-mv {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5vw 0 5vw;
        & .content-col {
          & .content-name {
            font-size: 1.8rem;
            font-weight: 600;
            & p {
              margin: 0;
            }
          }
          & .content-desg {
            font-size: 1.4rem;
            font-weight: 600;
            & p {
              margin: 0;
            }
          }
        }
        & .content-icon {
          @include flex-row;
          column-gap: 10px;
          & img {
            height: 3vh;
          }
        }
      }
      & .content-para {
        font-size: 1.1rem;
        font-weight: 500;
        text-align: justify;
        hyphens: auto;
       -webkit-hyphens: auto;
        word-spacing: -0.05em;
        padding: 0 5vw 0 5vw;
        & p {
          margin: 0;
        }
      }
    }
  }
}
}
.team {
  @include flex-column;
  flex-wrap: wrap;
  width: 100%;
  padding: 6vh 3vw;
  background-color: $background-color;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  box-shadow: 12px 6px 12px 6px $grey;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
}
@media only screen and (min-width:2000px){
   .team-header {
    font-size: 3rem;
    font-weight: 600;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1100px) {
  .team {
    padding: 4vh 3vw;
    row-gap: 6px;
    justify-content: flex-start;
    & .team-header {
      text-align: center;
      font-size: 2.7rem;
      font-weight: 500;
    }
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .team {
    padding: 3vh 3vw;
    row-gap: 1px;
    justify-content: start;
    align-items: center;
    & .team-header {
      text-align: center;
      font-size: 3rem;
      font-weight: 500;
    }
  }
}
@media only screen and (min-width: 312px) and (max-width: 480px) {
  .team {
    row-gap: 1px;
    justify-content: start;
    padding: 3vh 3vw;
    & .team-header {
      text-align: center;
      font-size: 2rem;
      font-weight: 500;
    }
  }
}
@media (max-width: 767px) {
  .leadership-container {
    padding-left: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1900px){
  .know-us-better{
    & .col-content{
      & .col-desc{
        & p{
          font-size: 1.5rem;
        }
      }
    }
  }

}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .know-us-better{
    & .know-us-better-heading{
      font-size: 3.2rem !important;
    }
    & .col-content{
      & .col-desc{
        & p{
          font-size: 1.1rem;
        }
      }
    }
  }
  }
@media only screen and (min-width: 2000px) and (max-width: 2400px){
    .know-us-better{
      & .col-content{
        & .col-desc{
          & p{
            font-size: 1.8rem;
          }
        }
      }
    }
}
@media only screen and (min-width: 2401px) and (max-width: 2800px){
  .know-us-better{
    & .col-content{
      & .col-desc{
        & p{
          font-size: 1.9rem !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 2801px) and (max-width: 2999px){
  .know-us-better{
    & .col-content{
      & .col-desc{
        & p{
          font-size: 2.2rem !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 3000px){
  .know-us-better{
    & .col-content{
      & .col-desc{
        & p{
          font-size: 2.5rem !important;
        }
      }
    }
  }
}

