@import "../../shared/assets/css/mixins.scss";
@import "../../shared/assets/css/_variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300;400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

// top section css started here
.our-story,
.make-us-different {
  @include flex-column;
  padding: 4em 6em 4em 6em;
  flex-wrap: wrap;
}
//About us section styling started from here
.about-us {
  @include flex-column;
  font-family: "open sans";
  padding-left: 10%;
  z-index: -1;
  margin: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 767px) {
  .about-us {
    padding-left: 0;
    height: fit-content;
    margin-top: 7.5vh;
  }
}
.about-image-container {
  background-image: url("../../shared/assets/images/about-us-bg.jpg");
}
@media screen and (min-width: 3300px) {
  .about-image-container {
    padding-left: 4vw;
  }
  .our-story {
    & .our-story-content {
      & .os-heading {
        font-size: 5rem;
      }
      & p {
        font-size: 2.5rem;
      }
    }
  }
  .core-values {
    & .web-view {
      & .core-value-content {
        & .cv-heading {
          font-size: 6rem;
        }
        & .cv-text-stroke {
          font-size: 9em !important;
        }
        & .box-content {
          font-size: 3rem;
        }
      }
    }
  }
  .mkd-bg {
    & .make-us-different {
      & .mkd-content {
        & h2 {
          font-size: 4.5rem;
        }
        & p {
          font-size: 2.5rem;
        }
      }
      & .mkd-btn {
        & button {
          font-size: 3rem;
        }
      }
    }
  }
  .about-kub {
    & .kub-header {
      & h1 {
        font-size: 4.5rem;
      }
    }
    & button {
      font-size: 3rem;
    }
  }
}

@media screen and (min-width: 3500px) {
  .our-story {
    & .our-story-content {
      & .os-para-3 {
        font-size: 2.5rem;
        margin-top: 6rem;
      }
    }
  }
  .core-values {
    & .web-view {
      & .core-value-content {
        & .cv-heading {
          font-size: 6rem;
        }
        & .cv-text-stroke {
          font-size: 9em !important;
        }
        & .box-content {
          font-size: 3rem;
        }
      }
    }
  }
  .mkd-bg {
    & .make-us-different {
      & .mkd-content {
        & h2 {
          font-size: 4.5rem;
        }
        & p {
          font-size: 2.5rem;
        }
      }
      & .mkd-btn {
        & button {
          font-size: 3rem;
        }
      }
    }
  }
  .about-kub {
    & .kub-header {
      & h1 {
        font-size: 4.5rem;
      }
    }
    & button {
      font-size: 3rem;
    }
  }
}
//About us section ended here
//Our story section started from here
.our-story {
  @include flex-column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: hidden;
  background-color: $blue-shadow;
  background-size: cover;
  padding-top: 3em;
  height: 100%;
  padding-bottom: 2.5vh;
}
.our-story-content .os-heading {
  color: $primary;
  text-align: center;
  font-weight: 500;
  font-size: 3.2rem;
}
.our-story-content p {
  font-weight: 500;
  text-align: justify;
  font-size: 1.5rem;
}

.our-story-images {
  margin-top: 1.5vh;
}

.our-story-images .row .os-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  padding-top: 3vh;
}
.our-story .os-content {
  @include flex-row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.our-story-content {
  & .os-para-3 {
    padding-top: 2vh;
  }
  & .os-para-4 {
    padding-top: 1vh;
  }
}
//For our story Mobile section started from here
.os-mob-view {
  flex-wrap: wrap;
  justify-content: center;
}
.os-mob-col {
  flex: 30%;
}
.os-image-team1 {
  width: 94%;
}
.os-mob-view .os-image-mob {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.os-image-mv img {
  width: 100%;
}

.os-image-mv {
  width: 100%;
  padding-top: 2em;
  padding-bottom: 1.5em;
}
.os-para2-mob {
  padding-top: 1em;
}
@media screen and (min-width: 3101px) {
  .our-story-content .os-heading {
    font-size: 3.8rem !important;
  }
  .our-story-content p {
    font-size: 1.8rem;
    font-weight: 500;
  }
  .our-story-content {
    & .os-para-3 {
      padding-top: 5%;
    }
    & .os-para-4 {
      padding-top: 1vh;
    }
  }
}
//mobile view ended here
//media query started from here
@media screen and (min-width: 2000px) and (max-width: 3100px) {
  .our-story,
  .make-us-different {
    padding-left: 6em;
    padding-right: 6em;
  }
  .our-story {
    height: 100%;
    padding-bottom: 5vh;
  }
  .our-story-content .os-heading {
    font-size: 3.8rem !important;
  }
  .our-story-content p {
    font-size: 1.8rem;
    font-weight: 500;
  }
  .our-story-content {
    & .os-para-3 {
      padding-top: 4%;
    }
    & .os-para-4 {
      padding-top: 1vh;
    }
  }
}

@media screen and (min-width: 1500px) and (max-width: 1999px) {
  .our-story-content .os-heading {
    font-size: 3.2rem;
  }
  .our-story-content p {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .our-story-content {
    & .os-para-3 {
      padding-top: 3%;
    }
    & .os-para-4 {
      padding-top: 1vh;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .our-story {
    padding: 2em;
  }
  .our-story-content .os-heading {
    font-size: 3rem;
  }
  .our-story-content p {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .os-image-mob {
    display: block;
    width: 100%;
    padding-bottom: 1.8em;
  }
  .os-ashav-img {
    display: none !important;
    position: relative;
  }

  .os-mob-col {
    flex: 40%;
  }

  .our-story {
    padding: 0em;
  }

  .our-story-content .os-heading {
    font-size: 2.8rem;
    padding-top: 1em;
  }

  .our-story-content p {
    font-size: 1.2rem;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .os-image {
    display: none;
  }

  .os-mob {
    display: block;
  }

  .os-image-mv {
    display: block;
  }
  .our-story-content {
    .os-para-3 {
      padding-top: 1.5vh;
    }
  }
  .image-grid {
    padding-left: 0.8em;
    padding-right: 0.8em;
  }
}
@media screen and (min-width: 312px) and (max-width: 480px) {
  .os-image-mob {
    display: block;
    width: 100%;
    padding-bottom: 1em;
  }
  .os-ashav-img {
    display: none !important;
  }
  .os-mob-col {
    flex: 30%;
  }
  .our-story {
    padding: 0em;
  }
  .os-image {
    display: none;
  }
  .os-mob {
    display: block;
  }
  .os-image-mv {
    padding: 0;
    display: block;
  }
  .our-story-content .os-heading {
    font-size: 2.5rem;
    padding-top: 0.8em;
  }
  .our-story-content p {
    font-size: 1rem;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  .image-grid {
    padding-left: 0.8em;
    padding-right: 0.8em;
  }
  .our-story-content {
    .os-para-3 {
      padding-top: 1.8vh;
    }
  }
}

//Our story section ended here
//Core value section started from here
.core-values {
  @include flex-row;
  flex-wrap: wrap;
  align-items: center;
  overflow-x: hidden;
}
//Core value section for web view
.web-view {
  @include flex-column;
  flex-wrap: wrap;
  row-gap: 25px;
  padding: 6em;
}
.cv-mission-img {
  & img {
    margin-left: 4vw;
  }
}
.core-value-content {
  align-items: center;
}
.core-content-box {
  padding-left: 3vw;
}
.row .cv-image {
  width: 90%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.cv-heading {
  color: $secondary;
  letter-spacing: 4px;
  font-size: 2rem;
  font-weight: 700;
}

.cv-text-stroke {
  @include text-stroke;
  font-weight: 800;
  color: transparent;
}

.box-content {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
}

//core value section for mobile view
.cv-mob-view {
  display: none !important;
  overflow: hidden;
}
.cv-mob-header p {
  color: $secondary;
  letter-spacing: 1.2px;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 6vw 4vw 0 4vw;
}

.cv-header-name {
  width: 100%;
  @include flex-column;
  letter-spacing: 5px;
  justify-content: space-between;
  padding: 1vw 4vw 0 4vw;
}

.cv-mob-bg {
  background-color: $blue-shadow;
  width: 60vw;
  height: 10vh;
  right: 0;
  z-index: -1;
  overflow-x: hidden;
}

.cv-mob-bg img {
  width: 15%;
  height: 15%;
  bottom: 1em;
  right: 1em;
}

.cv-mob-content {
  width: fit-content;
  height: 100%;
  @include flex-column;
  margin-bottom: 4vh;
}
.cv-mob-content img {
  width: 100vw;
}

.cv-overlay-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  text-align: center;
  color: $white;
  font-family: "open sans";
  font-weight: 600;
  line-height: 1.5rem;
  width: 80%;
  font-size: 1.2rem;
  margin: 0;
}

@media screen and (min-width: 2000px) {
  .our-story,
  .box-content {
    font-size: 2rem;
    font-weight: 500;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
  }
  .core-values {
    padding-left: 6em;
    padding-right: 6em;
  }
  .cv-heading {
    letter-spacing: 4px;
    font-size: 2.5rem;
    font-weight: 700;
  }

  .cv-text-stroke {
    font-size: 10em !important;
  }
}
//media query for core value section
@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .box-content {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
  }

  .cv-heading {
    letter-spacing: 4px;
    font-size: 2rem;
    font-weight: 700;
  }

  .cv-text-stroke {
    font-size: 6em !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .web-view {
    padding-left: 6em;
    padding-right: 6em;
  }
  .box-content {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
  }

  .cv-heading {
    letter-spacing: 4px;
    font-size: 2.5rem;
    font-weight: 700;
  }

  .cv-text-stroke {
    font-size: 5em !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .web-view {
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .web-view {
    display: none;
  }
  .cv-mob-view {
    display: block !important;
  }

  .cv-mob-header p {
    font-size: 1.5rem;
  }
  .cv-text-stroke {
    padding-bottom: 0.5em;
    padding-left: 4vw;
  }
}
@media screen and (min-width: 361px) and (max-width: 480px) {
  .web-view {
    display: none;
  }

  .core-values {
    padding: 0;
  }
  .cv-mob-view {
    display: block !important;
  }

  .cv-mob-header p {
    font-size: 1.3rem;
  }
  .cv-overlay-text {
    width: 80%;
    font-size: 1rem;
    margin: 0;
    padding: 2vh 1vw 0 1vw;
    width: 75%;
    line-height: 1.3rem;
  }
  .cv-text-stroke {
    padding-bottom: 0.5em;
    padding-left: 4vw;
    font-size: 4rem;
  }
}
@media screen and (min-width: 312px) and (max-width: 360px) {
  .web-view {
    display: none;
  }

  .core-values {
    padding: 0;
  }
  .cv-mob-view {
    display: block !important;
  }

  .cv-mob-header p {
    font-size: 1.2rem;
    padding-left: 5vw;
  }
  .cv-overlay-text {
    width: 80%;
    font-size: 0.9rem;
    margin: 0;
    padding: 2vh 0.8vw 0 0.8vw;
    width: 75%;
    line-height: 1.3rem;
  }
  .cv-text-stroke {
    margin-top: -0.1em;
    padding-left: 0vw;
    font-size: 4rem;
    -webkit-text-stroke: 1px solid $black-shadow;
  }

  .cv-mob-content {
    width: fit-content;
    height: 100%;
    @include flex-column;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }
}
//core value section ended here
// what's make us different section started from here
.mkd-bg {
  background-color: $background-color;
  width: 100%;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  overflow: hidden;
}

.make-us-different {
  @include flex-column;
  row-gap: 1.2em;
}

.mkd-content {
  @include flex-column;
  flex-wrap: wrap;
  row-gap: 1em;
  justify-content: center;
  text-align: center;
}

.mkd-content h2 {
  font-size: 3.2rem;
  color: $primary;
  text-align: center;
  font-weight: 500;
  justify-content: center;
}

.mkd-content p {
  font-weight: 500;
  margin-bottom: 0px;
}

.mkd-btn {
  margin: 0 auto;
  padding-top: 0.9rem;
}

.card-mv {
  display: none;
}
//media query started from here
@media only screen and (min-width: 1921px) {
  .mkd-content h2 {
    font-size: 3.6rem;
  }
  .make-us-different {
    padding-left: 10em;
    padding-right: 10em;
  }
  .mkd-content p {
    font-size: 1.8rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1920px) {
  .mkd-content h2 {
    font-size: 3.3rem;
  }

  .mkd-content p {
    font-size: 1.5rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .mkd-content h2 {
    font-size: 2.7rem;
  }

  .mkd-content p {
    font-size: 1.5rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .card-mv {
    display: block;
  }
  .make-us-different {
    padding: 2em;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .card-mv {
    display: block;
  }
  .mkd-content h2 {
    font-size: 2.3rem;
  }
  .mkd-content p {
    font-size: 1.2rem;
  }
  .make-us-different {
    padding: 2.3em;
  }
}
@media screen and (min-width: 312px) and (max-width: 480px) {
  .card-mv {
    display: block;
  }

  .mkd-content h2 {
    font-size: 2.2rem;
  }
  .mkd-content p {
    font-size: 1rem;
    text-align: center;
  }
  .make-us-different {
    padding: 1.5em;
  }
}
//mkd section ended here
//awards section started from her
.awards {
  @include flex-row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 4em 6em 4em 6em;
  overflow-x: hidden;
}

.awards-container {
  @include flex-row;
  justify-content: space-between;
}

.awards-col {
  @include flex-column;
  row-gap: 10px;
  justify-content: center;
}

.awards-heading {
  @include text-stroke;
  color: $white;
  @include font;
  font-size: 5em;
  letter-spacing: 6px;
  font-weight: 800;
  display: block;
  row-gap: 20px;
}

.awards-mv {
  line-height: 3.6rem !important;
  display: none;
}

.awards-mv-bg {
  display: none;
  height: 9em !important;
}

.awards-content {
  color: $secondary;
  letter-spacing: 2px;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.1em;
  padding-bottom: 0.6em;
}

.slideshow {
  overflow: hidden;
  max-width: 100%;
}

.slideshowSlider {
  white-space: nowrap;
}

.slide {
  display: inline-block;
  height: 80%;
  width: 100%;
}

.slide img {
  height: 80%;
  width: 100%;
  box-shadow: 0px 10px 20px $grey;
}

//carousel btn

.case-study-round-btn {
  @include flex-row;
  column-gap: 1vw;
  vertical-align: middle;
  padding-top: 4vh;
  .btn {
    &.rounded-circle {
      &.btn-lg {
        padding: 18px;
        .svg-inline--fa {
          color: $white;
          font-size: 2rem;
          vertical-align: middle;
        }
      }
    }
  }
  & .btn:hover {
    background-color: $secondary;
  }
}

.disableButton {
  background-color: $light-secondary !important;
  cursor: default !important;
}

.enableButton {
  background-color: $secondary;
}
//media query started from here
@media screen and (min-width: 2001px) {
  .awards-heading {
    @include text-stroke;
    font-size: 8em !important;
    letter-spacing: 2px;
    font-weight: 800;
  }

  .awards-content {
    letter-spacing: 2px;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2em;
    padding-bottom: 0.6em;
  }
  .case-study-round-btn {
    @include flex-row;
    column-gap: 1vw;
    vertical-align: middle;
    padding-top: 4vh;
    .btn {
      &.rounded-circle {
        &.btn-lg {
          padding: 30px;
          .svg-inline--fa {
            color: $white;
            font-size: 3rem;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .awards-heading {
    @include text-stroke;
    font-size: 6em !important;
    letter-spacing: 2px;
    font-weight: 800;
    line-height: 1em;
  }

  .awards-content {
    letter-spacing: 2px;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2em;
    padding-bottom: 0.6em;
  }
  .carousel-btn {
    padding-bottom: 2.4%;
    border: none;
    padding-right: 2.4%;
    padding-left: 2%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .awards-heading {
    @include text-stroke;
    color: $white;
    @include font;
    font-size: 4em !important;
    letter-spacing: 2px;
    font-weight: 800;
  }

  .awards-content {
    letter-spacing: 2px;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2em;
    padding-bottom: 0.6em;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .awards {
    padding: 4em 2em 4em 2em;
  }
  .awards-heading {
    @include text-stroke;
    color: $white;
    @include font;
    font-size: 3em !important;
    letter-spacing: 1px;
    font-weight: 800;
  }
  .awards-content {
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2em;
    padding-bottom: 0.6em;
  }
}
@media screen and (min-width: 611px) and (max-width: 767px) {
  .awards {
    padding: 4em 2.5em 4em 2.5em;
  }
  .carousel-btn {
    display: none;
  }
  .awards-mv {
    display: block;
    font-size: 3em;
    line-height: 0.9em !important;
    padding-top: 0.2em;
  }
  .awards-heading {
    display: none;
  }
  .awards-mv-bg {
    height: 7em !important;
  }
  .cv-text-stroke {
    margin-top: 0.1em;
  }
  .awards-content {
    padding-top: 1.5em;
    padding-bottom: 1em;
    font-size: 1em;
  }
}
@media screen and (min-width: 481px) and (max-width: 610px) {
  .awards {
    padding: 2em 2.5em 3em 2.5em;
  }
  .carousel-btn {
    display: none;
  }
  .awards-heading {
    display: none;
  }
  .awards-mv {
    display: block;
    font-size: 3em;
    line-height: 0.9em !important;
    padding-left: 0vw;
    padding-top: 0.2em;
  }
  .awards-mv-bg {
    height: 7em !important;
  }
  .cv-text-stroke {
    margin-top: 0.1em;
    margin-left: -4vw;
  }
  .awards-content {
    padding-top: 1.5em;
    padding-bottom: 1em;
    font-size: 1em;
  }
}
@media screen and (min-width: 361px) and (max-width: 480px) {
  .carousel-btn {
    display: none;
  }
  .cv-text-stroke {
    margin-top: 0.1em;
    margin-left: -4vw;
  }
  .awards {
    padding: 2em 2em 2em 2em;
  }
  .awards-heading {
    display: none;
  }
  .awards-mv {
    display: block;
    font-size: 2.1em;
    line-height: 0.9em !important;
    padding-top: 0.2em;
    padding-left: 0;
  }
  .awards-mv-bg {
    display: block;
    height: 5.5em !important;
  }
  .awards-content {
    padding-top: 1.5em;
    padding-bottom: 1em;
    font-size: 1em;
    letter-spacing: 1px;
  }
  .cv-header-name{
    padding: 1vw 4vw 0 1vw !important;
  }
}
@media screen and (min-width: 312px) and (max-width: 360px) {
  .awards {
    padding: 2em 1em 2em 1em;
  }
  .awards-heading {
    display: none;
  }
  .awards-mv {
    display: block;
    font-size: 2.3em;
    line-height: 0.9em !important;
    padding-top: 0.2em;
    margin-top: 0.1em;
    margin-left: -6vw;
  }
  .awards-mv-bg {
    display: block;
    height: 7em !important;
  }
  .awards-content {
    padding-top: 3em;
    padding-bottom: 0em;
    font-size: 1em;
    letter-spacing: 1px;
  }
  .slide {
    display: inline-block;
    height: 80%;
    width: 100%;
  }
  .slide img {
    height: 80%;
    width: 100%;
    box-shadow: 0px 10px 20px $grey;
  }
  .awards-img {
    margin-top: 2vh;
  }
}
//awards section ended here
.about-kub {
  @include flex-column;
  flex-wrap: wrap;
  width: 100%;
  background-color: $background-color;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  row-gap: 3vh;
  padding: 6vh 3vw;
}
.kub-header h1 {
  font-size: 3em;
  letter-spacing: 2px;
}
@media screen and (min-width: 2001px) {
  .kub-header h1 {
    font-size: 3.8em;
    font-weight: 600;
  }
}
@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .kub-header h1 {
    font-size: 3.2em;
    font-weight: 600;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .about-kub {
    @include flex-column;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 4vh;
    padding: 6vh 3vw;
  }
  .kub-header h1 {
    font-size: 3.2em;
    font-weight: 600;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .about-kub {
    @include flex-column;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 3vh;
    padding: 4vh 3vh;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .about-kub {
    @include flex-column;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 7px;
  }

  .kub-header h1 {
    font-size: 2.3rem;
    letter-spacing: 2px;
    text-align: center;
  }
}
@media screen and (min-width: 312px) and (max-width: 480px) {
  .about-kub {
    @include flex-row;
    row-gap: 3px;
    justify-content: center;
    align-items: center;
    padding: 2em;
  }
  .kub-header h1 {
    font-size: 1.7em;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .our-story {
  & .our-story-content .os-heading {
    font-size: 3.2rem !important;
  }
  .our-story-content p {
    font-size: 1.1rem;
    font-weight: 500;
  }
  }
  .box-content{
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1699px) {
  .our-story-content p{
    font-size: 18px;
    font-weight: 500;
  }
  .box-content{
    font-size: 18px;
  }
  .mkd-content{
    & p{
      font-size: 18px;
    }
  }
}
@media screen and (min-width:2000px) {
  .our-story{
    padding-top: 2em;
  }
  
}
@media screen and (min-width: 2000px) and (max-width: 2400px) {
  .our-story-content p{
    font-size: 1.8rem !important;
    font-weight: 500;
  }
  .box-content{
    font-size: 1.8rem !important;
  }
  .mkd-content{
    & p{
      font-size: 1.8rem !important;
    }
  }
}

@media screen and (min-width: 2401px) and (max-width: 2800px) {
  .our-story-content p{
    font-size: 1.9rem;
    font-weight: 500;
  }
  .box-content{
    font-size: 1.9rem;
  }
  .mkd-content{
    & p{
      font-size: 1.9rem;
    }
  }
}
@media screen and (min-width: 2801px) and (max-width: 2999px) {
  .our-story-content p{
    font-size: 2.2rem;
    font-weight: 500;
  }
  .box-content{
    font-size: 2.2rem;
  }
  .mkd-content{
    & p{
      font-size: 2.2rem;
    }
  }
}
@media screen and (min-width:3000px) {
  .our-story-content p{
    font-size: 2.5rem !important;
    font-weight: 500;
  }
  .box-content{
    font-size: 2.5rem !important;
  }
  .mkd-content{
    & p{
      font-size: 2.5rem !important;
    }
  }
  
}