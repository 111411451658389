@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/mixins.scss";

.page-container-Blogs {
  background-color: $background-color;
  font-family: Open Sans;
  @include position-rel;
  overflow-x: hidden;
}
.main-container-Blogs {
  background-color: $white;
  margin: 0;
  padding-bottom: 4.5%;
  width: 100%;
  z-index: 0;

  & h1 {
    @include text-center;
    width: 100%;
    color: $primary;
    font-size: 3.2rem !important;
    font-weight: 500;
    padding-top: 3%;
    margin: 0;
  }

  & .blog-card-row {
    @include position-rel;

    & .last-div-Blogs {
      padding-top: 2.5%;

      & .first-Blogs {
        color: $secondary;
        font-weight: 700;
        font-size: 2rem;
        letter-spacing: 0.2rem;
      }

      & .second-Blogs {
        border: 1px solid;
        font-size: 7rem;
        font-weight: 900;
        letter-spacing: 0.5rem;
        line-height: 7.1rem;
        -webkit-text-stroke: 2px $stroke;
        text-align: justify;
        color: $white;
        padding-bottom: 8%;
        padding-top: 2%;
      }
    }

    .blue-box-upper-Blogs {
      @include position-abs;
      height: 10%;
    }
  }
}
.vert-line-Blogs {
  @include position-abs;
  top: 0%;
  left: 97%;
  height: 100%;
}
.card-heading-Blogs {
  @include display-none;
}
.blog-card-row {
  @include flex-row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow-x: hidden;
  padding-top: 3%;
  padding-left: 9%;
  padding-bottom: 2%;
  row-gap: 4vh;
  margin: 0 auto;
  & .blog-card-col {
    @include flex-column;
    font-weight: 600;
    margin-right: 2%;
    border: 0;
    background-color: $white;
    box-shadow: 1px 1px 10px $black-shadow;
    border-radius: 0%;
    padding: 0;
    width: 30%;
    justify-content: space-between;
    & .blog-card-body {
      padding: 3vh 3.5vh 2.5vh 3.5vh;
      & .blog-card-content {
        @include flex-column;
        flex-wrap: wrap;
        row-gap: 1.5vh;
        & p {
          margin: 0;
          font-size: 1rem;
        }
        & .blog-card-first-word {
          color: $primary;
        }
      }
    }
    & .read-btn-blog {
      position: relative;
      bottom: 8px;
      left: 1.8vw;
      padding-top: 2vh;
      width: 8vw;
      a {
        @include flex-row;
        row-gap: 2vw;
        align-items: center;
        font-size: 1.2rem;
        text-decoration: none;
        color: $primary;
        width: 100%;
      }
      .arrow-icon {
        margin-left: 10px;
        transition: transform 0.2s ease-in-out;
      }
      .effect.hover-effect:hover .arrow-icon {
        transform: translateX(5px);
      }
    }
  }
}
@media screen and (max-width: 1800px) {
  .main-container-Blogs {
    & h1 {
      font-size: 56px;
    }
  }
   .blog-card-col {
    & .read-btn-blog {
      width: 30vw !important;
      & a {
        font-size: 1.8rem;
      }
    }
  }
}
@media screen and (min-width: 2200px) {
  .main-container-Blogs {
    & h1 {
      font-size: 56px;
    }
    .blog-card-row {
      & .blog-card-col {
        & .blog-card-body {
          & .blog-card-content {
            & p {
              margin: 0;
              font-size: 1.3rem;
            }
            & a {
              font-size: 1.2rem;
            }
          }
        }
        & .read-btn-blog {
          width: 10vw;
          & a {
            font-size: 1.8rem;
          }
        }
      }
      & .last-div-Blogs {
        padding-top: 2.5%;

        & .first-Blogs {
          font-size: 2rem;
        }

        & .second-Blogs {
          font-size: 80px;
        }
      }
    }
  }
}

@media screen and (min-width: 2600px) {
  .main-container-Blogs {
    & h1 {
      font-size: 3.9rem;
    }
    .blog-card-row {
      & .blog-card-col {
        & .blog-card-body {
          & .blog-card-content {
            & p {
              font-size: 1.6rem;
            }
            & a {
              font-size: 1.4rem;
            }
          }
        }
        & .read-btn {
          margin-top: 3vh;
          margin-left: -0.5vw;
          & a {
            width: 7vw;
            font-size: 1.7rem;
          }
        }
      }
      & .last-div-Blogs {
        padding-top: 2.5%;

        & .first-Blogs {
          font-size: 3rem;
        }

        & .second-Blogs {
          font-size: 6rem;
        }
      }
    }
  }
}

@media screen and (min-width: 3000px) {
  .main-container-Blogs {
    & h1 {
      font-size: 5rem !important;
    }
    .blog-card-row {
      & .blog-card-col {
        & .blog-card-body {
          & .blog-card-content {
            & p {
              font-size: 2rem;
            }
            & a {
              font-size: 1.8rem;
            }
          }
        }
        & .read-btn-blog {
          width: 10vw;
          & a {
            font-size: 2rem;
          }
        }
      }
      & .last-div-Blogs {
        padding-top: 2.5%;
        & .first-Blogs {
          font-size: 4rem;
        }

        & .second-Blogs {
          font-size: 8rem;
          line-height: 8rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .main-container-Blogs {
    & .blog-card-row {
      padding-left: 2%;
      padding-right: 8%;

      & .blog-card-col {
        .blog-card-body {
          padding: 12%;
        }
        & .read-btn-blog {
          left: 2vw;
          width: 30vw !important;
        }
      }
    }
    }
  }


@media screen and (max-width: 767px) {
  .page-container-Blogs {
    background-color: $background-color;
    width: 100%;
    padding-left: 0%;
    @include height-fit-content;
  }
  .main-container-Blogs {
    padding-left: 2%;
    background-color: $background-color;
    & h1 {
      text-align: left !important;
      font-size: 2rem !important;
      padding-left: 5%;
      padding-bottom: 1vh;
    }
    & img {
      right: 0;
      top: -10%;
    }
    & .card-heading-Blogs {
      padding-top: 0%;
      padding-left: 5%;
      display: block;
      font-size: 4rem;
      line-height: 4.1rem;
      font-weight: 900;
      letter-spacing: 0.2rem;
      -webkit-text-stroke: 1px $stroke;
      text-align: justify;
      color: $background-color;
      padding-bottom: 3%;
      z-index: 0;
      @include position-rel;
      & .blue-box {
        @include position-abs;
        top: 5%;
        right: 0%;
        height: 100%;
        width: 43%;
        z-index: -1;
        padding-bottom: 2vh;
      }
      & .circles-Test-mob {
        @include position-abs;
        display: none;
      }
      .box-Blogs {
        @include position-rel;
        left: 50%;
        width: 40%;
        height: 100%;
        z-index: 100;
      }
      .blog-card-row {
        margin-top: 2vh !important;
      }
    }
    & .blog-card-row {
        padding: 0vw;
        & .blog-card-col {
          width: 90%;
          & .read-btn-blog {
            left: 8vw;
            width: 30vw !important; 
          }
        }
        & .last-div-Blogs {
          & a {
            @include flex-row;
            justify-content: center;
            text-decoration: none;
          }
        }
      }

    & .last-div-Blogs {
      & .first-Blogs {
        @include display-none;
      }
      & .second-Blogs {
        @include display-none;
      }
    }
  }
  .vert-line-Blogs {
    @include display-none;
  }
}
@media screen and (max-width: 450px) {
  .main-container-Blogs {
    & h1 {
      font-size: 1.5rem;
      text-align: center;
    }

    & .card-heading-Blogs {
      font-size: 4rem;
      line-height: 4.1rem;
      padding-top: 0.5vh;
    }
    & .blog-card-row {
      padding: 0vw;
      & .blog-card-col {
        width: 90%;
        & .blog-card-body{
          padding: 2em;
        }
        & .read-btn-blog {
          left: 8vw;
          width: 43vw !important;
        }
      }
      & .last-div-Blogs {
        & a {
          @include flex-row;
          justify-content: center;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 430px){
  .blog-card-row {
    & .blog-card-col {
      & .read-btn-blog {
        left: 8vw;
        width: 80vw !important;
      }
    }
  }
}

@media screen and (min-width:312px) and (max-width: 350px) {
  .main-container-Blogs {
    & .card-heading-Blogs {
      font-size: 3.1rem;
      line-height: 3.5rem;
    }
  }
}
@media screen and (max-width: 1050px) {
  .main-container-Blogs {
    & .blog-card-row {
      & .last-div-Blogs {
        & .first-Blogs {
          font-size: 1.2rem;
        }
        & .second-Blogs {
          font-size: 4rem;
          line-height: 4.1rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1051px) and (max-width: 1350px) {
  .main-container-Blogs {
    & .blog-card-row {
    & .last-div-Blogs {
      & .second-Blogs {
          font-size: 5rem !important;
          line-height: 5.1rem !important;
        }
      }
    }
  }
}
