@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/common.scss';
@import '../../../../shared/assets/css/mixins.scss';

.card-main-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2vw;
  padding-top: 6vh;

  & .team-card {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 50vh;
    perspective: 1000px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 1.5s;
    // cursor: pointer;  // used in future.
    border-radius: 10px;
    & .team-card-face {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .team-card-front,
    .team-card-back {
      width: 100%;
      height: 100%;
      border-radius: 10px;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    & .team-card-front {
      transform: rotateY(0deg);
    }

    .team-card-back {
      border-radius: 10px;
      transform: rotateY(180deg);
      overflow: hidden;
    }

    &.flipped {
      transform: rotateY(180deg);
      border: none;
    }
  }

  & .team-cards {
    @include flex-column;
    align-items: center;
    justify-content: center;
  }
  & .team-card-content {
    @include flex-column;
    padding-top: 2vh;
    text-align: center;

    & .team-card-name {
      font-size: 1.5rem;
      font-weight: 700;
    }
    & .team-card-desg {
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      margin-top: -1.2em;
    }
  }
}
@media only screen and (min-width: 2600px) and (max-width: 3500px){
  .card-main-container {
    & .team-card-content {
      padding-top: 0.5vh;
      & .team-card-name {
        font-size: 2.5rem;
        font-weight: 600;
      }
      & .team-card-desg {
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
        margin-top: -0.7em;
      }
    }
    & .team-card-front{
      border-radius: 4px;
    }
  }
}
@media only screen and (min-width: 1101px) and (max-width: 1499px){
  .card-main-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 4vh;
    

    & .team-card {
      grid-template-columns: repeat(4, 1fr);
      height: 40vh;
    }
    & .team-card-content {
      padding-top: 0.5vh;
      & .team-card-name {
        font-size: 1.5rem;
        font-weight: 700;
      }
      & .team-card-desg {
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        margin-top: -1.2em;
      }
    }
    & .team-card-front{
      border-radius: 4px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1100px){
  .card-main-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3vw;
    padding-top: 4vh;

    & .team-card {
      grid-template-columns: repeat(3, 1fr);
      height: 28vh;
    }
    & .team-card-content {
      padding-top: 0.5vh;
      & .team-card-name {
        font-size: 1.2rem;
        font-weight: 700;
      }
      & .team-card-desg {
        font-size: 0.8rem;
        font-weight: 600;
        text-align: center;
        margin-top: -1.2em;
      }
    }
    & .team-card-front{
      border-radius: 4px;
    }
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px){
  .card-main-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4vw;
    padding-top: 4vh;

    & .team-card {
      grid-template-columns: repeat(2, 1fr);
      height: 26vh;
    }
    & .team-card-content {
      padding-top: 0.5vh;
      & .team-card-name {
        font-size: 1.2rem;
        font-weight: 700;
      }
      & .team-card-desg {
        font-size: 0.8rem;
        font-weight: 600;
        text-align: center;
        margin-top: -1.2em;
      }
    }
    & .team-card-front{
      border-radius: 4px;
    }
  }
}
@media only screen and (min-width: 312px) and (max-width: 480px){
  .card-main-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4vw;
    padding-top: 2vh;
    counter-reset: 1vw;
    & .team-card {
      grid-template-columns: repeat(2, 1fr);
      height: 23vh;
    }
    & .team-card-content {
      padding-top: 0.5vh;
      & .team-card-name {
        font-size: 1rem;
        font-weight: 700;
      }
      & .team-card-desg {
        font-size: 0.8rem;
        font-weight: 600;
        text-align: center;
        margin-top: -1.2em;
      }
    }
    & .team-card-front{
      border-radius: 4px;
    }
  }
}