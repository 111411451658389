@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/mixins.scss';

.breadcrumb-hover {
    cursor: default;
}

.page-container-DevOps {
    @include font;
    background-color: $background-color;
    overflow-x: hidden;
    padding-left: 10%;
    @include position-rel;
    & .image-container {
        background-image: url('../../shared/assets/images/devops-bg.jpg');
    }

    & .about-our-service {
        & .left-section {
            & h2 {
                text-align: left;
            }

            & p {
                width: 100%;
            }
        }

        &>img {
            @include display-none;
        }

        & .circles-DevOps {
            @include display-none;
        }
    }

    & .lower-div {

        & .teams-accreditation {
            background-color: $white;
            padding: 2.5rem 0rem;

            &>h2 {
                @include text-center;
                font-weight: 600;
            }

            &>p {
                @include text-center;
                font-weight: 500;
                padding: 0.5rem 0rem 1rem 0rem;
            }

            & .images-teams-accreditation {
                @include flex-row;
                justify-content: space-around;

                & img {
                    height: 15%;
                    width: 15%;
                }
            }
        }

        & .devops-expertise {
            padding-top: 4rem;

            &>h2 {
                @include text-center;
                color: $primary;
            }

            & .devops-expertise-images {
                padding-top: 2rem;
                @include flex-row;
                justify-content: space-evenly;
                overflow-y: hidden;

                & img {
                    height: 12%;
                    width: 12%;
                }

                & .vertical-line-devops {
                    width: 0.4%;
                    height: 20vh;
                    border-left: 2px solid $grey;
                }

                .image-wrapper {
                    position: relative;
                    margin: 10px;
                    text-align: center;
                }

                .hover-effect {
                    width: 65%; // Set the desired width
                    height: 100%;
                    transition: transform 0.3s ease-in-out;
                }

                .text-overlay {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 76%;
                    background-color: transparent;
                    color: gray;
                    text-align: center;
                    padding: 10px;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }

                .image-wrapper:hover .hover-effect {
                    transform: scale(1.1); // Set the desired scale on hover
                }

                .image-wrapper:hover .text-overlay {
                    opacity: 1;
                }
            }
        }
    }
}

@media screen and (min-width: 2500px) {
    .page-container-DevOps {
        .about-our-service {
            & .left-section {

                height: 58vh;

                & h5 {
                    font-size: 4.5rem;
                }

                & p {
                    font-size: 3rem;
                }

                & h2 {
                    font-size: 8rem;
                    line-height: 7rem;
                }

                & p {
                    & .blue-box {
                        top: -7%;
                        height: 15%;
                        right: 3%;
                    }

                    & .red-box-sq {
                        bottom: -18%;
                        height: 15%;
                        width: 15%;
                    }
                }

            }
        }
        & .lower-div {

            & .teams-accreditation {

                &>h2 {
                    font-size: 3rem;
                }

                &>p {
                    font-size: 2rem;
                }
            }

            & .devops-expertise {
                &>h2 {
                    font-size: 3rem;
                }

                & .devops-expertise-images {
                    .text-overlay {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 3000px) {
    .page-container-DevOps {
        .about-our-service {
            & .left-section {

                height: 58vh;

                & h5 {
                    font-size: 5.8rem;
                }
                & p {
                    font-size: 3.5rem;
                }
    
                & h2 {
                    font-size: 10rem;
                    line-height: 9rem;
                }

                & p {
                    & .blue-box {
                        top: -7%;
                        height: 15%;
                        right: 3%;
                    }

                    & .red-box-sq {
                        bottom: -18%;
                        height: 15%;
                        width: 15%;
                    }
                }

            }
        }

        & .lower-div {

            & .teams-accreditation {

                &>h2 {
                    font-size: 3rem;
                }

                &>p {
                    font-size: 2.5rem;
                }
            }

            & .devops-expertise {
                &>h2 {
                    font-size: 3rem;
                }

                & .devops-expertise-images {
                    .text-overlay {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .page-container-DevOps {
        & .about-our-service {
            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 82px;
                    line-height: 6rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .page-container-DevOps {
        & .about-our-service {
            padding-top: 5vh;
            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .page-container-DevOps {
        & .about-our-service {
            & .left-section {
                & h2 {
                    font-size: 3rem;
                    line-height: 3.5rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .page-container-DevOps {
        & .about-our-service {
            padding-top: 5vh;
            & .left-section {
                & h2 {
                    font-size: 3rem;
                    line-height: 3.5rem;
                    width: 50%;
                    padding-top: 2vh;
                }
                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-container-DevOps {
        padding-left: 0%;
        & .about-our-service {
            @include position-rel;
            z-index: 100;

            & .left-section {
                @include position-rel;
                width: 100%;

                & h5 {
                    @include display-none;
                }

                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;

                    & img {
                        padding-left: 3%;
                        padding-bottom: 1%;
                    }
                }

                & p {
                    margin-top: 4%;
                    position: relative;
                    background-image: url('../../shared/assets/images/devops-img.jpg');
                    background-color: gray;
                    background-size: cover;
                    color: $white;
                    @include text-center;
                    width: 100%;
                    height: fit-content;
                    padding: 30% 10%;
                    font-weight: 500;
                    z-index: 200;
                    font-size: 1.7rem;

                    & .blue-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        right: -9%;
                        top: -3%
                    }

                    & .red-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        left: -9%;
                        bottom: -4%
                    }

                    & .red-box-sq {
                        @include display-none;
                    }
                }

                & p::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    z-index: -1;
                }

                &>img {
                    @include display-none;
                }
            }

            & .right-section {
                @include display-none;
            }

            &>img {
                display: block;
                @include position-abs;
                top: 3%;
                right: 0%;
                z-index: -1;
                transform: rotate(90deg);
                height: 22%;
            }

            .circles-DevOps {
                display: block;
                height: 1.5%;
                transform: rotate(0deg);
                @include position-abs;
                z-index: -1;
                top: 20%;
                right: 2%;
            }
        }

        & .lower-div {

            & h2 {
                font-size: 36px;
            }
            
            & .page-container-Cards {
                padding-bottom: 15%;
            }

            & .teams-accreditation {
                & p{
                    font-size: 1.2rem;
                }
                & .images-teams-accreditation {
                    text-align: center;
                    margin: auto;
                    width: 80%;
                    display: block;

                    & img {
                        height: 25%;
                        width: 25%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .page-container-DevOps {
        & .about-our-service {

            & .left-section {
                & h2 {
                    width: 100%;
                    padding-top: 0 !important;
                    padding-bottom: 4vh;
                }

                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;
                }

                & p {
                    padding: 30% 5%;
                    font-weight: 500;
                    z-index: 200;
                    font-size: 1.2rem;

                }
            }
        }

        & .lower-div {

            & h2 {
                font-size: 36px;
            }
            & .page-container-Cards {
                padding-bottom: 6em;
            }

            & .teams-accreditation {
                & .images-teams-accreditation {
                    & img {
                        height: 25%;
                        width: 25%;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:768px) and (max-width: 1024px) {
    .page-container-DevOps {
        & .about-our-service {
            & .left-section {
                & p {
                    font-size: 1.1rem;
                }
            }
        }
    }

}
@media screen and (min-width:768px) {
    .teams-accreditation {
        & p{
            font-size: 1.2rem;
        }
    }
}
@media screen and (min-width:2000px) and (max-width:2400px){
    .teams-accreditation {
        & p{
            font-size: 1.8rem;
        }
    }
}
@media screen and (min-width:2401px) and (max-width:2800px){
    .teams-accreditation {
        & p{
            font-size: 1.9rem;
        }
    }
}
@media screen and (min-width:2801px) and (max-width:2999px){
    .teams-accreditation {
        & p{
            font-size: 2.2rem;
        }
    }
}

