@import "../../shared/assets/css/mixins.scss";
@import '../../shared/assets/css/variables';
@import "../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";
.team-container {
  @include flex-column;
  @include font;
  z-index: -1;
  padding-left: 10%;
}
@media (max-width:767px) {
  .team-container{
    padding-left: 0;
    margin-top:7.5vh ;
  }
}
.team-image-container {
  background-image: url("../../shared/assets/images/team-bg.jpg");
}
.meet-our-team{
  @include flex-column;
  padding: 6vh 6vw 6vh 6vw;
  & .team-header{
    color: $primary;
    text-align: center;
    & h2{
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
    }
  }
}
//Media Queries started from here
@media only screen and (min-width: 2600px) and (max-width: 3500px) {
  .meet-our-team{
    & .team-header{
      & h2{
        font-size: 4.5rem;
      }
    }
  }
}
@media only screen and (min-width: 1921px) and (max-width: 2600px) {
  .meet-our-team{
    & .team-header{
      & h2{
        font-size: 3.8rem;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1100px){
  .meet-our-team{
    @include flex-column;
    padding: 4vh 6vw 4vh 6vw;
    & .team-header{
      color: $primary;
      text-align: center;
      & h2{
        font-size: 2.8rem;
        font-weight: 500;
        margin: 0;
      }
    }
  }
  }
@media only screen and (min-width: 481px) and (max-width: 767px){
  .meet-our-team{
    @include flex-column;
    padding: 4vh 4vw 4vh 4vw;
    & .team-header{
      color: $primary;
      text-align: center;
      & h2{
        font-size: 2.5rem;
        font-weight: 500;
        margin: 0;
      }
    }
  }
  }
@media only screen and (min-width: 312px) and (max-width: 480px){
.meet-our-team{
  @include flex-column;
  padding: 2vh 4vw 4vh 4vw;
  & .team-header{
    color: $primary;
    text-align: center;
    & h2{
      font-size: 2rem;
      font-weight: 500;
      margin: 0;
    }
  }
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .meet-our-team{
    & .team-header{
      & h2{
        font-size: 3.2rem;
        font-weight: 500;
      }
    }
  }
  }