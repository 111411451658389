@import '../../../shared/assets/css/variables';
@import '../../../shared/assets/css/mixins.scss';

.fs-20 {
  font-size: 20px;
}
.fs-22{
  font-size: 22px;
}
.fs-90{
    font-size: 90px;
}
.fs-26{
    font-size: 26px;
}
.fs-54{
    font-size: 54px;
}
.fs-32{
    font-size: 32px;
}
.m-20 {
  margin: 20px;
}

.pd-5 {
  padding: 5px;
}

.pd-left-10 {
  padding-left: 10px;
}

.scroll-y {
  overflow-y: auto;
}
.scroll-x {
  overflow-x: hidden !important;
}

// To hide scroll bar
.scroll-y::-webkit-scrollbar {
  display: none;
}
.fw-light{
  font-weight: 400;
  font-style: italic;
  text-decoration: none;
  color: $white;
}
.no-decoration{
  text-decoration: none;
  color: $white;
}
.no-decoration:hover{
  color: $white;
}
.img-size {
  width: 100%;
  height: 100%;
}
.margin-bottom{
  margin-bottom: 0 !important;
}
.display-toggle {
  display: none;
}
.display-block{
    display: block;
}
.display-initial {
  display: initial;
}
.rotated {
  transform: rotate(180deg);
}

.blue-rect-mob::before {
  position: absolute;
  height: 100%;
  content: url('../images/blue-rect.svg');
}
.red-rect-mob::after {
  position: absolute;
  height: 100%;
  content: url('../images/red-rect.svg');
}
.page-content {
    margin-left: 270px;
    background: aqua;
    width: 100%;
}
.display-void {
  display: none !important;
}
//common image container css

.image-container {
  @include flex-column;
  border: 1px solid $white;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  @include position-rel;
  padding: 6vh 1vw 20vh 6vw;

  & .text-container {
    @include flex-column;
    row-gap: 1vh;
    padding-top: 14vh;
  & .heading{
    @include position-rel;
    font-family: "Fira Sans";
    font-size: 4.5rem;
    font-weight: 500;
    letter-spacing: 3px;
    z-index: 1;
    margin: 0;
    padding-right: 0.5vw
  }
  & .heading-desc{
    @include font;
    @include position-rel;
    font-size: 1.5rem;
    width: 90%;
    font-weight: 500;
    z-index: 1;
    margin: 0;
    line-height: 2rem;
  }
}
  & .breadcrumb {
    @include flex-row;
    position: absolute;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    color: $white;
    bottom: 0;
    z-index: 10;
    margin-bottom: 0;
    & .breadcrumb-separator {
      margin: 0 0.5rem; /* Adjust the margin value to your preference */
    }
  }
  & a {
    text-decoration: none;
    color: $white;
    z-index: 100;
  }
  & .red-bg-box {
    @include position-abs;
    background-color: $light-primary;
    z-index: 0;
    height: 80%;
    width: 27%;
    bottom: 0%;
    left: 15%;
    opacity: 0.7;
  }
}
@media only screen and (min-width: 2600px) {
  .image-container {
    & .text-container {
      row-gap: 2vh;
      & .heading {
        font-size: 7rem;
        line-height: 7.5rem;
      }
      & .heading-desc{
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
      & p {
        font-size: 3.8rem;
        line-height: 4.4rem;
      }
    }
    .breadcrumb {
      font-size: 2.2rem;
      font-weight: 500;
    }
  }
} 
  @media only screen and (min-width: 3200px) {
    .image-container {
      & .text-container {
        & .heading {
          font-size: 7.5rem;
        }
        & .heading-desc{
          font-size: 2.5rem;
          line-height: 3rem;
        }
        & p {
          font-size: 3.8rem;
          line-height: 4.4rem;
        }
      }
      .breadcrumb {
        font-size: 2.5rem;
        font-weight: 500;
      }
    }
  } 
@media only screen and (min-width: 1921px) and (max-width:2500px) {
  .image-container {
    padding-left: 4vw;
    & .text-container {
      & .heading {
        font-size: 5rem;
      }
      & .heading-desc{
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 43px;
      }
      & p {
        font-size: 3.8rem;
      }
    }
    .breadcrumb {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .image-container {
    padding-left: 2em;
    & .text-container {
      padding-top: 5vh;
    & .heading{
      font-size: 4rem;
      line-height: 4.5rem;
      font-weight: 600;
    }
    & .heading-desc{
      font-size: 1.2rem;
      width: 95%;
      font-weight: 500;
    }
  }
    .breadcrumb {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}

@media screen and (min-width:2500px) and (max-width:2599px){
  .image-container{
    & .text-container{
      & .heading-desc{
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
    } 
    
}
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .image-container {
    & .text-container {
      margin-top: -3vh;
    & .heading{
      font-size: 3.5rem;
      font-weight: 600;
      & .heading-desc{
        display: none;
      }
    }    
  }
    .breadcrumb {
      font-size: 1.4rem;
      font-weight: 500;
      & .breadcrumb-separator {
        margin: 0 0.2rem;
      }
    }
    & .red-bg-box {
      height: 68%;
      width: 36%;
    }
  }
}
@media only screen and (min-width: 312px) and (max-width: 480px) {
  .image-container {
    & .text-container {
      margin-top: -3vh;
    & .heading{
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 3.2rem;
      & .heading-desc{
        display: none;
      }
    }
  }
    .breadcrumb {
      font-size: 1rem;
      font-weight: 500;
    }
    & .red-bg-box {
      height: 68%;
      width: 38%;
      left: 17%;
    }
  }
}
// Media query starts from here
@media (max-width: 767px) {
    .display-none {
        display: none !important;
    }

    .display-toggle {
        display: block !important;
    }

    .page-content {
        margin-left: 0;
        background: aqua;
        width: 100%;
    }

    .image-container{
        & .heading-WebApp{
            font-size: 48px;    
        }

        & .breadcrumb{
            font-size: 16px;
        }
    }

    .footer-mob {
      margin-left: -1%;
    }
}
  
  .fs-18{
    font-size: 18px;
  }
  .fs-40{
    font-size: 40px;
  }

@media (max-width:1300px){
  .fs-l-20{
    font-size: 20px;
  }
}
.position-abs {
  position: absolute;
}

.position-rel {
  position: relative;
}

.sideBorder {
  border-right: 4px solid $primary;
  height: 100%;
  position: absolute;
  right: 2%;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.drop-in {
  animation: drop-in 1s ease 150ms backwards;
 }
.drop-in-2 {
  animation: drop-in 1200ms ease 400ms backwards;
 }
 .drop-in-3{
  animation: drop-in 1400ms ease 600ms backwards;
 }
 @keyframes drop-in {
  from {
   opacity: 0;
   transform: translateY(-100px);
  }
  to {
   opacity: 1;
   transform: translate(0px);
  }
 }
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

