@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';

.page-container-techspaces {
    padding-left: 10%;

    & .image-container {
        background-image: url('../../../../shared/assets/images/tech_bg.png');
        @include flex-column;
        border: 1px solid $white;
        height: 65vh;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;
        padding-left: 6vw;
        @include position-rel;
        padding-top: 6%;

        & .heading {
            @include position-rel;
            font-family: "Fira Sans";
            font-size: 4.5rem;
            font-weight: 600;
            letter-spacing: 3px;
            z-index: 1;
            margin-top: 48px;
            line-height: 4.5rem;
            width: 30%;
        }

        & .red-bg-box {
            @include position-abs;
            background-color: $light-primary;
            z-index: 0;
            height: 85%;
            width: 27%;
            bottom: 0%;
            left: 17%;
        }

        & .breadcrumb-separator {
            margin: 0 0.5rem;
        }
    }
}

@media screen and (min-width: 3200px) {
    .page-container-techspaces {
        & .image-container {
            & .heading {
                font-size: 7.5rem;
                line-height: 8rem;
                margin-top: 130px;
            }
        }
    }
}

@media screen and (min-width: 2600px) {
    .page-container-techspaces {
        & .image-container {
            & .heading {
                font-size: 7rem;
                line-height: 7.5rem;
                margin-top: 100px;
            }
        }
    }
}

@media screen and (min-width: 1921px) and (max-width: 2599px) {
    .page-container-techspaces {
        & .image-container {
            & .heading {
                font-size: 5rem;
                line-height: 5.5rem;
                margin-top: 80px;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .page-container-techspaces {
        & .image-container {
            height: 60vh;

            & .heading {
                width: 45%;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .page-container-techspaces {
        & .image-container {
            & .heading {
                font-size: 62px;
                line-height: 4.5rem;
                width: 50%;
            }

            & .breadcrumb {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .page-container-techspaces {
        & .image-container {
            & .heading {
                font-size: 54px;
            }

            & .breadcrumb {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-container-techspaces {
        padding-left: 0%;
        margin-top: 7.5vh;

        .image-container {
            height: 40vh;

            & .heading {
                font-size: 46px;
                line-height: 3.5rem;
                width: 60%
            }

            & .breadcrumb {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .page-container-techspaces {
        padding-left: 0%;

        .image-container {
            height: 30vh;

            & .heading {
                width: 100%;
                font-size: 2.5rem;
                line-height: 3rem;
            }

            & .breadcrumb {
                font-size: 16px;
            }
        }
    }
}