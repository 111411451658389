@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';

.page-container-info-architecture-design {
    padding-left: 10%;

    & .image-container {
        background-image: url('../../../../shared/assets/images/info-architecture-design-bg.jpg');
    }

    & .main-container-services {
        & .card-services {
            height: 100%;
        }
    }
}
@media screen and (max-width: 767px) {
    .page-container-info-architecture-design {
        padding-left: 0%;
    }
}