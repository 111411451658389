@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/common.scss';
@import '../../../../shared/assets/css/mixins.scss';

.modal-box {
  display: block;
  background-color: $white;
  margin-left: 11%;
  width: 85vw;
  height: 80vh;
  padding: 1rem;
  box-shadow: 0 0 20px $black-shadow;
  z-index: 9000 !important;
  color: $white;
  outline: none;
  overflow-y: scroll;
}
.my-modal{
  display: block;
}
.close-icon {
  position: absolute;
  right: 2%;
  top: 4%;
  cursor: pointer;

  & img {
    width: 100%;
  }
}

.contact-container {
  & .contact-row {
    flex-wrap: wrap;
    width: 87%;

    & .contact-col {
      @include flex-column;
      flex-wrap: wrap;
      padding-top: 3vh;
      padding-left: 6vh;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;

      & .contact-header {
        @include text-stroke;

        & p {
          font-size: 2rem;
          font-weight: 900;
          letter-spacing: 10px;
          margin: 0;
          padding-left: 3.2vh;
        }
      }

      & .contact-sub-header {
        & p {
          font-size: 2rem;
          color: $secondary;
          font-weight: 600;
          letter-spacing: 1px;
          margin: 0;
          padding-left: 3vh;
          padding-top: 1vh;
          text-align: start;
          line-height: 2.5rem;
        }
      }

      & .contact-img {
        padding-top: 3vh;

        & img {
          width: 95%;
        }
      }

      & .contact-info {
        @include flex-row;
        justify-content: flex-start;
        padding-left: 3vh;
        column-gap: 15px;
        margin-top: 2vh;

        & img {
          width: 100%;
          padding-top: 1%;
        }

        & p {
          margin: 0;
          font-size: 1rem;
          font-weight: 400;
          text-align: start;
          color: $black;
          text-decoration: none;

          & a {
            color: $black;
            text-decoration: none;
          }
        }

        & .phone-icon {
          width: 15%;
        }
      }

    }

    & .contact-col-details {
      @include flex-column;
      padding-top: 3vh;
      margin: 0 auto;

      & .contact-sub-header {
        & p {
          font-size: 2rem;
          color: $secondary;
          font-weight: 600;
          letter-spacing: 1px;
          margin: 0;
          padding-left: 2.8vw;
          padding-top: 2vh;
          text-align: start;
          line-height: 2rem;
        }
      }

      & .or-text {
        font-size: 1.5rem;
        margin-top: 1rem;
        font-weight: 600;
        color: black;
        margin-bottom: -1rem;
        text-align: center;
      }

      & .email-link {
        padding-top: 3vh;
        z-index: 9999;
        text-align: center;

        & p {
          font-size: 1.1rem;
          color: $black;
          padding-left: 1vw;

        }

        & a {
          color: $secondary;
          font-size: 1.1rem;
          text-decoration: none;
        }
      }
    }
  }
}

.vertical-line {
  border-left: 2px solid $grey;
  height: 68vh;
  position: absolute;
  left: 57%;
  top: 8%;
}

@media screen and (min-width: 2001px) and (max-width: 2700px) {
  .modal-box {
    display: block;
    background-color: $white;
    margin-left: 11%;
    width: 80vw;
    height: 80vh;
    padding: 1rem;
    box-shadow: 0 0 20px $black-shadow;
    z-index: 1000;
    color: $white;
  }

  .close-icon {
    position: absolute;
    right: 2%;
    top: 4%;
    cursor: pointer;
  }

  .contact-container {
    & .contact-row {
      flex-wrap: wrap;
      width: 87%;

      & .contact-col {
        @include flex-column;
        flex-wrap: wrap;
        padding-top: 3vh;
        padding-left: 5vw;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        & .contact-header {
          @include text-stroke;

          & p {
            font-size: 2rem;
            font-weight: 900;
            letter-spacing: 8px;
            margin: 0;
            padding-left: 3.2vh;
          }
        }

        & .contact-sub-header {
          & p {
            font-size: 2rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0;
            padding-left: 3vh;
            padding-top: 1vh;
            text-align: start;
            line-height: 2.5rem;
          }
        }

        & .contact-img {
          padding-top: 3vh;

          & img {
            width: 95%;
          }
        }

        & .contact-info {
          @include flex-row;
          justify-content: flex-start;
          padding-left: 3vh;
          column-gap: 15px;
          margin-top: 2vh;

          & img {
            width: 100%;
            padding-top: 1%;
          }

          & p {
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
            text-align: start;
            color: $black;
            text-decoration: none;

            & a {
              color: $black;
              text-decoration: none;
            }
          }

          & .phone-icon {
            width: 15%;
          }
        }

      }

      & .contact-col-details {
        @include flex-column;
        padding-top: 3vh;
        margin: 0 auto;

        & .contact-sub-header {
          & p {
            font-size: 2rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0;
            padding-left: 2.8vw;
            padding-top: 2vh;
            text-align: start;
            line-height: 2rem;
          }
        }

        & .or-text {
          font-size: 1.7rem;
          margin-left: -1rem;
          margin-top: 1rem;
          font-weight: 600;
          color: black;
          margin-bottom: -1rem;
          text-align: center;
        }

        & .email-link {
          padding-top: 3vh;
          z-index: 9999;
          text-align: center;

          & p {
            font-size: 1.1rem;
            color: $black;
            padding-left: 1vw;

          }

          & a {
            color: $secondary;
            font-size: 1.1rem;
            text-decoration: none;
          }
        }
      }
    }
  }

  .vertical-line {
    border-left: 2px solid $grey;
    height: 68vh;
    position: absolute;
    left: 52%;
    top: 8%;
  }
}

@media screen and (min-width: 2800px) {
  .modal-box {
    display: block;
    background-color: $white;
    margin-left: 11%;
    width: 80vw;
    height: 80vh;
    padding: 1rem;
    box-shadow: 0 0 20px $black-shadow;
    z-index: 1000;
    color: $white;
  }

  .close-icon {
    position: absolute;
    right: 2%;
    top: 4%;
    cursor: pointer;

    & img {
      width: 133%;
    }
  }

  .contact-container {
    & .contact-row {
      flex-wrap: wrap;
      width: 87%;

      & .contact-col {
        @include flex-column;
        flex-wrap: wrap;
        padding-top: 3vh;
        padding-left: 3vw;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        & .contact-header {
          @include text-stroke;

          & p {
            font-size: 3rem;
            font-weight: 900;
            letter-spacing: 8px;
            margin: 0;
            padding-left: 3.2vh;
          }
        }

        & .contact-sub-header {
          & p {
            font-size: 3rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 2px;
            margin: 0;
            padding-left: 3vh;
            padding-top: 1vh;
            text-align: start;
            line-height: 3.5rem;
          }
        }

        & .contact-img {
          padding-top: 3vh;

          & img {
            width: 95%;
          }
        }

        & .contact-info {
          @include flex-row;
          justify-content: flex-start;
          padding-left: 3vh;
          column-gap: 15px;
          margin-top: 2vh;

          & img {
            width: 100%;
            padding-top: 50%;
          }

          & p {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 400;
            text-align: start;
            color: $black;
            text-decoration: none;

            & a {
              color: $black;
              text-decoration: none;
            }
          }

          & .phone-icon {
            width: 15%;
          }
        }

      }

      & .contact-col-details {
        @include flex-column;
        padding-top: 3vh;
        margin: 0 auto;

        & .contact-sub-header {
          & p {
            font-size: 3rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0;
            padding-left: 2.8vw;
            padding-top: 2vh;
            text-align: start;
            line-height: 2rem;
          }
        }

        & .or-text {
          font-size: 2rem;
          font-weight: 600;
          color: black;
          margin-bottom: 0rem;
          text-align: center;
        }

        & .email-link {
          padding-top: 3vh;
          z-index: 9999;
          text-align: center;

          

          & p {
            font-size: 1.7rem;
            color: $black;
            padding-left: 1vw;

          }

          & a {
            color: $secondary;
            font-size: 1.7rem;
            text-decoration: none;
          }
        }
      }
    }
  }

  .vertical-line {
    border-left: 2px solid $grey;
    height: 68vh;
    position: absolute;
    left: 52%;
    top: 8%;
  }
}

@media screen and (min-width: 3200px) {
  .modal-box {
    display: block;
    background-color: $white;
    margin-left: 11%;
    width: 80vw;
    height: 80vh;
    padding: 1rem;
    box-shadow: 0 0 20px $black-shadow;
    z-index: 1000;
    color: $white;
    overflow-y: scroll;
  }

  .close-icon {
    position: absolute;
    right: 2%;
    top: 4%;
    cursor: pointer;

    & img {
      width: 133%;
    }
  }

  .contact-container {
    & .contact-row {
      flex-wrap: wrap;
      width: 87%;

      & .contact-col {
        @include flex-column;
        flex-wrap: wrap;
        padding-top: 3vh;
        padding-left: 3vw;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        & .contact-header {
          @include text-stroke;

          & p {
            font-size: 4rem;
            font-weight: 900;
            letter-spacing: 8px;
            margin: 0;
            padding-left: 3.2vh;
          }
        }

        & .contact-sub-header {
          & p {
            font-size: 4rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 4px;
            margin: 0;
            padding-left: 3vh;
            padding-top: 1vh;
            text-align: start;
            line-height: 4.5rem;
          }
        }

        & .contact-img {
          padding-top: 3vh;

          & img {
            width: 95%;
          }
        }

        & .contact-info {
          @include flex-row;
          justify-content: flex-start;
          padding-left: 3vh;
          column-gap: 15px;
          margin-top: 2vh;

          & img {
            width: 60px;
            padding-top: 30%;
          }

          & p {
            margin: 9px;
            font-size: 2.2rem;
            font-weight: 400;
            text-align: start;
            color: $black;
            text-decoration: none;

            & a {
              color: $black;
              text-decoration: none;
            }
          }

          & .phone-icon {
            width: 15%;
          }
        }

      }

      & .contact-col-details {
        @include flex-column;
        padding-top: 3vh;
        margin: 0 auto;

        & .contact-sub-header {
          & p {
            font-size: 3rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0;
            padding-left: 2.8vw;
            padding-top: 2vh;
            text-align: start;
            line-height: 2rem;
          }
        }

        & .or-text {
          font-size: 2.5rem;
          margin-left: 2rem;
          font-weight: 600;
          color: black;
          margin-bottom: -1rem;
          text-align: center;
        }

        & .email-link {
          padding-top: 3vh;
          z-index: 9999;
          text-align: center;

          & p {
            font-size: 1.7rem;
            color: $black;
            padding-left: 1vw;

          }

          & a {
            color: $secondary;
            font-size: 1.7rem;
            text-decoration: none;
          }
        }
      }
    }
  }

  .vertical-line {
    border-left: 2px solid $grey;
    height: 68vh;
    position: absolute;
    left: 52%;
    top: 8%;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
  .modal-box {
    display: block;
    background-color: $white;
    margin-left: 11%;
    width: 85vw;
    height: 80vh;
    padding: 1rem;
    box-shadow: 0 0 20px $black-shadow;
    z-index: 1000;
    color: $white;
    overflow-y: scroll;
  }

  .close-icon {
    position: absolute;
    right: 4%;
    top: 8%;
    cursor: pointer;
  }

  .contact-container {
    & .contact-row {
      flex-wrap: wrap;
      width: 87%;

      & .contact-col {
        @include flex-column;
        flex-wrap: wrap;
        padding-top: 3vh;
        padding-left: 6vh;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        & .contact-header {
          @include text-stroke;

          & p {
            font-size: 2rem;
            font-weight: 900;
            letter-spacing: 10px;
            margin: 0;
            padding-left: 3.2vh;
          }
        }

        & .contact-sub-header {
          & p {
            font-size: 2rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0;
            padding-left: 3vh;
            padding-top: 1vh;
            text-align: start;
            line-height: 2.5rem;
          }
        }

        & .contact-img {
          padding-top: 3vh;

          & img {
            width: 95%;
          }
        }

        & .contact-info {
          @include flex-row;
          justify-content: flex-start;
          padding-left: 3vh;
          column-gap: 15px;
          margin-top: 2vh;

          & img {
            width: 100%;
            padding-top: 1%;
          }

          & p {
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
            text-align: start;
            color: $black;
            text-decoration: none;

            & a {
              color: $black;
              text-decoration: none;
            }
          }

          & .phone-icon {
            width: 15%;
          }
        }

      }

      & .contact-col-details {
        @include flex-column;
        padding-top: 3vh;
        margin: 0 auto;

        & .contact-sub-header {
          & p {
            font-size: 2rem;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0;
            padding-left: 3vw;
            padding-top: 2vh;
            text-align: start;
            line-height: 2rem;
          }
        }

        & .or-text {
          font-size: 1.5rem;
          margin-top: 1rem;
          font-weight: 600;
          color: black;
          margin-bottom: -1rem;
          text-align: center;
        }

        & .email-link {
          padding-top: 3vh;
          z-index: 9999;
          text-align: center;

          & p {
            font-size: 1.1rem;
            color: $black;
            padding-left: 1vw;

          }

          & a {
            color: $secondary;
            font-size: 1.1rem;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
  .close-icon {
    position: absolute;
    right: 2%;
    top: 4%;
    cursor: pointer;
  }

  .vertical-line {
    border-left: 2px solid $grey;
    height: 68vh;
    position: absolute;
    left: 58%;
    top: 8%;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1500px) {
  .modal-box {
    margin-left: 12%;
    width: 85vw;
    height: 80vh;
    padding: 1rem;
    box-shadow: 0 0 20px $black-shadow;
    z-index: 1000;
    color: $white;
    overflow-y: scroll;
  }

  .close-icon {
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: pointer;
  }

  .contact-container {
    & .contact-row {
      width: 85%;

      & .contact-col {
        padding-top: 3vh;
        padding-left: 1vw;

        & .contact-header {
          @include text-stroke;

          & p {
            font-size: 1.8rem;
            font-weight: 900;
            letter-spacing: 10px;
            padding-left: 1.5vw;
            line-height: 2rem;
          }
        }

        & .contact-sub-header {
          & p {
            font-size: 1.6rem;
            color: $secondary;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin: 0;
            padding-left: 1.8vh;
            padding-top: 1vh;
            text-align: start;
            line-height: 1.8rem;
          }
        }

        & .contact-img {
          padding-top: 3vh;

          & img {
            width: 95%;
          }
        }

        & .contact-info {
          @include flex-row;
          justify-content: flex-start;
          padding-left: 1.5vh;
          column-gap: 12px;
          margin-top: 2vh;

          & img {
            width: 98%;
            padding-top: 1%;
            padding-left: 2%;
          }

          & p {
            margin: 0;
            font-size: 0.8rem;
            font-weight: 400;
            text-align: start;
            color: $black;
            text-decoration: none;

            & a {
              color: $black;
              text-decoration: none;
            }
          }

          & .phone-icon {
            width: 14%;
          }
        }

      }

      & .contact-col-details {
        @include flex-column;
        padding-top: 1vh;

        & .contact-sub-header {
          & p {
            font-size: 1.8rem;
            color: $secondary;
            font-weight: 500;
            letter-spacing: 1px;
            margin: 0;
            padding-top: 2vh;
            padding-left: 3.5vw;
            line-height: 2rem;
            text-align: start;
          }
        }

        & .or-text {
          font-size: 1.5rem;
          margin-top: 1rem;
          font-weight: 600;
          color: black;
          margin-bottom: -1rem;
          text-align: center;
        }

        & .email-link {
          padding-top: 3vh;
          z-index: 9999;
          text-align: center;

          & p {
            font-size: 1rem;
            color: $black;
            padding-left: 1vw;
            padding-right: 1vw;

          }

          & a {
            color: $secondary;
            font-size: 1rem;
            text-decoration: none;
          }
        }
      }
    }
  }

  .vertical-line {
    border-left: 2px solid $grey;
    height: 65vh;
    position: absolute;
    left: 57%;
    top: 4%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
  .modal-box {
    margin-left: 12%;
    width: 85vw;
    height: 72vh;
    padding: 1rem;
    box-shadow: 0 0 20px $black-shadow;
    z-index: 1000;
    color: $white;
  }

  .close-icon {
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: pointer;
  }

  .contact-container {
    & .contact-row {
      width: 87%;

      & .contact-col {
        padding-top: 3vh;
        padding-left: 1vw;

        & .contact-header {
          @include text-stroke;

          & p {
            font-size: 1.8rem;
            font-weight: 900;
            letter-spacing: 10px;
            padding-left: 1.2vw;
            line-height: 2rem;
          }
        }

        & .contact-sub-header {
          & p {
            font-size: 1.6rem;
            color: $secondary;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin: 0;
            padding-left: 1.2vh;
            padding-top: 1vh;
            text-align: start;
            line-height: 1.8rem;
          }
        }

        & .contact-img {
          padding-top: 3vh;

          & img {
            width: 95%;
          }
        }

        & .contact-info {
          @include flex-row;
          justify-content: flex-start;
          padding-left: 1vh;
          column-gap: 2px;
          margin-top: 2vh;

          & img {
            width: 80%;
            padding-top: 1%;
          }

          & p {
            margin: 0;
            font-size: 0.8rem;
            font-weight: 400;
            text-align: start;
            color: $black;
            text-decoration: none;

            & a {
              color: $black;
              text-decoration: none;
            }
          }

          & .phone-icon {
            width: 14%;
          }
        }

      }

      & .contact-col-details {
        @include flex-column;
        padding-top: 1vh;
        margin: 0 auto;

        & .contact-sub-header {
          & p {
            font-size: 1.6rem;
            color: $secondary;
            font-weight: 500;
            letter-spacing: 1px;
            padding-left: 0.2vw;
            padding-top: 2vh;
            text-align: center;
            line-height: 2rem;
            text-align: start;
          }
        }

        & .or-text {
          font-size: 1.3rem;
          margin-top: 1rem;
          font-weight: 600;
          color: black;
          margin-bottom: -1rem;
          text-align: center;
        }

        & .email-link {
          padding-top: 3vh;
          z-index: 9999;
          text-align: center;

          & p {
            font-size: 0.8rem;
            color: $black;
            padding-left: 1vw;
            padding-right: 1vw;

          }

          & a {
            color: $secondary;
            font-size: 0.8rem;
            text-decoration: none;
          }
        }
      }
    }
  }

  .vertical-line {
    border-left: 2px solid $grey;
    height: 65vh;
    position: absolute;
    left: 56%;
    top: 4%;
  }
}
@media screen and (max-width:767){
  .my-modal{
    display: none !important;
  }
}