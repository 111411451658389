.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}


.successful-image {
    width: 15%;
}
.successful-p {
    margin: 0 auto;
    font-size: 2rem;
    margin-top: 4%;
    font-weight: 600; 
    padding: 0 2vw; 
    text-align: center
}

@media screen and (min-width: 312px) and (max-width: 480px) {
    .successful-image {
        width: 28%;
    }
    .successful-p {
        font-size: 1.2rem;
        margin-top: 15%;
    }
}
@media screen and (min-width: 481px) and (max-width: 540px) {
    .successful-image {
        width: 23%;
    }
    .successful-p {
        font-size: 1.4rem;
        margin-top: 15%;

    }
}

@media screen and (min-width: 541px) and (max-width: 767px) {
    .successful-image {
        width: 23%;
    }
    .successful-p {
        font-size: 1.4rem;
        margin-top: 10%;

    }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .successful-image {
        width: 15%;
    }
    .successful-p {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1300px) and (max-width: 2000px) {
    .successful-image {
        width: 15%;
    }
    .successful-p {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 2000px) and (max-width: 2500px) {
    .successful-image {
        width: 20%;
    }
    .successful-p {
        font-size: 2rem;
    }
}

@media screen and (min-width: 2500px) and (max-width: 3000px) {
    .successful-image {
        width: 18%;
    }
    .successful-p {
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 3000px) and (max-width: 3500px) {
    .successful-image {
        width: 20%;
    }
    .successful-p {
        font-size: 3rem;
    }
}