@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';

.slider-Testimonials{
  position: relative;
  width: 10%;
  display: grid;
  place-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  @include position-rel;
}
.slider-Testimonials::after{
  @include position-abs;
  top: 0%;
  right: -1080%;
  height: 100%;
  width: 120%;
  content: "";
  z-index: 2;
  background-color: $background-color; 
}
.slide-track-Testimonials{
  display: flex;
  width: calc(200px*10);
  animation: scroll 25s infinite linear;
  margin: 0;
  & .col-md-1{
    width: 15%;
    padding: 3%;
  }
  & div{
    height: fit-content;
    text-align: center;

    & img{
      height: 70%;
      width: 70%;
    }
  }

  & div:nth-child(7){
    margin-right: 2%;
  }

  & div:nth-child(8){
    margin-right: 2%;
  }

  & div:nth-child(17){
    margin-right: 2%;
  }

  & div:nth-child(18){
    margin-right: 2%;
  }

  & .image1{
      width: 68%;
  }

  & .image2{
      width: 65%;
  }

  & .image3{
    width: 63%;
  }

  & .image4{
    width: 90%;
  }

  & .image5{
    width: 78%;
  }

  & .image6{
    width: 140%;
  }

  & .image7{
    width: 150%;
  }

  & .image8{
    width: 112%;
  }

  & .image9{
    width: 90%;
  }
}

@keyframes scroll {
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-100%);
  }
}
@media screen and (min-width: 3000px){
  .slide-track-Testimonials{
    & .col-md-1{
      width: 17%;
    }
  }
}
@media screen and (max-width: 767px) {
  .slide-track-Testimonials{
    width: calc(350px*10);
    & div{
      height: none;
      & img{
        width: 100%;
      }
    }
    & .col-md-1{
      padding: 1%;
      width: 30%;
    }
    & .image0{
      width: 75%;
    }

    & .image1{
      width: 68%;
  }

  & .image2{
      width: 65%;
  }

  & .image3{
    width: 63%;
  }

  & .image4{
    width: 90%;
  }

  & .image5{
    width: 78%;
  }

  & .image6{
    width: 140%;
  }

  & .image7{
    width: 140%;
  }

  & .image8{
    width: 112%;
  }

  & .image9{
    width: 90%;
  }

  }
}

@media screen and (max-width: 450px) {
  .slide-track-Testimonials{
    width: calc(250px*10);
    & div{
      height: none;
      & img{
        width: 100%;
      }
    }
    & .col-md-1{
      padding: 0.5%;
      width: 10%;
    }
    & .image0{
      width: 75%;
    }

    & .image1{
      width: 68%;
  }

  & .image2{
      width: 65%;
  }

  & .image3{
    width: 63%;
  }

  & .image4{
    width: 90%;
  }

  & .image5{
    width: 78%;
  }

  & .image6{
    width: 140%;
  }

  & .image7{
    width: 140%;
  }

  & .image8{
    width: 112%;
  }

  & .image9{
    width: 90%;
  }

  }
}

