@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';

.mob-img {
    @include display-none;
}

.main-container-stories {
    padding: 4% 7% 3%;
    @include font;
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.05em;

    & .web-img {
        float: right;
        width: 40%;
        margin-left: 3.5%;
        margin-bottom: 3.5%;
    }

    .main-div {
        margin-bottom: 3.5%;
    }

    .inner-div {
        margin-bottom: 1.5%;
    }

    & h2 {
        color: $secondary;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 1.5%;
    }

    & h4 {
        color: $primary;
        font-size: 20px;
        font-weight: 500;
    }

    & p {
        font-size: 20px;
        font-weight: 500;
    }

    .story-footer {
        font-size: 32px;
        font-weight: 600;
    }
}

.mob-img {
    float: right;
    margin: 5px;
}

.filled-circle-list {
    list-style-type: none;
    padding-left: 1em;
}

.filled-circle {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    margin-left: 1.2em;
    margin-bottom: 1%;
}

.filled-circle::before {
    content: '\2022';
    color: black;
    font-size: 1.2em;
    position: absolute;
    left: -1.2em;
}

@media screen and (min-width: 2600px) {
    .main-container-stories {

        & h2 {
            font-size: 42px;
        }

        & h4 {
            font-size: 30px;
        }

        & p {
            font-size: 30px;
        }

        .filled-circle {
            font-size: 30px;
        }

        .story-footer {
            font-size: 42px;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 767px) {
    .mob-img {
        display: block;
        width: 100%;
        padding-top: 5%;
        padding-bottom: 3%;
        background-size: cover;
    }

    .main-container-stories {
        & .web-img {
            @include display-none;
        }

        & h2 {
            color: $secondary;
            font-size: 27px;
            font-weight: 700;
            margin-bottom: 3%;
        }

        & h4 {
            color: $primary;
            font-size: 17.3px;
        }

        & p {
            font-size: 17.3px;
        }

        .filled-circle {
            font-size: 17.3px;
        }
    }
}

@media screen and (max-width: 425px) {

    .main-container-stories {
        & h2 {
            font-size: 24px;
            font-weight: 600;
        }

        & h4 {
            font-size: 16px;
            font-weight: 500;
        }

        & p {
            font-size: 16px;
            font-weight: 500;
        }

        .filled-circle {
            font-size: 16px;
            font-weight: 500;
        }

        .story-footer {
            font-size: 16px;
            font-weight: 700;
            text-align: center;
        }
    }
}

@media screen and (max-width: 767px) {

    .main-container-stories {
        .story-footer {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }
    }
}

@media screen and (min-width: 1400px) and (max-width: 1999px) {
    .main-container-stories {
        & p {
            font-size: 1.5rem !important;
        }

        & h4 {
            font-size: 1.5rem !important;
        }
    }

    .filled-circle {
        font-size: 1.5rem !important;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .main-container-stories {
        & p {
            font-size: 1.1rem !important;
        }
    }

    .filled-circle {
        font-size: 1.1rem !important;
        font-weight: 500;
    }

}

@media screen and (min-width: 2000px) and (max-width: 2400px) {
    .main-container-stories {
        & p {
            font-size: 1.8rem !important;
        }

        & h4 {
            font-size: 1.8rem !important;
        }
    }

    .filled-circle {
        font-size: 1.8rem !important;
    }
}

@media screen and (min-width: 2401px) and (max-width: 2800px) {
    .main-container-stories {
        & p {
            font-size: 1.9rem !important;
        }

        & h4 {
            font-size: 1.9rem !important;
        }
    }

    .filled-circle {
        font-size: 1.9rem !important;
    }
}

@media screen and (min-width: 2801px) and (max-width: 2999px) {
    .main-container-stories {
        & p {
            font-size: 2.2rem !important;
        }

        & h4 {
            font-size: 2.2rem !important;
        }
    }

    .filled-circle {
        font-size: 2.2rem !important;
    }
}

@media screen and (min-width: 3000px) {
    .main-container-stories {
        & p {
            font-size: 2.5rem !important;
        }

        & h4 {
            font-size: 2.5rem !important;
        }
    }

    .filled-circle {
        font-size: 2.5rem !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1120px) {
    .main-container-stories {
        .inner-div {
            margin-bottom: 2%;
        }
    }

    .filled-circle {
        margin-bottom: 2%;
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .main-container-stories {
        .inner-div {
            margin-bottom: 3%;
        }
    }

    .filled-circle {
        margin-bottom: 2%;
    }
}

@media screen and (max-width:500px) {
    .main-container-stories {
        .inner-div {
            margin-bottom: 5%;
        }
    }

    .filled-circle {
        margin-bottom: 4%;
    }
}