@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/mixins.scss";


.main-testimonials {
  align-items: center;
}

.page-container-testimonials {
  @include position-rel;
  background-color: $background-color;
  overflow: hidden;
  padding: 6vh 6vw;
  & h2 {
    @include text-center;
    font-size: 24px;
  }
}
.content-testimonials {
  @include position-rel;
  padding-top: 3%;
  z-index: 1;
  height: 90%;
  margin-right: 4%;
  padding-right: 3%;
  & h1 {
    @include position-rel;
    -webkit-text-stroke: 2px $stroke;
    color: transparent;
    @include font;
    letter-spacing: 0.2rem;
    padding-left: 0%;
    z-index: 1;
    font-weight: 700;
    text-align: left;
    font-size: 7rem;
    line-height: 7.1rem;
  }

  & h2 {
    color: $primary;
    text-align: left;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    font-size: 2rem;
  }
  & h3 {
    @include display-none;
  }
  & .blue-box-Testimonials {
    width: 50%;
    height: 100%;
    z-index: -1;
    @include position-abs;
    right: 0%;
    top: 5%;
  }
  & .dark-blue-box-Testimonials {
    @include position-abs;
    height: 12%;
    width: 15%;
    right: 0%;
    top: 3%;
    background-color: $secondary;
    z-index: 500;
  }
  & .red-box-Testimonials {
    @include position-abs;
    height: 15%;
    width: 12%;
    left: 54%;
    bottom: -10%;
    z-index: 500;
    background-color: $primary;
  }
  & .testimonial-btn{
    @include flex-row;
    justify-content: flex-end;
    column-gap: 1vw;
    vertical-align: middle;
    padding-top:2vh;
    // padding: 0;
    .btn {
      &.rounded-circle {
        &.btn-lg {
          padding: 18px;
          .svg-inline--fa {
            color: $white;
            font-size: 2rem;
            vertical-align: middle;
          }
        }
      }
    }

    & .disableButton {
      background-color: $light-secondary;
    }
    & .enableButton {
      background-color: $secondary;
    }
  }

  
}
.none {
  @include display-none;
}
.testimonial-mob-bg {
  background-color: $blue-shadow;
  width: 60vw;
  height: 15vh;
  right: -30%;
  top: 0.8%;
  z-index: -1;
  overflow-x: hidden;
}
.content-img-testimonials {
  margin-left: 3%;
  background-image: url("../../../../shared/assets/images/bg-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 43%;
  padding: 8% 1% 3% 1%;
  min-height: 70vh;
  & div {
    @include position-rel;
    z-index: 1;
    background-color: $light-primary;
    left: 7%;
    width: 90%;
    border-radius: 8px;
    color: $white;
    font-size: 14px;
    padding: 10% 3% 2%;

    & .quote-img {
      width: 18%;
      @include position-abs;
      top: 0%;
      padding-top: 15px;
      right: 0%;
    }
    & img {
      @include position-abs;
      height: auto;
      width: 40%;
      top: -21%;
      opacity: 1;
    }

    & .stat-testimonials {
      @include position-rel;
      left: 1%;
      background-color: transparent;
      width: 90%;
      opacity: 1;
      font-size: 1.4rem;
      padding-bottom: 40px;
    }

    & .name-testimonials {
      @include position-rel;
      background-color: transparent;
      font-size: 1.3rem;
      font-weight: 500;
      left: 1.6%;
      width: 90%;
      opacity: 1;

      & .designation-testimonials {
        @include position-rel;
        background-color: transparent;
        font-size: 14px;
        left: -3%;
        width: 100%;
        font-weight: 300;
        opacity: 1;
        padding-top: 2%;
      }
    }
  }
  .blue-box-mob-Testimonials {
    @include display-none;
  }
  .red-box-mob-Testimonials {
    @include display-none;
  }
}
.red-vert-line-testimonials {
  width: 0.1%;
  background-color: $primary;
  height: 100%;
}
@media screen and (min-width: 2600px) {
  .page-container-testimonials{
    & h2{
      font-size: 3rem !important;
    }
  }
  .content-testimonials {
    & h1 {
      font-size: 8rem;
      line-height: 8.1rem;
    }
    & .case-study-round-btn {
      padding-top: 4vh;
      .btn {
        &.rounded-circle {
          &.btn-lg {
            padding: 30px;
            .svg-inline--fa {
              font-size: 3rem;
            }
          }
        }
      }
    }
  }
  .content-img-testimonials {
    min-height: 80vh !important;
    & div {
      & img {
        top: -22%;
        width: 40%;
      }
      & .stat-testimonials {
        font-size: 2.5rem !important;
      }
      & .name-testimonials {
        top: -46%;
        font-size: 2.6rem !important;
        font-weight: 500;
        left: 1.6%;
        & .designation-testimonials {
          font-size: 2.3rem !important;
        }
      }
    }
  }
}
@media screen and (min-width: 2000px) {
  .page-container-testimonials {
    & h2 {
      font-size: 34px;
    }
  }
  .content-testimonials {
    & h2 {
      font-size: 34px;
    }

    & h1 {
      font-size: 120px;
    }
  }
  .content-img-testimonials {
    min-height: 65vh;
    & div {
      & .stat-testimonials {
        font-size: 1.5rem;
        padding-bottom: 80px;
      }
      & .name-testimonials {
        font-size: 26px;

        & .designation-testimonials {
          font-size: 18px;
        }
      }
    }
  }
}
@media screen and (max-width: 1450px) {
  .content-testimonials {
    & h1 {
      font-size: 6rem;
      line-height: 6.1rem;
    }
  }
  .content-img-testimonials {
    & div {
      & img {
        top: -17%;
        width: 40%;
      }

      & .stat-testimonials {
        font-size: 1.2rem;
      }

      & .name-testimonials {
        top: -46%;
        font-size: 1.3rem;
        font-weight: 500;
        left: 1.6%;
      }
    }
  }
}
@media screen  and (min-width: 1051px) and (max-width: 1350px) {
  .content-testimonials {

    & .red-box-Testimonials{
      left: 51%;
    }
    & .blue-box-Testimonials{
      width:54%;
    }
    & h1 {
      font-size: 5rem;
      line-height: 5rem;
    }
    & h2 {
      font-size: 1.5rem;
    }
  }
  .content-img-testimonials {
    min-height: 60vh;
    & div {
      & img {
        top: -14%;
        width: 40%;
      }

      & .stat-testimonials {
        font-size: 1.1rem;
      }

      & .name-testimonials {
        top: -46%;
        font-size: 1.2rem;
        font-weight: 500;
        left: 1.6%;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .page-container-testimonials {
    padding: 4vh 3vw;
  }
  .content-testimonials {
    & h1 {
      font-size: 4rem;
      line-height: 4.1rem;
    }
    & h2 {
      font-size: 1.2rem;
    }
    & .case-study-round-btn {
      padding-top: 4vh;
      .btn {
        &.rounded-circle {
          &.btn-lg {
            padding: 8px;
            .svg-inline--fa {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
  .content-img-testimonials {
    margin: 0;
    min-height: 62vh;
    & div {
      & img {
        top: -12%;
        width: 40%;
      }

      & .stat-testimonials {
        font-size: 1.1rem;
      }

      & .name-testimonials {
        top: -46%;
        font-size: 1.2rem;
        font-weight: 500;
        left: 1.6%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .page-container-testimonials {
    padding: 4% 6% 4% 6%;
    & h2 {
      font-size: 24px;
      @include text-center;
    }
  }
  .content-img-testimonials {
    padding: 20% 6% 8% 8%;
    width: 100%;
    & div {
      z-index: 1;
      background-color: $light-primary;
      top: 8%;
      left: 2.5%;
      width: 95%;
      height: 100%;
      border-radius: 8px;
      color: $white;
      font-size: 14px;
      padding-top: 15.5%;
      padding-left: 3%;
      & .quote-img {
        width: 19%;
        @include position-abs;
        top: 0%;
        left: 78%;
      }
      & img {
        @include position-abs;
        top: -22%;
        left: -2%;
        opacity: 1;
        width: 45%;
      }

      & .stat-testimonials {
        top: -26%;
        left: 1%;
        background-color: transparent;
        width: 99%;
        opacity: 1;
        font-size: 1.1rem;
      }

      & .name-testimonials {
        font-size: 20px;
        top: -2vh;
        font-size: 1.3rem;
        font-weight: 500;
        left: 1.6%;
        width: 90%;
        opacity: 1;
        & .designation-testimonials {
          @include position-rel;
          top: -5%;
          font-size: 14px;
          @include height-fit-content;
        }
      }
    }
    .blue-box-mob-Testimonials {
      @include position-abs;
      display: block;
      top: -5%;
      left: 85%;
      z-index: -1;
    }

    .red-box-mob-Testimonials {
      @include position-abs;
      display: block;
      top: 95%;
      left: -3%;
      z-index: -1;
    }
  }

  .Arrows-mob-Testimonials {
    @include flex-row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 2vh;
    padding-left: 0;
    padding-right: 0;
    .testimonial-btn{
      @include flex-row;
      column-gap: 5vw;
      justify-content: flex-end;
      vertical-align: middle;
      padding: 0;
      margin-left:0;
      margin-right:0;

      
      .btn {
        &.rounded-circle {
          padding: 12px;
          margin-left: 15px;
          .svg-inline--fa {
            color: $white;
            font-size: 2rem;
            vertical-align: middle;
          }
        }
        
      }
      & .disableButton {
        background-color: $light-secondary;
      }
      & .enableButton {
        background-color: $secondary;
      }

    }
  }

  .content-testimonials {
    padding: 0;
    width: fit-content;
    & h1 {
      font-size: 4rem;
      font-weight: 900;
      padding-left: 0%;
      text-align: justify;
      -webkit-text-stroke: 1px $stroke;
    }
    & h2 {
      @include display-none;
    }
    & h3 {
      display: block;
      color: $primary;
      font-size: 1.5rem;
      padding-top: 1vh;
      & img {
        margin-left: 2%;
      }
    }
    & button {
      @include display-none;
    }
  }
  .dark-blue-box-Testimonials {
    @include display-none;
  }
  .red-box-Testimonials {
    @include display-none;
  }
}

@media screen and (max-width: 600px) {
  .page-container-testimonials {
    padding: 4% 6% 4% 6%;
    & h2 {
      font-size: 24px;
      @include text-center;
    }
  }
  .content-img-testimonials {
    padding: 20% 6% 8% 8%;
    width: 100%;
    min-height: 65vh;
    & div {
      z-index: 1;
      background-color: $light-primary;
      top: 8%;
      left: 2.5%;
      width: 95%;
      height: 100%;
      border-radius: 8px;
      color: $white;
      font-size: 14px;
      padding-top: 15.5%;
      padding-left: 3%;
      & .quote-img {
        width: 19%;
        @include position-abs;
        top: 0%;
        left: 78%;
      }
      & img {
        @include position-abs;
        top: -19%;
        left: -2%;
        opacity: 1;
        width: 46%;
      }

      & .stat-testimonials {
        top: -26%;
        left: 1%;
        background-color: transparent;
        width: 99%;
        opacity: 1;
        font-size: 1.1rem;
      }

      & .name-testimonials {
        font-size: 20px;
        top: -2vh;
        font-size: 1.3rem;
        font-weight: 500;
        left: 1.6%;
        width: 90%;
        opacity: 1;
        & .designation-testimonials {
          @include position-rel;
          top: -5%;
          font-size: 14px;
          @include height-fit-content;
        }
      }
    }
    .blue-box-mob-Testimonials {
      @include position-abs;
      display: block;
      top: -5%;
      left: 85%;
      z-index: -1;
    }

    .red-box-mob-Testimonials {
      @include position-abs;
      display: block;
      top: 95%;
      left: -3%;
      z-index: -1;
    }
  }

  .content-testimonials {
    padding: 0;
    width: fit-content;
    & h1 {
      font-size: 68px;
      font-weight: 900;
      padding-left: 0%;
      text-align: justify;
      -webkit-text-stroke: 1px $stroke;
    }
    & h2 {
      @include display-none;
    }
    & h3 {
      display: block;
      color: $primary;
      font-size: 1.5rem;
      padding-top: 1vh;
      & img {
        margin-left: 2%;
      }
    }
    & button {
      @include display-none;
    }
  }
  .dark-blue-box-Testimonials {
    @include display-none;
  }
  .red-box-Testimonials {
    @include display-none;
  }
}

@media screen and (min-width:312px) and (max-width: 350px) {
.content-testimonials{
  & h1{
    font-size: 3.1rem;
    line-height: 3.5rem;
  }
}

}
@media screen and (min-width:351px) and (max-width: 375px) {
  .content-testimonials{
    & h1{
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }
}

@media screen and (min-width:312px) and (max-width: 400px) {
  .content-img-testimonials {
    & div{
      & img {
        @include position-abs;
        top: -12%;
        left: -2%;
        opacity: 1;
        width: 55%;
      }
    }
  }

}
@media screen and (min-width:401px) and (max-width: 500px) {
  .content-img-testimonials {
    & div{
      & img {
        @include position-abs;
        top: -15%;
        left: -2%;
        opacity: 1;
        width: 50%;
      }
    }
  }

}

@media screen and (min-width:768px) and (max-width: 890px) {
  .content-img-testimonials {
    & div{
      & img {
        @include position-abs;
        top: -9%;
        // left: -2%;
        opacity: 1;
        width: 43%;
      }
    }
  }

}

@media screen  and (min-width: 768px) and (max-width: 900px){

.content-testimonials {
  & .blue-box-Testimonials {
    width: 64%;
  }
  & .dark-blue-box-Testimonials {
    width: 15%;
  }
  & .red-box-Testimonials {
    @include position-abs;
    height: 14%;
    width: 11%;
    left: 40%;
  }
}

}

@media screen  and (min-width: 901px) and (max-width: 1050px){

  .content-testimonials {
    & .blue-box-Testimonials {
      width: 64%;
    }
    & .dark-blue-box-Testimonials {
      width: 16%;
    }
    & .red-box-Testimonials {
      @include position-abs;
      height: 16%;
      width: 12%;
      left: 44%;
    }
  }
  
  }