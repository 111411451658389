// style.scss
@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/mixins.scss";
@import "../../../../shared/assets/css/common.scss";

.teams-accreditation-home {
  font-family: Open Sans;
  background-color: white;
  position: relative;
  padding-bottom: 5%;
  padding-left: 1%;
  width: 100%;

  & > h1 {
    color: $primary;
    font-size: 60px;
    text-align: center;
    font-weight: 500;
    padding: 0.5rem 0rem 0rem 0rem;
  }

  & > p {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    padding: 0rem 0rem 1rem 0rem;
  }


& .accreditation-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 0%;
  padding-top: 1%;
  width: 100%;

  & img {
    margin-right: 4%;
    margin-bottom: 2%;
  }

  // Adjust styles for different images based on their position

  & img:nth-child(1) {
    width: 16%;
    margin-right: 4%;
  }

  & img:nth-child(2) {
    width: 15%;
    margin-right: 4%;
  }

  & img:nth-child(3) {
    width: 15%;
    margin-right: 4%;
  }
  & img:nth-child(4) {
    width: 15%;
    margin-right: 4%;
  }
  & img:nth-child(5) {
    width: 15%;
    margin-right: 4%;
  }
  & img:nth-child(6) {
    width: 15%;
    margin-right: 6%;
  }
  & img:nth-child(7) {
    width: 15%;
    margin-right: 6%;
  }
  & img:nth-child(8) {
    width: 30%;
  }

}

}

// Media Query for screen width between 312px to 767px
@media screen and (min-width: 312px) and (max-width: 767px) {
  .teams-accreditation-home {
    font-family: Open Sans;
    background-color: white;
    position: relative;
    padding-bottom: 5%;
    padding-left: 1%;
    width: 100%;

    & > h1 {
      color: $primary;
      font-size: 2rem !important;
      text-align: center;
      font-weight: 500;
      padding: 0.5rem 0rem 0rem 0rem;
    }

    & > p {
      text-align: center;
      font-size: 1.2rem !important;
      font-weight: 400;
      padding: 0rem 0rem 1rem 0rem;
    }


  & .accreditation-home {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 2%;
    padding-top: 1%;
    width: 100%;

    & img {
      margin-right: 0%;
      margin-bottom: 4%;
    }

    // Adjust styles for different images based on their position

    & img:nth-child(1) {
      width: 40%;
      margin-right: 4%;
    }

    & img:nth-child(2) {
      width: 38%;
      margin-right: 4%;
    }

    & img:nth-child(3) {
      width: 38%;
      margin-right: 4%;
    }
    & img:nth-child(4) {
      width: 38%;
      margin-left: 4%;
    }
    & img:nth-child(5) {
      width: 38%;
      margin-right: 5%;
      margin-left:4%
    }
    & img:nth-child(6) {
      width: 38%;
      margin-left: 4%;
      margin-right: 4%;
    }
    & img:nth-child(7) {
      width: 45%;
      margin-right: 8%;
      margin-left:3%;
    }
    & img:nth-child(8) {
      width: 70%;
    }

  }

  }
}

@media screen and (min-width: 768px) {
  .teams-accreditation-home {
    & > h1 {
      color: $primary;
      font-size: 3.2rem !important;
      text-align: center;
      font-weight: 500;
      padding: 0.5rem 0rem 0rem 0rem;
    }

    & > p {
      text-align: center;
      font-size: 1.5rem !important;
      font-weight: 500;
      padding: 0rem 0rem 1rem 0rem;
    }
  }
}

@media screen and (min-width: 3000px) {
  .teams-accreditation-home {
    & > h1 {
      color: $primary;
      font-size: 5rem !important;
      text-align: center;
      font-weight: 500;
      padding: 0.5rem 0rem 0rem 0rem;
    }

    & > p {
      text-align: center;
      font-size: 2.5rem !important;
      font-weight: 500;
      padding: 0rem 0rem 1rem 0rem;
    }
  }
}

@media screen and (min-width: 768px) and (max-width:1024px) {
  .teams-accreditation-home {
    & > p {
      font-size: 1.1rem !important;
      font-weight: 500;
    }
  }
}
@media screen and (min-width: 2000px) and (max-width:2400px) {
  .teams-accreditation-home {
    & > p {
      font-size: 1.8rem !important;
      font-weight: 500;
    }
  }
}
@media screen and (min-width: 1300px) and (max-width:1699px) {
  .teams-accreditation-home {
    & > p {
      font-size: 1.125rem !important;
    }
  }
}
@media screen and (min-width: 2401px) and (max-width:2800px) {
  .teams-accreditation-home {
    & > p {
      font-size: 1.9rem !important;
      font-weight: 500;
    }
  }
}
@media screen and (min-width: 2801px) and (max-width:2999px) {
  .teams-accreditation-home {
    & > p {
      font-size: 2.2rem !important;
      font-weight: 500;
    }
  }
}
@media screen and (min-width:3000px) {
  .teams-accreditation-home {
    & > p {
      font-size: 2.5rem !important;
      font-weight: 500;
    }
  }
}