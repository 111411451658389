@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');
@import '../css/variables';

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin filter-red {
    filter: brightness(0) saturate(100%) invert(19%) sepia(72%) saturate(3018%) hue-rotate(342deg) brightness(75%) contrast(97%);
}

@mixin font{
    font-family: 'Open Sans',sans-serif;
}

@mixin font-fira{
    font-family: 'Fira Sans', sans-serif;
}

@mixin display-none{
    display: none;
}

@mixin position-abs{
    position: absolute;
}

@mixin position-rel{
    position: relative;
}

@mixin text-center{
    text-align: center;
}

@mixin height-fit-content{
    height: fit-content;
}

@mixin text-stroke{
    font-family: "Open Sans", sans-serif;
    font-size: 5rem;
    -webkit-text-stroke: 2px $stroke;
    color: transparent;
    font-weight: 700;
}
@mixin position-rel{
    position: relative;
}
@mixin position-abs{
    position: absolute;
}
