@import "../../shared/assets/css/mixins.scss";
@import "../../shared/assets/css/_variables.scss";
@import "../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";
.life-at-wmp-container {
  @include flex-column;
  @include font;
  z-index: -1;
  padding-left: 10%;
  overflow: hidden;
}
@media (max-width:767px) {
  .life-at-wmp-container{
    padding-left: 0;
    margin-top: 7.5vh;
  }
}
.life-at-wmp-image-container {
  background-image: url("../../shared/assets/images/life-at-wmp-bg.jpg");
}
.our-culture-container{
  @include flex-column;
  padding: 6vh 6vw 8vh 6vw;
  background-color: $background-color;
  & .our-culture-heading{
    color: $primary;
    text-align: center;
    & h2{
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
    }
  }
  & .our-culture-desc{
    text-align: center;
    width: 75%;
    margin: 0 auto;
    & p{
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
  }
  & .culture-people-container{
    @include flex-row;
    row-gap: 3vh;
    padding-top: 6vh;
    & .culture-people{
      @include flex-row;
      justify-content: center;
      align-items: center;
      & .culture-content{
        & .culture-content-header{
          & p{
            @include text-stroke;
            font-size: 5rem;
            margin: 0;
            font-weight: 700;
            line-height: 5.2rem;
            letter-spacing: 2px;
            color: transparent;
          }
        }
        & .culture-para{
          padding-top: 2vh;
          & p{
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0;
          }
        }
      }
      & .culture-content-right{
        padding-left: 4vw;
      }
      & .culture-content-img{
        & img{
          width: 100%;
        }
      }
      & .culture-right-img{
        position: relative;
        margin-left: 0.8vw;
      }
      & .culture-left-img{
        margin-right: 0.8vw;
      }
    }
  }
}
.celebration-container{
  padding-top: 6vh;
  padding-bottom: 8vh;
  & .our-culture-heading{
    color: $primary;
    text-align: center;
    & h2{
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
    }
  }
  & .our-culture-desc{
    text-align: center;
    width: 75%;
    margin: 0 auto;

    & p{
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
  }
    & .culture-card-row{
      padding: 6vh 4vw 1vh 4vw;
      & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & .culture-card-col{
        box-shadow: 1px 1px 10px $black-shadow;
        padding: 0;
        border-radius: 0;
        margin: 1em;
        overflow: hidden;
        & img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          align-content: center;
          object-position: center;
          transition: transform ease-in-out 0.5s;
        }
      }
    }
}  
.rhythm-container{
  background-color: $background-color;
  margin-bottom: 0vh;
}
@media only screen and (min-width: 1921px){
  .life-at-wmp-image-container {
    & .life-at-wmp-text-container{
      & .heading-desc-life-at-wmp{
        font-size: 1.8rem;
        width: 100%;
        line-height: 2.3rem;
        font-weight: 500;
      }
      & p {
        font-size: 3.8rem;
      }
    }
    .life-at-wmp-breadcrumb {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
}

@media only screen and (min-width: 2800px) {
  .life-at-wmp-container {
    & .our-culture-container {
      & .our-culture-heading {
        & h2 {
          font-size: 4rem;
        }
      }
      & .our-culture-desc {
        & p{ 
          font-size: 2.2rem;
        }
      }
      & .culture-people-container {
        & .culture-people {
          & .culture-content {
            & .culture-content-header {
              & p {
                font-size: 6rem;
                line-height: 8rem;
              }
            }
            & .culture-para {
              & p{@media only screen and (min-width: 1921px) and (max-width: 2600px) {
                .awards-image-container {
                  & .awards-text-container{
                    & .heading-desc-award{
                      font-size: 1.8rem;
                      width:80%;
                      line-height: 2.3rem;
                      font-weight: 500;
                    }
                    & p {
                      font-size: 3.8rem;
                    }
                  }
                  .awards-breadcrumb {
                    font-size: 1.8rem;
                    font-weight: 500;
                  }
                }
              }
                font-size: 2.2rem;
              }
            }
          }
        }
      }
    }
    & .celebration-container {
      & .our-culture-heading {
        & h2{
          font-size: 4rem;
        }
      }
      & .our-culture-desc {
        & p{ 
          font-size: 2.2rem;
        }
      }
    }
    & .see-all-btn {
      & button {
        font-size: 3rem;
      }
    }
  }
}

@media only screen and (min-width: 3200px) {
  .life-at-wmp-container {
    & .our-culture-container {
      & .our-culture-heading {
        & h2 {
          font-size: 6rem;
        }
      }
      & .our-culture-desc {
        & p{ 
          font-size: 2.7rem;
        }
      }
      & .culture-people-container {
        & .culture-people {
          & .culture-content {
            & .culture-content-header {
              & p {
                font-size: 8rem;
                line-height: 10rem;
              }
            }
            & .culture-para {
              & p{
                font-size: 2.7rem;
              }
            }
          }
        }
      }
    }
    & .celebration-container {
      & .our-culture-heading {
        & h2{
          font-size: 6rem;
        }
      }
      & .our-culture-desc {
        & p{ 
          font-size: 2.7rem;
        }
      }
    }
    & .see-all-btn {
      & button {
        font-size: 3rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .our-culture-container
    .culture-people-container {
    & .culture-people {
      & .culture-content {
        & .culture-content-header {
          & p {
            font-size: 3rem;
            line-height: 3.5rem;
          }
        }
        & .culture-para {
          & p{
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .life-at-wmp-image-container {
    height: 38vh;
    & .life-at-wmp-text-container{
      margin-top: -8vh;
      & .heading-desc-life-at-wmp{
        font-size: 1.2rem;
        width: 100%;
        font-weight: 500;
      }
      & p {
        font-size: 3.2rem;
      }
    }
    .life-at-wmp-breadcrumb {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .life-at-wmp-image-container {
    height: 25vh;
    & .life-at-wmp-text-container {
        margin-top: -14vh;
      & p {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
    }
    .life-at-wmp-breadcrumb {
      font-size: 1.4rem;
      font-weight: 500;
      & .breadcrumb-separator {
        margin: 0 0.3rem;
      }
    }
    & .red-bg-box {
      height: 76%;
      width: 36%;
    }
  }
}
@media only screen and (min-width: 312px) and (max-width: 480px) {
  .life-at-wmp-image-container {
    height: 20vh;
    padding-left: 4vw;
    & .life-at-wmp-text-container {
      margin-top: -16vh;
      & p{
        font-size: 2.8rem;
        line-height: 3rem;
      }
    }
    .life-at-wmp-breadcrumb {
      font-size: 1rem;
      font-weight: 500;
      line-height: 0rem;
      & .breadcrumb-separator {
        margin: 0 0.2rem;
      } 
    }
    & .red-bg-box {
      height: 78%;
      width: 35%;
    }
  }

}
.culture-mv-container{
  @include flex-column;
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  row-gap: 2vh;
 
  & .culture-mob-content{
    @include flex-column;
    row-gap: 3vh;
  & .culture-header-mv{
    padding-top: 5vh;
    padding-left: 4vw;
    width: 100%;
    @include flex-column;
    letter-spacing: 5px;
    justify-content: space-between;
    & .culture-text-stroke{
      @include text-stroke;
      font-size: 2.8rem;
      line-height: 3rem;
      font-weight: 900;
      letter-spacing: 2px;
      color: transparent;
      padding-top: 0.7vh;
      padding-bottom: 0.5vh;
      -webkit-text-stroke: 1px $stroke;
    }
    & .culture-mob-bg{
      background-color: $blue-shadow;
      width: 60vw;
      height: 16vh;
      right: 0;
      z-index: -1;
      overflow-x: hidden;
      & img{
        width: 15%;
        height: 15%;
        bottom: 1em;
        right: 1em;
      }
    }
  }
  }
  & .culture-mob-image{
    & img{
      width: 100%;
      padding-top: 2vh;
    }
  }
  & .culture-mob-para{
    text-align: center;
    width: 90%;
    margin: 0 auto;
    & p{
      font-size: 1rem;
      font-weight: 500;
    }
  }

}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .our-culture-container{
    background-color: $white;
    padding: 3vh 4vw 1vh 4vw;
    & .our-culture-heading{
      & h2{
        font-size: 2.5rem;
      }
    }
    & .our-culture-desc{
      width: 100%;
      & p{
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
  .celebration-container{
    padding: 2vh;

    & .our-culture-heading{
      & h2{
        font-size: 2.5rem;
      }
    }
    & .our-culture-desc{
      width: 100%;
      & p{
        font-size: 1.2rem;
      }
    }
    & .culture-card-row{
      padding-top: 2vh;
      row-gap: 0;
      & .culture-card-col{
        flex:0 0 46%;
        margin: 0.3em;
      }
  }
}
.rhythm-container{
  background-color: $background-color;
 }
.culture-mv-container{
  & .culture-mob-content{
    & .culture-header-mv{
      & .culture-text-stroke{
        & h1{
          padding-top: 3vh;
        }
      }
    }
    & .culture-mob-image{
      margin-top: 4vh;
    }
    & .culture-mob-para{
      & p{
      font-size: 1.2rem;
      font-weight: 500;
      }
    }
  }
}
}
@media only screen and (min-width: 312px) and (max-width: 480px) {
  .our-culture-container{
    background-color: $white;
    padding: 3vh 2vw 1vh 2vw;
    & .our-culture-heading{
      & h2{
        font-size: 2rem;
      }
    }
    & .our-culture-desc{
      width: 100%;
      & p{
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
  .celebration-container{
    padding: 2vh;

    & .our-culture-heading{
      & h2{
        font-size: 2rem;
      }
    }
    & .our-culture-desc{
      width: 100%;
      & p{
        font-size: 1.2rem;
      }
    }
    & .culture-card-row{
      padding-top: 2vh;
      row-gap: 0;
      & .culture-card-col{
        flex:0 0 46%;
        margin: 0.3em;
      }
  }
}
.rhythm-container{
  background-color: $background-color;
 }
}

@media only screen and (min-width: 312px) and (max-width: 390px){
.culture-mv-container{
  & .culture-mob-content{
    & .culture-header-mv{
      & .culture-text-stroke{
        font-size: 2.3rem;
        line-height: 2.5rem;
      }
    }
  }
}
}

@media screen and (min-width:2000px) and (max-width:2799px) {
  .our-culture-container{
    & .our-culture-desc{
      & p{
        font-size: 1.8rem;
      }
    }
    & .our-culture-heading{
      & h2{
        font-size: 3.8rem;
      }
    }

  & .culture-people-container{
    & .culture-people{
      & .culture-content{
        & .culture-para{
          & p{
            font-size: 1.8rem;
          }
        }
        & .culture-content-header{
          & p{
            font-size: 6rem;
          }
        }
      }
    }
  }
  }
  .celebration-container{
    & .our-culture-heading{
      & h2{
        font-size: 3.8rem;
      }
    }
    & .our-culture-desc{
      & p{
        font-size: 1.8rem;
      }
    }
  }
  
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .our-culture-container
    .our-culture-heading {
        & h2{
        font-size: 3.2rem;
      }
    }
    .our-culture-desc{
      & p{
        font-size: 1.1rem !important;
      }
    }
  
}


