@import '../../shared/assets/css/variables';


.global-btn {
  button {
    padding: 8px 16px;
    background-color: transparent;
    border: 2px solid $hover;
    border-radius: 6px;
    font-weight: 500;
    text-decoration: none !important;
    color: $black;

    &:hover {
      background-color: $hover;
      color: $white;
    }
  }
}

  @media screen and (min-width: 2001px){
    .global-btn {
    button{
      padding: 18px 34px;
      background-color: transparent;
      border: 2px solid $hover;
      border-radius: 6px;
      font-weight: 600;
      font-size: 1.8rem;
    }
    }
  }
@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .global-btn {
  button{
    padding: 12px 22px;
    background-color: transparent;
    border: 2px solid $hover;
    border-radius: 6px;
    font-weight: 600;
    font-size: 25px;
  }
}
}
@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .global-btn {
  button{
    padding: 12px 24px;
    background-color: transparent;
    border: 2px solid $hover;
    border-radius: 6px;
    font-weight: 600;
    width: fit-content;
    font-size: 20px;
  }
  }
}
@media (max-width: 768px) {
  .global-btn {
  button{
    padding: 8px 10px !important;
    background-color: transparent;
    border: 2px solid $hover;
    border-radius: 6px;
    font-weight: 500;
    width: fit-content;
    font-size: 14px;
  }
  }
}
