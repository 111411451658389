@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/mixins.scss';

.breadcrumb-hover {
    cursor: default;
}

.page-container {
    @include font;
    background-color: $background-color;
    overflow-x: hidden;
    padding-left: 10%;
    @include position-rel;

    & .success-image-container {
        background-image: url('../../shared/assets/images/success-stories-background.jpg');
    }
    & .cards-div {
        background-color: $white;

        & .heading-mob {
            @include display-none;
        }
    }
}
@media screen and (max-width: 767px) {
    .page-container {
        padding-left: 0%;
        margin-top: 7.5vh;
            & .heading-desc {
                @include display-none;
            }
        & .cards-div {
            & .heading-mob {
                display: block;
                text-align: center;
                padding: 4% 6% 0;
                font-size: 22px;
            }
        }
    }
}
