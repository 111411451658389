@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';
@import '../../../../shared/assets/css/common.scss';

.modal {
  z-index: 0;
  width: 100%;
  height: 50vh;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 1.5s;
  cursor: pointer;
  box-shadow: 4px 4px 20px 0px $black-shadow;
  border: 3px solid $grey;
  border-radius: 10px;
  flex: 0 0 47%;
  margin: 0 auto;
  @include flex-column;
}

.modal-face {
  backface-visibility: hidden;
  @include flex-column;
  justify-content: center;
  align-items: center;

}

.modal-text {
  font-size: 0.9rem;
  font-weight: 600;
  color: $stroke;
  text-align: center;
}

.modal-front {
  background-color: $white;
  border-radius: 10px;
  padding: 2.3em;
}

.modal img {
  height: 50%;
  width: 60%;
}
.modal-img {
  width: 80vw;
  border-radius: 10px;
}
.modal-container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3em 0;
  gap: 0.8em;
  flex-wrap: wrap;
}
.modal-back {
  @include flex-column;
  box-shadow: 0px 4px 4px 0px $black-shadow;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px); // For Safari 
}
.modal-back::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent; // Background color for color inversion
  mix-blend-mode: difference; // Invert the colors
}
.overlay-text-card {
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $white;
  padding: 1em 4.5em;
}
.overlay-text-card .back-header {
  font-size: 24px;
  font-weight: 500;
  color: $white;
  text-align: center;
}
.overlay-text-card .back-content {
  font-size: 16px;
  font-weight: 400;
  color: $white;
}
.modal-back {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  box-shadow: 24px;
}
@media (min-width:769px) and (max-width:1100px) {
  .modal {
    width: 100%;
    height: 20vh;
    flex: 0 0 43%;
  }
  .modal img {
    width: 42%;
    height: 70%;
  }
  .overlay-text-card {
    width: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $white;
    padding: 0;
  }

  .overlay-text-card .back-header {
    font-size: 1.7rem;
    font-weight: 600;
    color: $white;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .overlay-text-card .back-content {
    font-size: 1.2rem;
    font-weight: 500;
    color: $white;
  }

  .modal-img {
    width: 50%;
  }

  .modal-front {
    padding: 1em;
  }

  .modal-text {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
}
@media (min-width:551px) and (max-width:768px) {
  .modal {
    width: 100%;
    height: 25vh;
    flex: 0 0 43%;
  }
  .modal img {
    width: 45%;
    height: 65%;
  }
  .overlay-text-card {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $white;
    padding: 0 4em;
  }
  .overlay-text-card .back-header {
    font-size: 1.7rem;
    font-weight: 500;
    color: $white;
    text-align: center;
  }
  .overlay-text-card .back-content {
    font-size: 1.2rem;
    font-weight: 400;
    color: $white;
  }
  .modal-front {
    padding: 1em;
  }

  .modal-text {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
}

@media (min-width:481px) and (max-width:550px) {
  .modal {
    width: 100%;
    height: 18vh;
    flex: 0 0 44%;
  }

  .modal img {
    width: 40%;
    height: 55%;
  }

  .overlay-text-card {
    width: 85%;
  }

  .modal-front {
    padding: 0.3em;
  }

  .modal-text {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    padding: 1px;
  }
}

@media (min-width:312px) and (max-width:480px) {
  .modal {
    width: 80vw;
    height: 20vh;
    flex: 0 0 46%;
  }

  .modal img {
    width: 52%;
    height: 65%;
  }

  .modal-front {
    padding: 1em;
  }

  .modal-text {
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
  }
}

@media screen and (min-width:312px) and (max-width:399px){
  .overlay-text-card .back-header {
    font-size: 1rem;
    font-weight: 500;
    color: $white;
    text-align: center;
  }
  .overlay-text-card .back-content {
    font-size: 0.8rem;
    font-weight: 400;
    color: $white;
  }
  
}

@media screen and (min-width: 551px) and (max-width:625px){
  .modal-img{
    width: 70vw;
  }
  .overlay-text-card{
    & .back-header{
      padding-bottom: 2%;
    }
    & .back-content{
      padding: 0 8%;
      font-size: 1.1rem;
    }
  }
}
@media screen and (min-width: 626px) and (max-width:768px){
  .modal-img{
    width: 67vw;
  }
  .overlay-text-card{
    & .back-header{
      padding-bottom: 2%;
    }
    & .back-content{
      padding: 0 15%;
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 768px) and (max-width:850px){
  .modal-img{
    width: 55%;
  }
}