@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';


.page-container-WebApp {
    padding-left: 10%;
    @include font;
    background-color: $background-color;
    & .main-container-services {
        .card-services {
            height: 100%;
        }
    }
    .image-container {
        background-image: url('../../../../shared/assets/images/web-app-bg.jpg');
    }
}


@media screen and (max-width: 767px) {
    .page-container-WebApp {
        padding-left: 0%;
    }
}

