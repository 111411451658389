// Variables for Colors
$white: #FFFFFF;
$background-color: #F3F2F2;
$primary: #A21D22;
$light-primary:#A21D2280;
$secondary: #07395D;
$light-secondary: #A1ACBD;
$dark-gray: #9B9B9B;
$black-gradiant-shadow : rgba(0, 0, 0, 0.5);
$gradient-shadow:rgba(9, 56, 88, 0.4);
$gradient-shadow-light:rgba(7, 57, 93, 0.80);
$blue-shadow:#E9E9EA;
$grey: #D9D9D9;
$black-shadow: rgba(0, 0, 0, 0.25);
$hover : #4F728B;
$stroke: #333333;
$loading-ind-border: #9CB0BE;
$black : #000000;
$green : #0a740a;
$light-orange : #ffdddd;
$red : #d8000c;
$dark-aqua: #092E40;
$light-aqua: #44B9DE;
$sky: #2493D6;
$light-grey: #E9F4FF;
$light-blue: #007EA7;
$dark-yellow: #FEDC2E;
$light-green: #0DC2B5;
$cyan-blue: #2696AE;
$light-red: #be6165;
$light-sky: #63A9D5;