@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/mixins.scss';

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideFromDown {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes arrow {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(5px);
    }
}

.container-cards {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 4%;
    padding: 3% 10%;
    justify-content: center;
}

.animated-card {
    position: relative;
    overflow: hidden;

    .opaque-bg {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .animated-card-bg {
        height: 100%;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0.5);
    }


    & .heading {
        height: fit-content;
        margin-left: 24px;
        padding-top: 24px;
        font-weight: 600;
        font-size: 2rem;
        color: $white;
    }

    & .desc {
        font-size: 20px;
        font-weight: 600;
        padding: 5% 24px 28%;
        color: $white;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
    }

    & .read-now-btn {
        background-color: rgba(162, 29, 34, 0.5);
        ;
        position: absolute;
        bottom: 0;
        width: 47%;
        height: 13%;
        @include flex-row;
        justify-content: center;
        align-items: center;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;

        & p {
            color: $white;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
            padding-right: 3px;
        }

        & .arrow {
            transform: translateX(0);
        }

        &:hover {
            & .arrow {
                transform: translateX(5px);
                animation: arrow 0.5s ease-in-out;
            }
        }
    }

    &:hover {
        box-shadow: 2px 2px 15px $black-shadow;

        & .animated-card-bg {
            transform: scale(1.01);
        }

        & .desc {
            transform: none;
            animation: slideFromLeft 0.5s ease-in-out;
        }

        & .read-now-btn {
            transform: translateY(0);
            animation: slideFromDown 0.5s ease-in-out;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .animated-card p {
        font-size: 1.25rem;
    }
    
}



@media screen and (max-width: 1019px) {
    .container-cards {
        grid-template-columns: none;
        padding: 4% 10%;
        margin:0;
        gap: 2.5%;
    }
}
@media screen and (min-width: 1020px) and (max-width:1100px){
    .container-cards {
        grid-template-columns: none;
        padding: 4% 10%;
        margin:0;
        gap: 2.5%;
    }
}
@media screen and (min-width: 2300px) {
    .container-cards {
        & .animated-card {
            & .animated-card-bg {
                & .opaque-bg {
                    & .heading {
                        font-size: 3rem;
                    }

                    & .desc {
                        font-size: 2rem;
                        transform: none;
                    }

                    & .read-now-btn {
                        & p {
                            font-size: 2rem;
                        }

                        & .arrow-height {
                            height: 2vh;
                            width: 2vw;
                        }
                    }
                }
            }

        }
    }
}

@media screen and (min-width: 3300px) {
    .container-cards {
        & .animated-card {
            & .animated-card-bg {
                & .opaque-bg {
                    & .heading {
                        font-size: 4rem;
                    }

                    & .desc {
                        font-size: 3rem;
                        transform: none;
                    }

                    & .read-now-btn {
                        & p {
                            font-size: 3rem;
                        }

                        & .arrow-height {
                            height: 2vh;
                            width: 2vw;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1020px) and (max-width: 3500px){
    .animated-card {
    
        .animated-card-bg {
            transition: none;
        }

        & .desc {
            // transform: none !important; For future reference
            animation: slideFromLeft 0.5s ease-in-out;
        }
    
        & .read-now-btn {
            transform: none !important;
    
            & p {
                color: $white;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0;
                padding-right: 3px;
            }
    
            & .arrow {
                transform: translateX(5px);
            }
        }
    }    
}


@media screen and (max-width: 1019px) {
    .animated-card {
    
        .animated-card-bg {
            transition: none;
        }

        & .desc {
            transform: none !important;
        }
    
        & .read-now-btn {
            transform: none !important;
    
            & p {
                color: $white;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0;
                padding-right: 3px;
            }
    
            & .arrow {
                transform: translateX(5px);
            }
        }
    }    
}

@media screen and (max-width: 500px){
    .container-cards {
        padding-bottom: 6vh;
 
    }
}
@media screen and (max-width: 425px) {
    .animated-card {
    
        .animated-card-bg {
            transition: none;
        }

        .heading {
            font-size: 1.6rem;;
        }

        & .desc {
            font-size: 15px;
        }
        & .read-now-btn {

            width: 50%;
            height: 10%;
    
            & p {
                color: $white;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
                padding-right: 3px;
            }
    
            & .arrow {
                transform: translateX(3px);
            }
        }
    }    
}
@media screen and (min-width:490px) and (max-width:660px) {
    .animated-card{
        & .desc{
            padding: 5% 24px 43% !important;
        }
    }
}
@media screen and (min-width:426px) and (max-width:489px) {
    .animated-card{
        & .desc{
            padding: 5% 24px 55% !important;
        }
    }
    .container-cards{
        gap: 2% !important;
    }
}
@media screen and (min-width:380px) and (max-width:425px) {
    .animated-card{
        & .desc{
            padding: 5% 24px 43% !important;
        }
    }
}
@media screen and (min-width:352px) and (max-width:379px) {
    .animated-card{
        & .desc{
            padding: 5% 24px 46% !important;
        }
    }
}
@media screen and (min-width:326px) and (max-width:351px) {
    .animated-card{
        & .desc{
            padding: 5% 24px 55% !important;
        }
    }
}
@media screen and (min-width:312px) and (max-width:325px) {
    .animated-card{
        & .desc{
            padding: 5% 24px 75% !important;
        }
    }
}
@media screen and (min-width:768px) and (max-width:1024px) {
    .animated-card{
        & .desc{
            font-size: 1.1rem !important;
        }
    }
}
@media screen and (min-width:2000px) and (max-width:2400px) {
    .animated-card{
        & .desc{
            font-size: 1.8rem !important;
        }
    }
}
@media screen and (min-width:1300px) and (max-width:1699px) {
    .animated-card{
        & .desc{
            font-size: 18px !important;
        }
    }
}
@media screen and (min-width:2401px) and (max-width:2800px) {
    .animated-card{
        & .desc{
            font-size: 1.9rem !important;
        }
    }
}
@media screen and (min-width:2801px) and (max-width:2999px) {
    .animated-card{
        & .desc{
            font-size: 2.2rem !important;
        }
    }
}
@media screen and (min-width:3000px){
    .animated-card{
        & .desc{
            font-size: 2.5rem !important;
        }
    }
}

