@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";

.main-container-solutions-trakwa {
    display: flex;
    @include height-fit-content;
    padding-left: 5%;
    padding-top: 3%;
    padding-bottom: 1%;
    @include font;
}

.trakwa-heading-desc {
    @include font;
    @include position-rel;
    font-size: 1.5rem;
    width: 53%;
    font-weight: 500;
    z-index: 1;
    top: 173px;
    line-height: 2rem;
    color: $sky;
}

.trakwa-last-content {
    display: flex;
    padding: 5% 10%;
    text-align: center;
    font-size: 1.5rem;
}

.tech-stack-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10%;

    & img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
    }
}

.trakwa-techstack {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: center;

    & h4 {
        text-align: center;
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 600;
        padding-top: 5%;
        padding-bottom: 2%;
    }

    & p {
        text-align: center !important;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
    }
}

.card-trakwa-h5 {
    color: $dark-aqua !important;
    font-weight: 600 !important;
}

.card-trakwa-p {
    font-weight: 700 !important;
}

.card-trakwa-subpoints {
    color: $dark-aqua !important;
    font-weight: 400 !important;
}

.trakwa-para {
    color: $dark-aqua !important;
    font-weight: 700 !important;
}

.breadcrumb-trakwa {
    color: $sky !important;
}

.last-trakwa {
    & p {
        color: $dark-aqua;
        text-align: center;
        padding-top: 2%;
        font-weight: 500;
    }
}

.card-solution-trakwa {
    display: flex;
    flex-direction: column;
    flex: 0 0 33%;
    text-align: left;
    justify-content: flex-start;
    background-color: $light-grey;
    padding: 2em;
    box-shadow: 5px 10px 20px $grey;
    row-gap: 25px;
    border-radius: 5px;
    column-gap: 60px;
    margin-bottom: 25px;
    text-align: left;
}

.trakwatulz-container {
    @include flex-column;
    @include font;
    z-index: -1;
    padding-left: 10% !important;
    overflow: hidden;

    & .trakwatulz-image-container {
        background-image: url("../../../../shared/assets/images/trakwa_thumbnail.jpg");
        background-size: contain !important;

    }

    & .trakwatulz-image-container {
        height: 550px;
    }
}

.main-container-trakwa {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.last-trakwa-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.trakwa-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0 30px;

    & p {
        width: 90%;
        text-align: center;
    }
}

.trakwa-cards img {
    width: 90%;
    height: 70px;
    align-items: center;
    justify-content: center;
}

.vert-line-solutions-trakwa {
    @include flex-column;
    width: 2px;
    background-color: $light-aqua;
    height: 350px;
    padding: 0;
    margin-left: 1px;
}

.trakwa-content-container {
    padding: 3vh 9vw;

    & .trakwa-upper-section-heading {
        @include flex-row;
        justify-content: center;
        align-items: center;
        column-gap: 0.5vw;
        padding-top: 2%;
        font-size: 2.5rem;

        & img {
            width: 300px;

        }
    }
}

.trakwa-content-container {
    & .content-heading {
        padding-top: 1vw;

        & p {
            text-align: center;
            padding-top: 1%;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
}

.trakwa-main-content {
    @include flex-row;
    justify-content: space-between;
    width: 100%;

    & .up-content {
        display: flex;

        & a {
            color: $light-blue;
        }

        & p {
            font-size: 1.5rem;
            text-align: justify;
            hyphens: auto;
            -webkit-hyphens: auto;
            word-spacing: -0.05em;
            padding-top: 3%;
            font-weight: 500;
        }

        & .trakwa-content {
            @include flex-column;
        }
    }

    & .mid-content {
        display: flex;

        & .dashboard-trakwa-img {
            display: flex;
            margin: 0;
            width: 0;

            & img {
                width: 864px;
                position: relative;
                left: 130px;
            }

        }

        & .app-trakwa-img {
            display: flex;
            margin: 0;
            width: 0;

            & img {
                width: 864px;
                position: relative;
                left: 130px;
            }

        }
    }

    & h1 {
        @include text-stroke;
        -webkit-text-stroke: 1px $black !important;
        border: 1px !important;
        font-size: 6rem;
        line-height: 6.1rem;
        padding-bottom: 6%;
    }

}

.center-content-trakwa {
    @include flex-row;
    align-items: center;
    justify-content: center;

    & h2 {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 600;
        padding-top: 3%;
        padding-bottom: 3%;
        text-align: center
    }


}

.main-container-solution-trakwa {
    @include flex-row;
    flex-wrap: wrap;
    column-gap: 4vw;
    row-gap: 3vw;
    justify-content: center
}

.main-container-solutions-trakwa {
    @include flex-row;

    & .card-solutions-trakwa {
        flex: 0 0 33%;
        padding-left: 2%;
        padding-right: 7%;
        padding-bottom: 5%;
    }
}

.trakwa-role {
    display: flex;
    padding: 5% 10%;
    padding-bottom: 0;

    & h3 {
        font-size: 3rem;
        line-height: 3.5rem;
        text-align: center;
        font-weight: 600;
        padding-bottom: 2%;
    }

    & p {
        text-align: center;
        font-size: 26px;
        font-weight: 500;
    }

    & .trakwa-para-content {
        @include flex-column;
    }

    & .trakwa-mid-content {
        display: flex;
    }
}

.card-solution {
    flex: 0 0 46%;
    text-align: left;
    background-color: $light-grey;
    justify-content: flex-start;
    padding: 2em;
    box-shadow: 0px 10px 20px $grey;

    & h5 {
        font-size: 1.4rem;
        line-height: 2.9rem;
        font-weight: 600;
        align-items: flex-start;
    }
}

.card-solutions-trakwa {
    @include flex-row;
    text-align: left;
    padding-bottom: 8%;
    padding-right: 5%;
    width: 100%;

    & p {

        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
    }

    & h5 {
        font-weight: 600;
        color: $dark-aqua;
        font-size: 1.8rem;
        line-height: 2.3rem;
    }
}

.color-text-trakwa {
    color: $light-aqua;
}

.trakwa-point-container {
    & p {
        text-align: left;
        padding-left: 0%;
        margin: 0;
        font-size: 20px;
    }

}

.trakwa-last-content {
    @include flex-row;
    width: 100%;
    padding-bottom: 5%;
    align-items: center;

    & p {
        position: relative;
        font-size: 2rem;
        font-weight: 500;
        padding-left: 5%;
    }

    & a {
        color: $light-blue;
    }

    & img {
        width: 600px;
        height: fit-content;
    }
}

.trakwa-point-containers {
    align-items: left;
    @include flex-row;

    & p {
        text-align: left;
        padding-left: 5%;
        margin: 0;
        font-size: 20px;
        padding-bottom: 4%;
    }
}


@media screen and (max-width:767px) {
    .main-container-solution-trakwa {
        display: flex;
        flex-direction: row !important;
    }

    .trakwatulz-container {
        padding-left: 0 !important;

        & .trakwatulz-image-container {
            height: 500px;
            padding-top: 13%;
        }
    }

    .trakwa-content-container {
        .content-heading {
            & p {
                font-size: 1rem;
                font-weight: 500;
                padding-top: 3%;
            }
        }
    }

    .trakwa-content-container {
        .trakwa-upper-section-heading {

            & img {
                width: 100%;
            }
        }
    }

    .trakwa-main-content {
        column-gap: 2vw;
        margin: 0 auto;
        @include flex-row;

        & h1 {
            font-size: 2.7rem;
            line-height: 3.1rem;
            order: 0;

        }

        & .up-content {
            display: flex;
            padding-right: 0;
            padding-top: 5%;

            & p {
                order: 2;
            }
        }

        & .trakwa-content {
            & .dashboard-trakwa-mob {
                & img {
                    padding-top: 8%;
                }
            }
        }

        & .mid-content {
            display: flex;

            & .dashboard-trakwa-img {
                display: flex;

                & img {
                    width: 350px;
                    order: 1;
                }
            }

            & .app-trakwa-img {
                display: flex;

                & img {
                    width: 350px;
                    order: 1;
                }
            }
        }

        .cv-mob-bg {
            background-color: $blue-shadow;
            width: 54vw;
            height: 15vh;
            right: 0;
            z-index: -1;
            overflow-x: hidden;
        }

        .cv-mob-bg img {
            width: 3em;
            height: fit-content;
            bottom: 1em;
            right: 1em;
            order: 0;
        }

    }

    .center-content-trakwa {
        & h2 {
            font-size: 2rem;
            line-height: 2.5rem;
            padding-top: 8%;
            text-align: center;
            padding-bottom: 10%;
        }
    }

    .card-solution {
        @include flex-column;
        margin-bottom: 8%;
        padding: 3% 3%;
        background-color: $light-grey;
        width: auto;
        height: fit-content;
        align-items: flex-start;
        justify-content: flex-start;

        & img {
            align-items: left;
        }
    }

    .trakwa-point-containers {
        & p {
            font-size: 16px;
        }
    }

    .last-trakwa {
        padding-top: 3%;
    }

    .trakwa-cards {
        & p {
            width: 258px;
            padding-top: 15px;
        }

        & img {
            width: 100%;
        }
    }

    .trakwa-role {
        padding: 5% 5%;
        @include flex-row;
        flex-wrap: wrap;

        & h3 {
            font-size: 2rem !important;
            line-height: 2.5rem !important;
            padding-bottom: 3%;
        }

        & p {
            font-size: 1rem;
            width: 275px;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }

        & .trakwa-p {
            & p {
                text-align: left;
                width: 285px;
            }
        }
    }

    .card-solution-trakwa {
        margin-left: 7%;
        row-gap: 5px;
        padding: 1em;
    }

    .card-solutions-trakwa {
        display: flex;
        flex-direction: row;
        flex: 0 0 50% !important;
        width: fit-content;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 5px;

        & .trakwa-card-heading {
            display: flex;
            flex-direction: column;
            padding-left: 3%;

            & p {
                width: 250px;
            }
        }

    }

    .vert-line-solutions-trakwa {
        width: 2px;
        padding: 1px;
        margin-left: 1px;
    }

    .trakwa-last-content {
        @include flex-column;

        & img {
            width: 380px;
            align-items: center;
            padding-left: 2%;
        }

        & p {
            padding-top: 4%;
            font-size: 1.2rem;
            text-align: justify;
            hyphens: auto;
            -webkit-hyphens: auto;
            word-spacing: -0.05em;
            text-align: left;
        }
    }

    .main-container-solutions-trakwa {
        align-items: center;
        padding-left: 6%;
    }

    .main-container-trakwa {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .trakwa-techstack {
        & h4 {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width:350px) {
    .trakwa-last-content {
        align-items: center;
        justify-content: center;

        & img {
            width: 300px;
        }
    }

    .trakwa-main-content {
        & .mid-content {
            & .dashboard-trakwa-img {
                width: 100%;
            }

            & .app-trakwa-img {
                width: 100%;
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 1%;
        }
    }
}

@media screen and (min-width:768px) and (max-width:1199px) {
    .card-solutions-trakwa {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        & .trakwa-card-heading {
            & p {
                font-size: 1.2rem;
                text-align: left !important;
            }
        }
    }

    .card-solution-trakwa {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        & .trakwa-card-heading {
            & p {
                font-size: 1.2rem;
                line-height: 1.7rem;
            }
        }
    }

    .trakwa-main-content {
        & .up-content {
            & p {
                font-size: 1.2rem;
                line-height: 1.7rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    .content-heading {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
        }
    }

    .trakwa-role {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }

    .trakwa-cards {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
            padding-top: 10%;
        }
    }

    .last-trakwa {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
            padding-top: 3%;
            text-align: center;
        }
    }

    .last-trakwa-content {
        padding: 0;
        padding-left: 5%;
    }
}

@media screen and (min-width:1121px) and (max-width:1175px) {
    .trakwatulz-container {
        padding-left: 18px;

        & .trakwatulz-image-container {
            height: 432px !important;
            padding-top: 5%;
            padding-left: 8% !important;
        }
    }
}

@media screen and (min-width:1200px) and (max-width:1240px) {
    .trakwatulz-image-container {
        height: 457px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 185px !important;
        width: 62%;
    }
}

@media screen and (min-width:1241px) and (max-width:1299px) {
    .trakwatulz-image-container {
        height: 477px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 190px !important;
        width: 60%;
    }
}

@media screen and (min-width:1300px) and (max-width:1350px) {
    .trakwatulz-image-container {
        height: 490px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 190px !important;
        width: 60%;
    }
}

@media screen and (min-width:1300px) and (max-width:1500px) {
    .trakwa-card-heading p {
        width: 270px !important;
    }

    .vert-line-solutions-trakwa {
        height: 510px !important;
    }
}

@media screen and (min-width:1351px) and (max-width:1399px) {
    .trakwatulz-image-container {
        height: 515px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 200px !important;
        width: 60%;
    }
}

@media screen and (min-width:1400px) and (max-width:1460px) {
    .trakwatulz-image-container {
        height: 535px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 220px !important;
        width: 55%;
    }
}

@media screen and (min-width:1461px) and (max-width:1500px) {
    .trakwatulz-image-container {
        height: 555px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 225px !important;
        width: 53%;
    }
}

@media screen and (min-width:1501px) and (max-width:1555px) {
    .trakwatulz-image-container {
        height: 570px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 235px !important;
        width: 50%;
    }
}

@media screen and (min-width:1556px) and (max-width:1600px) {
    .trakwatulz-image-container {
        height: 590px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 240px !important;
        width: 50%;
    }
}

@media screen and (min-width:1601px) and (max-width:1650px) {
    .trakwatulz-image-container {
        height: 605px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 250px !important;
        width: 50%;
    }
}

@media screen and (min-width:1651px) and (max-width:1700px) {
    .trakwatulz-image-container {
        height: 625px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 255px !important;
        width: 50%;
    }
}

@media screen and (min-width:1701px) and (max-width:1760px) {
    .trakwatulz-image-container {
        height: 645px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 265px !important;
        width: 50%;
    }
}

@media screen and (min-width:1761px) and (max-width:1810px) {
    .trakwatulz-image-container {
        height: 665px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 275px !important;
        width: 50%;
    }
}

@media screen and (min-width:1811px) and (max-width:1850px) {
    .trakwatulz-image-container {
        height: 680px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 285px !important;
        width: 50%;
    }
}

@media screen and (min-width:1851px) and (max-width:1900px) {
    .trakwatulz-image-container {
        height: 698px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 290px !important;
        width: 50%;
    }
}

@media screen and (min-width:1901px) and (max-width:1950px) {
    .trakwatulz-image-container {
        height: 715px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 300px !important;
        width: 50%;
    }
}

@media screen and (min-width:1951px) and (max-width:1999px) {
    .trakwatulz-image-container {
        height: 735px !important;
        padding-top: 8%;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 315px !important;
        width: 50%;
    }
}

@media screen and (min-width:2000px) and (max-width:2045px) {
    .trakwatulz-image-container {
        height: 750px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 310px !important;
        width: 50%;
    }
}

@media screen and (min-width:2046px) and (max-width:2100px) {
    .trakwatulz-image-container {
        height: 768px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 320px !important;
        width: 50%;
    }
}

@media screen and (min-width:2101px) and (max-width:2150px) {
    .trakwatulz-image-container {
        height: 795px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 330px !important;
        width: 50%;
    }
}

@media screen and (min-width:2151px) and (max-width:2200px) {
    .trakwatulz-image-container {
        height: 810px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 340px !important;
        width: 50%;
    }
}

@media screen and (min-width:2201px) and (max-width:2250px) {
    .trakwatulz-image-container {
        height: 826px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 345px !important;
        width: 50%;
    }
}

@media screen and (min-width:2251px) and (max-width:2300px) {
    .trakwatulz-image-container {
        height: 845px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 355px !important;
        width: 50%;
    }
}

@media screen and (min-width:2301px) and (max-width:2350px) {
    .trakwatulz-image-container {
        height: 862px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 365px !important;
        width: 55%;
    }
}

@media screen and (min-width:2351px) and (max-width:2400px) {
    .trakwatulz-image-container {
        height: 880px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 375px !important;
        width: 55%;
    }
}

@media screen and (min-width:2401px) and (max-width:2450px) {
    .trakwatulz-image-container {
        height: 898px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 385px !important;
        width: 60%;
    }
}

@media screen and (min-width:2451px) and (max-width:2510px) {
    .trakwatulz-image-container {
        height: 920px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 395px !important;
        width: 62%;
    }
}

@media screen and (min-width:2511px) and (max-width:2560px) {
    .trakwatulz-image-container {
        height: 940px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 400px !important;
        width: 60%;
    }
}

@media screen and (min-width:2561px) and (max-width:2610px) {
    .trakwatulz-image-container {
        height: 960px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 410px !important;
        width: 60%;
    }
}

@media screen and (min-width:2611px) and (max-width:2675px) {
    .trakwatulz-image-container {
        height: 980px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 430px !important;
        width: 60%;
    }
}

@media screen and (min-width:2676px) and (max-width:2730px) {
    .trakwatulz-image-container {
        height: 1000px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 430px !important;
        width: 60%;
    }
}

@media screen and (min-width:2731px) and (max-width:2800px) {
    .trakwatulz-image-container {
        height: 1022px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 450px !important;
        width: 60%;
    }
}

@media screen and (min-width:2801px) and (max-width:2850px) {
    .trakwatulz-image-container {
        height: 1050px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 460px !important;
        width: 60%;
    }
}

@media screen and (min-width:2851px) and (max-width:2920px) {
    .trakwatulz-image-container {
        height: 1070px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 465px !important;
        width: 60%;
    }
}

@media screen and (min-width:2921px) and (max-width:2980px) {
    .trakwatulz-image-container {
        height: 1090px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 470px !important;
        width: 60%;
    }
}

@media screen and (min-width:2981px) and (max-width:3050px) {
    .trakwatulz-image-container {
        height: 1120px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 480px !important;
        width: 60%;
    }
}

@media screen and (min-width:3051px) and (max-width:3100px) {
    .trakwatulz-image-container {
        height: 1135px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 490px !important;
        width: 60%;
    }
}

@media screen and (min-width:3101px) and (max-width:3150px) {
    .trakwatulz-image-container {
        height: 1154px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 500px !important;
        width: 60%;
    }
}

@media screen and (min-width:3151px) and (max-width:3200px) {
    .trakwatulz-image-container {
        height: 1173px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 520px !important;
        width: 60%;
    }
}

@media screen and (min-width:3201px) and (max-width:3250px) {
    .trakwatulz-image-container {
        height: 1190px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 520px !important;
        width: 60%;
    }
}

@media screen and (min-width:3251px) and (max-width:3300px) {
    .trakwatulz-image-container {
        height: 1210px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 530px !important;
        width: 60%;
    }
}

@media screen and (min-width:3301px) and (max-width:3350px) {
    .trakwatulz-image-container {
        height: 1226px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 530px !important;
        width: 60%;
    }
}

@media screen and (min-width:3351px) and (max-width:3400px) {
    .trakwatulz-image-container {
        height: 1245px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 540px !important;
        width: 60%;
    }
}

@media screen and (min-width:3401px) and (max-width:3450px) {
    .trakwatulz-image-container {
        height: 1264px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 540px !important;
        width: 60%;
    }
}

@media screen and (min-width:3451px) and (max-width:3500px) {
    .trakwatulz-image-container {
        height: 1283px !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        top: 550px !important;
        width: 60%;
    }
}

@media screen and (min-width:1176px) and (max-width:1199px) {
    .trakwatulz-image-container {
        height: 441px !important;
        padding-top: 5%;
        padding-left: 8% !important;
    }
}

@media screen and (min-width:1061px) and (max-width:1120px) {
    .trakwatulz-image-container {
        height: 411px !important;
        padding: 0 !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        width: 55%;
        top: 210px;
    }
}

@media screen and (min-width:1001px) and (max-width:1060px) {
    .trakwatulz-image-container {
        height: 390px !important;
        padding: 0 !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        width: 60%;
        top: 200px;
    }
}

@media screen and (min-width:951px) and (max-width:1000px) {
    .trakwatulz-image-container {
        height: 368px !important;
        padding: 0 !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        width: 62%;
        top: 188px;
    }
}

@media screen and (min-width:901px) and (max-width:950px) {
    .trakwatulz-image-container {
        height: 350px !important;
        padding: 0 !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        width: 60%;
        top: 170px;
    }
}

@media screen and (min-width:841px) and (max-width:900px) {
    .trakwatulz-image-container {
        height: 330px !important;
        padding: 0 !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        width: 62%;
        top: 165px;
    }
}

@media screen and (min-width:786px) and (max-width:840px) {
    .trakwatulz-image-container {
        height: 310px !important;
        padding: 0 !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        width: 60%;
        top: 150px;
        font-size: 1.1rem !important;
        line-height: 1.6rem !important;
    }
}

@media screen and (min-width:768px) and (max-width:785px) {
    .trakwatulz-image-container {
        height: 290px !important;
        padding: 0 !important;
        padding-left: 8% !important;
    }

    .trakwa-heading-desc {
        width: 60%;
        top: 145px;
        font-size: 1.1rem !important;
        line-height: 1.6rem !important;
    }
}

@media screen and (min-width:768px) and (max-width:1120px) {
    .trakwatulz-container {
        & .trakwa-content-container {
            & .content-heading {
                & p {
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .trakwa-content-container {
            .trakwa-upper-section-heading {

                & img {
                    width: 250px;
                }
            }
        }

        .trakwa-main-content {
            display: flex;

            & h1 {
                font-size: 3rem;
                line-height: 3.5rem;
                width: 270px;
            }

            & p {
                font-size: 1.2rem;
                line-height: 1.7rem;
            }

            & .up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    width: 280px;

                }
            }

            & .mid-content {
                & .dashboard-trakwa-img {
                    & img {
                        width: 412px;
                        left: 15px;
                    }
                }

                & .app-trakwa-img {
                    & img {
                        width: 412px;
                        left: 15px;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .center-content-trakwa {
            & h2 {
                font-size: 2rem;
                line-height: 2.5rem;
                padding-top: 8%;
                text-align: center;
                padding-bottom: 6%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .trakwa-point-containers {
            & p {
                font-size: 16px;
            }
        }

        .trakwa-role {
            padding: 5% 5%;
            @include flex-column;
            flex-wrap: wrap;
            padding-left: 17%;
            width: 578px;

            & h3 {
                font-size: 2rem;
                line-height: 2.5rem;
                margin: 0 auto;
            }

            & p {
                font-size: 1.2rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 5%;
                padding-top: 3%;
            }

            & .trakwa-p {
                & p {
                    width: 515px;
                    text-align: left;
                }
            }

        }

        .card-solutions-trakwa {
            flex: 0 0 50%;
            padding-right: 0;

            & .trakwa-card-heading {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 230px;
                    padding-right: 3%;
                    font-size: 1.2rem;

                }
            }

        }

        .vert-line-solutions-trakwa {
            &.vert-trakwa-0 {
                height: 390px;
            }

            &.vert-trakwa-1 {
                height: 390px;
            }

            &.vert-trakwa-2 {
                height: 425px;
            }

            &.vert-trakwa-3 {
                height: 425px;
            }

            &.vert-trakwa-4 {
                height: 350px;
            }
        }

        .trakwa-last-content {
            @include flex-row;

            & img {
                width: 380px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.2rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .card-solution-trakwa {
            padding: 1em;
            display: flex;
            flex-direction: column;
            width: 530px;
            margin-left: 200px;
            row-gap: 0px !important;
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }
}

@media screen and (min-width:901px) and (max-width:959px) {
    .vert-line-solutions-trakwa {
        height: 386px !important;
    }
}

@media screen and (min-width:1024px) and (max-width:1120px) {
    .vert-line-solutions-trakwa {
        height: 300px !important;
    }

    .trakwa-card-heading {
        & p {
            width: 317px !important;
        }
    }
}

@media screen and (min-width:1500px) and (max-width:1520px) {
    .last-trakwa {
        & p {
            padding-top: 3% !important;
        }
    }
}

@media screen and (min-width:1121px) and (max-width:1500px) {
    .trakwa-content-container {
        .content-heading {
            & p {
                padding-bottom: 0%;
                font-size: 1.2rem;
            }
        }

        .card-solutions-trakwa {
            & h5 {
                padding-bottom: 4%;
            }

            & .trakwa-card-heading {
                padding-left: 3%;
            }
        }
    }

    .card-solution-trakwa {
        row-gap: 5px;
        padding: 1em;
    }

    .trakwa-card-heading p {
        font-size: 1.2rem;
        width: 230px;
    }

    .trakwa-role {
        padding-left: 5% !important;

        & p {
            text-align: center !important;
            padding-bottom: 4%;
        }

        & .trakwa-p {
            & p {
                text-align: left !important;
            }
        }
    }

    .trakwa-main-content {
        .up-content {
            padding-top: 3%;
        }

        & h1 {
            font-size: 5rem;
            line-height: 5.1rem;
            padding-bottom: 2%;
        }

        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem;
            padding-right: 4%;
            text-align: left;
        }

        .mid-content {
            & .dashboard-trakwa-img {
                & img {
                    width: 400px;
                    height: fit-content;
                    left: 90px;
                    padding-left: 15px;
                }
            }

            & .app-trakwa-img {
                & img {
                    width: 345px;
                    height: fit-content;
                    left: 90px;
                    padding-left: 15px;
                }
            }
        }
    }

    .last-trakwa-content {
        padding-top: 2%;
        padding-left: 5%;
    }

    .main-container-solutions-trakwa {
        padding-left: 5%;

        & .card-solutions-trakwa {
            flex: 0 0 30%;
            padding-right: 0;

        }
    }

    .main-container-solution-trakwa {
        padding-left: 5%;
        display: flex;
        flex-direction: column;
    }

    .vert-line-solutions-trakwa {
        width: 3px;
        height: 421px;
        padding: 1px;
        margin-left: 1px;
    }

    .trakwa-role {
        padding: 0;
        align-items: center;
        justify-content: center;

        & h3 {
            padding-top: 2%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 7%;
        }
    }

    .trakwa-cards {
        & p {
            text-align: center;
            width: 260px !important;
        }

    }

    .last-trakwa {
        & p {
            text-align: center;
        }
    }

    .trakwa-techstack {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:1501px) {
    .main-container-solution-trakwa {
        display: flex;
        flex-direction: column;
        row-gap: 1vw;
        padding: 1em;
    }

    .trakwa-heading-desc {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .trakwa-content-container {
        & .content-heading {
            & p {
                padding-bottom: 3%;
            }
        }
    }

    .vert-line-solutions-trakwa {
        width: 3px;

        &.vert-trakwa-0 {
            height: 465px !important;
        }

        &.vert-trakwa-1 {
            height: 465px;
        }

        &.vert-trakwa-2 {
            height: 465px;
        }

        &.vert-trakwa-3 {
            height: 470px;
        }

        &.vert-trakwa-4 {
            height: 470px;
        }
    }

    .trakwa-card-heading {
        & p {
            width: 305px;
        }
    }

    .trakwa-last-content {
        & p {
            font-size: 26px;
        }
    }

    .card-solutions-trakwa {
        padding-right: 0% !important;

        & h5 {
            padding-bottom: 5%;
        }

        & .trakwa-card-heading {
            padding-left: 3%;
        }
    }

    .card-solution-trakwa {
        row-gap: 5px;

        & h5 {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }

        & p {
            text-align: left;
        }
    }

    .trakwa-role {
        padding: 0;

        & h3 {
            padding-top: 2%;
        }

        & p {
            padding-bottom: 4%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }

    .trakwa-cards {
        & p {
            padding: 15px 0;
            text-align: center;
        }
    }

    .last-trakwa {
        & p {
            color: $dark-aqua;
            text-align: center;
        }
    }

    .dashboard-trakwa-img {
        & img {
            padding-top: 10px;
            padding-left: 50px;
            left: 10px !important;
            width: 460px !important;
        }
    }

    .app-trakwa-img {
        & img {
            padding-top: 60px;
            padding-left: 20px;
            left: 10px !important;
            width: 600px !important;
        }
    }

    .trakwa-techstack {
        & p {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

@media screen and (min-width:1200px) and (max-width:1500px) {
    .trakwa-card-heading {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }

    .trakwa-cards {
        & p {
            width: 253px;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .last-trakwa {
        & p {
            text-align: center;
            padding-top: 3% !important;
        }
    }

    .card-trakwa-h5 {
        font-size: 1.6rem;
    }
}

@media screen and (min-width:3000px) and (max-width:3500px) {
    .trakwa-card-heading {
        padding-right: 0px;

        & p {
            width: 490px;
        }
    }

    .card-solution-trakwa {
        & h5 {
            font-size: 2.6rem;
            line-height: 3rem;
        }
    }

    .card-trakwa {
        & p {
            width: 620px;
            margin-right: 50px;
            padding-bottom: 35px;
            padding-top: 30px;
        }
    }

    .trakwa-role {
        & p {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .trakwa-card-heading {
        padding-right: 0px;

        & p {
            width: 455px;
        }
    }

    .card-solution-trakwa {
        & h5 {
            font-size: 2.4rem;
            line-height: 2.9rem;
        }
    }

    .card-trakwa {
        & p {
            width: 620px;
            margin-right: 50px;
            padding-bottom: 35px;
            padding-top: 30px;
        }
    }

    .vert-line-solutions-trakwa {
        height: 523px !important;
    }

    .trakwa-role {
        & p {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }
    }
}

@media screen and (min-width:2501px) and (max-width:2800px) {
    .trakwa-card-heading {
        padding-right: 0px;

        & p {
            width: 390px;
        }
    }

    .card-solution-trakwa {
        & h5 {
            font-size: 2.3rem;
            line-height: 2.8rem;
        }
    }

    .card-trakwa {
        & p {
            width: 595px;
            margin-right: 50px;
            padding-bottom: 35px;
            padding-top: 30px;
        }
    }

    .vert-line-solutions-trakwa {
        height: 523px !important;
    }

    .dashboard-trakwa-img img {
        right: -34px;
        width: 560px !important;
    }

    .app-trakwa-img img {
        padding-top: 60px;
        padding-left: 10%;
        width: 700px !important;
    }
}

@media screen and (min-width:2000px) and (max-width:2500px) {
    .trakwa-card-heading {
        padding-right: 0px;

        & p {
            width: 390px;
        }
    }

    .card-solution-trakwa {
        & h5 {
            font-size: 2.3rem;
            line-height: 2.8rem;
        }
    }

    .card-trakwa {
        & p {
            width: 443px;
            margin-right: 50px;
            padding-bottom: 25px;
        }
    }
}

@media screen and (min-width:1800px) {
    .card-solutions-trakwa {
        & h5 {
            padding-bottom: 5%;
        }

        & .trakwa-card-heading {
            padding-left: 3%;
        }
    }

    .trakwa-heading-desc {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .vert-line-solutions-trakwa {
        width: 3px;

        &.vert-trakwa-0 {
            height: 465px;
        }

        &.vert-trakwa-1 {
            height: 465px;
        }

        &.vert-trakwa-2 {
            height: 465px;
        }

        &.vert-trakwa-3 {
            height: 440px;
        }

        &.vert-trakwa-4 {
            height: 440px;
        }
    }

    .trakwa-role {
        & h3 {
            padding-top: 0;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }
}

@media screen and (min-width:2000px) {
    .card-solution {
        & h5 {
            font-size: 2rem;
        }
    }

    .trakwa-point-containers {
        & p {
            font-size: 1.8rem;
            font-weight: 500;
        }
    }

    .trakwa-heading-desc {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    .card-solutions-trakwa {
        & p {
            font-size: 1.8rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 1.8rem;
            line-height: 2.3rem;
            padding-bottom: 2%;
        }
    }

    .trakwa-main-content {
        & .mid-content {
            & .dashboard-trakwa-img {
                & img {
                    right: -34px;
                    width: 500px;
                }
            }

            & .app-trakwa-img {
                & img {
                    right: -34px;
                }
            }
        }
    }

    .trakwa-content-container {
        & .trakwa-upper-section-heading {
            & img {
                width: 330px;
            }
        }

        & .content-heading {
            & p {
                font-size: 1.8rem;
            }
        }
    }

    .trakwa-main-content {

        & .up-content {
            & p {
                font-size: 1.8rem;
                line-height: 2.3rem;
            }
        }
    }

    .trakwa-last-content {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }

    .trakwa-role {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }

    .vert-line-solutions-trakwa {
        width: 3px;

        &.vert-trakwa-0 {
            height: 485px !important;
        }

        &.vert-trakwa-1 {
            height: 485px !important;
        }

        &.vert-trakwa-2 {
            height: 485px !important;
        }

        &.vert-trakwa-3 {
            height: 445px !important;
        }

        &.vert-trakwa-4 {
            height: 445px !important;
        }
    }
}

@media screen and (min-width:3000px) and (max-width:3500px) {

    .vert-line-solutions-trakwa {
        width: 3px;

        &.vert-trakwa-0 {
            height: 665px !important;
        }

        &.vert-trakwa-1 {
            height: 665px !important;
        }

        &.vert-trakwa-2 {
            height: 665px !important;
        }

        &.vert-trakwa-3 {
            height: 615px !important;
        }

        &.vert-trakwa-4 {
            height: 615px !important;
        }
    }
}

@media screen and (max-width:350px) {
    .card-solutions-trakwa {
        & h5 {
            padding-bottom: 2%;
        }

        & .trakwa-card-heading {
            & p {
                width: 247px;
            }
        }
    }

    .trakwatulz-container {
        & .trakwatulz-image-container {
            padding-top: 33%;
        }
    }
}

@media screen and (min-width:351px) and (max-width:450px) {
    .card-solutions-trakwa {
        & h5 {
            padding-bottom: 3%;
        }

        & .trakwa-card-heading {
            & p {
                width: 285px;
                padding-right: 7vw;
            }
        }
    }

    .vert-line-solutions-trakwa {
        height: 173px;
    }

    .card-solution {
        & h5 {
            padding-bottom: 3%;
        }
    }
}

@media screen and (min-width:351px) and (max-width:370px) {
    .trakwatulz-image-container {
        height: 153px !important;
        margin-top: 18% !important;
    }
}

@media screen and (min-width:371px) and (max-width:400px) {
    .trakwatulz-image-container {
        height: 170px !important;
        margin-top: 17% !important;
    }
}

@media screen and (min-width:401px) and (max-width:440px) {
    .trakwatulz-image-container {
        height: 180px !important;
        margin-top: 15% !important;
    }
}

@media screen and (min-width:441px) and (max-width:490px) {
    .trakwatulz-image-container {
        height: 200px !important;
        margin-top: 13% !important;
    }
}

@media screen and (min-width:491px) and (max-width:530px) {
    .trakwatulz-image-container {
        height: 220px !important;
        margin-top: 12% !important;
    }
}

@media screen and (min-width:531px) and (max-width:575px) {
    .trakwatulz-image-container {
        height: 235px !important;
        margin-top: 11% !important;
    }
}

@media screen and (min-width:576px) and (max-width:600px) {
    .trakwatulz-image-container {
        height: 250px !important;
        margin-top: 11% !important;
    }
}

@media screen and (min-width:601px) and (max-width:635px) {
    .trakwatulz-image-container {
        height: 260px !important;
        margin-top: 10% !important;
    }
}

@media screen and (min-width:636px) and (max-width:670px) {
    .trakwatulz-image-container {
        height: 275px !important;
        margin-top: 10% !important;
    }
}

@media screen and (min-width:671px) and (max-width:710px) {
    .trakwatulz-image-container {
        height: 290px !important;
        margin-top: 9% !important;
    }
}

@media screen and (min-width:711px) and (max-width:745px) {
    .trakwatulz-image-container {
        height: 305px !important;
        margin-top: 9% !important;
    }
}

@media screen and (min-width:746px) and (max-width:767px) {
    .trakwatulz-image-container {
        height: 315px !important;
        margin-top: 8% !important;
    }
}

@media screen and (min-width:312px) and (max-width:767px) {
    .trakwatulz-image-container {
        padding: 0 !important;
        padding-left: 18px !important;
    }
}

@media screen and (min-width:451px) and (max-width:550px) {
    .card-solutions-trakwa {
        & h5 {
            padding-bottom: 4%;
        }

        & .trakwa-card-heading {
            & p {
                width: 326px;
            }
        }
    }

    .vert-line-solutions-trakwa {
        height: 157px;
    }

    .trakwatulz-container {
        & .trakwatulz-image-container {
            padding-top: 21%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }
}

@media screen and (min-width:551px) and (max-width:670px) {
    .card-solutions-trakwa {
        & h5 {
            padding-bottom: 3%;
        }

        & .trakwa-card-heading {
            & p {
                width: 392px;
            }
        }
    }

    .vert-line-solutions-trakwa {
        &.vert-trakwa-0 {
            height: 290px !important;
        }

        &.vert-trakwa-1 {
            height: 260px;
        }

        &.vert-trakwa-2 {
            height: 290px;
        }

        &.vert-trakwa-3 {
            height: 290px;
        }

        &.vert-trakwa-4 {
            height: 270px;
        }
    }

    .trakwa-main-content {
        & .trakwa-content {
            & .dashboard-trakwa-mob {
                & img {
                    padding-top: 4%;
                }
            }
        }
    }

    .trakwatulz-container {
        & .trakwatulz-image-container {
            padding-top: 21%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 5%;
        }
    }
}

@media screen and (min-width:671px) and (max-width:767px) {
    .card-solutions-trakwa {
        & h5 {
            padding-bottom: 4%;
        }

        & .trakwa-card-heading {
            & p {
                width: 500px;
            }
        }
    }

    .vert-line-solutions-trakwa {
        &.vert-trakwa-0 {
            height: 270px !important;
        }

        &.vert-trakwa-1 {
            height: 250px;
        }

        &.vert-trakwa-2 {
            height: 275px;
        }

        &.vert-trakwa-3 {
            height: 275px;
        }

        &.vert-trakwa-4 {
            height: 240px;
        }
    }

    .trakwa-main-content {
        & .trakwa-content {
            & .dashboard-trakwa-mob {
                & img {
                    padding-top: 5%;
                }
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 8%;
        }
    }
}

@media screen and (min-width:2200px) and (max-width:2499px) {
    .card-solution {
        img.logo-0 {
            width: 12%;
        }

        img.logo-1 {
            width: 18%;
        }

        img.logo-2 {
            width: 17%;
            padding-bottom: 7%;
        }

        img.logo-3 {
            width: 15%;
        }
    }

    .trakwatulz-container {
        .trakwatulz-image-container {
            height: 700px;
            padding-bottom: 30%;
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.85rem;
            line-height: 2.4rem;
        }
    }

    .trakwa-point-containers {
        & p {
            font-size: 1.85rem;
            font-weight: 500;
            padding-top: 1%;
        }
    }

    .card-solutions-trakwa {
        & p {
            font-size: 1.8rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 1.8rem;
            line-height: 2.3rem;
            padding-bottom: 3%;
        }
    }

    .trakwa-last-content {
        & p {
            font-size: 33px;
        }
    }

    .trakwa-content-container {
        & .trakwa-upper-section-heading {
            & img {
                width: 425px;
            }
        }
    }

    .trakwa-role {
        & p {
            font-size: 1.85rem;
        }
    }
}

@media screen and (min-width:2500px) and (max-width:2999px) {
    .card-solution {
        & h5 {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }
    }

    .trakwa-point-containers {
        & p {
            font-size: 2rem;
            font-weight: 500;
            padding-top: 1%;
        }
    }

    .card-solutions-trakwa {
        & p {
            font-size: 1.9rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }
    }

    .trakwa-last-content {
        & p {
            font-size: 2.2rem;
        }
    }

    .trakwa-content-container {
        & .trakwa-upper-section-heading {
            & img {
                width: 425px;
            }
        }

        & .content-heading {
            & p {
                font-size: 2.2rem;
            }
        }
    }

    .trakwa-role {
        & p {
            font-size: 2rem;
        }

        & h3 {
            font-size: 3.2rem !important;
            line-height: 3.7rem !important;
        }
    }

    .trakwa-main-content {
        & h1 {
            font-size: 6.5rem;
            line-height: 7rem;
        }

        & p {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }

        & .up-content {
            & p {
                font-size: 2.2rem;
            }
        }
    }

    .center-content-trakwa {
        & h2 {
            font-size: 3.2rem;
            line-height: 3.7rem;
        }
    }

    .trakwa-techstack {
        & h4 {
            font-size: 3.2rem;
            line-height: 3.7rem;
        }
    }
}

@media screen and (min-width:3000px) {
    .card-solution {
        & h5 {
            font-size: 2.8rem;
            line-height: 3.3rem;
            padding-bottom: 5%;
        }
    }

    .trakwa-point-containers {
        & p {
            font-size: 2.3rem;
            line-height: 2.8rem;
            font-weight: 500;
        }
    }

    .card-solutions-trakwa {
        & p {
            font-size: 2.5rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }

    .trakwa-content-container {
        & .trakwa-upper-section-heading {
            & img {
                width: 450px;
            }
        }

        & .content-heading {
            & p {
                font-size: 2.5rem;
            }
        }
    }

    .trakwa-main-content {
        & h1 {
            font-size: 8.5rem;
            line-height: 9rem;
        }

        & p {
            font-size: 2.5rem;
            line-height: 3rem;
        }

        & .up-content {
            & p {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }
    }

    .trakwa-last-content {
        & p {
            font-size: 2.8rem;
        }
    }

    .trakwa-role {
        & p {
            font-size: 2.5rem;
        }

        & h3 {
            font-size: 3.5rem;
            line-height: 4rem;
            padding-top: 0;
            padding-bottom: 3%;
        }
    }

    .center-content-trakwa {
        & h2 {
            font-size: 3.5rem;
            line-height: 4rem;
        }
    }

    .dashboard-trakwa-img img {
        right: -34px;
        width: 726px !important;
        height: 800px !important;
    }

    .trakwa-techstack {
        & h4 {
            font-size: 3.5rem;
            line-height: 4rem;
        }
    }
}

@media screen and (min-width:1400px) and (max-width:1999px) {
    .trakwa-content-container {
        & .content-heading {
            & p {
                font-size: 1.5rem;
            }
        }
    }

    .trakwa-main-content {
        & p {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        & .up-content {
            & p {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

@media screen and (min-width:960px) and (max-width:1024px) {
    .trakwatulz-container {
        & .card-solutions-trakwa {
            & .trakwa-card-heading {
                & p {
                    width: 290px !important;
                }
            }
        }

        & .vert-line-solutions-trakwa {
            height: 327px !important;
        }
    }
}

@media screen and (min-width:893px) and (max-width:959px) {
    .trakwatulz-container {
        & .card-solutions-trakwa {
            & .trakwa-card-heading {
                & p {
                    width: 234px !important;
                }
            }
        }
    }
}

@media screen and (min-width:807px) and (max-width:850px) {
    .trakwatulz-container {
        & .card-solutions-trakwa {
            & .trakwa-card-heading {
                & p {
                    width: 232px !important;
                }
            }
        }

        & .vert-line-solutions-trakwa {
            height: 411px !important;
        }
    }
}

@media screen and (min-width:851px) and (max-width:892px) {
    .trakwatulz-container {
        & .card-solutions-trakwa {
            & .trakwa-card-heading {
                & p {
                    width: 218px !important;
                }
            }
        }
    }
}

@media screen and (min-width:1300px) and (max-width:1699px) {
    .trakwa-content-container {
        & .content-heading {
            & p {
                font-size: 1.5rem !important;
                line-height: 2rem;


            }
        }
    }

    .trakwa-main-content {
        & h1 {
            font-size: 4rem;
            line-height: 4.5rem;
        }

        & p {
            font-size: 1.5rem !important;
            line-height: 2rem;
        }

        & .up-content {
            & p {
                font-size: 1.5rem !important;
                line-height: 2rem;

            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;

        }
    }
}

@media screen and (min-width:2000px) and (max-width:2400px) {
    .trakwa-content-container {
        & .content-heading {
            & p {
                font-size: 1.8rem;
            }
        }
    }

    .trakwa-main-content {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }

        & .up-content {
            & p {
                font-size: 1.8rem;
                line-height: 2.3rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.9rem;
            line-height: 2.3rem;
        }
    }

    .trakwa-techstack {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }
}

@media screen and (min-width:2401px) and (max-width:2800px) {
    .trakwa-content-container {
        & .content-heading {
            & p {
                font-size: 1.9rem;
                line-height: 2.4rem;

            }
        }
    }

    .trakwa-heading-desc {
        font-size: 1.9rem;
        line-height: 2.4rem;
    }

    .trakwa-main-content {
        & p {
            font-size: 1.9rem;
            line-height: 2.4rem;
        }

        & .up-content {
            & p {
                font-size: 1.9rem;
                line-height: 2.4rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }

    .trakwa-techstack {
        & p {
            font-size: 1.9rem;
            line-height: 2.4rem;
        }
    }
}

@media screen and (min-width:2401px) and (max-width:2500px) {
    .vert-line-solutions-trakwa {
        width: 3px;

        &.vert-trakwa-0 {
            height: 460px !important;
        }

        &.vert-trakwa-1 {
            height: 460px !important;
        }

        &.vert-trakwa-2 {
            height: 460px !important;
        }

        &.vert-trakwa-3 {
            height: 500px !important;
        }

        &.vert-trakwa-4 {
            height: 500px !important;
        }
    }
}

@media screen and (min-width:2501px) and (max-width:2800px) {
    .vert-line-solutions-trakwa {
        width: 3px;

        &.vert-trakwa-0 {
            height: 500px !important;
        }

        &.vert-trakwa-1 {
            height: 500px !important;
        }

        &.vert-trakwa-2 {
            height: 500px !important;
        }

        &.vert-trakwa-3 {
            height: 500px !important;
        }

        &.vert-trakwa-4 {
            height: 500px !important;
        }
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .trakwa-content-container {
        & .content-heading {
            & p {
                font-size: 2.2rem;
            }
        }
    }

    .trakwa-heading-desc {
        font-size: 2.2rem;
        line-height: 2.7rem;
    }

    .trakwa-main-content {
        & p {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }

        & .up-content {
            & p {
                font-size: 2.2rem;
                line-height: 2.7rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2.3rem;
            line-height: 2.8rem;
        }
    }

    .app-trakwa-img img {
        padding-top: 2px;
        padding-left: 15%;
        width: 850px !important;
    }

    .vert-line-solutions-trakwa {
        width: 3px;

        &.vert-trakwa-0 {
            height: 505px !important;
        }

        &.vert-trakwa-1 {
            height: 505px !important;
        }

        &.vert-trakwa-2 {
            height: 505px !important;
        }

        &.vert-trakwa-3 {
            height: 565px !important;
        }

        &.vert-trakwa-4 {
            height: 565px !important;
        }
    }

    .trakwa-techstack {
        & p {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }
    }
}

@media screen and (min-width:3000px) {
    .trakwa-content-container {
        & .content-heading {
            & p {
                font-size: 2.5rem !important;
            }
        }
    }

    .trakwa-heading-desc {
        font-size: 2.5rem;
        line-height: 3rem;
    }

    .trakwa-main-content {
        & p {
            font-size: 2.5rem !important;
            line-height: 3rem;
        }

        & .up-content {
            & p {
                font-size: 2.5rem !important;
                line-height: 3rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2.6rem !important;
            line-height: 3.2rem;
        }
    }

    .app-trakwa-img img {
        padding-top: 40px;
        padding-left: 20%;
        width: 950px !important;
    }

    .trakwa-techstack {
        & p {
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }
}

@media screen and (min-width:312px) and (max-width:767px) {
    .dashboard-trakwa-mob {
        & img {
            width: 300px;
            padding-top: 3%;
            height: 350px;
            padding-right: 45px;
            padding-bottom: 10px;
        }
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .card-solutions-trakwa {
        & .trakwa-card-heading {
            & p {
                font-size: 2.2rem;
            }
        }
    }
}

@media screen and (min-width:2401px) and (max-width:2800px) {
    .card-solutions-trakwa {
        & .trakwa-card-heading {
            & p {
                font-size: 1.9rem;
            }
        }
    }
}

@media screen and (min-width:2000px) and (max-width:2400px) {
    .card-solutions-trakwa {
        & .trakwa-card-heading {
            & p {
                font-size: 1.8rem;
            }
        }
    }
}

@media screen and (min-width:1300px) and (max-width:1999px) {
    .card-solutions-trakwa {
        & .trakwa-card-heading {
            & p {
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:1100px) {
    .card-solutions-trakwa {
        & .trakwa-card-heading {
            & p {
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (min-width:481px) and (max-width:767px) {
    .card-solutions-trakwa {
        & .trakwa-card-heading {
            & p {
                font-size: 1rem;
            }
        }
    }

    .trakwa-heading-desc {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }

    .trakwa-techstack {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:420px) and (max-width:767px) {
    .trakwa-content-container {
        .trakwa-upper-section-heading {
            & img {
                width: 240px;
            }
        }
    }
}

@media screen and (min-width:312px) and (max-width:419px) {
    .main-container-solutions-trakwa {
        padding-left: 5%;
    }

    .trakwa-content-container {
        .trakwa-upper-section-heading {

            & img {
                width: 200px;
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:1120px) {
    .dashboard-trakwa-img {
        & img {
            width: 424px;
            height: 570px;
            padding-right: 60px;
            padding-bottom: 0px;
            padding-top: 20px;
        }
    }

    .app-trakwa-img {
        & img {
            width: 424px;
            height: 570px;
            padding-right: 60px;
            padding-bottom: 0px;
            padding-top: 20px;
        }
    }

    .trakwa-heading-desc {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }

    .trakwa-techstack {
        & h4 {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:810px) and (max-width:1120px) {
    .trakwatulz-container {
        & .trakwa-role {
            padding: 5% 5%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-left: 20%;
            width: 750px;
            padding-top: 0 !important;
        }
    }
}

@media screen and (min-width:661px) and (max-width:767px) {
    .dashboard-trakwa-mob {
        & img {
            width: 500px;
            height: 432px;
            padding-right: 5px;
            padding-bottom: 20px;
            padding-top: 20px;
        }
    }

    .trakwa-role {
        & p {
            width: 510px;
        }

        & .trakwa-p {
            & p {
                width: 510px !important;
            }
        }
    }

    .trakwa-cards {
        & p {
            width: 440px !important;
        }
    }
}

@media screen and (min-width:551px) and (max-width:660px) {
    .dashboard-trakwa-mob {
        & img {
            width: 400px;
            height: 370px;
            padding-right: 5px;
            padding-bottom: 20px;
            padding-top: 20px;
        }
    }

    .trakwa-role {
        & p {
            width: 375px;
        }

        & .trakwa-p {
            & p {
                width: 425px !important;
            }
        }
    }

    .trakwa-cards {
        & p {
            width: 440px !important;
        }
    }

    .trakwa-main-content {
        & .up-content {
            & p {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }
}

@media screen and (min-width:481px) and (max-width:550px) {
    .vert-line-solutions-trakwa {
        &.vert-trakwa-0 {
            height: 360px !important;
        }

        &.vert-trakwa-1 {
            height: 360px;
        }

        &.vert-trakwa-2 {
            height: 360px;
        }

        &.vert-trakwa-3 {
            height: 340px;
        }

        &.vert-trakwa-4 {
            height: 290px;
        }
    }

    .dashboard-trakwa-mob {
        & img {
            width: 350px;
            height: 350px;
            padding-right: 5px;
            padding-bottom: 15px;
            padding-top: 15px;
        }
    }

    .trakwa-role {
        & p {
            width: 335px;
        }

        & .trakwa-p {
            & p {
                width: 360px !important;
            }
        }
    }

    .trakwa-cards {
        & p {
            width: 357px !important;
        }
    }
}

@media screen and (min-width:481px) and (max-width:767px) {
    .trakwa-main-content {
        & .up-content {
            & p {
                font-size: 1.2rem !important;
                line-height: 1.7rem;
            }
        }
    }

    .content-heading {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem;
        }
    }

    .card-solutions-trakwa {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        & .trakwa-card-heading {
            & p {
                font-size: 1.2rem;
            }
        }
    }

    .card-solution-trakwa {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    .trakwa-role {
        & p {
            font-size: 1.2rem;
            line-height: 1.5rem;
        }
    }

    .trakwa-cards {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }

    .last-trakwa {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:401px) and (max-width:480px) {
    .vert-line-solutions-trakwa {
        &.vert-trakwa-0 {
            height: 260px !important;
        }

        &.vert-trakwa-1 {
            height: 260px;
        }

        &.vert-trakwa-2 {
            height: 290px;
        }

        &.vert-trakwa-3 {
            height: 260px;
        }

        &.vert-trakwa-4 {
            height: 240px;
        }
    }

    .trakwa-card-heading {
        & p {
            width: 301px !important;
        }
    }

    .main-container-solutions-trakwa {
        padding-left: 4%;
    }

    .trakwa-role {
        & p {
            width: 335px;
        }

        & .trakwa-p {
            & p {
                width: 285px !important;
            }
        }
    }

    .trakwa-cards {
        & p {
            width: 325px !important;
        }
    }

    .dashboard-trakwa-mob {
        padding-bottom: 15px;
        padding-top: 15px;
    }
}

@media screen and (min-width:351px) and (max-width:400px) {
    .trakwa-card-heading {
        & p {
            width: 300px;
            padding-right: 10vw;
        }
    }

    .main-container-solutions-trakwa {
        padding-left: 0%;
    }

    .vert-line-solutions-trakwa {
        &.vert-trakwa-0 {
            height: 290px !important;
        }

        &.vert-trakwa-1 {
            height: 280px;
        }

        &.vert-trakwa-2 {
            height: 290px;
        }

        &.vert-trakwa-3 {
            height: 280px;
        }

        &.vert-trakwa-4 {
            height: 240px;
        }
    }
}

@media screen and (min-width:312px) and (max-width:350px) {
    .vert-line-solutions-trakwa {
        &.vert-trakwa-0 {
            height: 300px !important;
        }

        &.vert-trakwa-1 {
            height: 280px;
        }

        &.vert-trakwa-2 {
            height: 305px;
        }

        &.vert-trakwa-3 {
            height: 280px;
        }

        &.vert-trakwa-4 {
            height: 250px;
        }
    }

    .trakwa-role {
        padding: 5% 5%;
        @include flex-row;
        flex-wrap: wrap;

        & h3 {
            font-size: 2rem;
            padding-bottom: 3%;
        }

        & p {
            font-size: 1rem;
            width: 275px;
            padding-left: 15%;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }

        & .trakwa-p {
            & p {
                text-align: left;
                width: 220px;
                padding-left: 0% !important;
            }
        }
    }

    .trakwa-content-container {
        padding: 2vh 9vw;

        &.trakwa-upper-section-heading {
            padding-top: 4% !important;
        }
    }

    .last-trakwa-content {
        & p {
            margin: 0%;
            padding-top: 10%;
        }
    }

    .main-container-trakwa {
        row-gap: 20px;
    }

    .trakwatulz-image-container {
        height: 145px !important;
        margin-top: 19% !important;
    }
}

@media screen and (min-width:312px) and (max-width:480px) {
    .card-solutions-trakwa {
        & h5 {
            font-size: 1.3rem;
            line-height: 1.8rem;
        }

        & .trakwa-card-heading {
            & p {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }

    .trakwa-main-content {
        & .up-content {
            & p {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.3rem;
            line-height: 1.8rem;
        }
    }

    .content-heading {
        & p {
            font-size: 1rem !important;
            line-height: 1.5rem !important;
        }
    }

    .trakwa-role {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .trakwa-cards {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .last-trakwa {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .trakwa-heading-desc {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .trakwa-techstack {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

@media screen and (min-width:312px) and (max-width:330px) {
    .main-container-solutions-trakwa {
        padding-left: 0%;
    }
}

@media screen and (min-width:768px) and (max-width:1120px) {
    .trakwa-role {
        .dashboard-trakwa-mob {
            display: block !important;

            & img {
                width: 400px;
                height: 500px;
                padding-bottom: 10px;
            }
        }
    }

    .app-trakwa-img {
        display: none !important;
    }
}

@media screen and (min-width:2501px) and (max-width: 2599px) {
    .trakwatulz-image-container {
        .breadcrumb {
            & a {
                font-size: 2rem !important;
                line-height: 2.5rem !important;
            }
        }
    }

}