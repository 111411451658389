@import "../../shared/assets/css/mixins.scss";
@import "../../shared/assets/css/_variables.scss";
@import "../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";

.award-container {
  @include flex-column;
  @include font;
  z-index: -1;
  padding-left: 10%;
}

@media (max-width: 767px) {
  .award-container {
    padding-left: 0;
    margin-top: 7.5vh;
  }
}

.awards-image-container {
  background-image: url("../../shared/assets/images/awards-bg.jpg");
}

.awards-card-container {
  overflow: hidden;
  @include flex-column;
  padding: 6vh 3vw 15vh 0vw;
  margin-bottom: -10vh;
  background-color: $background-color;
  justify-content: center;

  & .awards-celebrated-heading {
    color: $primary;
    text-align: center;

    & h2 {
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
    }
  }

  & .awards-card-row {
    @include flex-row;
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden;
    padding-top: 6vh;
    padding-left: 3vw;
    padding-bottom: 1vh;
    margin-right: -3vw;
    row-gap: 4vh;

    & .awards-card-img {
      padding-left: 3.5vw;
      padding-right: 3.5vw;
    }

    & .awards-card-col {
      @include flex-column;
      font-weight: 500;
      margin-right: 2%;
      border: 0;
      background-color: $white;
      box-shadow: 1px 1px 10px $black-shadow;
      border-radius: 0%;
      padding: 0;
      width: 33%;
      justify-content: space-between;

      & .awards-card-body {
        padding: 3vh 2.2vw 2.5vh 2.2vw;

        & .awards-card-content {
          @include flex-column;
          flex-wrap: wrap;
          row-gap: 1.5vh;

          & .awards-card-heading-desc {
            align-items: center;

            & img {
              width: 7%;
              margin-left: 7px;
              margin-bottom: 5px;
            }
          }

          & .URL-desc {
            font-size: 2rem;
            color: $secondary;
            text-decoration: none;
          }

          & p {
            margin: 0;
            font-size: 1.2rem;
          }

          & .blog-card-first-word {
            color: $primary;
          }
        }
      }

      & .read-btn-blog {
        position: relative;
        bottom: 8px;
        left: 2vw;
        padding-top: 2vh;
        width: 10vw;
        a {
          @include flex-row;
          row-gap: 2vw;
          align-items: center;
          font-size: 1.2rem;
          text-decoration: none;
          color: $primary;
          width: 100%;
        }
        
        .arrow-icon {
          margin-left: 10px;
          transition: transform 0.2s ease-in-out;
        }
        
        .effect.hover-effect:hover .arrow-icon {
          transform: translateX(5px);
        }
      }
    }
  }
}
@media only screen and (min-width: 1921px) {
  .awards-card-container {
    & .awards-celebrated-heading {
      & h2 {
        font-size: 4rem;
      }
    }
    & .awards-card-row {
      & .awards-card-col {
        & .awards-card-body {
          & .awards-card-content {
            & .awards-card-heading-desc {
              & a {
                font-size: 2rem;
              }
            }
            & p {
              font-size: 1.5rem;
            }
          }
        }
        & .read-btn-blog {

          & a {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 2800px) {
  .awards-card-container {
    & .awards-celebrated-heading {
      & h2 {
        font-size: 4rem;
      }
    }

    & .awards-card-row {
      & .awards-card-col {
        & .awards-card-body {
          & .awards-card-content {
            & .awards-card-heading-desc {
              & a {
                font-size: 3rem;
              }
            }

            & p {
              font-size: 2rem;
            }
          }
        }

        & .read-btn-blog {
          left: 2vw;
          & a {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .awards-image-container {
    height: 38vh;

    & .awards-text-container {
      padding-top: 2vh;

      & .heading-desc-award {
        font-size: 1.2rem;
        width: 100%;
        font-weight: 500;
      }

      & p {
        font-size: 3.2rem;
      }
    }

    .awards-breadcrumb {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}
@media only screen and (min-width: 901px) and (max-width: 1300px){
   .awards-card-col {
    & .read-btn-blog {
        left: 5vw;
        width: 15vw !important;
    }
  }
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .awards-card-container {
    padding: 3vh 0vw 13vh 0vw;

    & .awards-celebrated-heading {
      & h2 {
        font-size: 2rem;
        padding-left: 4vw;
      }
    }

    & .awards-card-row {
      padding-top: 3vh;
      padding-left: 0vw;
      margin-right: -8vw;
      margin-left: -6vw;
      row-gap: 2.5vh;

      & .awards-card-col {
        flex: 0 0 40%;

        & .awards-card-body {
          padding: 3vh 4vw 2vh 4vw;

          & .awards-card-content {
            row-gap: 0.4vh;
          }
        }
        & .read-btn-blog {
            left: 4vw;
            width: 25vw;
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .awards-card-container {
    padding: 3vh 4vw 13vh 0vw;

    & .awards-celebrated-heading {
      & h2 {
        font-size: 2rem;
        padding-left: 4vw;
      }
    }

    & .awards-card-row {
      padding-top: 3vh;
      padding-left: 7vw;
      margin-right: -1vw;
      row-gap: 2.5vh;

      & .awards-card-col {
        flex: 0 0 90%;

        & .awards-card-body {
          padding: 3vh 4vw 2vh 4vw;

          & .awards-card-content {
            row-gap: 0.4vh;
          }
        }
        & .read-btn-blog {
          left: 4vw;
          width: 30vw;
      }
      }
    }
  }
}

@media only screen and (min-width: 312px) and (max-width: 480px) {
  .awards-card-container {
    padding: 3vh 2vw 14vh 0vw;

    & .awards-celebrated-heading {
      & h2 {
        font-size: 2rem;
      }
    }

    & .awards-card-row {
      padding-top: 3vh;
      padding-left: 4vw;
      margin-right: -3vw;
      row-gap: 2.5vh;

      & .awards-card-col {
        flex: 0 0 90%;

        & .awards-card-body {
          padding: 3vh 4vw 2vh 4vw;

          & .awards-card-content {
            row-gap: 0.4vh;
          }
        }
        & .read-btn-blog {
          width: 42vw;
          left: 4vw;
      }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .awards-card-container{
    & .awards-celebrated-heading{
      & h2{
        font-size: 3.2rem;
        line-height: 3.7rem;
      }
    }
  }
}