@import '../../shared/assets/css/mixins.scss';
@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/common.scss';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');


@keyframes fillAnimation {
    to {
        background-size: 100% 100%;
    }
}

.carousel-img,
.carousel-img-mob {
    height: 100vh !important;
    width: 100vw !important;
    object-fit: cover !important;
}

.page-container-Home {
    overflow-x: hidden;
    overflow-y: hidden;
    @include position-rel;
    padding-left: 10%;
    height: 100vh;

    & .red-vert-line {
        @include position-abs;
        background-color: $primary;
        width: 0.2%;
        height: 443vh;
        top: 18.6%;
        left: 98%;
        z-index: 1000;
    }

    & .carousel.carousel-slider {
        @include position-rel;
        margin: 0;
        overflow: hidden;
        width: 100%;
        @include flex-row;
        flex-direction: row;
    }
}

.home-footer {
    margin-left: 10%
}

.red-rect-Home {
    @include position-abs;
    z-index: 50;
    opacity: 50%;
    background-color: $light-primary;
    bottom: 0%;
    left: 15%;
    height: 80%;
    width: 30%;
}

.carousel .thumbs-wrapper {
    display: none !important;
}

.slide-Home {
    width: 100%;
    @include position-rel;

}

.ind-Home {
    width: 2.8%;
    height: 4.7%;
    list-style: none;
    display: inline-block;
    cursor: pointer;
    @include position-rel;
    z-index: 100;
}

.ind.active {
    transition: 500ms 200ms;
}

.scroll-container {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(162, 29, 34, 0.5);
    height: 10%;
    width: 13%;

    @media screen and (max-width: 1507px) {
        height: 14%;
        width: 18%;
    }
}

.scroll-inner-container {
    @include flex-row;
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
    padding: 10%;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer
}

.scroll-mouse-container {
    width: 20% !important;
    height: 100%;

    @media screen and (max-width: 1507px) {
        width: 20%;
        height: 60%;
    }
}

.scroll-mouse {
    height: 115%;
    width: 65%;
    box-shadow: none !important;
}

#line_r {
    -webkit-animation: line_r_animation 2s infinite;
    animation: line_r_animation 2s infinite;
}

.line {
    width: 100%;
    height: 2px;
    background: $white;
    margin-left: 50%;
    margin-top: -10%;
    position: relative;
}

@keyframes line_r_animation {
    0% {
        left: calc(2% + 50px);
        width: 0px;
    }

    35% {
        left: calc(2% + 0px);
        width: 50px;
    }

    70% {
        left: calc(2% + 0px);
        width: 0px;
    }

    100% {
        left: calc(2% + 0px);
        width: 0px;
    }
}

@-webkit-keyframes line_r_animation {
    0% {
        right: calc(2% + 50px);
        width: 0px;
    }

    35% {
        right: calc(2% + 0px);
        width: 50px;
    }

    70% {
        right: calc(2% + 0px);
        width: 0px;
    }

    100% {
        right: calc(2% + 0px);
        width: 0px;
    }
}

@keyframes line_r_animation {
    0% {
        right: calc(2% + 50px);
        width: 0px;
    }

    35% {
        right: calc(2% + 0px);
        width: 50px;
    }

    70% {
        right: calc(2% + 0px);
        width: 0px;
    }

    100% {
        right: calc(2% + 0px);
        width: 0px;
    }
}

.scroll-text {
    color: $white;
    font-size: 1.375rem;
    font-weight: 400;

    @media screen and (min-width: 1100) and (max-width: 1507px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 1099px) {
        font-size: 0.8rem;
    }

    @media screen and (min-width: 2000px) {
        font-size: 1.9rem;
    }

    @media screen and (min-width: 2400px) {
        font-size: 2rem;
    }

    @media screen and (min-width: 2700px) {
        font-size: 2.4rem;
    }

    @media screen and (min-width: 3100px) {
        font-size: 2.7rem;
    }

    @media screen and (min-width: 3600px) {
        font-size: 3rem;
    }
}

.scroll-text-container {
    @include flex-column;
    justify-content: center;
}

.carousel.carousel-slider .control-dots .dot.selected {
    opacity: 1;
    background-color: red;
}

.pd-left {
    padding-left: 10%;
}

.carousel.carousel-slider .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: 1;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: $white;
    border-radius: 50%;
    width: 0.7vw;
    height: 1.4vh;
    cursor: pointer;
    display: inline-block;
    margin: 2vh 0.8vw;

    @media screen and (min-width: 2400px) {
        width: 0.7vw;
        height: 1.4vh;
        margin: 2vh 1vw;
    }

    @media screen and (min-width: 3000px) {
        width: 0.7vw;
        height: 1.4vh;
        margin: 2vh 1.1vw;
    }

    @media screen and (min-width: 3400px) {
        width: 0.7vw;
        height: 1.4vh;
        margin: 2vh 1.1vw;
    }
}


.carousel .control-dots {
    @include flex-column;
    @include position-abs;
    padding: 10px !important;
    @include text-center;
    height: 100%;
    z-index: 100;
    right: 0;
    justify-content: center;
    align-items: flex-end;
    background-image: url('../../shared/assets/images/dot-background.png');
    background-size: 4% 18.3%;
    background-repeat: no-repeat;
    background-position: 100% 50%;
}

.caraousel-content-Home {
    @include position-abs;
    width: 100%;
    z-index: 100;
    top: 25%;
    left: 7%;
}

.caraousel-content-Home h2 {
    @include font-fira;
    font-size: 60px;
    font-weight: 500;
    letter-spacing: 0.05em;
    padding-bottom: 2%;
    text-align: left;
    color: $white;
}

.caraousel-content-heading0-Home {
    width: 70%;
    @include height-fit-content;
}

.caraousel-content-heading-Home {
    width: 60%;
    @include height-fit-content;
}

.caraousel-content-Home p {
    width: 65%;
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: justify;
    color: $white;
    padding-bottom: 2%;

}

.loading-img-container-Home {
    width: 100%;
    @include flex-row;

    & div {
        height: 7px;
        border: 0.5px solid $loading-ind-border;
    }
}

.loader-active {
    width: 25%;
    margin-right: 15px;
    background-color: $white;
    background-image: linear-gradient(to right, red, red);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    animation: fillAnimation 5s linear forwards;
    animation-iteration-count: infinite;
}

.loader-inactive {
    width: 10%;
    margin-right: 1%;
    background-color: $white;
}

.home-btn {
    z-index: 100;
    color: $white;
    background-color: transparent;
    z-index: 100;
    @include position-abs;
    display: block;
    left: 16%;
    top: 75%;
    border: 2px solid $white;
    border-radius: 8px;
    padding: 1%;
    font-weight: 600;
}

.home-btn:hover {
    background-color: $primary;
    border-color: $primary;
}

.carousel .carousel-img-mob {
    @include display-none;
}

.carousel-status {
    @include display-none;
}

@media screen and (min-width: 1386px) and (max-width: 1594px) {
    .carousel .control-dots {
        background-size: 4.5% 19%;
    }
}

@media screen and (min-width: 901px) and (max-width: 1385px) {
    .carousel .control-dots {
        background-size: 5% 29%;

        & .ind-Home {
            width: 5%;
            height: 8%;
        }
    }

    .carousel.carousel-slider .control-dots .dot {
        width: 14px;
        height: 14px;
        margin: 2vh 0.8vw;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1800px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 3.8rem;
        }

        & p {
            font-size: 23px;
            width: 50%;
        }
    }

    .loading-img-container-Home {

        & div {
            height: 10px;
            border: 0.5px solid $loading-ind-border;
        }
    }

    .caraousel-content-heading0-Home {
        width: 40%;
    }

    .caraousel-content-heading-Home {
        width: 54%;
    }
}

@media screen and (min-width: 2000px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 80px;
        }

        & p {
            font-size: 32px;
        }
    }
}

@media screen and (min-width: 2300px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 6rem;
        }

        & p {
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: 2600px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 7rem;
        }

        & p {
            font-size: 2.4rem;
        }
    }
}

@media screen and (min-width: 2900px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 7rem;
        }

        & p {
            font-size: 2.8rem;
        }
    }
}

@media screen and (min-width: 3200px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 7.5rem;
        }

        & p {
            font-size: 3rem;
        }
    }
}

@media screen and (max-width: 1072px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 50px;
        }

        & p {
            font-size: 16px;
            width: 50%;
        }
    }

    .caraousel-content-heading0-Home {
        width: 40%;
    }

    .caraousel-content-heading-Home {
        width: 70%;
    }


    .home-btn {
        top: 10%;
    }
}

@media screen and (max-width: 900px) {
    .page-container-Home {
        padding-left: 10%;

        & .carousel.carousel-slider {
            @include position-rel;
            height: 100vh;
            margin: 0;
            overflow: hidden;
            width: 100%;
            @include flex-row;
            flex-direction: row;
        }
    }

    .home-btn {
        @include display-none;
    }

    .caraousel-content-Home {
        padding-top: 0%;
        width: 100%;

        & h2 {
            font-size: 52px;
        }

        & p {
            padding-top: 5%;
            width: 70%;
            font-size: 18px;
            text-align: left;
        }
    }

    .carousel .carousel-img-mob {
        display: block;
    }

    .carousel .carousel-img {
        @include display-none;
    }

    .loader-active {
        width: 45%;
        margin-right: 5px;
    }

    .loader-inactive {
        width: 20%
    }

    .red-rect-Home {
        top: 15%;
        height: 95%;
    }

    .caraousel-content-heading0-Home {
        width: 80%;
        @include height-fit-content;
    }

    .caraousel-content-heading-Home {
        width: 90%;
        @include height-fit-content;
    }

    .control-dots {
        display: none !important;
    }

    .scroll-container {
        @include display-none;
    }

}

@media screen and (max-width: 767px) {
    .pd-left {
        padding-left: 0;
    }

    .page-container-Home {
        padding-left: 0%;


        & .carousel.carousel-slider {
            @include position-rel;
            margin: 0;
            overflow: hidden;
            width: 100%;
            @include flex-row;
            flex-direction: row;
        }
    }

    .caraousel-content-Home {
        padding-top: 0%;
        width: 100%;

        & h2 {
            font-size: 52px;
        }

        & p {
            padding-top: 5%;
            width: 70%;
            font-size: 18px;
            text-align: left;
        }
    }

    .loader-active {
        width: 45%;
        margin-right: 5px;
    }

    .loader-inactive {
        width: 20%
    }

    .red-rect-Home {
        top: 20%;
        height: 80%;
    }

    .caraousel-content-heading0-Home {
        width: 67%;
        @include height-fit-content;
    }

    .caraousel-content-heading-Home {
        width: 85%;
        @include height-fit-content;
    }
}

@media screen and (max-width: 450px) {

    .caraousel-content-Home {
        width: 100%;

        & h2 {
            font-size: 36px;
        }

        & p {
            padding-top: 5%;
            width: 70%;
            font-size: 18px;
            text-align: left;
        }
    }

    .carousel .carousel-img-mob {
        display: block;
    }

    .carousel .carousel-img {
        @include display-none;
    }

    .loader-active {
        width: 45%;
        margin-right: 5px;
    }

    .loader-inactive {
        width: 20%
    }

    .red-rect-Home {
        top: 20%;
        height: 80%;
    }
}

@media screen and (min-width:1300px) and (max-width:1699px) {
    .caraousel-content-Home {
        & p {
            font-size: 22px !important;
        }
    }

}

@media screen and (min-width:901px) and (max-width:1299px) {
    .caraousel-content-Home {
        & h2 {
            font-size: 52px;
        }

        & p {
            font-size: 22px;
        }
    }

}