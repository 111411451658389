@import "../../shared/assets/css/mixins.scss";
@import "../../shared/assets/css/_variables.scss";
@import "../../shared/assets/css/common.scss";

.contact-mob-container{
    display: none;
}
@media (max-width: 768px) {
  .contact-mob-container{
    margin-top: 7.5vh;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .contact-mob-container{
    display: block;
    overflow: hidden;
    padding-top: 1vh;
    & .contact-mob-content{
      @include flex-column;
      row-gap: 2vh;
    & .contact-header-name{
      padding-top: 2vh;
      padding-left: 4vw;
      width: 100%;
      @include flex-column;
      letter-spacing: 5px;
      justify-content: space-between;
      & .contact-text-stroke{
        @include text-stroke;
        font-size: 3rem;
        font-weight: 900;
        letter-spacing: 2px;
        color: transparent;
        padding-top: 0.5vh;
      }
      & .contact-mob-bg{
        background-color: $blue-shadow;
        width: 60vw;
        height: 8vh;
        right: 0;
        z-index: -1;
        overflow-x: hidden;
        & img{
          width: 15%;
          height: 15%;
          bottom: 1em;
          right: 1em;
        }
      }
    }
    & .contact-mob-subheader{
      color: $secondary;
      letter-spacing: 1px;
      font-size: 1.4rem;
      font-weight: 600;
      width: 100%;
      padding-left: 2vh;
      padding-right: 2vh;
    }
    & .contact-mob-img{
      & img{
        width: 100%;
        margin-top: -4vh;
      }
    }
    & .contact-info {
      @include flex-row;
      justify-content:flex-start;
      padding-left: 3vh;
      column-gap: 17px;
      align-items: center;
      & img{
        width: 35px;
        padding: 0.8%;
      }
      & p{
        margin: 0;
        font-size: 1.2rem;
        font-weight: 400;
        text-align: start;
      }
      & .phone-icon{
        width: 5%;
      }
    }
    & hr{
      width: 92%;
      height: 1px;
      margin: 0 auto;
      border: 1px solid $dark-gray;
      margin-top:1vh;
    }
    & .contact-share-details{
      @include flex-row;
      padding-right:2vh ;
      justify-content: space-between;
      & img{
        width: 9%;
        padding-bottom: 2vh;
      }
    }
    & .email-link{
      padding-top: 3vh;
      padding-right: 1vw;
      padding-left: 1vw;
      & p{
        font-size: 1rem;
        text-align: center;
      }
      & a{
        color: $secondary;
        font-size: 1rem;
        text-decoration: none;
      }
    }
  }
  }
}
@media screen and (min-width: 312px) and (max-width: 480px) {
  .contact-mob-container{
    display: block;
    overflow: hidden;
    padding-top: 1vh;
    & .contact-mob-content{
      @include flex-column;
      row-gap: 2vh;
    & .contact-header-name{
      padding-top: 2vh;
      padding-left: 4vw;
      width: 100%;
      @include flex-column;
      letter-spacing: 5px;
      justify-content: space-between;
      & .contact-text-stroke{
        @include text-stroke;
        font-size: 3rem;
        font-weight: 900;
        letter-spacing: 2px;
        color: transparent;
        padding-top: 0.5vh;
      }
      & .contact-mob-bg{
        background-color: $blue-shadow;
        width: 60vw;
        height: 8vh;
        right: 0;
        z-index: -1;
        overflow-x: hidden;
        & img{
          width: 15%;
          height: 15%;
          bottom: 1em;
          right: 1em;
        }
      }
    }
    & .contact-mob-subheader{
      color: $secondary;
      letter-spacing: 1px;
      font-size: 1.2rem;
      font-weight: 600;
      width: 100%;
      padding-left: 2vh;
      padding-right: 2vh;
    }
    & .contact-mob-img{
      & img{
        width: 100%;
        margin-top: -4vh;
      }
    }
    & .contact-info {
      @include flex-row;
      justify-content:flex-start;
      padding-left: 2vh;
      column-gap: 12px;
        & img{
          width: 30px;
          padding-top: 1%;
        }
      & p{
        margin: 0;
        font-size: 1.1rem;
        font-weight: 400;
        text-align: start;
        & a{
          text-decoration: none;
          color: $black;
        }
      }
      & .phone-icon{
        width: 6%;
      }
    }
    & hr{
      width: 92%;
      height: 1px;
      margin: 0 auto;
      border: 1px solid $dark-gray;
      margin-top:1vh;
    }
    & .contact-share-details{
      @include flex-row;
      padding-right:2vh ;
      justify-content: space-between;
      & img{
        width: 11%;
        padding-bottom: 2vh;
      }
    }
    & .email-link{
      padding-top: 3vh;
     
      & p{
        font-size: 1rem;
        text-align: center;
        padding-right: 1vw;
        padding-left: 2vw;
      }
      & a{
        color: $secondary;
        font-size: 1rem;
        text-decoration: none;
      }
    }
  }
  }
}

@media screen and (min-width: 312px) and (max-width: 480px) {
  .contact-mob-container{
    display: block;
    overflow: hidden;
    padding-top: 1vh;
    & .contact-mob-content{
      @include flex-column;
      row-gap: 2vh;
    & .contact-header-name{
      padding-top: 2vh;
      padding-left: 4vw;
      width: 100%;
      @include flex-column;
      letter-spacing: 5px;
      justify-content: space-between;
      & .contact-text-stroke{
        @include text-stroke;
        font-size: 2.5rem;
        font-weight: 900;
        letter-spacing: 2px;
        color: transparent;
        padding-top: 0.5vh;
      }
    }
    }
  }
}

     