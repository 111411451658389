@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";

.main-container-solutions-qufree {
    display: flex;
    @include height-fit-content;
    padding-left: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
    @include font;
}

.qufree-heading-desc {
    @include font;
    @include position-rel;
    font-size: 1.5rem;
    width: 53%;
    font-weight: 500;
    z-index: 1;
    top: 173px;
    line-height: 2rem;
    color: $cyan-blue;
}

.breadcrumb-qufree {
    color: $cyan-blue !important;
}

.qu-card-p {
    font-weight: 700 !important;
    color: $black !important;
}

.qu-card-subpoints {
    font-weight: 400 !important;
    color: $black !important;
}

.last-qufree-content {
    display: flex;
    padding: 5% 10%;
    text-align: center;
    font-size: 1.5rem;
}

.card-solution-qufree {
    display: flex;
    flex-direction: column;
    flex: 0 0 33%;
    text-align: left;
    justify-content: flex-start;
    padding: 0em;
    row-gap: 50px;
    column-gap: 60px;
    margin-bottom: 25px;
    text-align: left;
}

.qufree-techstack {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: center;

    & h4 {
        text-align: center;
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 600;
        padding-top: 5%;
        padding-bottom: 2%;
    }

    & p {
        text-align: center !important;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
    }
}

.qufree-container {
    @include flex-column;
    @include font;
    z-index: -1;
    font-weight: 500;
    overflow: hidden;

    & .qufree-image-container {
        background-image: url("../../../../shared/assets/images/qufree_thumbnail.jpg");
    }
}

.main-container-qufree {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.last-qufree-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.qufree-crads {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0 30px;

    & p {
        width: 90%;
        text-align: center;
    }
}

.qufree-crads img {
    width: 90%;
    height: 70px;
    align-items: center;
    justify-content: center;
}

.vert-line-solutions-qufree {
    @include flex-column;
    width: 2px;
    background-color: $light-green;
    height: 350px;
    padding: 0;
    margin-left: 1px;
}

.vert-line-solutions-second {
    @include flex-column;
    width: 2px;
    background-color: $light-green;
    height: 350px;
    padding: 0;
    margin-left: 1px;
}

.qufree-content-container {
    padding: 3vh 9vw;

    & .qufree-upper-section-heading {
        @include flex-row;
        justify-content: center;
        align-items: center;
        column-gap: 0.5vw;
        padding-top: 2%;
        font-size: 2.5rem;

        & img {
            width: 300px;
        }
    }
}

.qufree-content-container {
    & .qufree-content-heading {
        padding-top: 1vw;

        & p {
            text-align: center;
            padding-top: 1%;
            font-weight: 500;
            color: black;
            padding-bottom: 6%;
        }
    }
}

.qufree-main-content {
    @include flex-row;
    justify-content: space-between;
    width: 100%;

    & .qufree-up-content {
        display: flex;

        & a {
            color: $light-blue;
        }

        & p {
            text-align: justify;
            hyphens: auto;
            -webkit-hyphens: auto;
            word-spacing: -0.05em;
            padding-top: 3%;
            font-weight: 500;
        }
    }

    & .qufree-mid-content {
        display: flex;

        & .dashboard-qufree-img {
            display: flex;
            margin: 0;
            width: 0;

            & img {
                width: 864px;
                position: relative;
                left: 130px;
            }

        }
    }

    & h1 {
        @include text-stroke;
        -webkit-text-stroke: 1px $black !important;
        border: 1px !important;
        font-size: 6rem;
        line-height: 6.1rem;
        padding-bottom: 6%;
    }

}

.qufree-center-content {
    @include flex-row;
    align-items: center;
    justify-content: center;

    & h2 {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 600;
        padding-top: 3%;
        padding-bottom: 3%;
        text-align: center
    }


}

.main-container-solutions-qufree {
    @include flex-row;

    & .card-solutions-qufree {
        flex: 0 0 25%;
        padding-left: 2%;
        padding-right: 7%;
        padding-bottom: 5%;
    }
}

.qufree-role {
    display: flex;
    padding: 5% 10%;
    padding-bottom: 0;

    & h3 {
        font-size: 3rem;
        line-height: 3.5rem;
        text-align: center;
        font-weight: 600;
        padding-bottom: 2%;
    }

    & p {
        text-align: center;
        font-weight: 500;
    }

    & .qufree-para-content {
        @include flex-column;
        flex: 0 0 33%;
    }

    & .qufree-mid-content {
        display: flex;
    }
}

.card-solution {
    flex: 0 0 46%;
    text-align: left;
    background-color: $light-grey;
    justify-content: flex-start;
    padding: 2em;
    box-shadow: 0px 10px 20px $grey;

    & h5 {
        font-size: 1.4rem;
        font-weight: 600;
        align-items: flex-start;
    }
}

.card-solutions-qufree {
    @include flex-row;
    flex: 0 0 25%;
    text-align: left;
    padding-bottom: 8%;
    padding-right: 5%;
    width: 100%;


    & p {

        font-size: 20px;
        font-weight: 500;
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
    }

    & h5 {
        font-weight: 600;
        color: $dark-aqua;
    }
}

.color-text-qufree {
    color: $dark-yellow;
}

.last-qufree-content {
    @include flex-row;
    width: 100%;
    padding-bottom: 5%;
    align-items: center;

    & p {
        position: relative;
        font-size: 2rem;
        font-weight: 500;
        padding-left: 5%;
    }

    & a {
        color: $light-blue;
    }

    & img {
        width: 600px;
        height: fit-content;
    }
}

@media screen and (max-width:767px) {
    .qufree-container {
        margin-top: 7.5vh;
        padding-left: 0;
    }

    .qufree-image-container {
        background-size: contain !important;
    }

    .qufree-content-container {
        .qufree-content-heading {
            & p {
                font-weight: 400;
                padding-top: 3%;
            }
        }
    }

    .qufree-main-content {
        column-gap: 2vw;
        margin: 0 auto;
        @include flex-row;

        & h1 {
            font-size: 2.7rem;
            line-height: 3.1rem;
            order: 0;

        }

        & .qufree-up-content {
            display: flex;
            padding-right: 0;
            padding-top: 5%;

            & p {
                order: 2;
            }
        }

        & .qufree-mid-content {
            display: flex;

            & .dashboard-qufree-img {
                display: flex;

                & img {
                    width: 350px;
                    order: 1;
                }
            }
        }

        .cv-mob-bg {
            background-color: $blue-shadow;
            width: 54vw;
            height: 15vh;
            right: 0;
            z-index: -1;
            overflow-x: hidden;
        }

        .cv-mob-bg img {
            width: 3em;
            height: fit-content;
            bottom: 1em;
            right: 1em;
            order: 0;
        }

    }

    .qufree-center-content {
        & h2 {
            font-size: 2rem;
            line-height: 2.5rem;
            padding-top: 8%;
            text-align: center;
            padding-bottom: 10%;
        }
    }

    .card-solution {
        @include flex-column;
        margin-bottom: 8%;
        padding: 3% 3%;
        background-color: $light-grey;
        width: auto;
        height: fit-content;
        align-items: flex-start;
        justify-content: flex-start;

        & img {
            align-items: left;
        }
    }

    .qufree-role {
        padding: 5% 5%;
        @include flex-row;
        flex-wrap: wrap;
        padding-top: 0 !important;

        & h3 {
            font-size: 2rem;
            line-height: 2.5rem;
            padding-bottom: 3%;
        }

        & p {
            width: 100%;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            padding-bottom: 5%;
        }

    }

    .card-solutions-qufree {
        display: flex;
        flex-direction: row;
        flex: 0 0 50% !important;
        width: fit-content;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 5% !important;

        & .solution-card-heading-qufree {
            display: flex;
            flex-direction: column;
            padding-left: 3%;

            & p {
                width: 250px;
            }
        }

    }

    .vert-line-solutions-qufree {
        width: 2px;
        height: 170px;
        padding: 1px;
        margin-left: 1px;
        height: 300px;
    }

    .vert-line-solutions-second {
        width: 2px;
        background-color: $light-green;
        height: 170px;
        padding: 1px;
        margin-left: 1px;
        height: 300px;
    }

    .last-qufree-content {
        @include flex-column;

        & img {
            width: 380px;
            align-items: center;
            padding-left: 2%;
        }

        & p {
            padding-top: 4%;
            font-size: 1.2rem;
            text-align: justify;
            hyphens: auto;
            -webkit-hyphens: auto;
            word-spacing: -0.05em;
            text-align: left;
        }
    }

    .main-container-solutions-qufree {
        align-items: center;
        padding-left: 6%;
    }

    .qufree-techstack {
        & h4 {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width:350px) {
    .last-qufree-content {
        align-items: center;
        justify-content: center;

        & img {
            width: 270px;
            padding-left: 0;
        }
    }

    .qufree-main-content {
        & .qufree-mid-content {
            & .dashboard-qufree-img {
                width: 100%;
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 1%;
        }
    }

    .vert-line-solutions-second {
        height: 275px;
    }
}

@media screen and (min-width:340px) and (max-width:390px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 225px;
        }

        &.vert-qufree-1 {
            height: 200px;
        }

        &.vert-qufree-2 {
            height: 230px;
        }

        &.vert-qufree-3 {
            height: 290px;
        }

        &.vert-qufree-4 {
            height: 300px;
        }

        &.vert-qufree-5 {
            height: 285px;
        }

        &.vert-qufree-6 {
            height: 255px;
        }

        &.vert-qufree-7 {
            height: 245px;
        }

        &.vert-qufree-8 {
            height: 225px;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 205px;
        }

        &.vert-second-1 {
            height: 225px;
        }

        &.vert-second-2 {
            height: 205px;
        }

        &.vert-second-3 {
            height: 230px;
        }

        &.vert-second-4 {
            height: 250px;
        }

        &.vert-second-5 {
            height: 230px;
        }
    }
}

@media screen and (min-width:701px) and (max-width:767px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 210px !important;
        }

        &.vert-qufree-1 {
            height: 210px !important;
        }

        &.vert-qufree-2 {
            height: 195px !important;
        }

        &.vert-qufree-3 {
            height: 235px !important;
        }

        &.vert-qufree-4 {
            height: 235px !important;
        }

        &.vert-qufree-5 {
            height: 235px !important;
        }

        &.vert-qufree-6 {
            height: 215px !important;
        }

        &.vert-qufree-7 {
            height: 215px !important;
        }

        &.vert-qufree-8 {
            height: 215px !important;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 185px;
        }

        &.vert-second-1 {
            height: 220px;
        }

        &.vert-second-2 {
            height: 195px;
        }

        &.vert-second-3 {
            height: 215px;
        }

        &.vert-second-4 {
            height: 210px;
        }

        &.vert-second-5 {
            height: 195px;
        }
    }
}

@media screen and (min-width:581px) and (max-width:700px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 210px !important;
        }

        &.vert-qufree-1 {
            height: 205px !important;
        }

        &.vert-qufree-2 {
            height: 200px !important;
        }

        &.vert-qufree-3 {
            height: 235px !important;
        }

        &.vert-qufree-4 {
            height: 255px !important;
        }

        &.vert-qufree-5 {
            height: 235px !important;
        }

        &.vert-qufree-6 {
            height: 235px !important;
        }

        &.vert-qufree-7 {
            height: 235px !important;
        }

        &.vert-qufree-8 {
            height: 235px !important;
        }
    }
}

@media screen and (min-width:501px) and (max-width:580px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 225px !important;
        }

        &.vert-qufree-1 {
            height: 220px !important;
        }

        &.vert-qufree-2 {
            height: 200px !important;
        }

        &.vert-qufree-3 {
            height: 245px !important;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 200px;
        }

        &.vert-second-1 {
            height: 225px;
        }

        &.vert-second-2 {
            height: 200px;
        }

        &.vert-second-3 {
            height: 250px;
        }

        &.vert-second-4 {
            height: 255px;
        }

        &.vert-second-5 {
            height: 255px;
        }
    }
}

@media screen and (min-width:551px) and (max-width:700px) {
    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 185px;
        }

        &.vert-second-1 {
            height: 230px;
        }

        &.vert-second-2 {
            height: 200px;
        }

        &.vert-second-3 {
            height: 210px;
        }

        &.vert-second-4 {
            height: 210px;
        }

        &.vert-second-5 {
            height: 210px;
        }
    }
}

@media screen and (min-width:521px) and (max-width:550px) {
    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 200px;
        }

        &.vert-second-1 {
            height: 225px;
        }

        &.vert-second-2 {
            height: 200px;
        }

        &.vert-second-3 {
            height: 240px;
        }

        &.vert-second-4 {
            height: 230px;
        }

        &.vert-second-5 {
            height: 230px;
        }
    }
}

@media screen and (min-width:481px) and (max-width:500px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 255px !important;
        }

        &.vert-qufree-1 {
            height: 230px !important;
        }

        &.vert-qufree-2 {
            height: 230px !important;
        }

        &.vert-qufree-3 {
            height: 320px !important;
        }

        &.vert-qufree-4 {
            height: 325px !important;
        }

        &.vert-qufree-5 {
            height: 320px !important;
        }

        &.vert-qufree-6 {
            height: 285px !important;
        }

        &.vert-qufree-7 {
            height: 270px !important;
        }

        &.vert-qufree-8 {
            height: 260px !important;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 205px;
        }

        &.vert-second-1 {
            height: 260px;
        }

        &.vert-second-2 {
            height: 240px;
        }

        &.vert-second-3 {
            height: 265px;
        }

        &.vert-second-4 {
            height: 265px;
        }

        &.vert-second-5 {
            height: 265px;
        }
    }
}

@media screen and (min-width:451px) and (max-width:480px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 205px !important;
        }

        &.vert-qufree-1 {
            height: 185px !important;
        }

        &.vert-qufree-2 {
            height: 185px !important;
        }

        &.vert-qufree-3 {
            height: 230px !important;
        }

        &.vert-qufree-4 {
            height: 230px !important;
        }

        &.vert-qufree-5 {
            height: 220px !important;
        }

        &.vert-qufree-6 {
            height: 230px !important;
        }

        &.vert-qufree-7 {
            height: 230px !important;
        }

        &.vert-qufree-8 {
            height: 210px !important;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 195px;
        }

        &.vert-second-1 {
            height: 215px;
        }

        &.vert-second-2 {
            height: 190px;
        }

        &.vert-second-3 {
            height: 215px;
        }

        &.vert-second-4 {
            height: 220px;
        }

        &.vert-second-5 {
            height: 220px;
        }
    }
}

@media screen and (min-width:391px) and (max-width:450px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 205px !important;
        }

        &.vert-qufree-1 {
            height: 205px !important;
        }

        &.vert-qufree-2 {
            height: 205px !important;
        }

        &.vert-qufree-3 {
            height: 230px !important;
        }

        &.vert-qufree-4 {
            height: 250px !important;
        }

        &.vert-qufree-5 {
            height: 255px !important;
        }

        &.vert-qufree-6 {
            height: 230px !important;
        }

        &.vert-qufree-7 {
            height: 230px !important;
        }

        &.vert-qufree-8 {
            height: 230px !important;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 185px;
        }

        &.vert-second-1 {
            height: 210px;
        }

        &.vert-second-2 {
            height: 205px;
        }

        &.vert-second-3 {
            height: 230px;
        }

        &.vert-second-4 {
            height: 245px;
        }

        &.vert-second-5 {
            height: 245px;
        }
    }
}

@media screen and (min-width:361px) and (max-width:390px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-1 {
            height: 205px !important;
        }

        &.vert-qufree-2 {
            height: 205px !important;
        }

        &.vert-qufree-3 {
            height: 280px !important;
        }

        &.vert-qufree-5 {
            height: 280px !important;
        }

        &.vert-qufree-6 {
            height: 255px !important;
        }

        &.vert-qufree-7 {
            height: 250px !important;
        }

        &.vert-qufree-8 {
            height: 250px !important;
        }
    }
}

@media screen and (min-width:367px) and (max-width:390px) {
    .vert-line-solutions-second {
        &.vert-second-3 {
            height: 255px;
        }

        &.vert-second-4 {
            height: 255px;
        }

        &.vert-second-5 {
            height: 255px;
        }
    }
}

@media screen and (min-width:351px) and (max-width:360px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-1 {
            height: 230px !important;
        }

        &.vert-qufree-2 {
            height: 255px !important;
        }

        &.vert-qufree-5 {
            height: 305px !important;
        }

        &.vert-qufree-6 {
            height: 265px !important;
        }

        &.vert-qufree-7 {
            height: 275px !important;
        }

        &.vert-qufree-8 {
            height: 250px !important;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 210px;
        }

        &.vert-second-1 {
            height: 250px;
        }

        &.vert-second-2 {
            height: 225px;
        }

        &.vert-second-3 {
            height: 255px;
        }

        &.vert-second-4 {
            height: 255px;
        }

        &.vert-second-5 {
            height: 255px;
        }
    }
}

@media screen and (min-width:312px) and (max-width:339px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 245px;
        }

        &.vert-qufree-1 {
            height: 225px;
        }

        &.vert-qufree-2 {
            height: 250px;
        }

        &.vert-qufree-3 {
            height: 300px;
        }

        &.vert-qufree-4 {
            height: 305px;
        }

        &.vert-qufree-5 {
            height: 300px;
        }

        &.vert-qufree-6 {
            height: 300px;
        }

        &.vert-qufree-7 {
            height: 260px;
        }

        &.vert-qufree-8 {
            height: 250px;
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 205px;
        }

        &.vert-second-1 {
            height: 275px;
        }

        &.vert-second-2 {
            height: 255px;
        }

        &.vert-second-3 {
            height: 250px;
        }

        &.vert-second-4 {
            height: 250px;
        }

        &.vert-second-5 {
            height: 250px;
        }
    }

}

@media screen and (min-width:826px) and (max-width:965px) {
    .vert-line-solutions-qufree {
        height: 223px !important;
    }

    .card-solutions-qufree {
        & .solution-card-heading-qufree {
            & p {
                width: 215px !important;
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .qufree-content-container {
        & .qufree-content-heading {
            & p {
                font-size: 1.2rem !important;
                line-height: 1.7rem !important;
            }
        }
    }

    .qufree-main-content {
        & h1 {
            font-size: 3.2rem !important;
            line-height: 3.7rem !important;
        }

        & .qufree-up-content {
            & p {
                font-size: 1.1rem;
            }
        }
    }

    .card-solution {
        height: 600px !important;
    }

    .qufree-role {
        & p {
            font-size: 1.1rem !important;
        }
    }

    .card-solutions-qufree {
        & .solution-card-heading-qufree {
            & p {
                font-size: 1.1rem !important;
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:889px) {
    .qufree-image-container {
        & .breadcrumb {
            top: 280px;
        }
    }
}

@media screen and (min-width:890px) and (max-width:980px) {
    .qufree-image-container {
        & .breadcrumb {
            top: 320px !important;
        }
    }
}

@media screen and (min-width: 981px) and (max-width:1024px) {
    .qufree-image-container {
        & .breadcrumb {
            top: 355px !important;
        }
    }
}

@media screen and (min-width:768px) and (max-width:1120px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 310px;
            padding-top: 0%;
            padding-left: 8%;
        }

        .qufree-heading-desc {
            & p {
                font-size: 1.1rem !important;
                line-height: 1.6rem !important;
                width: 382px;
            }
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 3rem;
                line-height: 3.5rem;
                width: 270px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    width: 280px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 350px;
                        left: 15px;
                        height: fit-content;
                        top: 90px;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                font-size: 2rem;
                line-height: 2.5rem;
                padding-top: 8%;
                text-align: center;
                padding-bottom: 6%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 5% 5%;
            @include flex-row;
            padding-left: 10%;
            justify-content: center;
            padding-top: 0 !important;

            & h3 {
                font-size: 2rem;
                line-height: 2.5rem;
                margin: 0 auto;
            }

            & p {
                font-size: 1.2rem;
                line-height: 1.7rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 5%;
                width: 100%;
                padding-top: 3%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 3% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 165px;
                    padding-right: 3%;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 385px;
            }

            &.vert-qufree-1 {
                height: 385px;
            }

            &.vert-qufree-2 {
                height: 385px;
            }

            &.vert-qufree-3 {
                height: 490px;
            }

            &.vert-qufree-4 {
                height: 490px;
            }

            &.vert-qufree-5 {
                height: 490px;
            }

            &.vert-qufree-6 {
                height: 435px;
            }

            &.vert-qufree-7 {
                height: 435px;
            }

            &.vert-qufree-8 {
                height: 435px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            height: 390px;
            padding: 1px;
        }

        .last-qufree-content {
            @include flex-row;

            & img {
                width: 380px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.2rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-bottom: 0 !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 140px;
        }

        & .logo {
            width: 70px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .qufree-techstack {
        & h4 {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:851px) and (max-width: 959px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 340px !important;
        }

        &.vert-qufree-1 {
            height: 340px !important;
        }

        &.vert-qufree-2 {
            height: 340px !important;
        }

        &.vert-qufree-3 {
            height: 435px !important;
        }

        &.vert-qufree-4 {
            height: 435px !important;
        }

        &.vert-qufree-5 {
            height: 435px !important;
        }

        &.vert-qufree-6 {
            height: 395px !important;
        }

        &.vert-qufree-7 {
            height: 395px !important;
        }

        &.vert-qufree-8 {
            height: 385px !important;
        }
    }
}

@media screen and (min-width:960px) and (max-width: 1024px) {
    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 310px !important;
        }

        &.vert-qufree-1 {
            height: 310px !important;
        }

        &.vert-qufree-2 {
            height: 310px !important;
        }

        &.vert-qufree-3 {
            height: 385px !important;
        }

        &.vert-qufree-4 {
            height: 385px !important;
        }

        &.vert-qufree-5 {
            height: 385px !important;
        }

        &.vert-qufree-6 {
            height: 365px !important;
        }

        &.vert-qufree-7 {
            height: 365px !important;
        }

        &.vert-qufree-8 {
            height: 365px !important;
        }
    }
}

@media screen and (min-width:1025px) and (max-width: 1120px) {
    .solution-card-heading-qufree {
        & p {
            width: 210px !important;
        }
    }

    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 340px !important;
        }

        &.vert-qufree-1 {
            height: 340px !important;
        }

        &.vert-qufree-2 {
            height: 340px !important;
        }

        &.vert-qufree-3 {
            height: 455px !important;
        }

        &.vert-qufree-4 {
            height: 455px !important;
        }

        &.vert-qufree-5 {
            height: 455px !important;
        }

        &.vert-qufree-6 {
            height: 430px !important;
        }

        &.vert-qufree-7 {
            height: 430px !important;
        }

        &.vert-qufree-8 {
            height: 430px !important;
        }
    }
}

@media screen and (min-width:981px) and (max-width:1120px) {
    .qufree-image-container {
        height: 390px !important;
        padding-top: 4% !important;
    }

    .qufree-heading-desc {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
            width: 382px;
        }
    }
}

@media screen and (min-width:890px) and (max-width:980px) {
    .qufree-image-container {
        height: 350px !important;
        padding-top: 2% !important;
    }

    .qufree-heading-desc {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
            width: 382px;
        }
    }
}

@media screen and (min-width:3000px) and (max-width:3500px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 800px;
            padding-top: 16%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 2.5rem;
                    line-height: 3rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 6.5rem;
                line-height: 7rem;
                width: 470px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 2.5rem;
                    line-height: 3.3rem;
                    width: 980px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 600px;
                        left: 140px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                font-size: 3.5rem;
                line-height: 4rem;
                padding-top: 5%;
                text-align: center;
                padding-bottom: 3%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 3% 5%;
            @include flex-row;
            padding-left: 4%;
            justify-content: center;
            padding-top: 1% !important;
            padding-bottom: 2% !important;

            & h3 {
                font-size: 3.5rem;
                line-height: 4rem;
                margin: 0 auto;
                padding-top: 0 !important;
            }

            & p {
                font-size: 2.5rem;
                line-height: 3rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 3%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 3% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 630px !important;
                    padding-right: 3%;
                }

                & h5 {
                    font-size: 2.5rem;
                    line-height: 3rem;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            height: 630px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 500px;
            }

            &.vert-qufree-1 {
                height: 500px;
            }

            &.vert-qufree-2 {
                height: 500px;
            }

            &.vert-qufree-6 {
                height: 565px;
            }

            &.vert-qufree-7 {
                height: 565px;
            }

            &.vert-qufree-8 {
                height: 565px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            padding: 1px;

            &.vert-second-0 {
                height: 505px;
            }

            &.vert-second-1 {
                height: 505px;
            }

            &.vert-second-2 {
                height: 505px;
            }

            &.vert-second-3 {
                height: 555px;
            }

            &.vert-second-4 {
                height: 555px;
            }

            &.vert-second-5 {
                height: 555px;
            }
        }

        .last-qufree-content {
            @include flex-row;
            padding-bottom: 1%;

            & img {
                width: 765px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 2.5rem;
                line-height: 3rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-bottom: 1% !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 485px;
        }

        & .logo {
            width: 160px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 1020px;
            padding-top: 15%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 2.2rem;
                    line-height: 2.7rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 5.5rem;
                line-height: 6rem;
                width: 386px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 2.2rem;
                    line-height: 2.7rem;
                    width: 980px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 600px;
                        left: 140px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                font-size: 3.2rem;
                line-height: 3.7rem;
                padding-top: 5%;
                text-align: center;
                padding-bottom: 3%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 3% 5%;
            @include flex-row;
            padding-left: 5%;
            justify-content: center;
            padding-top: 1% !important;
            padding-bottom: 2% !important;

            & h3 {
                font-size: 3.2rem;
                line-height: 3.7rem;
                margin: 0 auto;
                padding-top: 0 !important;
            }

            & p {
                font-size: 2.2rem;
                line-height: 2.7rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 3%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 3% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 580px !important;
                    padding-right: 3%;
                }

                & h5 {
                    font-size: 2.5rem;
                    line-height: 3rem;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            height: 540px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 440px;
            }

            &.vert-qufree-1 {
                height: 440px;
            }

            &.vert-qufree-2 {
                height: 440px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            padding: 1px;

            &.vert-second-0 {
                height: 470px;
            }

            &.vert-second-1 {
                height: 470px;
            }

            &.vert-second-2 {
                height: 470px;
            }

            &.vert-second-3 {
                height: 500px;
            }

            &.vert-second-4 {
                height: 500px;
            }

            &.vert-second-5 {
                height: 500px;
            }
        }

        .last-qufree-content {
            @include flex-row;
            padding-bottom: 1%;

            & img {
                width: 765px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 2.2rem;
                line-height: 2.7rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-bottom: 1% !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 450px;
        }

        & .logo {
            width: 150px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:2401px) and (max-width:2800px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 920px;
            padding-top: 15%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 5.5rem;
                line-height: 6rem;
                width: 386px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.9rem;
                    line-height: 2.7rem;
                    width: 840px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 600px;
                        left: 140px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                font-size: 3rem;
                line-height: 3.5rem;
                padding-top: 5%;
                text-align: center;
                padding-bottom: 3%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 3% 5%;
            @include flex-row;
            padding-left: 3%;
            justify-content: center;
            padding-top: 1% !important;

            & h3 {
                font-size: 3rem;
                line-height: 3.5rem;
                margin: 0 auto;
                padding-top: 0 !important;
            }

            & p {
                font-size: 1.9rem;
                line-height: 2.4rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 3%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 3% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 480px !important;
                    padding-right: 3%;
                }

                & h5 {
                    font-size: 2.2rem;
                    line-height: 2.7rem;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 395px;
            }

            &.vert-qufree-1 {
                height: 395px;
            }

            &.vert-qufree-2 {
                height: 395px;
            }

            &.vert-qufree-3 {
                height: 530px;
            }

            &.vert-qufree-4 {
                height: 530px;
            }

            &.vert-qufree-5 {
                height: 530px;
            }

            &.vert-qufree-6 {
                height: 455px;
            }

            &.vert-qufree-7 {
                height: 455px;
            }

            &.vert-qufree-8 {
                height: 455px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            padding: 1px;

            &.vert-second-0 {
                height: 405px;
            }

            &.vert-second-1 {
                height: 405px;
            }

            &.vert-second-2 {
                height: 405px;
            }

            &.vert-second-3 {
                height: 445px;
            }

            &.vert-second-4 {
                height: 445px;
            }

            &.vert-second-5 {
                height: 445px;
            }
        }

        .last-qufree-content {
            @include flex-row;
            padding-bottom: 1%;

            & img {
                width: 765px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.9rem;
                line-height: 2.4rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            padding-bottom: 2% !important;
            display: flex;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 380px;
        }

        & .logo {
            width: 120px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:2000px) and (max-width:2400px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 810px;
            padding-top: 14%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 5.5rem;
                line-height: 6rem;
                width: 386px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                    width: 650px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 575px;
                        left: 47px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                font-size: 3rem;
                line-height: 3.5rem;
                padding-top: 5%;
                text-align: center;
                padding-bottom: 3%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 3% 5%;
            @include flex-row;
            padding-left: 3%;
            justify-content: center;
            align-items: center;
            padding-top: 1% !important;
            padding-bottom: 2% !important;

            & h3 {
                font-size: 3rem;
                line-height: 3.5rem;
                margin: 0 auto;
                padding-top: 0 !important;
            }

            & p {
                font-size: 1.8rem;
                line-height: 2.3rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 3%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 3% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 420px !important;
                    padding-right: 3%;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            height: 485px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 415px;
            }

            &.vert-qufree-1 {
                height: 415px;
            }

            &.vert-qufree-2 {
                height: 415px;
            }

            &.vert-qufree-6 {
                height: 460px;
            }

            &.vert-qufree-7 {
                height: 460px;
            }

            &.vert-qufree-8 {
                height: 460px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            height: 415px;
            padding: 1px;
        }

        .last-qufree-content {
            @include flex-row;
            padding-bottom: 1%;

            & img {
                width: 630px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.8rem;
                line-height: 2.3rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-bottom: 1% !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 335px;
        }

        & .logo {
            width: 105px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:1801px) and (max-width:1999px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 720px;
            padding-top: 12%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 5rem;
                line-height: 5.5rem;
                width: 360px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    width: 570px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 495px;
                        left: 47px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                padding-top: 5%;
                text-align: center;
                padding-bottom: 3%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 5% 5%;
            @include flex-row;
            padding-left: 5%;
            justify-content: center;
            padding-top: 1% !important;
            padding-bottom: 2% !important;

            & h3 {
                margin: 0 auto;
                padding-top: 0 !important;
            }

            & p {
                font-size: 1.5rem;
                line-height: 2rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 3%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 4% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 380px !important;
                    padding-right: 3%;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            height: 440px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 330px;
            }

            &.vert-qufree-1 {
                height: 330px;
            }

            &.vert-qufree-2 {
                height: 330px;
            }

            &.vert-qufree-6 {
                height: 375px;
            }

            &.vert-qufree-7 {
                height: 375px;
            }

            &.vert-qufree-8 {
                height: 375px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            padding: 1px;

            &.vert-second-0 {
                height: 335px !important;
            }

            &.vert-second-1 {
                height: 335px !important;
            }

            &.vert-second-2 {
                height: 335px !important;
            }

            &.vert-second-3 {
                height: 375px !important;
            }

            &.vert-second-4 {
                height: 365px !important;
            }

            &.vert-second-5 {
                height: 365px !important;
            }
        }

        .last-qufree-content {
            @include flex-row;
            padding-bottom: 1%;

            & img {
                width: 630px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.5rem;
                line-height: 2rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-bottom: 0 !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 305px;
        }

        & .logo {
            width: 95px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:1501px) and (max-width:1800px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 595px;
            padding-top: 10%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 5rem;
                line-height: 5.5rem;
                width: 360px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    width: 490px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 520px;
                        left: 3px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                padding-top: 5%;
                text-align: center;
                padding-bottom: 5%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 5% 5%;
            @include flex-row;
            padding-left: 5%;
            justify-content: center;
            padding-top: 1% !important;
            padding-bottom: 0 !important;

            & h3 {
                margin: 0 auto;
                padding-top: 0 !important;
            }

            & p {
                font-size: 1.5rem;
                line-height: 2rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 5%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 3% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 315px !important;
                    padding-right: 3%;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            height: 510px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 400px;
            }

            &.vert-qufree-1 {
                height: 400px;
            }

            &.vert-qufree-2 {
                height: 400px;
            }

            &.vert-qufree-3 {
                height: 510px;
            }

            &.vert-qufree-4 {
                height: 510px;
            }

            &.vert-qufree-5 {
                height: 510px;
            }

            &.vert-qufree-6 {
                height: 470px;
            }

            &.vert-qufree-7 {
                height: 470px;
            }

            &.vert-qufree-8 {
                height: 470px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            padding: 1px;

            &.vert-second-0 {
                height: 430px !important;
            }

            &.vert-second-1 {
                height: 430px;
            }

            &.vert-second-2 {
                height: 430px;
            }

            &.vert-second-3 {
                height: 400px;
            }

            &.vert-second-4 {
                height: 400px;
            }

            &.vert-second-5 {
                height: 400px;
            }
        }

        .last-qufree-content {
            @include flex-row;

            & img {
                width: 480px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.5rem;
                line-height: 2rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-bottom: 0 !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 250px;
        }

        & .logo {
            width: 85px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:1301px) and (max-width:1500px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 500px;
            padding-top: 8%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 5rem;
                line-height: 5.5rem;
                width: 360px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    width: 420px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 455px;
                        left: 15px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                padding-top: 5%;
                text-align: center;
                padding-bottom: 6%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 0% 5%;
            @include flex-row;
            padding-left: 5%;
            justify-content: center;

            & h3 {
                margin: 0 auto;
                padding-top: 2%;
            }

            & p {
                font-size: 1.2rem;
                line-height: 1.7rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 6%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 3% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 275px !important;
                    padding-right: 3%;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            height: 375px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 285px;
            }

            &.vert-qufree-1 {
                height: 285px;
            }

            &.vert-qufree-2 {
                height: 285px;
            }

            &.vert-qufree-6 {
                height: 320px;
            }

            &.vert-qufree-7 {
                height: 320px;
            }

            &.vert-qufree-8 {
                height: 320px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            padding: 1px;

            &.vert-second-0 {
                height: 290px !important;
            }

            &.vert-second-1 {
                height: 290px;
            }

            &.vert-second-2 {
                height: 290px;
            }

            &.vert-second-3 {
                height: 320px;
            }

            &.vert-second-4 {
                height: 320px;
            }

            &.vert-second-5 {
                height: 320px;
            }
        }

        .last-qufree-content {
            @include flex-row;

            & img {
                width: 480px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.2rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 220px;
        }

        & .logo {
            width: 85px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:1121px) and (max-width:1300px) {
    .qufree-container {
        padding-left: 10%;

        & .qufree-image-container {
            height: 440px;
            padding-top: 5%;
            padding-left: 8%;
        }

        .qufree-content-container {
            .qufree-content-heading {
                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .qufree-main-content {
            display: flex;
            flex-direction: column;

            & h1 {
                font-size: 5rem;
                line-height: 5.5rem;
                width: 360px;
            }

            & .qufree-up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    width: 370px;

                }
            }

            & .qufree-mid-content {
                & .dashboard-qufree-img {
                    & img {
                        width: 400px;
                        left: 15px;
                        height: fit-content;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .qufree-center-content {
            & h2 {
                padding-top: 8%;
                text-align: center;
                padding-bottom: 6%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .qufree-role {
            padding: 5% 5%;
            @include flex-row;
            padding-left: 8%;
            justify-content: center;
            padding-top: 0 !important;

            & h3 {
                margin: 0 auto;
            }

            & p {
                font-size: 1.2rem;
                line-height: 1.7rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 5%;
                width: 100%;
                padding-top: 1%;
            }

        }

        .card-solutions-qufree {
            flex: 0 0 30%;
            padding-right: 0;
            padding-bottom: 2% !important;

            & .solution-card-heading-qufree {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 235px !important;
                    padding-right: 3%;
                }
            }
        }

        .vert-line-solutions-qufree {
            width: 2px;
            height: 435px;
            padding: 1px;

            &.vert-qufree-0 {
                height: 315px;
            }

            &.vert-qufree-1 {
                height: 315px;
            }

            &.vert-qufree-2 {
                height: 315px;
            }

            &.vert-qufree-3 {
                height: 425px;
            }

            &.vert-qufree-4 {
                height: 425px;
            }

            &.vert-qufree-5 {
                height: 425px;
            }

            &.vert-qufree-6 {
                height: 395px;
            }

            &.vert-qufree-7 {
                height: 395px;
            }

            &.vert-qufree-8 {
                height: 395px;
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            height: 345px;
            padding: 1px;

            &.vert-second-0 {
                height: 315px;
            }

            &.vert-second-1 {
                height: 315px;
            }

            &.vert-second-2 {
                height: 315px;
            }

        }

        .last-qufree-content {
            @include flex-row;

            & img {
                width: 480px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.2rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-qufree {
            align-items: flex-start;
            justify-content: space-between;
            padding-left: 0;
            display: flex;
            padding-bottom: 2% !important;

            & .card-solutions-qufree {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .app {
            width: 205px;
        }

        & .logo {
            width: 80px;
        }
    }

    .main-container-qufree {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (min-width:1121px) and (max-width:1500px) {
    .qufree-techstack {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:1501px) {
    .qufree-content-container {
        & .qufree-content-heading {
            & p {
                padding-bottom: 3%;
            }
        }
    }

    .vert-line-solutions-qufree {
        width: 2.5px;
        height: 350px;
        padding: 1px;
        margin-left: 1px;
    }

    .vert-line-solutions-second {
        width: 2.5px;
        padding: 1px;
        margin-left: 1px;
    }

    .last-qufree-content {
        & p {
            font-size: 1.5rem;
        }
    }

    .card-solutions-qufree {
        & h5 {
            padding-bottom: 5%;
        }

        & .solution-card-heading-qufree {
            padding-left: 3%;
        }
    }

    .qufree-role {
        padding: 0;

        & h3 {
            padding-top: 7%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }

    .qufree-techstack {
        & p {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

@media screen and (min-width:1800px) {
    .card-solutions-qufree {
        & h5 {
            padding-bottom: 5%;
        }

        & .solution-card-heading-qufree {
            padding-left: 3%;
        }
    }

    .vert-line-solutions-qufree {
        width: 2px;
        height: 350px;
        padding: 1px;
        margin-left: 1px;
    }

    .vert-line-solutions-second {
        width: 2px;
        padding: 1px;
        margin-left: 1px;
    }

    .qufree-role {
        & h3 {
            padding-top: 5%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }
}

@media screen and (min-width:2000px) {
    .card-solution {
        & h5 {
            font-size: 2rem;
        }
    }

    .card-solutions-qufree {
        & p {
            font-size: 1.8rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 30px;
            padding-bottom: 2%;
        }
    }

    .qufree-main-content {
        & .qufree-mid-content {
            & .dashboard-qufree-img {
                & img {
                    right: -34px;
                }
            }
        }
    }

    .qufree-content-container {
        & .qufree-upper-section-heading {
            & img {
                width: 330px;
            }
        }

        & .qufree-content-heading {
            & p {
                font-size: 1.8rem;
            }
        }
    }

    .qufree-main-content {

        & .qufree-up-content {
            & p {
                font-size: 1.8rem;
            }
        }
    }

    .last-qufree-content {
        & p {
            font-size: 30px;
        }
    }

    .qufree-role {
        & p {
            font-size: 30px;
        }
    }

}

@media screen and (max-width:350px) {
    .card-solutions-qufree {
        & h5 {
            padding-bottom: 2%;
        }
    }
}

@media screen and (min-width:671px) and (max-width:767px) {

    .vert-line-solutions-qufree {
        height: 235px;

        &.vert-qufree-5 {
            height: 240px !important;
        }

        &.vert-qufree-6 {
            height: 220px !important;
        }

        &.vert-qufree-7 {
            height: 220px !important;
        }

        &.vert-qufree-8 {
            height: 220px !important;
        }
    }

    .vert-line-solutions-second {
        height: 205px;
    }

    .main-container-solutions-qufree {
        row-gap: 20px;
    }
}

@media screen and (min-width:571px) and (max-width:670px) {

    .vert-line-solutions-qufree {
        height: 255px;

        &.vert-qufree-5 {
            height: 240px !important;
        }

        &.vert-qufree-6 {
            height: 240px !important;
        }

        &.vert-qufree-7 {
            height: 240px !important;
        }

        &.vert-qufree-8 {
            height: 240px !important;
        }
    }

    .vert-line-solutions-second {
        height: 235px;
    }

    .main-container-solutions-qufree {
        row-gap: 20px;
    }
}

@media screen and (min-width:531px) and (max-width:570px) {

    .vert-line-solutions-qufree {
        height: 255px;

        &.vert-qufree-7 {
            height: 225px !important;
        }

        &.vert-qufree-8 {
            height: 225px !important;
        }
    }

    .vert-line-solutions-second {
        height: 220px;
    }

    .main-container-solutions-qufree {
        row-gap: 15px;
    }
}

@media screen and (min-width:501px) and (max-width:530px) {

    .vert-line-solutions-qufree {
        height: 240px;
    }

    .vert-line-solutions-second {
        height: 220px;
    }

    .main-container-solutions-qufree {
        row-gap: 15px;
    }
}

@media screen and (min-width:481px) and (max-width:500px) {
    .vert-line-solutions-second {
        height: 260px;
    }

    .main-container-solutions-qufree {
        row-gap: 15px;
    }
}

@media screen and (min-width:421px) and (max-width:480px) {

    .vert-line-solutions-qufree {
        height: 230px;
    }

    .vert-line-solutions-second {
        height: 235px;
    }

    .main-container-solutions-qufree {
        row-gap: 15px;
    }
}

@media screen and (min-width:391px) and (max-width:420px) {
    .vert-line-solutions-second {
        height: 236px;
    }

    .main-container-solutions-qufree {
        row-gap: 15px;
    }
}

@media screen and (min-width:351px) and (max-width:390px) {

    .vert-line-solutions-qufree {
        height: 300px;
    }

    .vert-line-solutions-second {
        height: 260px;
    }

    .main-container-solutions-qufree {
        row-gap: 15px;
    }
}

@media screen and (min-width:351px) and (max-width:450px) {
    .card-solutions-qufree {
        & h5 {
            padding-bottom: 3%;
        }

        & .solution-card-heading-qufree {
            & p {
                width: 285px;
                padding-right: 7vw;
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 3%;
        }
    }

    .main-container-solutions-qufree {
        row-gap: 15px;
    }
}

@media screen and (min-width:451px) and (max-width:500px) {
    .card-solutions-qufree {
        & h5 {
            padding-bottom: 4%;
        }

        & .solution-card-heading-qufree {
            & p {
                width: 326px;
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }

    .main-container-solutions-qufree {
        padding-left: 10%;
        row-gap: 15px;
    }

    .dashboard-qufree-mob img {
        width: 380px !important;
        padding-left: 3%;
    }
}

@media screen and (min-width:551px) and (max-width:670px) {
    .card-solutions-qufree {
        & h5 {
            padding-bottom: 3%;
        }

        & .solution-card-heading-qufree {
            & p {
                width: 415px;
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 5%;
        }
    }

    .dashboard-qufree-mob img {
        width: 450px !important;
        height: 370px !important;
        padding-left: 7%;
    }

    .last-qufree-content img {
        width: 395px;
        padding-left: 0;
    }
}

@media screen and (min-width:740px) and (max-width:767px) {
    .solution-card-heading-qufree {
        & p {
            width: 540px !important;
        }
    }
}

@media screen and (min-width:671px) and (max-width:767px) {
    .card-solutions-qufree {
        & h5 {
            padding-bottom: 4%;
        }

        & .solution-card-heading-qufree {
            & p {
                width: 490px;
            }
        }
    }

    .dashboard-qufree-mob img {
        width: 520px !important;
        height: 400px !important;
        padding-left: 3%;
    }

    .card-solution {
        & h5 {
            padding-bottom: 8%;
        }
    }
}

@media screen and (min-width:2200px) and (max-width:2499px) {
    .qufree-image-container {
        height: 700px;
        padding-bottom: 30%;
    }

    .card-solution {
        & h5 {
            font-size: 1.85rem;
        }
    }

    .card-solutions-qufree {
        & p {
            font-size: 1.85rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 30px;
            padding-bottom: 3%;
        }
    }

    .last-qufree-content {
        & p {
            font-size: 33px;
        }
    }

    .qufree-content-container {
        & .qufree-upper-section-heading {
            & img {
                width: 425px;
            }
        }
    }

    .qufree-role {
        & p {
            font-size: 1.85rem;
        }
    }
}

@media screen and (min-width:2500px) and (max-width:2999px) {
    .card-solution {
        & h5 {
            font-size: 2.2rem;
        }
    }

    .qufree-heading-desc {
        & p {
            font-size: 2.2rem !important;
            line-height: 2.7rem !important;
        }
    }

    .card-solutions-qufree {
        & p {
            font-size: 1.85rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 2.2rem;
        }
    }

    .last-qufree-content {
        & p {
            font-size: 2.2rem;
        }
    }

    .qufree-content-container {
        & .qufree-upper-section-heading {
            & img {
                width: 425px;
            }
        }

        & .qufree-content-heading {
            & p {
                font-size: 2rem;
            }
        }
    }

    .qufree-role {
        & p {
            font-size: 2rem;
        }

        & h3 {
            font-size: 3.2rem !important;
        }
    }

    .qufree-main-content {
        & h1 {
            font-size: 6.5rem;
            line-height: 7rem;
        }

        & .qufree-up-content {
            & p {
                font-size: 2rem;
            }
        }
    }

    .qufree-center-content {
        & h2 {
            font-size: 3.2rem !important;
            line-height: 3.7rem !important;
        }
    }

    .qufree-techstack {
        & h4 {
            font-size: 3.2rem;
            line-height: 3.7rem;
        }
    }

}

@media screen and (min-width:3000px) {
    .card-solution {
        & h5 {
            font-size: 2.8rem;
            padding-bottom: 5%;
        }
    }

    .qufree-heading-desc {
        & p {
            font-size: 2.5rem !important;
            line-height: 3rem !important;
        }
    }

    .card-solutions-qufree {
        & p {
            font-size: 2.3rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 2.5rem;
        }
    }

    .qufree-content-container {
        & .qufree-upper-section-heading {
            & img {
                width: 450px;
            }
        }

        & .qufree-content-heading {
            & p {
                font-size: 2.5rem;
            }
        }
    }

    .qufree-main-content {
        & h1 {
            font-size: 8.5rem;
            line-height: 9rem;
        }

        & .qufree-up-content {
            & p {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }
    }

    .last-qufree-content {
        & p {
            font-size: 2.8rem;
            line-height: 3.4rem;
        }
    }

    .qufree-role {
        & p {
            font-size: 2.5rem;
        }

        & h3 {
            font-size: 3.5rem;
            line-height: 4rem;
            padding-top: 7%;
            padding-bottom: 3%;
        }
    }

    .qufree-image-container {
        height: 1180px !important;
    }

    .qufree-techstack {
        & h4 {
            font-size: 3.5rem;
            line-height: 4rem;
        }

        & p {
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }
}

@media screen and (min-width: 1921px) and (max-width:2500px) {
    .qufree-heading-desc {
        & p {
            font-size: 1.8rem !important;
            line-height: 2.4rem !important;
        }
    }
}

@media screen and (min-width:1501px) and (max-width:1999px) {
    .qufree-content-container {
        & .qufree-content-heading {
            & p {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }

    .qufree-main-content {
        & p {
            font-size: 1.5rem;
        }

        & .qufree-up-content {
            & p {
                font-size: 1.5rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.5rem;
        }
    }

    .card-solution-qufree {
        & h5 {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }
}

@media screen and (min-width:960px) and (max-width:1024px) {
    .qufree-container {
        & .card-solutions-qufree {
            & .solution-card-heading-qufree {
                & p {
                    width: 200px !important;
                }
            }
        }

        .vert-line-solutions-second {
            width: 2px;
            padding: 1px;

            &.vert-second-0 {
                height: 340px !important;
            }

            &.vert-second-1 {
                height: 340px;
            }

            &.vert-second-2 {
                height: 340px;
            }

            &.vert-second-3 {
                height: 340px;
            }

            &.vert-second-4 {
                height: 340px;
            }

            &.vert-second-5 {
                height: 340px;
            }
        }
    }
}

@media screen and (min-width:893px) and (max-width:959px) {
    .qufree-container {
        & .card-solutions-qufree {
            & .solution-card-heading-qufree {
                & p {
                    width: 190px !important;
                }
            }
        }
    }

    .vert-line-solutions-second {
        height: 370px !important;
    }
}

@media screen and (min-width:807px) and (max-width:850px) {
    .qufree-container {
        & .card-solutions-qufree {
            & .solution-card-heading-qufree {
                & p {
                    width: 170px !important;
                }
            }
        }
    }

    .vert-line-solutions-second {
        &.vert-second-0 {
            height: 370px !important;
        }

        &.vert-second-1 {
            height: 370px;
        }

        &.vert-second-2 {
            height: 370px;
        }

        &.vert-second-3 {
            height: 390px;
        }

        &.vert-second-4 {
            height: 390px;
        }

        &.vert-second-5 {
            height: 390px;
        }
    }

    .vert-line-solutions-qufree {
        &.vert-qufree-0 {
            height: 365px !important;
        }

        &.vert-qufree-1 {
            height: 365px !important;
        }

        &.vert-qufree-2 {
            height: 365px !important;
        }

        &.vert-qufree-3 {
            height: 460px !important;
        }

        &.vert-qufree-4 {
            height: 460px !important;
        }

        &.vert-qufree-5 {
            height: 460px !important;
        }

        &.vert-qufree-6 {
            height: 420px !important;
        }

        &.vert-qufree-7 {
            height: 420px !important;
        }

        &.vert-qufree-8 {
            height: 420px !important;
        }
    }
}

@media screen and (min-width:851px) and (max-width:892px) {
    .qufree-container {
        & .card-solutions-qufree {
            & .solution-card-heading-qufree {
                & p {
                    width: 180px !important;
                }
            }
        }
    }

    .vert-line-solutions-second {
        height: 365px !important;
    }

    .vert-line-solutions-qufree {
        height: 440px !important;
    }
}

@media screen and (min-width:2000px) and (max-width:2400px) {
    .qufree-content-container {
        & .qufree-content-heading {
            & p {
                font-size: 1.8rem;
            }
        }
    }

    .qufree-main-content {
        & p {
            font-size: 1.8rem;
        }

        & .qufree-up-content {
            & p {
                font-size: 1.8rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.9rem;
        }
    }

    .qufree-techstack {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }
}

@media screen and (min-width:2401px) and (max-width:2800px) {
    .qufree-content-container {
        & .qufree-content-heading {
            & p {
                font-size: 1.9rem;
            }
        }
    }

    .qufree-main-content {
        & p {
            font-size: 1.9rem;
        }

        & .qufree-up-content {
            & p {
                font-size: 1.9rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2rem;
        }
    }

    .qufree-techstack {
        & p {
            font-size: 1.9rem;
            line-height: 2.4rem;
        }
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .qufree-content-container {
        & .qufree-content-heading {
            & p {
                font-size: 2.2rem;
            }
        }
    }

    .qufree-main-content {
        & p {
            font-size: 2.2rem;
        }

        & .qufree-up-content {
            & p {
                font-size: 2.2rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2.3rem;
        }
    }

    .qufree-techstack {
        & p {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }
    }
}

@media screen and (min-width:3200px) {
    .qufree-heading-desc {
        & p {
            font-size: 2.5rem !important;
            line-height: 3rem !important;
        }
    }
}

@media screen and (min-width:3000px) {
    .qufree-content-container {
        & .qufree-content-heading {
            & p {
                font-size: 2.5rem !important;
            }
        }
    }

    .qufree-main-content {
        & p {
            font-size: 2.5rem !important;
        }

        & .qufree-up-content {
            & p {
                font-size: 2.5rem !important;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2.6rem !important;
        }
    }
}

@media screen and (max-width:767px) {
    .main-container-qufree {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .qufree-image-container {
        height: 315px !important;
    }
}

@media screen and (min-width:481px) and (max-width:767px) {
    .qufree-content-heading {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
        }
    }

    .qufree-up-content {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
        }
    }

    .solution-card-heading-qufree {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
        }

        & h5 {
            font-size: 1.5rem !important;
            line-height: 2rem !important;
        }
    }

    .qufree-role {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
        }
    }

    .qufree-point {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
        }

        & h2 {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
            text-align: center;
            font-weight: 700;
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .logo {
            width: 80px;
        }
    }

    .qufree-techstack {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width: 521px) and (max-width: 550px) {
    .solution-card-heading-qufree p {
        width: 380px !important;
    }

    .main-container-solutions-qufree {
        padding-left: 7%;
    }

    .last-qufree-content {
        & img {
            width: 365px;
            padding-left: 0;
        }
    }

    .dashboard-qufree-mob img {
        width: 460px !important;
        height: 370px !important;
        padding-left: 3%;
    }
}

@media screen and (min-width: 501px) and (max-width: 520px) {
    .solution-card-heading-qufree p {
        width: 360px !important;
    }

    .main-container-solutions-qufree {
        padding-left: 8%;
    }

    .last-qufree-content {
        & img {
            width: 345px;
            padding-left: 0;
        }
    }

    .dashboard-qufree-mob img {
        width: 440px !important;
        padding-left: 3%;
    }
}

@media screen and (min-width:420px) and (max-width:767px) {
    .qufree-upper-section-heading {
        & img {
            width: 240px !important;
        }
    }
}

@media screen and (min-width:312px) and (max-width:419px) {
    .qufree-upper-section-heading {
        & img {
            width: 200px !important;
        }
    }

    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 6vh 1vw 11vh 6vw !important;
    }
}

@media screen and (min-width:312px) and (max-width:480px) {
    .qufree-content-heading {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .qufree-up-content {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .main-container-solutions-qufree {
        row-gap: 20px;
    }

    .solution-card-heading-qufree {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        & h5 {
            font-size: 1.3rem;
            line-height: 1.8rem;
        }
    }

    .qufree-role {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .qufree-point {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        & h2 {
            font-size: 1rem;
            line-height: 1.5rem;
            text-align: center;
            font-weight: 700;
        }
    }

    .card-qufree {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 8%;

        & .logo {
            width: 80px;
        }
    }

    .qufree-techstack {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

@media screen and (min-width:651px) and (max-width:700px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 285px !important;
        padding-left: 15px !important;
    }
}

@media screen and (min-width:601px) and (max-width:650px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 265px !important;
        padding-left: 15px !important;
    }
}

@media screen and (min-width:551px) and (max-width:600px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 245px !important;
        padding-left: 15px !important;
    }
}

@media screen and (min-width:501px) and (max-width:550px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 225px !important;
        padding-left: 15px !important;
    }
}

@media screen and (min-width:451px) and (max-width:500px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 205px !important;
        padding-left: 15px !important;
    }
}

@media screen and (min-width:401px) and (max-width:450px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 185px !important;
        padding-left: 15px !important;
    }
}

@media screen and (min-width:371px) and (max-width:400px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 170px !important;
        padding-left: 15px !important;
    }
}

@media screen and (min-width:312px) and (max-width:370px) {
    .qufree-image-container {
        background-size: contain !important;
        margin-top: 4px;
        padding: 0 !important;
        height: 153px !important;
        padding-left: 10px !important;
    }
}

@media screen and (min-width: 421px) and (max-width: 450px) {
    .solution-card-heading-qufree p {
        width: 340px !important;
    }

    .main-container-solutions-qufree {
        padding-left: 7%;
    }

    .last-qufree-content {
        & img {
            width: 325px;
            padding-left: 0;
        }
    }

    .dashboard-qufree-mob img {
        width: 380px !important;
        padding-left: 3%;
    }
}

@media screen and (min-width: 391px) and (max-width: 420px) {
    .solution-card-heading-qufree p {
        width: 310px !important;
    }

    .main-container-solutions-qufree {
        padding-left: 8%;
    }

    .last-qufree-content {
        & img {
            width: 325px;
            padding-left: 0;
        }
    }
}

@media screen and (min-width: 361px) and (max-width: 390px) {
    .solution-card-heading-qufree p {
        width: 280px !important;
    }

    .main-container-solutions-qufree {
        padding-left: 8%;
    }

    .last-qufree-content {
        & img {
            width: 305px;
            padding-left: 0;
        }
    }
}

@media screen and (min-width: 340px) and (max-width: 360px) {
    .solution-card-heading-qufree p {
        width: 260px !important;
    }

    .main-container-solutions-qufree {
        padding-left: 7%;
    }

    .last-qufree-content {
        & img {
            width: 280px;
            padding-left: 0%;
        }
    }
}

@media screen and (min-width: 312px) and (max-width: 339px) {
    .solution-card-heading-qufree p {
        width: 230px !important;
    }
}

@media screen and (min-width:312px) and (max-width:767px) {
    .dashboard-qufree-mob {
        & img {
            width: 300px;
            padding-top: 3%;
            height: 350px;
            padding-right: 55px;
            padding-bottom: 10px;
        }
    }

    .qufree-role {
        & h3 {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width:768px) {
    .qufree-content-container {
        padding: 3vh 9vw;
    }

    .qufree-role {
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width:768px) {
    .qufree-para-content {
        padding-bottom: 3% !important;
    }
}