@import "../../shared/assets/css/variables";
@import "../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";

.main-container-solutions {
    display: flex;
    @include height-fit-content;
    padding: 5% 17%;
    @include font;
}

.solution-points {
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease-in-out;
}

.card-sol {
    &:hover {
        transform: scale(1.03);
        box-shadow: 5px 2px 10px 5px $grey;
    }
}

.explore-now {
    text-decoration: none !important;
    display: inline-block !important;

    & img {
        width: 20px;
    }

    & a {
        text-decoration: none;
    }
}

.explore-text {
    display: inline !important;
    margin-right: 6px;
    font-weight: 600 !important;
    text-decoration: none;
    cursor: grab !important;
}

.solutions-container {
    @include flex-column;
    @include font;
    z-index: -1;
    padding-left: 10% !important;
    overflow: hidden;

    & .solutions-image-container {
        background-image: url("../../shared/assets/images/solutions.jpg");

    }

    & .solutions-image-container {
        height: 550px;

        & .red-bg-box {
            left: 15%;
        }
    }
}

.solutions-content-container {
    padding: 3vh 9vw;

    & .sol-upper-section-heading {
        @include flex-row;
        padding-top: 2%;
        text-align: center;
        justify-content: center;
        align-items: center;

        & h2 {
            font-size: 3.2rem;
            font-weight: 500;
            color: $primary;
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        & img {
            width: 300px;

        }
    }
}

.solutions-content-container {
    & .sol-content-heading {
        padding-top: 1vw;

        & p {
            text-align: center;
            padding-top: 1%;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
}

.solutions-main-content {
    @include flex-row;
    justify-content: space-between;
    width: 100%;

    & h1 {
        @include text-stroke;
        -webkit-text-stroke: 1px $black !important;
        border: 1px !important;
        font-size: 6rem;
        line-height: 6.1rem;
        padding-bottom: 6%;
    }

}

.explore-more {
    margin-left: 5px;
    font-size: 1.2rem;
}

.main-container-solutions {
    @include flex-row;
    column-gap: 60px;
    justify-content: center;
    align-items: center;

    & img {
        align-items: center;
        justify-content: center;
    }
}

.card-sol {
    display: flex;
    flex-direction: column;
    flex: 0 0 33%;
    text-align: left;
    border-left: $light-blue 15px solid;
    justify-content: space-around;
    padding: 2em;
    box-shadow: 5px 2px 10px 5px $grey;
    row-gap: 25px;
    border-radius: 5px;


    & p {
        font-size: 1.4rem;
        font-weight: 500;
        align-items: flex-start;
        cursor: default;
    }
}

.color-text {
    color: $light-blue;
}

.cxo-point-container {
    & p {
        text-align: left;
        padding-left: 0%;
        margin: 0;
        font-size: 20px;
    }

}

@media screen and (max-width:767px) {
    .main-container-solution-cxo {
        @include flex-column;
    }

    .solutions-container {
        padding-left: 0 !important;

        & .solutions-image-container {
            height: 500px;
            padding-top: 13%;
        }
    }

    .solutions-content-container {
        .sol-content-heading {
            & p {
                font-weight: 500;
                padding-top: 3%;
            }
        }
    }

    .solutions-content-container {
        .sol-upper-section-heading {
            & img {
                width: 100%;
            }
        }
    }

    .solutions-main-content {
        column-gap: 2vw;
        margin: 0 auto;
        @include flex-row;

        & h1 {
            font-size: 2.7rem;
            line-height: 3.1rem;
            order: 0;

        }
    }

    .card-sol {
        @include flex-column;
        margin-bottom: 8%;
        padding: 5% 3%;
        background-color: $white;
        width: auto;
        height: fit-content;
        align-items: flex-start;
        justify-content: flex-start;

        .logo {
            display: flex;
            padding-left: 15%;
            width: 164px;
        }

        & p {
            font-size: 1.5rem;
        }
    }

    .main-container-solutions {
        align-items: center;
        padding-left: 0%;
        display: flex !important;
        flex-direction: column !important;

    }
}

@media screen and (max-width:350px) {
    .card-sol {
        & h5 {
            padding-bottom: 1%;
        }

        & p {
            font-size: 1rem;
        }
    }
}

@media screen and (min-width:3000px) and (max-width:3500px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 30px !important;
            margin-bottom: 3%;
            margin-left: 2%;
        }

        & p {
            width: 575px !important;
            padding-left: 8px;
            height: 375px;
        }

        .logo {
            padding-left: 20%;
            width: 433px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 4%;
            padding-top: 4%;

        }
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 25px !important;
            margin-bottom: 3%;
            margin-left: 2%;
        }

        & p {
            width: 515px !important;
            padding-left: 8px;
            height: 345px;
        }

        .logo {
            padding-left: 15%;
            width: 411px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 4%;
            padding-top: 4%;
        }
    }
}

@media screen and (min-width:2600px) and (max-width:2800px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 24px !important;
            margin-bottom: 3%;
            margin-left: 2%;
        }

        & p {
            width: 440px !important;
            padding-left: 8px;
            height: 280px;
        }

        .logo {
            padding-left: 14%;
            width: 355px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 5%;
        }
    }
}

@media screen and (min-width:2300px) and (max-width:2599px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 22px !important;
            margin-bottom: 3%;
            margin-left: 2%;
        }

        & p {
            width: 440px !important;
            padding-left: 8px;
            height: 280px;
        }

        .logo {
            padding-left: 21%;
            width: 354px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 5%;
        }
    }
}

@media screen and (min-width:2000px) and (max-width:2299px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 22px !important;
            margin-bottom: 3%;
            margin-left: 2%;
        }

        & p {
            width: 370px !important;
            padding-left: 8px;
            height: 320px;
        }

        .logo {
            padding-left: 16%;
            width: 250px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 5%;
        }
    }
}

@media screen and (min-width:1800px) and (max-width:1999px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 20px !important;
            margin-bottom: 2%;
            margin-left: 2%;
        }

        & p {
            width: 330px !important;
            padding-left: 8px;
            height: 225px;
        }

        .logo {
            padding-left: 16%;
            width: 250px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 5%;
        }
    }
}

@media screen and (min-width:1600px) and (max-width:1799px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 20px !important;
            margin-bottom: 2%;
            margin-left: 2%;
        }

        & p {
            width: 305px !important;
            padding-left: 8px;
            height: 270px;
        }

        .logo {
            padding-left: 16%;
            width: 250px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 5%;
        }
    }
}

@media screen and (min-width:1400px) and (max-width:1599px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 20px !important;
            margin-bottom: 2%;
            margin-left: 2%;
        }

        & p {
            width: 280px !important;
            padding-left: 8px;
            height: 280px;
        }

        .logo {
            padding-left: 19%;
            width: 224px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 18%;
            padding-bottom: 5%;
        }
    }
}

@media screen and (min-width:1300px) and (max-width:1399px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 20px !important;
            margin-bottom: 2%;
        }

        & p {
            width: 224px !important;
            padding-left: 8px;
            height: 390px;
        }

        .logo {
            padding-left: 13%;
            width: 190px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 20%;
        }
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) {
    .card-sol {
        row-gap: 30px !important;
        padding: 3% 3%;

        & img {
            width: 20px !important;
            margin-bottom: 2%;
        }

        & p {
            width: 232px !important;
            padding-left: 8px;
            height: 400px;
        }

        .logo {
            padding-left: 16%;
            width: 190px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 20%;
        }
    }
}

@media screen and (min-width:1121px) and (max-width:1199px) {
    .card-sol {
        margin-bottom: 15%;
        row-gap: 30px !important;

        & img {
            width: 20px !important;
            margin-bottom: 1%;
        }

        & p {
            width: 672px !important;
            padding-left: 8px;
        }

        .logo {
            padding-left: 37%;
            width: 441px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 20%;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
        }
    }

    .sol-content-heading {
        padding-bottom: 1%;
    }

    .solutions-content-container {
        padding: 0vh 9vw;
    }
}

@media screen and (min-width:768px) and (max-width:1120px) {
    .solutions-container {
        padding-left: 0;

        & .solutions-image-container {
            height: 500px;
            padding-top: 8%;
        }

        .solutions-content-container {
            .sol-content-heading {
                & p {
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding-top: 2%;
                    padding-bottom: 2%;
                }
            }
        }

        .solutions-content-container {
            .sol-upper-section-heading {
                & h2 {
                    font-size: 3.2rem;
                }

                & img {
                    width: 100%;
                }
            }
        }

        .solutions-main-content {
            display: flex;

            & h1 {
                font-size: 3rem;
                line-height: 3.5rem;
                width: 270px;
            }
        }

        .card-sol {
            @include flex-column;
            margin-bottom: 12%;
            padding: 3% 3%;
            background-color: $white;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;

            & p {
                font-size: 1.2rem;
                width: 600px;
                padding-left: 8px;
            }

            & h5 {
                padding-bottom: 8%;
            }

            & .logo {
                padding-left: 30%;
                width: 385px !important;
            }

            & img {
                width: 20px;
                margin-bottom: 2%;
            }
        }

        .main-container-solutions {
            align-items: center;
            padding-left: 0;
            display: flex !important;
            flex-direction: column !important;
            padding-left: 20%;
            padding-bottom: 0 !important;
        }

    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .solutions-container {
        & .solutions-content-container {
            & .sol-content-heading {
                & p {
                    font-size: 1.2rem !important;
                }
            }
        }

        & .solutions-main-content {
            & h1 {
                font-size: 3.2rem !important;
                line-height: 3.7rem !important;
            }
        }

        & .card-sol {
            height: 600px !important;

            & p {
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (min-width:1121px) and (max-width:1500px) {
    .solutions-content-container {
        .sol-content-heading {
            & p {
                padding-bottom: 0%;
                font-size: 1.5rem;
            }
        }
    }

    .solutions-main-content {
        & h1 {
            font-size: 5rem;
            line-height: 5.1rem;
            padding-bottom: 2%;
        }

        & p {
            font-size: 22px;
            padding-right: 1%;
        }
    }

    .card-sol {
        & p {
            font-size: 1.5rem;
        }

        & h5 {
            padding-bottom: 7%;
        }
    }
}

@media screen and (min-width:1501px) {
    .solutions-content-container {
        & .sol-content-heading {
            & p {
                padding-bottom: 0%;
                font-size: 1.5rem;
            }
        }
    }

    .card-sol {
        & h5 {
            padding-bottom: 4%;
        }

        & p {
            font-size: 1.5rem;
        }
    }
}

@media screen and (min-width:1800px) {
    .card-sol {
        & h5 {
            padding-bottom: 4%;
        }

        & p {
            font-size: 1.5rem;
        }
    }
}

@media screen and (min-width:2000px) {
    .card-sol {
        & h5 {
            font-size: 2rem;
        }

        & p {
            font-size: 1.8rem;
        }
    }

    .solutions-content-container {
        & .sol-upper-section-heading {
            & h2 {
                font-size: 3.8rem;
            }

            & img {
                width: 330px;
            }
        }

        & .sol-content-heading {
            & p {
                font-size: 1.8rem;
            }
        }
    }
}

@media screen and (max-width:350px) {
    .solutions-container {
        & .solutions-image-container {
            padding-top: 33%;
        }
    }
}

@media screen and (min-width:351px) and (max-width:450px) {
    .solutions-container {
        & .solutions-image-container {
            padding-top: 33%;
        }
    }

    .card-sol {
        & h5 {
            padding-bottom: 3%;
        }

        & p {
            font-size: 1rem;
        }
    }
}

@media screen and (min-width:451px) and (max-width:550px) {
    .solutions-container {
        & .solutions-image-container {
            padding-top: 21%;
        }
    }

    .card-sol {
        & h5 {
            padding-bottom: 4%;
        }

        & p {
            font-size: 1.2rem;
        }
    }
}

@media screen and (min-width:551px) and (max-width:670px) {
    .solutions-container {
        & .solutions-image-container {
            padding-top: 21%;
        }
    }

    .card-sol {
        & h5 {
            padding-bottom: 5%;
        }

        & p {
            font-size: 1.2rem;
        }
    }
}

@media screen and (min-width:671px) and (max-width:767px) {
    .card-sol {
        & img {
            width: 18px;
            margin-bottom: 2%;
        }

        & p {
            font-size: 1.2rem;
        }

        & h5 {
            padding-bottom: 8%;
        }
    }
}

@media screen and (min-width:2200px) and (max-width:2499px) {
    .card-sol {
        & img {
            width: 22px;
        }
    }

    .solutions-container {
        .solutions-image-container {
            height: 700px;
            padding-bottom: 30%;
        }
    }

    .card-sol {
        & h5 {
            font-size: 1.85rem;
        }

        & p {
            font-size: 1.8rem;
        }
    }

    .solutions-content-container {
        & .sol-upper-section-heading {
            & img {
                width: 425px;
            }
        }
    }
}

@media screen and (min-width:2500px) and (max-width:2999px) {
    .card-sol {
        & img {
            width: 12%;
        }

    }

    .solutions-container {
        .solutions-image-container {
            height: 700px;
            padding-bottom: 30%;
        }
    }

    .card-sol {
        & h5 {
            font-size: 2.2rem;
        }

        & p {
            font-size: 1.9rem;
        }
    }

    .solutions-content-container {
        & .sol-upper-section-heading {
            & img {
                width: 425px;
            }
        }

        & .sol-content-heading {
            & p {
                font-size: 2.1rem;
            }
        }
    }

    .solutions-main-content {
        & h1 {
            font-size: 6.5rem;
            line-height: 7rem;
        }
    }
}

@media screen and (min-width:3000px) {
    .card-sol {
        & h5 {
            font-size: 2.8rem;
            padding-bottom: 5%;
        }

        & p {
            font-size: 2.5rem;
        }
    }

    .solutions-content-container {
        & .sol-upper-section-heading {
            & h2 {
                font-size: 3.8rem;
                padding-top: 0.8em;
            }

            & img {
                width: 450px;
            }
        }

        & .sol-content-heading {
            & p {
                font-size: 2.5rem;
            }
        }
    }

    .solutions-main-content {
        & h1 {
            font-size: 8.5rem;
            line-height: 9rem;
        }
    }

    .solutions-container {
        & .solutions-image-container {
            height: 700px;
            padding-bottom: 31%;
        }
    }
}

@media screen and (min-width:1400px) and (max-width:1999px) {
    .solutions-content-container {
        & .sol-content-heading {
            & p {
                font-size: 1.5rem;
            }
        }
    }

    .solutions-main-content {
        & p {
            font-size: 1.5rem;
        }
    }

    .card-sol {
        & h5 {
            font-size: 1.5rem;
        }
    }
}

@media screen and (min-width:1300px) and (max-width:1699px) {
    .solutions-content-container {
        & .sol-content-heading {
            & p {
                font-size: 1.5rem !important;
            }
        }
    }

    .solutions-main-content {
        & h1 {
            font-size: 4rem;
            line-height: 4.5rem;
        }

        & p {
            font-size: 1.5rem !important;
        }
    }

    .card-sol {
        & h5 {
            font-size: 1.5rem;
        }

        & p {
            font-size: 1.5rem;
        }
    }
}

@media screen and (min-width:2000px) and (max-width:2400px) {
    .solutions-content-container {
        & .sol-content-heading {
            & p {
                font-size: 1.8rem !important;
            }
        }
    }

    .solutions-main-content {
        & p {
            font-size: 1.8rem;
        }
    }

    .card-sol {
        & h5 {
            font-size: 1.9rem;
        }

        & p {
            font-size: 1.8rem;
        }
    }
}

@media screen and (min-width:2401px) and (max-width:2800px) {
    .solutions-content-container {
        & .sol-content-heading {
            & p {
                font-size: 1.9rem !important;
            }
        }
    }

    .solutions-main-content {
        & p {
            font-size: 1.9rem;
        }
    }

    .card-sol {
        & h5 {
            font-size: 2rem;
        }

        & p {
            font-size: 1.9rem;
        }
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .solutions-content-container {
        & .sol-content-heading {
            & p {
                font-size: 2.2rem !important;
            }
        }
    }

    .solutions-main-content {
        & p {
            font-size: 2.2rem;
        }
    }

    .card-sol {
        & h5 {
            font-size: 2.3rem;
        }

        & p {
            font-size: 2.2rem;
        }
    }
}

@media screen and (min-width:3000px) {
    .solutions-content-container {
        & .sol-content-heading {
            & p {
                font-size: 2.5rem !important;
            }
        }
    }

    .solutions-main-content {
        & p {
            font-size: 2.5rem !important;
        }
    }

    .card-sol {
        & h5 {
            font-size: 2.6rem !important;
        }

        & p {
            font-size: 2.5rem;
        }
    }
}

@media screen and (min-width:481px) and (max-width:767px) {
    .sol-upper-section-heading {
        & h2 {
            font-size: 2.5rem !important;
        }
    }

    .card-sol {
        margin-bottom: 17% !important;

        & p {
            font-size: 1.2rem;
            padding-left: 8px;
        }
    }

    .main-container-solutions {
        padding-left: 15% !important;
        padding-top: 1% !important;
        padding-bottom: 0 !important;
    }

    .sol-content-heading {
        & p {
            margin-bottom: 9%;
            font-size: 1.2rem;
        }
    }

    .solutions-content-container {
        padding-bottom: 0vh;
    }
}

@media screen and (min-width:700px) and (max-width:767px) {
    .card-sol {
        margin-bottom: 17% !important;
    }

    .main-container-solutions {
        padding-left: 15% !important;
        padding-top: 1% !important;
        padding-bottom: 0 !important;
    }

    .sol-content-heading {
        & p {
            margin-bottom: 8%;
        }
    }
}

@media screen and (min-width:950px) and (max-width:1024px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 20px !important;
        }

        & p {
            width: 600px !important;
            padding-left: 8px;
        }

        .logo {
            padding-left: 35%;
            width: 380px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 20%;
        }
    }
}

@media screen and (min-width:768px) and (max-width:949px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 20px !important;
        }

        & p {
            width: 520px !important;
            padding-left: 8px;
        }

        .logo {
            padding-left: 26%;
            width: 340px !important;
        }
    }

    .solutions-container {
        & .main-container-solutions {
            padding-left: 20%;
        }
    }
}

@media screen and (min-width:731px) and (max-width:767px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 20px !important;
        }

        & p {
            width: 490px !important;
        }

        .logo {
            padding-left: 25%;
            width: 300px !important;
        }
    }
}

@media screen and (min-width:671px) and (max-width:730px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 20px !important;
        }

        & p {
            width: 445px !important;
        }

        .logo {
            padding-left: 25%;
            width: 300px !important;
        }
    }
}

@media screen and (min-width:631px) and (max-width:670px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 15px !important;
            margin-bottom: 2%;
        }

        & p {
            width: 390px !important;
        }

        .logo {
            padding-left: 28%;
            width: 270px !important;
        }
    }
}

@media screen and (min-width:581px) and (max-width:630px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 15px !important;
            margin-bottom: 2%;
        }

        & p {
            width: 370px !important;
        }

        .logo {
            padding-left: 25%;
            width: 250px !important;
        }
    }

    .sol-content-heading p {
        margin-bottom: 8% !important;
    }
}

@media screen and (min-width:536px) and (max-width:580px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 15px !important;
            margin-bottom: 5px;
        }

        & p {
            width: 345px !important;
        }

        .logo {
            padding-left: 25%;
            width: 245px !important;
        }
    }
}

@media screen and (min-width:461px) and (max-width:535px) {
    .card-sol {
        margin-bottom: 15%;

        & img {
            width: 15px !important;
            margin-bottom: 4px;
        }

        & p {
            width: 325px !important;
        }

        .logo {
            padding-left: 25%;
            width: 245px !important;
        }
    }
}

@media screen and (min-width:420px) and (max-width:460px) {
    .card-sol {
        margin-bottom: 15%;

        .sol-content-heading {
            & p {
                margin-bottom: 20%;
            }
        }

        & img {
            width: 15px !important;
        }

        & p {
            width: 280px !important;
        }

        .logo {
            padding-left: 25%;
            width: 200px !important;
        }
    }
}

@media screen and (min-width:350px) and (max-width:419px) {
    .card-sol {
        & img {
            width: 15px !important;
        }

        & p {
            width: 250px !important;
        }

        .logo {
            padding-left: 20%;
            width: 190px !important;
        }
    }
}

@media screen and (min-width:312px) and (max-width:480px) {
    .sol-upper-section-heading {
        & h2 {
            font-size: 2rem !important;
        }
    }

    .card-sol {
        margin-bottom: 20%;

        & img {
            width: 15px;
            align-items: center;
            justify-content: center;
            display: inline-block !important;
            margin-bottom: 3px !important;
        }

        & p {
            font-size: 1rem;
            line-height: 1.5rem !important;
            width: 200px;
            padding-left: 6px;
        }
    }

    .sol-content-heading {
        & p {
            margin-bottom: 12%;
            font-size: 1rem !important;
            line-height: 1.5rem !important;
        }
    }

    .main-container-solutions {
        padding-left: 15% !important;
        padding-top: 2%;
        padding-bottom: 0;
    }

    .solutions-content-container {
        padding: 1vh 9vw;
    }
}