@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';

.main-container-services {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    row-gap: 6vh;
    column-gap: 1.6vh;
    @include height-fit-content;
    padding-left: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
    @include font;
}
.card-services {
    width: 95%;
    border: 1px solid $grey;
    border-radius: 8px;
    padding: 8% 6% 6% 6%;
    background-color: $white;
    @include text-center;
    box-shadow: 2px 2px 2px $grey;
    height: 60vh;
    transition: transform 0.5s ease-in-out;
    &:hover {
        transform: scale(1.03);
        box-shadow: 2px 10px 15px $grey;
        border: none;
    }
    & .services-card-heading{
        @include flex-column;
        align-items: center;
        justify-content: center;
        & h5 {
            margin: 0;
            font-size: 2rem;
            width: 100%;
            font-weight: 700;
            padding-bottom: 8%;
            letter-spacing: 0.1rem
        }
    }
    & .sub-point-container{
        @include flex-row;
        flex-wrap: nowrap;
        align-items: flex-start;
        column-gap: 1vw;
        padding-bottom: 1vh;
        & img{
            width: 30px;
            padding-top: 8px;
        }
        & .sub-points {
            @include flex-row;
            justify-content: left;
            align-items: flex-start;        
            & p {
                font-weight: 500;
                font-size: 22px;
                // @include flex-row;
                text-align: left;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.01em;
                align-items: flex-start;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .card-services {
        & .services-card-heading{
            & h5 {
                margin: 0;
                font-size: 1.6rem;
                width: 98%;
                font-weight: 600;
            }
        }
        & .sub-point-container{
            column-gap: 1vw;
            padding-bottom: 1vh;
            & img{
                width: 30px;
            }
            & .sub-points {      
                & p {
                    font-weight: 500;
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (min-width: 2600px) {
    .card-services {
        & .services-card-heading{
            & h5 {
                font-size: 2.5rem;
            }
        }
        & .sub-point-container{
            & .sub-points {  
                & img {
                    height: 10%;
                    width: 10%;
                }
        
                & p {
                    font-size: 1.7rem;
                }
            }
        }
    }
}

@media screen and (min-width: 3200px) {
    .card-services {
        & .services-card-heading{
            & h5 {
                font-size: 2.9rem;
            }
        }
        & .sub-point-container{
            & .sub-points {  
                & img {
                    height: 10%;
                    width: 10%;
                }
        
                & p {
                    font-size: 2.1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .card-services {
        & .services-card-heading{
            & h5 {
                margin: 0;
                font-size: 1.5rem;
                width: 98%;
                font-weight: 600;
            }
        }
        & .sub-point-container{
            column-gap: 1.5vw;
            padding-bottom: 0vh;
            & img{
                width: 25px;
            }
            & .sub-points {      
                & p {
                    font-weight: 500;
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .main-container-services {
        display: block;
        padding: 5% 3% 2%;
    }

    .card-services {
        margin-bottom: 2vh;
        width: 100%;
        & .services-card-heading{
            & h5 {
                margin: 0;
                font-size: 1.4rem;
                width: 98%;
                font-weight: 600;
            }
        }
        & .sub-point-container{
            column-gap: 2.3vw;
            row-gap: 2vh;
            & img{
                width: 22px;
            }
            & .sub-points {
                & p {
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .card-services {
        height: 65vh;
    }
}

@media screen and (max-width: 450px) {

    .card-services {
        @include height-fit-content;

        & h5 {
            font-size: 20px;
            width: 100%;
        }

        & .sub-points {
            & img {
                height: 8%;
                width: 8%;
            }
        }
    }

}