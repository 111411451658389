@import '../../shared/assets/css/variables';
@import '../../shared/assets/css/mixins.scss';
@import '../../shared/assets/css/common.scss';

.bradcrumb-hover {
    cursor: default;
}

.page-container-Designing {
    @include font;
    background-color: $background-color;
    overflow-x: hidden;
    padding-left: 10%;
    @include position-rel;

    .image-container {
        background-image: url('../../shared/assets/images/designing-bg.jpg');
    }

    & .about-our-service {
        &>img {
            @include display-none;
        }
    }

    & .lower-div {
        & .page-container-Cards {
            grid-template-rows: none;
            padding-bottom: 3em;

            & :nth-child(3) p {
                width: 100%;
            }
        }
    }

    & .page-container-footer {
        & .header-footer {
            padding-top: 1em;
        }
    }
}

@media screen and (max-width: 1200px) {
    .page-container-Designing {
        & .about-our-service {
            padding-top: 7vh;
            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }

                & p {
                    font-size: 21px;
                }
            }
        }
    }
}

@media screen and (min-width: 2500px) {
    .page-container-Designing {
        .about-our-service {
            & .left-section {
                height: 58vh;

                & h5 {
                    font-size: 4.5rem;
                }
                & p {
                    font-size: 2.5rem;
                }

                & h2 {
                    font-size: 8rem;
                }

                & p {
                    & .blue-box {
                        top: -7%;
                        height: 15%;
                        right: 3%;
                    }

                    & .red-box-sq {
                        bottom: -18%;
                        height: 15%;
                        width: 15%;
                    }
                }

            }
        }

        .lower-div {
            &>h2 {
                font-size: 4rem;
            }
        }
    }
}

@media screen and (min-width: 3000px) {
    .page-container-Designing {
        .about-our-service {
            & .left-section {
                height: 58vh;

                & h5 {
                    font-size: 5.8rem;
                }

                & p {
                    font-size: 3.2rem;
                }

                & h2 {
                    font-size: 10rem;
                    line-height: 8rem;
                }

                & p {
                    & .blue-box {
                        top: -7%;
                        height: 15%;
                        right: 3%;
                    }

                    & .red-box-sq {
                        bottom: -18%;
                        height: 15%;
                        width: 15%;
                    }
                }

            }
        }

        .lower-div {
            &>h2 {
                font-size: 5rem;
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .page-container-Designing {
        & .about-our-service {
            padding-top: 5vh;
            & .left-section {
                & h5 {
                    font-size: 22px
                }

                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }
                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .page-container-Designing {
        & .about-our-service {
            padding-top: 5vh;
            & .left-section {
                & h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .page-container-Designing {
        & .about-our-service {
            padding-top: 5vh;
            & .left-section {
                & h2 {
                    font-size: 3rem;
                    line-height: 3.5rem;
                }
                & p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-container-Designing {
        padding-left: 0%;
        & .about-our-service {
            @include position-rel;
            z-index: 100;

            & .left-section {
                @include position-rel;
                width: 100%;

                & h5 {
                    @include display-none;
                }

                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;
                    z-index: 1;
                }

                & p {
                    margin-top: 4%;
                    position: relative;
                    background-image: url('../../shared/assets/images/designing-bg.jpg');
                    background-color: gray;
                    background-size: cover;
                    color: $white;
                    @include text-center;
                    width: 100%;
                    height: fit-content;
                    padding: 30% 10%;
                    font-weight: 500;
                    z-index: 200;
                    font-size: 1.7rem;

                    & .blue-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        right: -9%;
                        top: -3%
                    }

                    & .red-box {
                        @include position-abs;
                        display: block;
                        z-index: -200;
                        height: 9%;
                        left: -9%;
                        bottom: -4%
                    }

                    & .red-box-sq {
                        @include display-none;
                    }
                }

                & p::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    /* Adjust the color and opacity as needed */
                    z-index: -1;
                    /* Adjust the z-index to position the overlay above the background image */
                }

                &>img {
                    @include display-none;
                }
            }
            & .lower-div {
                & h2 {
                    font-size: 36px;
                }
            }

            & .right-section {
                @include display-none;
            }

            &>img {
                display: block;
                @include position-abs;
                top: 0%;
                right: 0%;
                z-index: -1;
                transform: rotate(90deg);
                height: 22%;
            }

            .circles-Designing {
                height: 1.5%;
                transform: rotate(0deg);
                @include position-abs;
                z-index: -1;
                top: 16%;
                right: 2%;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .page-container-Designing {
        & .about-our-service {

            & .left-section {
                & h3 {
                    padding-top: 8%;
                    display: block;
                    color: $primary;
                    font-weight: 700;
                    font-size: 24px;
                    letter-spacing: 0.1rem;
                }

                & p {
                    padding: 30% 5%;
                    font-weight: 500;
                    z-index: 200;
                    font-size: 1.2rem;

                }
            }
        }
        & .lower-div {
            & h2 {
                font-size: 36px;
            }
            
            & .teams-accreditation {
                & .images-teams-accreditation {
                    & img {
                        height: 25%;
                        width: 25%;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:768px) and (max-width: 800px) {
    .page-container-Designing {
        & .about-our-service {
            & .left-section {
                & p {
                    font-size: 1.1rem;
                }
            }
        }
    }
}