@import "../../../../shared/assets/css/variables";
@import "../../../../shared/assets/css/common.scss";
@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap";

.main-container-solutions-cxo {
    display: flex;
    @include height-fit-content;
    padding-left: 10%;
    padding-top: 3%;
    padding-bottom: 1%;
    @include font;
}

.heading-desc-cxo {
    @include font;
    @include position-rel;
    font-size: 1.5rem;
    width: 52%;
    font-weight: 500;
    z-index: 1;
    margin: 0;
    line-height: 2rem;
    top: 320px;
    left: 30px
}

.breadcrumb-color {
    color: $light-blue !important;
}

.cxotulz-container {
    @include flex-column;
    @include font;
    z-index: -1;
    padding-left: 10% !important;
    overflow: hidden;

    & .cxotulz-image-container {
        background-image: url("../../../../shared/assets/images/cxo-thumbnail.jpg");

    }

    & .cxotulz-image-container {
        height: 760px;
        background-size: contain !important;
    }
}

.cxo-techstack {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: center;

    & h4 {
        text-align: center;
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 600;
        padding-bottom: 2%;
    }

    & p {
        text-align: center !important;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
    }
}

.breadcrumb-cxo {
    @include flex-row;
    position: absolute;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    color: $white;
    bottom: 0;
    z-index: 10;
    margin-bottom: 0;
}

.vert-line-solutions {
    @include flex-column;
    width: 2px;
    background-color: $light-blue;
    height: 95%;
    padding: 0;
    margin-left: 1px;
}

.cxo-content-container {
    padding: 3vh 9vw;
    padding-top: 0 !important;

    & .upper-section-heading {
        @include flex-row;
        justify-content: center;
        align-items: center;
        column-gap: 0.5vw;
        padding-top: 2%;

        & img {
            width: 300px;

        }
    }
}

.cxo-content-container {
    & .content-heading {
        padding-top: 1vw;

        & p {
            text-align: center;
            padding-top: 1%;
            font-size: 26px;
            font-weight: 500;
        }
    }
}

.cxo-main-content {
    @include flex-row;
    justify-content: space-between;
    width: 100%;

    & .up-content {
        display: flex;

        & a {
            color: $light-blue;
        }

        & p {
            text-align: justify;
            hyphens: auto;
            -webkit-hyphens: auto;
            word-spacing: -0.05em;
            padding-top: 3%;
            font-weight: 500;
        }

        & .para-content {
            @include flex-column;
        }
    }

    & .mid-content {
        display: flex;

        & .dashboard-cxo-img {
            display: flex;
            margin: 0;
            width: 0;

            & img {
                width: 864px;
                position: relative;
                padding-top: 10%;
            }

        }
    }

    & h1 {
        @include text-stroke;
        -webkit-text-stroke: 1px $black !important;
        border: 1px !important;
        font-size: 6rem;
        line-height: 6.1rem;
        padding-bottom: 6%;
    }

}

.center-content {
    @include flex-row;
    align-items: center;
    justify-content: center;

    & h2 {
        font-size: 3rem;
        font-weight: 600;
        padding-top: 3%;
        padding-bottom: 3%;
        text-align: center
    }


}

.main-container-solution-cxo {
    @include flex-row;
    flex-wrap: wrap;
    column-gap: 4vw;
    row-gap: 3vw;
    justify-content: center
}

.main-container-solutions-cxo {
    @include flex-row;

    & .card-solutions {
        flex: 0 0 33%;
        padding-left: 2%;
        padding-right: 7%;
        padding-bottom: 5%;
    }
}

.cxo-role {
    display: flex;
    padding: 5% 10%;
    padding-bottom: 0;

    & h3 {
        font-size: 3rem;
        text-align: center;
        font-weight: 600;
        padding-bottom: 2%;
    }

    & p {
        text-align: center;
        font-size: 26px;
        font-weight: 500;
    }
}

.card-solution {
    flex: 0 0 46%;
    text-align: left;
    background-color: $light-grey;
    justify-content: flex-start;
    padding: 2em;
    box-shadow: 0px 10px 20px $grey;

    img.logo-0 {
        width: 16%;
        padding-bottom: 7%;
    }

    img.logo-1 {
        width: 23%;
        padding-bottom: 7%;
    }

    img.logo-2 {
        width: 23%;
        padding-bottom: 8%;
    }

    img.logo-3 {
        width: 20%;
        padding-bottom: 8%;
    }

    & h5 {
        font-size: 1.4rem;
        font-weight: 600;
        align-items: flex-start;
    }
}

.card-solutions {
    @include flex-row;
    text-align: left;
    padding-bottom: 8%;
    padding-right: 5%;
    width: 100%;


    & p {

        font-size: 20px;
        font-weight: 500;
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
        word-spacing: -0.05em;
    }

    & h5 {
        font-weight: 600;
        color: $dark-aqua;
        font-size: 24px;
    }
}

.color-text {
    color: $light-blue;
}

.cxo-point-container {
    & p {
        text-align: left;
        padding-left: 0%;
        margin: 0;
        font-size: 20px;
    }

}

.cxo-last-content {
    @include flex-row;
    width: 100%;
    padding-bottom: 5%;
    align-items: center;
    padding-top: 2%;

    & p {
        position: relative;
        font-size: 2rem;
        font-weight: 500;
        padding-left: 5%;
    }

    & a {
        color: $light-blue;
    }

    & img {
        width: 600px;
        height: fit-content;
    }
}

.cxo-point-containers {
    align-items: left;
    @include flex-row;

    & .bullet-point-img {
        text-align: left;
        padding: 0;
        width: 14px;
        height: fit-content;
        padding-top: 2%;
    }

    & p {
        text-align: left;
        padding-left: 5%;
        margin: 0;
        font-size: 20px;
        padding-bottom: 4%;
    }
}


@media screen and (max-width:767px) {
    .main-container-solution-cxo {
        @include flex-column;
    }

    .cxotulz-container {
        padding-left: 0 !important;

        & .cxotulz-image-container {
            height: 330px;
            padding-top: 13%;
        }
    }

    .cxo-content-container {
        .content-heading {
            & p {
                font-size: 1rem;
                font-weight: 400;
                padding-top: 3%;
            }
        }
    }

    .cxo-content-container {
        .upper-section-heading {

            & img {
                width: 100%;
            }
        }
    }

    .cxo-main-content {
        column-gap: 2vw;
        margin: 0 auto;
        @include flex-row;

        & h1 {
            font-size: 2.7rem;
            line-height: 3.1rem;
            order: 0;

        }

        & .up-content {
            display: flex;
            padding-right: 0;
            padding-top: 5%;

            & p {
                order: 2;
            }
        }

        & .para-content {
            & .dashboard-cxo-mob {
                & img {
                    padding-top: 8%;
                }
            }
        }

        & .mid-content {
            display: flex;

            & .dashboard-cxo-img {
                display: flex;

                & img {
                    width: 350px;
                    order: 1;
                }
            }
        }

        .cv-mob-bg {
            background-color: $blue-shadow;
            width: 54vw;
            height: 15vh;
            right: 0;
            z-index: -1;
            overflow-x: hidden;
        }

        .cv-mob-bg img {
            width: 3em;
            height: fit-content;
            bottom: 1em;
            right: 1em;
            order: 0;
        }

    }

    .center-content {
        & h2 {
            font-size: 2rem;
            padding-top: 8%;
            text-align: center;
            padding-bottom: 10%;
        }
    }

    .card-solution {
        @include flex-column;
        margin-bottom: 8%;
        padding: 3% 3%;
        background-color: $light-grey;
        width: auto;
        height: fit-content;
        align-items: flex-start;
        justify-content: flex-start;

        & img {
            align-items: left;
        }
    }

    .cxo-point-containers {
        & .bullet-point-img {
            width: 10px;
            padding-top: 3%;
        }

        & p {
            font-size: 16px;
        }
    }

    .cxo-role {
        padding: 5% 5%;
        @include flex-row;
        flex-wrap: wrap;

        & h3 {
            font-size: 2rem;
            line-height: 2.5rem;
            padding-bottom: 3%;
        }

        & p {
            font-size: 1rem;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            padding-bottom: 5%;
        }

    }

    .card-solutions {
        display: flex;
        flex-direction: row;
        flex: 0 0 50% !important;
        width: fit-content;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;

        & .solution-card-heading {
            display: flex;
            flex-direction: column;
            padding-left: 3%;

            & p {
                width: 250px;
            }
        }

    }

    .vert-line-solutions {
        width: 2px;
        background-color: $light-blue;
        height: 170px;
        padding: 1px;
        margin-left: 1px;
    }

    .cxo-last-content {
        @include flex-column;

        & img {
            width: 380px;
            align-items: center;
            padding-left: 2%;
        }

        & p {
            padding-top: 4%;
            font-size: 1.2rem;
            text-align: justify;
            hyphens: auto;
            -webkit-hyphens: auto;
            word-spacing: -0.05em;
            text-align: left;
        }
    }

    .main-container-solutions-cxo {
        align-items: flex-start;
        padding-left: 6%;
    }

    .cxo-techstack {
        & h4 {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

@media screen and (max-width:350px) {
    .cxo-last-content {
        align-items: center;
        justify-content: center;

        & img {
            width: 300px;
        }
    }

    .cxo-main-content {
        & .mid-content {
            & .dashboard-cxo-img {
                width: 100%;
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 1%;
        }
    }
}

@media screen and (min-width:768px) and (max-width:825px) {
    .cxotulz-container {
        & .card-solutions {
            & .solution-card-heading {
                & p {
                    width: 188px !important;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                }
            }
        }
    }
}

@media screen and (min-width:826px) and (max-width:965px) {
    .cxotulz-container {
        & .vert-line-solutions {
            height: 223px !important;
        }

        & .card-solutions {
            & .solution-card-heading {
                & p {
                    width: 215px !important;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                }
            }
        }
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .breadcrumb-cxo {
        font-size: 1.4rem !important;
        font-weight: 500;
        top: 155px;
    }

    .solution-card-heading {
        & p {
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
        }
    }

    .up-content {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }

    .cxo-techstack {
        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
    .breadcrumb-cxo {
        font-size: 1.2rem !important;
        font-weight: 500;
    }

    .heading-desc-cxo {
        top: 90px;
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .cxotulz-container {
        & .cxo-content-container {
            & .content-heading {
                & p {
                    font-size: 1.1rem !important;
                }
            }
        }

        & .cxo-main-content {
            & h1 {
                font-size: 3.2rem !important;
                line-height: 3.7rem !important;
            }

            & .up-content {
                & p {
                    font-size: 1.2rem !important;
                    line-height: 1.7rem !important;
                }
            }
        }

        & .cxo-point-containers {
            & p {
                font-size: 1.1rem !important;
            }
        }

        & .card-solution {
            height: 600px !important;
        }

        & .cxo-role {
            & p {
                font-size: 1.1rem !important;
            }
        }

        & .card-solutions {
            & .solution-card-heading {
                & p {
                    font-size: 1.2rem !important;
                    line-height: 1.7rem !important;
                    width: 200px !important;
                }
            }
        }

        & .vert-line-solutions {
            height: 270px !important;
        }
    }
}

@media screen and (min-width:807px) and (max-width:850px) {
    .vert-line-solutions {
        &.vert-line-0 {
            height: 250px !important;
        }

        &.vert-line-1 {
            height: 250px !important;
        }

        &.vert-line-2 {
            height: 225px !important;
        }

        &.vert-line-3 {
            height: 225px !important;
        }

        &.vert-line-4 {
            height: 280px !important;
        }

        &.vert-line-5 {
            height: 280px !important;
        }
    }
}

@media screen and (min-width:768px) and (max-width:1120px) {
    .cxotulz-container {
        padding-left: 0;

        & .cxotulz-image-container {
            height: 500px;
            padding-top: 8%;
        }

        .cxo-content-container {
            .content-heading {
                & p {
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding-top: 2%;
                }
            }
        }

        .cxo-content-container {
            .upper-section-heading {

                & img {
                    width: 100%;
                }
            }
        }

        .cxo-main-content {
            display: flex;

            & h1 {
                font-size: 3rem;
                line-height: 3.5rem;
                width: 270px;
            }

            & .up-content {
                padding-right: 0;
                padding-top: 2%;
                display: flex;
                flex-direction: row;

                & .cv-mob-bg {
                    display: none;
                }

                & p {
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    width: 280px;

                }
            }

            & .mid-content {
                & .dashboard-cxo-img {
                    & img {
                        width: 412px;
                        padding-top: 37px;
                        left: 15px;
                    }
                }
            }

            .cv-mob-bg {
                background-color: $blue-shadow;
                width: 60vw;
                height: 15vh;
                right: 0;
                z-index: -1;
                overflow-x: hidden;
            }

            .cv-mob-bg img {
                width: 3em;
                height: fit-content;
                bottom: 1em;
                right: 0em;
                order: 0;
            }

        }

        .center-content {
            & h2 {
                font-size: 2rem;
                padding-top: 8%;
                text-align: center;
                padding-bottom: 6%;
            }
        }

        .card-solution {
            @include flex-column;
            margin-bottom: 8%;
            padding: 3% 3%;
            background-color: $light-grey;
            width: auto;
            height: 535px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .cxo-point-containers {
            & .bullet-point-img {
                width: 10px;
                padding-top: 3%;
            }

            & p {
                font-size: 16px;
            }
        }

        .cxo-role {
            padding: 5% 5%;
            @include flex-row;
            flex-wrap: wrap;
            padding-left: 13%;

            & h3 {
                font-size: 2rem;
                line-height: 2.5rem;
                margin: 0 auto;
            }

            & p {
                font-size: 1.2rem;
                line-height: 1.7rem;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                padding-bottom: 5%;
                width: 100%;
                padding-top: 3%;
            }

        }

        .card-solutions {
            flex: 0 0 50%;
            padding-right: 0;

            & .solution-card-heading {
                display: flex;
                flex-direction: column;
                padding-left: 3%;

                & p {
                    width: 241px;
                    padding-right: 3%;
                    font-size: 1.2rem !important;
                    line-height: 1.7rem !important;

                }
            }

        }

        .vert-line-solutions {
            width: 2px;
            background-color: $light-blue;
            height: 225px;
            padding: 1px;
        }

        .cxo-last-content {
            @include flex-row;

            & img {
                width: 380px;
                align-items: center;
                padding-left: 2%;
            }

            & p {
                padding-top: 4%;
                font-size: 1.2rem;
                text-align: justify;
                hyphens: auto;
                -webkit-hyphens: auto;
                word-spacing: -0.05em;
                text-align: left;
            }
        }

        .main-container-solutions-cxo {
            align-items: flex-start;
            padding-left: 0;

            & .card-solutions {
                padding-right: 0;
                padding-left: 4%;
            }
        }

        .card-solution {
            & h5 {
                padding-bottom: 8%;
            }
        }
    }

    .cxo-techstack {
        & h4 {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:1121px) and (max-width:1500px) {
    .cxo-content-container {
        .content-heading {
            & p {
                padding-bottom: 0%;
                font-size: 24px;
            }
        }

        .card-solutions {
            & h5 {
                padding-bottom: 4%;
            }

            & .solution-card-heading {
                padding-left: 3%;

                & p {
                    font-size: 1.2rem !important;
                    line-height: 1.7rem !important;
                }
            }
        }
    }

    .cxo-main-content {
        .up-content {
            padding-top: 3%;
        }

        & h1 {
            font-size: 5rem;
            line-height: 5.1rem;
            padding-bottom: 2%;
        }

        & p {
            font-size: 22px;
            padding-right: 4%;
        }

        .mid-content {
            & .dashboard-cxo-img {
                & img {
                    width: 600px;
                    padding-top: 100px;
                }
            }
        }
    }

    .main-container-solutions-cxo {
        padding-left: 10%;

        & .card-solutions {
            flex: 0 0 30%;
            padding-right: 0;

        }
    }

    .vert-line-solutions {
        width: 3px;
        background-color: $light-blue;
        height: 280px;
        padding: 1px;
        margin-left: 1px;
    }

    .cxo-role {
        padding: 0;
        align-items: center;
        justify-content: center;

        & h3 {
            padding-top: 7%;
        }
    }

    .card-solution {
        img.logo-0 {
            width: 21%;
            padding-bottom: 12%;
        }

        img.logo-1 {
            width: 29%;
            padding-bottom: 13%;
        }

        img.logo-2 {
            width: 28%;
            padding-bottom: 13%;
        }

        img.logo-3 {
            width: 26%;
            padding-bottom: 10%;
        }

        & h5 {
            padding-bottom: 7%;
        }
    }

    .cxo-techstack {
        & h4 {
            font-size: 3rem;
            line-height: 3.5rem;
        }

        & p {
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
}

@media screen and (min-width:1221px) and (max-width:1238px) {
    .vert-line-solutions {
        &.vert-line-0 {
            height: 250px !important;
        }

        &.vert-line-1 {
            height: 250px !important;
        }

        &.vert-line-2 {
            height: 250px !important;
        }

        &.vert-line-3 {
            height: 280px !important;
        }

        &.vert-line-4 {
            height: 280px !important;
        }

        &.vert-line-5 {
            height: 280px !important;
        }
    }
}

@media screen and (min-width:1239px) and (max-width:1250px) {
    .vert-line-solutions {
        &.vert-line-0 {
            height: 250px !important;
        }

        &.vert-line-1 {
            height: 250px !important;
        }

        &.vert-line-2 {
            height: 250px !important;
        }

        &.vert-line-3 {
            height: 260px !important;
        }

        &.vert-line-4 {
            height: 260px !important;
        }

        &.vert-line-5 {
            height: 260px !important;
        }
    }
}

@media screen and (min-width:1501px) and (max-width:1510px) {
    .vert-line-solutions {
        height: 260px !important;
    }
}

@media screen and (min-width:1501px) {
    .cxo-content-container {
        & .content-heading {
            & p {
                padding-bottom: 3%;
            }
        }
    }

    .vert-line-solutions {
        width: 2.5px;
        background-color: $light-blue;
        height: 285px;
        padding: 1px;
        margin-left: 1px;
    }

    .cxo-last-content {
        & p {
            font-size: 26px;
        }
    }

    .card-solutions {
        & h5 {
            padding-bottom: 5%;
        }

        & .solution-card-heading {
            padding-left: 3%;
        }
    }

    .cxo-role {
        padding: 0;

        & h3 {
            padding-top: 7%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }

    .card-solutions {
        padding-right: 2% !important;
    }
}

@media screen and (min-width:1800px) {
    .card-solutions {
        & h5 {
            padding-bottom: 5%;
        }

        & .solution-card-heading {
            padding-left: 3%;

            & p {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }

    .vert-line-solutions {
        width: 2px;
        background-color: $light-blue;
        height: 230px;
        padding: 1px;
        margin-left: 1px;
    }

    .cxo-role {
        & h3 {
            padding-top: 5%;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }
}

@media screen and (min-width:2000px) {
    .card-solution {
        & h5 {
            font-size: 2rem;
        }
    }

    .cxo-point-containers {
        & p {
            font-size: 1.8rem;
            font-weight: 500;
        }
    }

    .card-solutions {
        & p {
            font-size: 1.8rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 30px;
            padding-bottom: 2%;
        }
    }

    .cxo-main-content {
        & .mid-content {
            & .dashboard-cxo-img {
                & img {
                    right: -34px;
                }
            }
        }
    }

    .cxo-content-container {
        & .upper-section-heading {
            & img {
                width: 330px;
            }
        }

        & .content-heading {
            & p {
                font-size: 1.8rem;
            }
        }
    }

    .cxo-main-content {

        & .up-content {
            & p {
                font-size: 1.8rem;
                line-height: 2.3rem;
            }
        }
    }

    .cxo-last-content {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }

    .cxo-role {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }

}

@media screen and (max-width:350px) {
    .card-solutions {
        & h5 {
            padding-bottom: 2%;
        }

        & .solution-card-heading {
            & p {
                width: 247px;
            }
        }
    }

    .cxotulz-container {
        & .cxotulz-image-container {
            height: 142px;
            margin-top: 19%;
            padding: 0;
        }
    }
}

@media screen and (min-width:351px) and (max-width:450px) {
    .card-solutions {
        & h5 {
            padding-bottom: 3%;
        }

        & .solution-card-heading {
            & p {
                width: 285px;
                padding-right: 7vw;
            }
        }
    }

    .vert-line-solutions {
        height: 173px;
    }

    .cxotulz-container {
        & .cxotulz-image-container {
            height: 185px;
            margin-top: 12%;
            padding: 0;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 3%;
        }
    }
}

@media screen and (min-width:451px) and (max-width:550px) {
    .card-solutions {
        & h5 {
            padding-bottom: 4%;
        }

        & .solution-card-heading {
            & p {
                width: 326px;
            }
        }
    }

    .vert-line-solutions {
        height: 175px;

        &.vert-line-2 {
            height: 195px;
        }
    }

    .cxotulz-container {
        & .cxotulz-image-container {
            height: 230px;
            margin-top: 12%;
            padding: 0;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 4%;
        }
    }
}

@media screen and (min-width:551px) and (max-width:670px) {
    .card-solutions {
        & h5 {
            padding-bottom: 3%;
        }

        & .solution-card-heading {
            & p {
                width: 415px;
            }
        }
    }

    .vert-line-solutions {
        height: 185px;
    }

    .cxo-main-content {
        & .para-content {
            & .dashboard-cxo-mob {
                & img {
                    padding-top: 4%;
                }
            }
        }
    }

    .cxotulz-container {
        & .cxotulz-image-container {
            height: 275px;
            margin-top: 10%;
            padding: 0;
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 5%;
        }
    }

    .breadcrumb-cxo {
        top: 180px;
        padding-left: 20px;
    }
}

@media screen and (min-width:671px) and (max-width:767px) {
    .card-solutions {
        & h5 {
            padding-bottom: 4%;
        }

        & .solution-card-heading {
            & p {
                width: 450px;
            }
        }
    }

    .vert-line-solutions {
        height: 190px;
    }

    .cxo-main-content {
        & .para-content {
            & .dashboard-cxo-mob {
                & img {
                    padding-top: 5%;
                }
            }
        }
    }

    .card-solution {
        & h5 {
            padding-bottom: 8%;
        }
    }

    .cxotulz-image-container {
        height: 330px !important;
        margin-top: 9% !important;
    }

    .breadcrumb-cxo {
        left: 20px;
        top: 230px !important;
    }
}

@media screen and (min-width:2200px) and (max-width:2499px) {
    .card-solution {
        img.logo-0 {
            width: 12%;
        }

        img.logo-1 {
            width: 18%;
        }

        img.logo-2 {
            width: 17%;
            padding-bottom: 7%;
        }

        img.logo-3 {
            width: 15%;
        }
    }

    .cxotulz-container {
        .cxotulz-image-container {
            height: 700px;
            padding-bottom: 30%;
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.85rem !important;
            line-height: 2.3rem !important;
        }
    }

    .cxo-point-containers {
        & p {
            font-size: 1.85rem !important;
            line-height: 2.3rem !important;
            font-weight: 500;
            padding-top: 1%;
        }
    }

    .card-solutions {
        & p {
            font-size: 1.85rem !important;
            line-height: 2.3rem !important;

            font-weight: 500;
        }

        & h5 {
            font-size: 1.9rem !important;
            line-height: 2.4rem !important;

            padding-bottom: 3%;
        }
    }

    .cxo-last-content {
        & p {
            font-size: 1.85rem !important;
            line-height: 2.3rem !important;

        }
    }

    .cxo-content-container {
        & .upper-section-heading {
            & img {
                width: 425px;
            }
        }
    }

    .cxo-role {
        & p {
            font-size: 1.85rem !important;
            line-height: 2.3rem !important;
        }
    }
}

@media screen and (min-width:2500px) and (max-width:2999px) {
    .card-solution {
        img.logo-0 {
            width: 12%;
        }

        img.logo-1 {
            width: 18%;
        }

        img.logo-2 {
            width: 17%;
            padding-bottom: 7%;
        }

        img.logo-3 {
            width: 15%;
        }
    }

    .cxotulz-container {
        .cxotulz-image-container {
            height: 700px;
            padding-bottom: 30%;
        }
    }

    .card-solution {
        & h5 {
            font-size: 2.2rem;
        }
    }

    .cxo-point-containers {
        & .bullet-point-img {
            width: 20px;
            padding-top: 4%;
        }

        & p {
            font-size: 2rem;
            font-weight: 500;
            padding-top: 1%;
        }
    }

    .card-solutions {
        & p {
            font-size: 1.85rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 2.2rem;
        }
    }

    .cxo-last-content {
        & p {
            font-size: 2.2rem;
        }
    }

    .cxo-content-container {
        & .upper-section-heading {
            & img {
                width: 425px;
            }
        }

        & .content-heading {
            & p {
                font-size: 2rem;
                line-height: 2.5rem;
            }
        }
    }

    .cxo-role {
        & p {
            font-size: 2rem !important;
            line-height: 2.5rem !important;
        }

        & h3 {
            font-size: 3.2rem !important;
            line-height: 3.7rem !important;
        }
    }

    .cxo-main-content {
        & h1 {
            font-size: 6.5rem;
            line-height: 7rem;
        }

        & .up-content {
            & p {
                font-size: 2rem;
            }
        }
    }

    .center-content {
        & h2 {
            font-size: 3.2rem;
        }
    }

    .cxo-techstack {
        & h4 {
            font-size: 3.2rem;
            line-height: 3.7rem;
        }
    }

}

@media screen and (min-width:3000px) {
    .card-solution {
        & h5 {
            font-size: 2.8rem;
            padding-bottom: 5%;
        }
    }

    .cxo-point-containers {
        & p {
            font-size: 2.3rem;
            font-weight: 500;
        }

        & .bullet-point-img {
            width: 20px;
            padding-top: 2%;
        }
    }

    .card-solutions {
        & p {
            font-size: 2.3rem;
            font-weight: 500;
        }

        & h5 {
            font-size: 2.5rem;
            line-height: 3rem;

        }
    }

    .cxo-content-container {
        & .upper-section-heading {
            & img {
                width: 450px;
            }
        }

        & .content-heading {
            & p {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }
    }

    .cxo-main-content {
        & h1 {
            font-size: 8.5rem;
            line-height: 9rem;
        }

        & .up-content {
            & p {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }
    }

    .cxo-last-content {
        & p {
            font-size: 2.5rem !important;
            line-height: 3rem !important;
        }
    }

    .cxo-role {
        & p {
            font-size: 2.5rem !important;
            line-height: 3rem !important;

        }

        & h3 {
            font-size: 3.5rem !important;
            line-height: 4rem !important;
            padding-top: 7%;
            padding-bottom: 3%;
        }
    }

    .center-content {
        & h2 {
            font-size: 3.5rem;
        }
    }

    .cxo-techstack {
        & h4 {
            font-size: 3.5rem;
            line-height: 4rem;
        }

        & p {
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }
}

@media screen and (min-width:1240px) and (max-width:1450px) {
    .vert-line-solutions {
        height: 255px !important;
    }
}

@media screen and (min-width:1451px) and (max-width:1500px) {
    .vert-line-solutions {
        height: 225px !important;
    }
}

@media screen and (min-width:1511px) and (max-width:1675px) {
    .vert-line-solutions {
        height: 260px !important;
    }
}

@media screen and (min-width:1676px) and (max-width:1799px) {
    .vert-line-solutions {
        height: 255px !important;
    }
}

@media screen and (min-width:2000px) and (max-width:2028px) {
    .vert-line-solutions {
        height: 285px !important;
    }
}

@media screen and (min-width:2029px) and (max-width:2119px) {
    .vert-line-solutions {
        height: 295px !important;
    }
}

@media screen and (min-width:2120px) and (max-width:2400px) {
    .vert-line-solutions {
        height: 255px !important;
    }
}

@media screen and (min-width:1400px) and (max-width:1999px) {
    .cxo-content-container {
        & .content-heading {
            & p {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }

    .cxo-main-content {
        & p {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        & .up-content {
            & p {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

@media screen and (min-width:960px) and (max-width:1024px) {
    .cxotulz-container {
        & .card-solutions {
            & .solution-card-heading {
                & p {
                    width: 252px !important;
                }
            }
        }

        & .vert-line-solutions {
            height: 220px !important;
        }
    }
}

@media screen and (min-width:893px) and (max-width:959px) {
    .cxotulz-container {
        & .card-solutions {
            & .solution-card-heading {
                & p {
                    width: 234px !important;
                }
            }
        }
    }

    .vert-line-solutions {
        &.vert-line-0 {
            height: 250px !important;
        }

        &.vert-line-1 {
            height: 250px !important;
        }

        &.vert-line-2 {
            height: 225px !important;
        }

        &.vert-line-3 {
            height: 225px !important;
        }

        &.vert-line-4 {
            height: 230px !important;
        }

        &.vert-line-5 {
            height: 230px !important;
        }
    }
}

@media screen and (min-width:807px) and (max-width:850px) {
    .cxotulz-container {
        & .card-solutions {
            & .solution-card-heading {
                & p {
                    width: 208px !important;
                }
            }
        }
    }
}

@media screen and (min-width:851px) and (max-width:892px) {
    .cxotulz-container {
        & .card-solutions {
            & .solution-card-heading {
                & p {
                    width: 218px !important;
                }
            }
        }
    }

    .vert-line-solutions {
        &.vert-line-0 {
            height: 250px !important;
        }

        &.vert-line-1 {
            height: 250px !important;
        }

        &.vert-line-2 {
            height: 225px !important;
        }

        &.vert-line-3 {
            height: 225px !important;
        }

        &.vert-line-4 {
            height: 230px !important;
        }

        &.vert-line-5 {
            height: 230px !important;
        }
    }
}

@media screen and (min-width:1300px) and (max-width:1699px) {
    .cxo-content-container {
        & .content-heading {
            & p {
                font-size: 1.2rem !important;
                line-height: 1.7rem !important;
            }
        }
    }

    .cxo-main-content {
        & h1 {
            font-size: 4rem;
            line-height: 4.5rem;
        }

        & p {
            font-size: 1.5rem !important;
            line-height: 2rem !important;
        }

        & .up-content {
            & p {
                font-size: 1.5rem !important;
                line-height: 2rem !important;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.5rem !important;
            line-height: 2rem !important;
        }
    }
}

@media screen and (min-width:2000px) and (max-width:2400px) {
    .cxo-content-container {
        & .content-heading {
            & p {
                font-size: 1.8rem !important;
                line-height: 2.3rem !important;

            }
        }
    }

    .cxo-main-content {
        & p {
            font-size: 1.8rem !important;
            line-height: 2.3rem !important;

        }

        & .up-content {
            & p {
                font-size: 1.8rem !important;
                line-height: 2.3rem !important;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 1.9rem !important;
            line-height: 2.4rem !important;
        }
    }

    .cxo-techstack {
        & p {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }
}

@media screen and (min-width:2401px) and (max-width:2800px) {
    .cxo-content-container {
        & .content-heading {
            & p {
                font-size: 1.9rem;
                line-height: 2.4rem !important;

            }
        }
    }

    .cxo-main-content {
        & p {
            font-size: 1.9rem !important;
            line-height: 2.4rem !important;

        }

        & .up-content {
            & p {
                font-size: 1.9rem;
                line-height: 2.4rem !important;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2rem;
        }
    }

    .vert-line-solutions {
        height: 310px !important;
    }

    .cxo-techstack {
        & p {
            font-size: 1.9rem;
            line-height: 2.4rem;
        }
    }
}

@media screen and (min-width:2701px) and (max-width:2800px) {
    .vert-line-solutions {
        height: 280px !important;
    }
}

@media screen and (min-width:2801px) and (max-width:2999px) {
    .cxo-content-container {
        & .content-heading {
            & p {
                font-size: 2.2rem;
                line-height: 2.7rem !important;

            }
        }
    }

    .cxo-main-content {
        & p {
            font-size: 2.2rem !important;
            line-height: 2.7rem !important;

        }

        & .up-content {
            & p {
                font-size: 2.2rem !important;
                line-height: 2.7rem !important;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2.3rem;
        }
    }

    .vert-line-solutions {
        height: 330px !important;
    }

    .cxo-techstack {
        & p {
            font-size: 2.2rem;
            line-height: 2.7rem;
        }
    }

    .heading-desc-cxo {
        font-size: 2.2rem;
        line-height: 2.8rem;
        top: 460px;
        width: 50%;
        left: 15px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        left: 175px !important;
    }
}

@media screen and (min-width:3000px) {
    .cxo-content-container {
        & .content-heading {
            & p {
                font-size: 2.5rem !important;
                line-height: 3rem !important;
            }
        }
    }

    .cxo-main-content {
        & p {
            font-size: 2.5rem !important;
            line-height: 3rem !important;
        }

        & .up-content {
            & p {
                font-size: 2.5rem !important;
                line-height: 3rem !important;
            }
        }
    }

    .card-solution {
        & h5 {
            font-size: 2.6rem !important;
            line-height: 3.2rem !important;
        }
    }

    .vert-line-solutions {
        height: 340px !important;
    }

    .card-solutions {
        & h5 {
            font-size: 2.6rem !important;
            line-height: 3.2rem !important;
        }
    }
}

@media screen and (min-width:501px) and (max-width:550px) {
    .breadcrumb-cxo {
        padding-left: 20px;
    }
}

@media screen and (min-width:451px) and (max-width:500px) {
    .breadcrumb-cxo {
        top: 155px !important;
        padding-left: 10px;
    }
}

@media screen and (min-width:401px) and (max-width:450px) {
    .breadcrumb-cxo {
        top: 135px !important;
        padding-left: 10px;

    }
}

@media screen and (min-width:360px) and (max-width:400px) {
    .breadcrumb-cxo {
        top: 125px !important;
        padding-left: 10px;
    }
}

@media screen and (min-width:312px) and (max-width:480px) {
    .breadcrumb-cxo {
        font-size: 1rem !important;
        font-weight: 500;
        top: 105px;
        left: 10px;
    }

    .solution-card-heading {
        & p {
            font-size: 1rem !important;
            line-height: 1.5rem !important;
        }
    }

    .cxo-techstack {
        & p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

@media screen and (min-width:312px) and (max-width:419px) {
    .main-container-solutions-cxo {
        padding-left: 7%;
    }
}

@media screen and (min-width:1921px) and (max-width: 2100px) {
    .heading-desc-cxo {
        font-size: 1.8rem;
        line-height: 2.5rem;
        top: 315px;
        width: 47%;
        left: 50px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }
}

@media screen and (min-width:2101px) and (max-width: 2299px) {
    .heading-desc-cxo {
        font-size: 1.8rem;
        line-height: 2.5rem;
        top: 340px;
        width: 45%;
        left: 65px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        left: 145px !important;
    }
}

@media screen and (min-width:2300px) and (max-width: 2499px) {
    .heading-desc-cxo {
        font-size: 1.9rem;
        line-height: 2.5rem;
        top: 375px;
        width: 55%;
        left: 70px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        left: 160px !important;
        padding-bottom: 0 !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 699px) {
    .breadcrumb-cxo {
        top: 205px;
    }

    .cxotulz-image-container {
        height: fit-content;
    }
}

@media only screen and (min-width: 700px) and (max-width:799px) {
    .breadcrumb-cxo {
        top: 250px;
        left: 50px;
    }

    .cxotulz-image-container {
        height: 325px !important;
    }

    .heading-desc-cxo {
        top: 80px;
        left: 20px;
        width: 63%;
    }
}

@media only screen and (min-width: 800px) and (max-width:899px) {
    .breadcrumb-cxo {
        top: 265px;
        left: 55px;
    }

    .cxotulz-image-container {
        height: 335px !important;
    }

    .heading-desc-cxo {
        top: 90px;
        left: 25px;
        width: 60%;
    }
}

@media only screen and (min-width: 900px) and (max-width:999px) {
    .breadcrumb-cxo {
        top: 300px;
        left: 60px;
    }

    .cxotulz-image-container {
        height: 375px !important;
    }

    .heading-desc-cxo {
        top: 110px;
        left: 30px;
    }
}

@media only screen and (min-width: 1000px) and (max-width:1100px) {
    .breadcrumb-cxo {
        top: 342px;
        left: 70px;
    }

    .cxotulz-image-container {
        height: 405px !important;
    }

    .heading-desc-cxo {
        top: 125px;
        left: 37px;
        width: 54%;
        font-size: 1.2rem;
        line-height: 1.7rem;
    }
}

@media only screen and (min-width: 1101px) and (max-width:1199px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 365px;
        left: 75px;
    }

    .cxotulz-image-container {
        height: 445px !important;
    }

    .heading-desc-cxo {
        top: 135px;
        left: 8px;
        width: 60%;
    }
}

@media screen and (min-width:1121px) and (max-width:1199px) {
    .heading-desc-cxo {
        top: 170px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1299px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 400px;
        left: 80px;
    }

    .cxotulz-image-container {
        height: 485px !important;
    }

    .heading-desc-cxo {
        top: 185px;
        left: 5px;
        width: 60%;
    }
}

@media only screen and (min-width: 1300px) and (max-width:1399px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 435px;
    }

    .cxotulz-image-container {
        height: 515px !important;
    }

    .heading-desc-cxo {
        top: 205px;
        left: 5px;
    }
}

@media only screen and (min-width: 1400px) and (max-width:1499px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 465px;
    }

    .cxotulz-image-container {
        height: 550px !important;
    }

    .heading-desc-cxo {
        top: 230px;
        left: 5px;
    }
}

@media only screen and (min-width: 1500px) and (max-width:1599px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 510px;
    }

    .cxotulz-image-container {
        height: 580px !important;

    }

    .heading-desc-cxo {
        top: 250px;
        left: 5px;
    }
}

@media only screen and (min-width: 1600px) and (max-width:1699px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 545px;
    }

    .cxotulz-image-container {
        height: 620px !important;
    }

    .heading-desc-cxo {
        top: 270px;
        left: 5px;
    }
}

@media only screen and (min-width: 1700px) and (max-width:1799px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 580px;
    }

    .cxotulz-image-container {
        height: 640px !important;
    }

    .heading-desc-cxo {
        top: 270px;
        left: 5px;
    }
}

@media only screen and (min-width: 1800px) and (max-width:1879px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 620px;
    }

    .cxotulz-image-container {
        height: 674px !important;
    }

    .heading-desc-cxo {
        top: 290px;
        left: 15px;
    }
}

@media only screen and (min-width: 1880px) and (max-width:1920px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        top: 645px;
        left: 120px;
    }

    .heading-desc-cxo {
        top: 300px;
        left: 15px;
    }
}

@media only screen and (min-width: 1921px) and (max-width:2500px) {
    .breadcrumb-cxo {
        font-size: 1.8rem;
        font-weight: 500;
        left: 125px;
    }
}

@media screen and (min-width:2500px) and (max-width: 2500px) {
    .heading-desc-cxo {
        font-size: 2.2rem;
        line-height: 2.8rem;
        top: 395px;
        width: 45%;
        left: 70px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        left: 170px !important;
    }
}

@media screen and (min-width:2501px) and (max-width: 2599px) {
    .heading-desc-cxo {
        font-size: 2.2rem;
        line-height: 2.8rem;
        top: 400px;
        width: 45%;
        left: 15px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        left: 170px !important;
    }
}

@media only screen and (min-width: 2300px) {
    .breadcrumb-cxo {
        font-size: 2.5rem;
        font-weight: 500;
        padding-bottom: 15px;
        left: 150px;
    }
}

@media only screen and (min-width: 2600px) {
    .breadcrumb-cxo {
        font-size: 2.7rem;
        font-weight: 500;
        padding-bottom: 10px;
    }
}

@media screen and (min-width:2600px) and (max-width: 2800px) {
    .heading-desc-cxo {
        font-size: 2.2rem;
        line-height: 2.8rem;
        top: 410px;
        width: 50%;
        left: 15px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }
}

@media screen and (min-width:2600px) and (max-width: 2700px) {
    .heading-desc-cxo {
        font-size: 2.2rem;
        line-height: 2.8rem;
        top: 420px;
        width: 50%;
        left: 15px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        left: 170px;
    }
}

@media screen and (min-width:2701px) and (max-width: 2800px) {
    .heading-desc-cxo {
        font-size: 2.2rem;
        line-height: 2.8rem;
        top: 445px;
        width: 50%;
        left: 15px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        left: 170px;
    }
}

@media screen and (min-width: 3300px) {
    .heading-desc-cxo {
        top: 555px !important;
    }
}

@media screen and (min-width: 3000px) {
    .heading-desc-cxo {
        font-size: 2.5rem;
        line-height: 3.2rem;
        top: 495px;
    }

    .cxotulz-image-container {
        height: 700px !important;
        padding-bottom: 38% !important;
    }

    .breadcrumb-cxo {
        font-size: 2.9rem;
        font-weight: 500;
        left: 200px;
    }
}

@media screen and (max-width:768px) {
    .cxo-content-container {
        padding: 0vh 9vw;
    }
}