@import '../../../../shared/assets/css/variables';
@import '../../../../shared/assets/css/mixins.scss';

.page-container-MobileApp {
    padding-left: 10%;
    @include font;
    background-color: $background-color;

    .main-container-services {

        & .card-services {
            height: 100%;
        }
    }

}   
@media screen and (max-width:767px) {
    .page-container-MobileApp{
        padding-left: 0%;
    }
}