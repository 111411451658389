@import '../../shared/assets/css/mixins.scss';
@import '../../shared/assets/css/_variables.scss';
@import '../../shared/assets/css/common.scss';

.sidebar {
    @include flex-column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 10%;
    background-color: $white;
    padding: 1% 1% 0px;
    padding-top: 0%;
    box-shadow: 4px 0 12px $black-shadow;
    text-align: center;
    justify-content: space-between;
    z-index: 1000;
}

.link-text-decoration {
    text-decoration: none !important;
}

.sidebar_item_mobview {
    font-size: 1rem;
    text-decoration: none;
    font-weight: bold;
    color: $black;
    padding-top: 0.8vh;
    padding-bottom: 0.8vh !important;
}

.items {
    padding-top: 7vh;
}

.sidebar_item {
    @include font;
    position: relative;
    font-size: 0.9rem;
    text-decoration: underline $dark-gray 1%;
    font-weight: 600;
    padding-bottom: 0%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    z-index: 1000;
    color: black;
}

.sidebar_item_option {
    font-size: 0.8rem;
    font-weight: 400;
    color: $black;
    text-decoration: none;
}

.sidebar_item_option:hover {
    color: $primary;
    cursor: pointer;
}

.sidebar_footer {
    @include flex-column;
    background-color: $primary;
    opacity: 0.85;
    margin: 15% -14% 0px;
    bottom: 0;
    position: sticky;
    padding: 13% 0% 13%;
    justify-content: space-around;
    align-items: center;
}

.wmp-img-container {
    @include flex-row;
    @include height-fit-content;
    width: 100%;
    position: sticky;
    top: auto;
    justify-content: space-between;
}

.nav-pd {
    padding-bottom: 4vh;
}

.hamburger-icon-container {
    width: 100%;
    @include position-rel;
    @include height-fit-content;
    top: 4.5%;

    & img {
        width: 50%;
    }
}

.li-separator {
    width: 20px;
    border-color: $primary;
    opacity: 0.7;
    margin-left: auto;
    margin-right: auto;
}

.options-separator {
    width: 95%;
    border-color: $dark-gray;
    opacity: 1.5;
}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid $primary;
    left: 89%;
    position: absolute;
    translate: 0px -82%;
}

.options {
    padding: 10%;
    padding-right: 15%;
    width: fit-content;
    border: 1px solid $primary;
    border-radius: 10px;
    left: 100%;
    position: absolute;
    translate: 0px -60%;
    background-color: $white;
    text-align: left;
    box-shadow: 4px 4px 12px $black-shadow;
    z-index: 1000;

    & a {
        text-decoration: none;
        color: black;

        & :hover {
            color: $primary;
        }
    }
}

.sidebar_footer_img {
    height: 50%;
    width: 50%;
    margin: 6px;
    border-radius: 0%;
}

.separator {
    position: absolute;
    top: -10%;
    width: 90px;
    height: 10px;
    background-color: $secondary;
    border-color: $secondary;
    opacity: 1;
    z-index: 1000;
}

.dropdown-arrow {
    right: 4%;
    position: absolute;
    padding: 25px;
}

#sidebarItem1 #sidebarOptions1,
#sidebarItem2 #sidebarOptions2,
#sidebarItem3 #sidebarOptions3,
#sidebarItem4 #sidebarOptions4 {
    display: none;
}
@media screen and (min-width:312px) and (max-width:370px) {
    .sidebar{
        .options{
            .line-height-0{
                line-height: 1.5rem;
                padding-bottom: 6%;
                padding-top: 5%;
            }

        }
    }

}
@media screen and (min-width:601px) and (max-width:767px) {
    .sidebar_footer {
        column-gap: 3vw !important;
        margin-left:0;
        padding-left: 10px;
        margin-right: 35px !important;
    }
}
@media screen and (min-width:501px) and (max-width:600px) {
    .sidebar_footer {
        column-gap: 6vw !important;
        margin-left:0;
        padding-left: 10px;
        margin-right: 45px !important;
    }
}
@media screen and (min-width:312px) and (max-width:500px) {
    .sidebar_footer {
        column-gap: 9vw !important;
        margin-left:0;
        padding-left: 10px;
        margin-right: 45px !important;
    }
}

// Media query starts from here
@media screen and (min-width:767px) and (max-width:850px) {
    .sidebar {
        padding: 0;
    }

    .sidebar_footer {
        margin-right: 0;
        margin-left: 0;

        & img {
            width: 100%;
        }
    }

    .separator {
        width: 50%;
        top: -12%;
    }
}

@media (max-width: 767px) {
    .sidebar {
        position: inherit;
        padding: 0px;
        box-shadow: none;
        width: 100%;
        height: auto;
    }

    .options {
        padding: 0 30px;
        border: none;
        left: 0;
        position: relative;
        translate: 0px;
        background-color: $background-color;
        text-align: left;
        box-shadow: none;
    }

    .hamburger-icon-container {
        display: none;
    }

    .sidebar_items {
        width: 80%;
        min-height: 90vh;
        margin-left: -15px;
        top: 10%;
        z-index: 9;
        position: absolute;
        text-align: left;
        padding: 0 5%;

        background-color: $background-color;
        animation: mymove 1s;

        & a{
            text-decoration: none;
        }
    }

    .li-separator {
        margin: 0;
        background-color: $dark-gray;
    }

    @keyframes mymove {
        from {
            left: -90%;
        }

        to {
            left: 0px;
        }
    }

    .nav-contact-btn {
        padding-right: 4px;

        & button {
            border: 2px solid $secondary;
            padding: 7px;
            margin-right: 1vw;
            font-size: 0.8rem;
            font-weight: 500;
            border-radius: 4px !important;
            color:black;
        }
    }

    .wmp-img-container {
        @include flex-row;
        height: 8vh;
        width: 100%;
        position: fixed;
        top: 0;
        justify-content: space-between;
        align-items: center;
        background-color: $background-color;
        z-index: 9900;
        box-shadow: 4px 0 12px $black-shadow;

        & .img-size {
            width: 50%;
            margin-left: 5%;
        }

        & .menu-icon-div {
            width: 40px;
        }
    }

    .option-li {
        @include flex-row;
    }

    .sidebar-wrapper {
        box-shadow: 4px 0 2px $black-shadow;
        position: fixed;
        left: 0;
        top: 0vh;
        margin: 0;
        @include flex-column;
        justify-content: space-between;
        z-index: 1000 !important;
        height: 100vh;
        padding-top: 2vh;
    }

    .sidebar_item {
        z-index: 1000;
        font-size: 1.1rem;
        font-weight: 600;
        text-decoration: none;
        line-height: 1.5rem;
    }

    .li-separator {
        width: 100%;
        border-color: $dark-gray;
        opacity: 0.7;
    }

    .sidebar_item_option {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2.5rem
    }

    .sidebar_footer {
        @include flex-row;
        background-color: $background-color;
        opacity: 0.7;
        bottom: 0;
        position: sticky;
        justify-content: center;
        align-items: center;
        column-gap: 3vw;
        margin-left:0;
        margin-right: 0;
    }

    .sidebar_footer_img {
        @include filter-red;
        width: 10%;
        height: 10%;
    }

    .nav-contact-btn {
        align-items: center;

        & .contact-btn {
            font-size: 0.7rem;
            padding: 0.5em;
            font-weight: 500;
            margin: 0;

        }
    }
}

@media (min-width: 768px) {

    #sidebarItem1:hover #sidebarOptions1,
    #sidebarItem2:hover #sidebarOptions2,
    #sidebarItem3:hover #sidebarOptions3,
    #sidebarItem4:hover #sidebarOptions4 {
        display: block;
    }

    .sidebar_item:hover {
        color: $primary;
    }

}

@media screen and (min-width: 1100px) {
    .sidebar_item {
        font-size: 1.2rem;
    }

    .sidebar_item_option {
        font-size: 1rem;
    }

    .separator {
        top: -8%;
    }
    
}

@media screen and (min-width: 1300px) {
    .sidebar_item {
        font-size: 1.2rem;
    }

    .sidebar_item_option {
        font-size: 1rem;
    }

    .separator {
        top: -8%;
    }
}

@media screen and (min-width: 2000px) {
    .sidebar_item {
        font-size: 1.2rem;
    }

    .sidebar_item_option {
        font-size: 1rem;
    }

    .separator {
        top: -8%;
    }
}

@media screen and (min-width: 2400px) {
    .sidebar_item {
        font-size: 1.6rem;
    }

    .sidebar_item_option {
        font-size: 1.3rem;
    }

    .separator {
        top: -8%;
    }
}

@media screen and (min-width: 2800px) {
    .sidebar_item {
        font-size: 1.8rem;
    }

    .sidebar_item_option {
        font-size: 1.4rem;
    }

    .separator {
        top: -8%;
    }

    .sidebar_footer {
        height: 24vh;

        & img {
            width: 50%;
        }
    }
}

@media screen and (min-width: 3200px) {
    .sidebar_item {
        font-size: 2rem;
    }

    .sidebar_item_option {
        font-size: 1.5rem;
    }

    .separator {
        top: -8%;
    }

    .sidebar_footer {
        height: 24vh;

        & img {
            width: 52%;
        }
    }
}

@media screen and (min-width: 3600px) {
    .sidebar_item {
        font-size: 2.2rem;
    }

    .sidebar_item_option {
        font-size: 1.6rem;
    }

    .separator {
        top: -8%;
    }
}