@import "../../../../../shared/assets/css/variables";
@import "../../../../../shared/assets/css/common.scss";

.notched-div {
  background-color: white;
	background-image: -moz-linear-gradient(top, #444, #444);
	position: absolute;
	color: black;
	padding: 7px;
	border-radius: 3px;
	margin: 25px;
	height: 39px;
  width: 150px;
  z-index: 2;
  top: 26px;
	border: 1px solid rgb(83, 80, 80);
}
.notched-div::before {
      content: "";
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
    }


.notched-div.bottom::before {
	left: 5%;
	top: -23px;
  border-bottom: 10px solid #444;
}
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5vh;
  padding-top: 5vh;
  row-gap: 2vh;
  padding-right: 3vw;
  input{
    border: 2px solid $black; 
    width: 100%;
    height: 14%;
    font-size: 1rem;
    border-radius: 5px;
    color:$black;
    transition: 0.3s;
    padding-right: 1vw;
    padding-left: 2.8vw;
    &.gray {
      background-color:$white;
    }
    &.shadow{
      box-shadow: inset 1px 1px 2px $dark-gray;
    }
    &::placeholder {
      color: $black; 
      font-size: 1rem; 
    }
 }
  textarea {
    border: 2px solid $black; 
    width: 100%;
    font-size: 1rem;
    border-radius: 5px;
    color:$black;
    margin: 0 auto;
    transition: 0.3s;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 0.2vw;
    height: 20vh;
    &.gray {
      background-color:$white;
    }
    &::placeholder {
      color: $black; 
      font-size: 1rem; 
    }
}
}
.input-container {
  @include flex-column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  & .contact-input{
    height: 5vh;
  }
  .input-icon {
    position: absolute;
    top: 47%;
    left: 4%;
    width: 4%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .mail-img{
    width: 4%;
  }
  .phone-img{
    width: 3.6%;
  }
}
.contact-btn{
  margin: 0 auto;
  padding: 1vh 1vw 1vh 1vw;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  z-index: 9999;
  border: 2px solid black;
  border-radius: 8px;
  background-color: transparent;
}
.contact-btn:hover{
 background-color: $hover;
 color: $white;
}
.error-popup {
  width:32%;
  position: absolute;
  top: -6vh;
  left: 81%;
  transform: translateX(-50%);
  background-color:$light-orange ; /* Light red background color */
  color:$red; /* Dark red text color */
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px $black-shadow;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 98%;
    left: 100%;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent $red transparent; 
  }
}
.input-container {
  position: relative;
}
@media screen and (min-width: 1101px) and (max-width: 1400px) {
  .contact-form {
    padding-left: 0vh;
    padding-top: 2vh;
    row-gap: 2vh;
    padding-right: 2vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .contact-form {
    padding-left: 0vh;
    padding-top: 2vh;
    row-gap: 2vh;
    padding-right: 1vw;
    input{
      font-size: 0.8rem;
      padding-right: 1vw;
      padding-left: 2.8vw;
      &::placeholder {
        color: $black; 
        font-size: 0.8rem; 
      }
   }
    textarea {
      border: 2px solid $black; 
      width: 100%;
      font-size: 0.8rem;
      border-radius: 5px;
      color:$black;
      margin: 0 auto;
      transition: 0.3s;
      padding-left: 1vw;
      padding-right: 1vw;
      padding-top: 0.2vw;
      height: 18vh;
      &.gray {
        background-color:$white;
      }
      &::placeholder {
        color: $black; 
        font-size: 0.8rem; 
      }
  }
  }
  .input-container {  
    & .contact-input{
      height: 4vh;
    }
    .input-icon {
      position: absolute;
      top: 47%;
      left: 4%;
      width: 4%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    .mail-img{
      width: 4%;
    }
    .phone-img{
      width: 3.6%;
    }
  }
  .contact-btn{
    margin: 0 auto;
    padding: 1vh 1vw 1vh 1vw;
    font-size: 1rem;
    font-weight: 500;
    z-index: 9999;
  }
  
  .error-popup {
    width:32%;
    position: absolute;
    top: -6vh;
    left: 81%;
    transform: translateX(-50%);
    background-color:$light-orange ; /* Light red background color */
    color:$red; /* Dark red text color */
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px $black-shadow;
    z-index: 1;
  
    &::before {
      content: '';
      position: absolute;
      top: 98%;
      left: 100%;
      margin-left: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent $red transparent; 
    }
  }
}

@media screen and (min-width: 2800px) {
  .contact-form {
    // padding-left: 0vh;
    padding-top: 4vh;
    row-gap: 2vh;
    padding-right: 1vw;

    input {
      font-size: 2rem;
      padding-right: 1vw;
      padding-left: 2.8vw;

      &::placeholder {
        color: $black;
        font-size: 1.6rem;
      }
    }

    textarea {
      border: 2px solid $black;
      width: 100%;
      font-size: 2rem;
      border-radius: 5px;
      color: $black;
      margin: 0 auto;
      transition: 0.3s;
      padding-left: 1vw;
      padding-right: 1vw;
      padding-top: 0.2vw;
      height: 18vh;

      &.gray {
        background-color: $white;
      }

      &::placeholder {
        color: $black;
        font-size: 1.6rem;
      }
    }
  }

  .input-container {
    & .contact-input {
      height: 6vh;
    }

    .input-icon {
      position: absolute;
      top: 47%;
      left: 4%;
      width: 4%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .mail-img {
      width: 4%;
    }

    .phone-img {
      width: 3.6%;
    }
  }

  .contact-btn {
    margin: 19px auto;
    padding: 1vh 1vw 1vh 1vw;
    font-size: 1.7rem;
    font-weight: 500;
    z-index: 9999;
  }

  .error-popup {
    width: 32%;
    position: absolute;
    top: -6vh;
    left: 81%;
    transform: translateX(-50%);
    background-color: $light-orange ;
    /* Light red background color */
    color: $red;
    /* Dark red text color */
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px $black-shadow;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 98%;
      left: 100%;
      margin-left: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent $red transparent;
    }
  }
}

@media screen and (min-width: 3400px) {
  .contact-form {
    // padding-left: 0vh;
    padding-top: 4vh;
    row-gap: 2vh;
    padding-right: 1vw;

    input {
      font-size: 2.2rem;
      padding-right: 1vw;
      padding-left: 2.8vw;

      &::placeholder {
        color: $black;
        font-size: 1.9rem;
      }
    }

    textarea {
      border: 2px solid $black;
      width: 100%;
      font-size: 2.2rem;
      border-radius: 5px;
      color: $black;
      margin: 0 auto;
      transition: 0.3s;
      padding-left: 1vw;
      padding-right: 1vw;
      padding-top: 0.2vw;
      height: 18vh;

      &.gray {
        background-color: $white;
      }

      &::placeholder {
        color: $black;
        font-size: 1.9rem;
      }
    }
  }

  .input-container {
    & .contact-input {
      height: 5.6vh;
    }

    .input-icon {
      position: absolute;
      top: 47%;
      left: 4%;
      width: 4%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .mail-img {
      width: 4%;
    }

    .phone-img {
      width: 3.6%;
    }
  }

  .contact-btn {
    margin: 19px auto;
    padding: 1vh 1vw 1vh 1vw;
    font-size: 2rem;
    font-weight: 500;
    z-index: 9999;
  }

  .error-popup {
    width: 32%;
    position: absolute;
    top: -3vh;
    left: 81%;
    font-size: 1.6rem;
    transform: translateX(-50%);
    background-color: $light-orange ;
    /* Light red background color */
    color: $red;
    /* Dark red text color */
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px $black-shadow;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 98%;
      left: 100%;
      margin-left: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent $red transparent;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .contact-form {
    padding-left: 2vh;
    padding-top: 1vh;
    row-gap: 1.5vh;
    padding-right: 2vh;
    input{
      width: 100%;
      height: 14%;
      padding-right: 1vw;
      padding-left: 10.5vw;
      margin: 0 auto;
    }
    textarea {
      width: 100%;
      padding-left: 2vw;
      padding-right: 1vw;
  }
  }
  .input-container {
    @include flex-column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  
    & .contact-input{
      height: 8vh;
    }
    .input-icon {
      position: absolute;
      top: 47%;
      left: 4%;
      width: 5%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    .mail-img{
      width: 5%;
    }
    .phone-img{
      width: 4.2%;
    }
  }
  .contact-btn{
    margin: 0 auto;
    padding: 1vh 2vw 1vh 2vw;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 1vh;
    z-index: 999;
  }
  
  .error-popup {
    width:32%;
    top: -6.6vh;
    left: 81%;   
  }
}  

@media screen and (min-width: 312px) and (max-width: 480px) {
  .contact-form {
    padding-left: 2vh;
    padding-top: 1vh;
    row-gap: 1.5vh;
    padding-right: 2vh;
    input{
      width: 100%;
      height: 14%;
      padding-right: 1vw;
      padding-left: 10.5vw;
      margin: 0 auto;
    }
    textarea {
      width: 100%;
      padding-left: 2vw;
      padding-right: 1vw;
  }
  }
  .input-container {
    @include flex-column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  
    & .contact-input{
      height: 5vh;
    }
    .input-icon {
      position: absolute;
      top: 47%;
      left: 4%;
      width: 5%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    .mail-img{
      width: 5%;
    }
    .phone-img{
      width: 4.2%;
    }
  }
  .contact-btn{
    margin: 0 auto;
    padding: 1vh 2vw 1vh 2vw;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 1vh;
    color: black;
    width:fit-content;
    border: 2px solid $hover;
    z-index: 999;
  }
  
  .error-popup {
    width:38%;
    top: -6.6vh;
    left: 78%;   
  }
}  

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}